import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import AlertStore from "common/store/alert";
import { getMixpanel } from "common/api";
import { StyleButton } from "./includes/NewFeature.style";

interface INewFeatureBanner {
  store?: AlertStore;
}

const NewFeatureBannerComponent = ({ store }: INewFeatureBanner): JSX.Element | null => {
  React.useEffect(() => {
    store!.getAlerts();
  }, []);

  const handleButtonClick = (text) => {
    getMixpanel(store!.parent).track("Suitcases > New Feature Banner", { Feature: text });
  };

  if (store!.alerts.length === 0) {
    return null;
  };

  return (
    <>
      {
        store!.alerts.map((alert) => {
          const { id, text, link_title, link } = alert;

          return (
            <div key={id} className="bg-info mb-3 mx-auto rounded-3 py-3 px-4 d-flex justify-content-between align-items-center text-white" style={{width: "940px"}}>
              <p className="fs-1125 mr-3 mb-0">
                {text}
              </p>
              <Link to={{ pathname: link }} target="_blank" onClick={() => handleButtonClick(text)}>
                <StyleButton inverted style={{ width: 130 }}>
                  {link_title}
                </StyleButton>
              </Link>
            </div>
          );
        })
      }
    </>
  );
};

export const NewFeatureBanner = inject((stores: any) => ({
  store: stores.store.alert,
}))(observer(NewFeatureBannerComponent));
