import styled from "styled-components";
import { $darkgrey, $opensans } from "common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

export const StyleBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
`;

export const StyleBox = styled.div<{ highlight?: string }>`
  vertical-align: top;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 200px;
  margin: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  cursor: pointer;
  padding: 20px;
  font-family: ${$opensans};
  position: relative;
  z-index: 3;
  width: 100%;
  @media(min-width: 576px) {
    width: 46%;
  }
  @media(min-width: 992px) {
    width: 31.2%;
  }
  ${p => (p.highlight === "true" ? `border: 1px solid ${COLORS.indigo600}` : null)}
}
`;

export const StyleBoxHeading = styled.h1`
  font-family: ${$opensans};
  font-size: 18px;
  color: ${COLORS.indigo600};
  margin-bottom: 8px;
`;

export const StyleBoxText = styled.p`
  font-family: ${$opensans};
  font-size: 16px;
  font-weight: normal;
  color: ${$darkgrey};
  display: inline-block;
  margin-right: 5px;
`;

export const StyleBoxTextSmall = styled.p`
  font-family: ${$opensans};
  font-size: 12px;
  font-weight: normal;
  color: ${$darkgrey};
  margin-bottom: 0px;
`;

export const StyleBoxInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${$darkgrey};
  .UserAvatar {
    cursor: pointer !important;
  };
`;
