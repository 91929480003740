/* eslint-disable */

export const toolName = "seer_unlayer_customToolFilters";

// add this to unlayer init tools config when needed
export const filterToolConfig = {
  [`custom#${toolName}`]: {
    properties: {
      /*
      insightColumns: {
        editor: {
          data: {
            options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
            ],
          },
        },
      },
      */
    },
  },
};

// adds event lister to editor instance for checking if row columns was updated to force refresh embedded insights for height update in editor
export const designUpdateInsightRefreshHandler = editorInstance => {
  editorInstance.addEventListener("design:updated", data => {
    // unlayer does not fire design:updated on column resize
    if (data?.type === "row:modified" && !data.changes) {
      // when number of columns changes the changes property is absent, so we can use this to avoid firing on every row property change
    }
  });
};

export default () => {
  return `
    var toolName = "${toolName}";

    if (window["unlayer"] && !window[toolName]) {
      console.log("registering ${toolName}");

      window[toolName + "nodeCount"] = 0; // to keep track of node ids for messaging between iframe and seer app

      unlayer.registerPropertyEditor({
        name: toolName,
        layout: 'bottom',
        Widget: unlayer.createWidget({
          render(value, updateValue, data) {
            const filterData = value ? JSON.parse(value) : null;
            const { variable, categories } = filterData || {};
            return \`
              <div>
                <p style="font-weight: bold; margin: 0 0 5px 0; font-size: 12px; color: #8F9699;">Filter Variable</p>
                $\{!variable ? (
                  \`<p>None selected.</p>\`
                ) : (\`
                  <p style="font-weight: 600; color: #5f2d5f;">$\{variable}</p>
                \`)}
                <button type="button" class="picker btn btn-primary btn-sm">
                  $\{!variable ? "Select" : "Change"} Filter
                </button>
              </div>
            \`;
          },
          mount(node, value, updateValue, data) {
            // set nodeId and update window count
            var nodeId = window[toolName + "nodeCount"] + 1;
            window[toolName + "nodeCount"] = window[toolName + "nodeCount"] + 1;

            // add listener for event updates from embeds parent window
            window.addEventListener("message", (event) => {
              if (event.origin === "${window.origin}" && event.data?.nodeId === nodeId) {
                updateValue(JSON.stringify(event.data.data));
              }
            }, false);

            // send message to parent window to trigger filter modal
            var pickerBtn = node.querySelector('.picker');
            pickerBtn.onclick = function () {
              window.parent.postMessage({ nodeId, toolName }, "${window.origin}");
            };
          },
        }),
      });

      unlayer.registerTool({
        name: toolName,
        label: 'Data Filter',
        icon: 'fa-filter',
        supportedDisplayModes: ['web', 'email'],
        options: {
          default: {
            title: null,
          },
          insight: {
            title: 'Data Filter',
            position: 1,
            options: {
              filterOption: {
                label: 'No Label',
                defaultValue: '',
                widget: toolName, // custom property editor
              },
              /*
              // this is where we will have theme and other static content later
              insightColumns: {
                label: 'Charts Per Row (Multi-Chart Only)',
                defaultValue: '1',
                widget: "dropdown",
              },
              hideLegend: {
                label: 'Hide Chart Legend',
                defaultValue: true,
                widget: "toggle",
              },
              */
            },
          },
        },
        values: {},
        renderer: {
          Viewer: unlayer.createViewer({
            render(values) {
              const { filterOption } = values;
              const filterData = filterOption ? JSON.parse(filterOption) : null;
              const { variable, categories, filterId } = filterData || {};
              if (!filterData) {
                return \`<div style="border: 1px dashed #5f2d5f; color: #5f2d5f; height: 150px; display: flex; align-items: center; justify-content: center; background: rgba(205, 146, 205, 0.2); font-size: 13px; font-family: 'Open Sans'; padding: 20px;">No filter variable selected. Click here and Select a filter from the right panel.</div>\`;
              }
              return \`
                <div style="font-size: 14px" class="custom-tool-data-filter">
                  <div style="display: inline-block; min-height: 1em; vertical-align: baseline; background: #e0e1e2 none; color: rgba(0,0,0,.6); margin: 0 .25em 0 0; padding: .78571429em 1.5em .78571429em; font-weight: 700; line-height: 1em; text-align: center; border-radius: 0.285rem; font-family: 'Lato', sans-serif; white-space: nowrap;">
                    $\{variable}
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="filter" class="svg-inline--fa fa-filter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"></path></svg>
                  </div>
                  <div style="display: none">
                    <select name="data_filter_$\{filterId}" data-seer-variable="$\{variable}" multiple disabled>
                      $\{categories.map(cat => \`<option value="$\{cat}">$\{cat}</option>\`).join("")}
                    </select>
                  </div>
                </div>
              \`;
            },
          }),
          exporters: {
            web: function (values) {
              const { filterOption } = values;
              const filterData = filterOption ? JSON.parse(filterOption) : null;
              const { variable, categories, filterId } = filterData || {};
              if (!filterData) {
                return \`<div style="border: 1px dashed #5f2d5f; color: #5f2d5f; height: 150px; display: flex; align-items: center; justify-content: center; background: rgba(205, 146, 205, 0.2); font-size: 13px; font-family: 'Open Sans'; padding: 20px;">No filter variable selected. Click here and Select a filter from the right panel.</div>\`;
              }
              return \`
                <div style="font-size: 14px" class="custom-tool-data-filter">
                  <div style="display: inline-block; min-height: 1em; vertical-align: baseline; background: #e0e1e2 none; color: rgba(0,0,0,.6); margin: 0 .25em 0 0; padding: .78571429em 1.5em .78571429em; font-weight: 700; line-height: 1em; text-align: center; border-radius: 0.285rem; font-family: 'Lato', sans-serif; white-space: nowrap;">
                    $\{variable}
                    <i class="filter icon"></i>
                  </div>
                  <div style="display: none">
                    <select name="data_filter_$\{filterId}" data-seer-variable="$\{variable}" multiple disabled>
                      $\{categories.map(cat => \`<option value="$\{cat}">$\{cat}</option>\`).join("")}
                    </select>
                  </div>
                </div>
              \`;
            },
            email: function (values) {
              return \`<p>N/A</p>\`;
            },
          },
          head: {
            css: function (values) {},
            js: function (values) {},
          },
        },
      });
      window[toolName] = true; // so we don't register more than once per load
    }
  `;
}
