import * as React from "react";
import styled from "styled-components";
import { Message, MessageProps } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

// @TODO - stuff for pass position/colour as prop

const StyledMessage = styled(Message)`
  &.ui.compact.icon.message {
    z-index: 10;
    position: fixed !important;
    top: 10%;
    right: 2rem;
    background: ${COLORS.aqua200};
    color: ${COLORS.aqua500};
    border-radius: 0.5rem;
    border: 1px solid ${COLORS.aqua500};
    font-weight: 700;
    width: auto !important;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    box-shadow: none;
    opacity: 0;
    transition: opacity 0.3s;
  }
  &.ui.compact.icon.message.show {
    opacity: 1;
  }
  &.ui.icon.message>.icon:not(.close){
    font-size: 1rem !important;
  }
`;

interface Props extends MessageProps {
  duration: number; // ms
}

export const SemanticToast = (props: Props): JSX.Element => {
  const { duration, ...rest } = props;
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    setVisible(true);
    const timeout = setTimeout(() => setVisible(false), duration);
    return () => clearTimeout(timeout);
  }, []);
  return <StyledMessage {...rest} className={`${visible ? "show" : ""} ${rest.className || ""}`} />;
};
