import styled from "styled-components";
import { Button, Label } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const StyleButtonGroup = styled(Button.Group)`
  &.ui.buttons .button:hover {
    color: #ffffff !important;
    background: ${COLORS.indigo600} !important;
  };
`;

export const StylePillButton = styled(Button)<{ active?: boolean }>`
  font-family: "Open Sans" !important;
  color: ${props => (props.active ? "#ffffff" : COLORS.indigo600)} !important;
  border: 1px solid ${COLORS.indigo600} !important;
  background: ${props => (props.active ? COLORS.indigo600 : "none")} !important;
  border-radius: 20px !important;
  transition: 0.5s !important;
  width: "fit-content";
`;

export const StylePillLabel = styled(Label)<{ bg_color: string; text_color: string }>`
  color: ${props => props.bg_color} !important;
  font-size: 11px !important;
  border-radius: 20px !important;
  transition: 0.5s !important;
  &.ui.circular.label {
    padding: 11px 20px !important;
  };
  &.ui.label {
    background-color:  ${props => props.text_color} !important;
  };
`;
