import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { datasets as requestDataApi, getMixpanel } from "common/api";
import { Icon } from "semantic-ui-react";
import Protected from "component/protected";
import { ls } from "common/helpers/storage";
import { SelectWidget } from "./SelectWidget";
import { InsightsList } from "./InsightsList";
import { ContentPlaceholder } from "component/ContentPlaceholder";

interface ISmartInsights {
  store?: Store;
  favouriteStore?: any;
  clickInsightHandler: (insight) => Promise<void>;
  unsaveInsightHandler: (id) => Promise<void>;
}

const QueryToolComponent = (props: ISmartInsights): JSX.Element => {
  const { store, favouriteStore, clickInsightHandler, unsaveInsightHandler } = props;
  const [insights, setInsights] = React.useState<any>(null);
  const [isInsightsLoading, setIsInsightsLoading] = React.useState(false);
  const [locationVariable, setLocationVariable] = React.useState("");
  const [locationCategory, setLocationCategory] = React.useState("");
  const [error, setError] = React.useState("");

  const onSubmit = async (values) => {
    try {
      const { variable, category, interestingness, numberOfInsights } = values;
      setLocationVariable(variable?.value || "");
      setLocationCategory(category?.value || "");
      setIsInsightsLoading(true);
      const res: any = await requestDataApi.post("v2/smart-insights", {
        "location_type": variable?.value,
        "location_name": category?.value,
        "degree": Math.min(interestingness / 100, 0.99),
        "size": numberOfInsights.value,
      }, store!.token ?? "");
      if (res?.body?.data) {
        setError("");
        setInsights(res.body.data.smart_insights);
        getMixpanel(store!).track("Generate Smart Insights", {
          "Location Variable": variable?.value,
          "Location Category": category?.value,
          "Interestingness": interestingness,
          "Number of Insights": numberOfInsights.value,
        });
        ls.setItem("smartInsightsQuery", JSON.stringify({ variable, category, interestingness, numberOfInsights }));
        ls.setItem("smartInsightsResults", JSON.stringify(res.body.data.smart_insights));
      }
      setIsInsightsLoading(false);
    } catch (_) {
      setError("We are having some issues. Please try again later.");
    };
  };

  const generateWholeInsight = (insight, locationVariable, locationCategory) => ({ ...insight, [locationVariable]: locationCategory });

  const saveSmartInsight = async (insight) => {
    const json = generateWholeInsight(insight, locationVariable, locationCategory);
    getMixpanel(store!).track("Save Smart Insight", { json });
    return await favouriteStore.saveFavourite("smart-insight", json);
  };

  React.useEffect(() => {
    const smartInsightsResults = JSON.parse(ls.getItem("smartInsightsResults"));
    if (smartInsightsResults) {
      setInsights(smartInsightsResults);
    }
    const smartInsightsQuery = JSON.parse(ls.getItem("smartInsightsQuery"));
    if (smartInsightsQuery) {
      setLocationVariable(smartInsightsQuery.variable.value);
      setLocationCategory(smartInsightsQuery.category.value);
    }
  }, []);

  return (
    <>
      <SelectWidget onSubmit={onSubmit} />
      {error ? (
        <div className="mt-4 d-flex">
          <Icon name="info circle" />
          <p className="text-muted fs-1125">{error}</p>
        </div>
      ) : (
        <>
          {isInsightsLoading ? <ContentPlaceholder /> : (
            <>
              {insights && (
                <>
                  {insights.length === 0 ? (
                    <div className="mt-4 d-flex">
                      <Icon name="info circle" />
                      <p className="text-muted fs-1125">
                        No results for this location. Please change the location and try again.
                      </p>
                    </div>
                  ) : (
                    <InsightsList
                      insights={insights}
                      globalLocationVariable={locationVariable}
                      clickInsightHandler={(insight) => clickInsightHandler(generateWholeInsight(insight, locationVariable, locationCategory))}
                      saveClickHandler={(insight) => saveSmartInsight(insight)}
                      unsaveClickHandler={(id) => unsaveInsightHandler(id)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export const QueryTool = Protected(inject((stores: any) => ({
  store: stores.store,
  favouriteStore: stores.store.favourite,
}))(observer(QueryToolComponent)));
