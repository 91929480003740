import { cloneDeep, find } from "lodash";
import { required, composeValidators, mustBeNumber, validDate } from "common/helpers/finalForm";
import { FieldInput } from "./FieldInput";
import { FieldSelect } from "./FieldSelect";
import {
  Schema,
  AggregationCategory,
  AggAuto,
  AggAutoYearMonth,
  AggAutoYearQuarter,
  AggAutoYear,
  monthNumber,
  quarterNumber,
  months,
  quarters,
  Aggregation,
  getVariableCombinations,
  monthToQuarter,
} from "common/helpers/dataset";
import { ObjectAny } from "common/helpers/types";
import { pad } from "common/helpers/string";

// must have "Measured quantity" variable
// must have at least 2 variables set
// must have at least 1 category in each variable
export const validateSchema = (schema: Schema): string | void => {
  const allVariables = [...schema.rows, ...schema.columns];
  if (allVariables.length < 2) {
    return "At least 2 Variables must be set";
  }
  const hasMeasuredQuantityVariable = allVariables.filter(variableData => variableData.variable === "Measured quantity").length === 1;
  if (!hasMeasuredQuantityVariable) {
    return "\"Measured quantity\" Variable must be set on the schema";
  }
  const variablesWithNoCategories = allVariables.map(variable => !!variable.categories.length).filter(hasCategories => !hasCategories).length;
  if (variablesWithNoCategories) {
    return "All Variables must have at least 1 category";
  }
  return;
};

export const generateYearQuarters = (fromYear: number, fromQuarter: quarterNumber, toYear: number, toQuarter: quarterNumber): string[] => {
  const options: string[] = [];
  let currentQuarter: number = fromQuarter;
  for (let _Year = fromYear; _Year <= toYear; _Year++) {
    for (let _Quarter = currentQuarter; _Quarter < 4; _Quarter++) {
      options.push(`${_Year} ${quarters[_Quarter]}`);
      if (_Year === toYear && currentQuarter === toQuarter) {
        break;
      }
      currentQuarter = (currentQuarter + 1) % 4;
    }
  }
  return options;
};

export const generateYearMonths = (fromYear: number, fromMonth: monthNumber, toYear: number, toMonth: monthNumber): string[] => {
  const options: string[] = [];
  let currentMonth: number = fromMonth;
  for (let _Year = fromYear; _Year <= toYear; _Year++) {
    for (let _Month = currentMonth; _Month < 12; _Month++) {
      options.push(`${_Year} ${months[_Month]}`);
      if (_Year === toYear && currentMonth === toMonth) {
        break;
      }
      currentMonth = (currentMonth + 1) % 12;
    }
  }
  return options;
};

export const generateYears = (fromYear: number, toYear: number): string[] => {
  const options: string[] = [];
  for (let _Year = fromYear; _Year <= toYear; _Year++) {
    options.push(`${_Year}`);
  }
  return options;
};

export const generateYearsFinancial = (fromYear: number, toYear: number): string[] => {
  const options: string[] = [];
  for (let _Year = fromYear; _Year <= toYear; _Year++) {
    options.push(`${_Year}-${(_Year + 1).toString().slice(-2)}`);
  }
  return options;
};

const pad2 = pad(2); // pad 2 digits numbers with 0
const formatDateString = (date: Date): string => `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(date.getDate())}`;

export const generateDates = (fromDate: string, toDate: string): string[] => {
  const dates: string[] = [];
  const next = new Date(fromDate);
  const end = new Date(toDate);
  while (next.getTime() <= end.getTime()) {
    dates.push(formatDateString(next));
    next.setDate(next.getDate() + 1);
  }
  return dates;
};

export const generateWeeks = (fromDate: string, toDate: string): string[] => {
  const dates: string[] = [];
  const next = new Date(fromDate);
  const end = new Date(toDate);
  while (next.getTime() <= end.getTime()) {
    dates.push(formatDateString(next));
    next.setDate(next.getDate() + 7);
  }
  return dates;
};

// @TODO - move this to a more appropriate common helper for better reuse
interface SemanticSelectOpt {
  key: string;
  text: string;
  value: any;
}
export const genSemanticSelectOptions = (arr: string[]): SemanticSelectOpt[] => arr.map(str => ({ key: str, text: str, value: str }));

// generator config for use on form
const quarterOptions = quarters.map((text, idx) => ({ key: `${idx}`, value: `${idx}`, text }));
const monthOptions = months.map((text, idx) => ({ key: `${idx}`, value: `${idx}`, text }));
const requiredNumberValidator = composeValidators(required, mustBeNumber);
const requiredDateValidator = composeValidators(required, validDate);
const fromYearArg = {
  name: "fromYear",
  label: "From Year",
  component: FieldInput,
  validate: requiredNumberValidator,
};
const toYearArg = {
  ...fromYearArg,
  name: "toYear",
  label: "To Year",
};
const fromQuarterArg = {
  name: "fromQuarter",
  label: "From Quarter",
  validate: requiredNumberValidator,
  component: FieldSelect,
  options: quarterOptions,
};
const toQuarterArg = {
  ...fromQuarterArg,
  name: "toQuarter",
  label: "To Quarter",
};
const fromMonthArg = {
  name: "fromMonth",
  label: "From Month",
  validate: requiredNumberValidator,
  component: FieldSelect,
  options: monthOptions,
};
const toMonthArg = {
  ...fromMonthArg,
  name: "toMonth",
  label: "To Month",
};
const fromDateArg = {
  name: "fromDate",
  label: "From Date",
  component: FieldInput,
  type: "date",
  validate: requiredDateValidator,
};
const toDateArg = {
  ...fromDateArg,
  name: "toDate",
  label: "To Date",
};
const fromDateArgWeeks = {
  ...fromDateArg,
  helperText: "This date also determines the day of each week generated",
};
const toDateArgWeeks = {
  ...toDateArg,
  helperText: "Weeks will be generated up to this date",
};
const yearValidator = (values: ObjectAny): ObjectAny | null => {
  const { fromYear, toYear } = values;
  if (Number(fromYear) > Number(toYear)) {
    return { fromYear: "\"From Year\" cannot be after \"To Year\"" };
  }
  return null;
};
const dateValidator = (values: ObjectAny): ObjectAny | null => {
  const { fromDate, toDate } = values;
  if (new Date(fromDate).getTime() > new Date(toDate).getTime()) {
    return { fromDate: "\"From Date\" cannot be after \"To Date\"" };
  }
  return null;
};
const transformToNumber = (val: string): number => Number(val);
export const generators = {
  "Year Quarter": {
    arguments: [fromYearArg, fromQuarterArg, toYearArg, toQuarterArg],
    generatorFunction: generateYearQuarters,
    submitValidator: (values: ObjectAny): ObjectAny | null => {
      const { fromYear, toYear, fromQuarter, toQuarter } = values;
      if (Number(fromYear) > Number(toYear)) {
        return { fromYear: "\"From Year\" cannot be after \"To Year\"" };
      } else if (Number(fromYear) === Number(toYear) && Number(fromQuarter) > Number(toQuarter)) {
        return { fromQuarter: "\"From Quarter\" for a single Year cannot be after \"To Quarter\"" };
      }
      return null;
    },
    valueTransform: transformToNumber,
  },
  "Year Month": {
    arguments: [fromYearArg, fromMonthArg, toYearArg, toMonthArg],
    generatorFunction: generateYearMonths,
    submitValidator: (values: ObjectAny): ObjectAny | null => {
      const { fromYear, toYear, fromMonth, toMonth } = values;
      if (Number(fromYear) > Number(toYear)) {
        return { fromYear: "\"From Year\" cannot be after \"To Year\"" };
      } else if (Number(fromYear) === Number(toYear) && Number(fromMonth) > Number(toMonth)) {
        return { fromMonth: "\"From Month\" for a single Year cannot be after \"To Month\"" };
      }
      return null;
    },
    valueTransform: transformToNumber,
  },
  "Year": {
    arguments: [fromYearArg, toYearArg],
    generatorFunction: generateYears,
    submitValidator: yearValidator,
    valueTransform: transformToNumber,
  },
  "Financial Year": {
    arguments: [fromYearArg, toYearArg],
    generatorFunction: generateYearsFinancial,
    submitValidator: yearValidator,
    valueTransform: transformToNumber,
  },
  "Date": {
    arguments: [fromDateArg, toDateArg],
    generatorFunction: generateDates,
    submitValidator: dateValidator,
  },
  "Week Starting": {
    arguments: [fromDateArgWeeks, toDateArgWeeks],
    generatorFunction: generateWeeks,
    submitValidator: dateValidator,
  },
  "Week Ending": {
    arguments: [fromDateArgWeeks, toDateArgWeeks],
    generatorFunction: generateWeeks,
    submitValidator: dateValidator,
  },
};

export const getSchemaVariables = (schema: Schema): string[] => [...schema.rows, ...schema.columns].map(item => item.variable);

// currently supported aggregate variables (we have to restrict to these because we aren't currently allowing manual aggregation configuration)
export const supportedAggregateVariables = ["Date", "Year Month", "Year Quarter"];
export const canAggregateOnSchema = (schema: Schema): boolean =>
  !validateSchema(schema) && getSchemaVariables(schema).some(variable => supportedAggregateVariables.includes(variable));

interface FilteredSchemaDate {
  category: string;
  date: Date;
}

// gets "Date" categories and returns them transformed and filtered to those that are valid dates (for anyone not using generator)
const getFilteredSchemaDates_Date = (schema: Schema): FilteredSchemaDate[] => {
  const dateConfig = [...schema.rows, ...schema.columns].find(item => item.variable === "Date");
  return dateConfig?.categories?.length
    ? dateConfig.categories.map(category => ({ category, date: new Date(category) })).filter(item => item.date.toString() !== "Invalid Date")
    : [];
};

// gets "Year Month" categories and returns them transformed and filtered to those that are valid dates (for anyone not using generator)
const getFilteredSchemaDates_YearMonth = (schema: Schema): FilteredSchemaDate[] => {
  const dateConfig = [...schema.rows, ...schema.columns].find(item => item.variable === "Year Month");
  return dateConfig?.categories?.length
    ? dateConfig.categories
      .map(category => {
        const [catYear, catMonth] = category.split(" ");
        return { category, date: new Date(`${catYear}-${pad2(months.indexOf(catMonth) + 1)}-01`) };
      })
      .filter(item => item.date.toString() !== "Invalid Date")
    : [];
};

// gets "Year Quarter" categories and returns them transformed and filtered to those that are valid dates (for anyone not using generator)
const getFilteredSchemaDates_YearQuarter = (schema: Schema): FilteredSchemaDate[] => {
  const dateConfig = [...schema.rows, ...schema.columns].find(item => item.variable === "Year Quarter");
  return dateConfig?.categories?.length
    ? dateConfig.categories
      .map(category => {
        const [catYear, catQuarter] = category.split(" ");
        const catQuarterNumber = quarters.indexOf(catQuarter) as quarterNumber;
        // we use the first month of the year quarter for comparisons
        return { category, date: new Date(`${catYear}-${pad2(monthToQuarter.indexOf(catQuarterNumber) + 1)}-01`) };
      })
      .filter(item => item.date.toString() !== "Invalid Date")
    : [];
};

// determines the earliest and latest FilteredSchemaDate.date and returns them as Date object types
const deduceFromToDates = (dates: FilteredSchemaDate[]): { fromDate?: Date; toDate?: Date } => {
  let fromDate;
  let toDate;
  if (!dates.length) {
    return {};
  }
  // now find the from/to dates to generate for based on the "Date" categories
  for (const item of dates) {
    if (!fromDate || item.date.getTime() < fromDate.getTime()) {
      fromDate = item.date;
    }
    if (!toDate || item.date.getTime() > toDate.getTime()) {
      toDate = item.date;
    }
  }
  return { fromDate, toDate };
};

// automatically generates aggregation categories for "Year Month"
const generateAggYearMonth = (schemaDates: FilteredSchemaDate[]): AggregationCategory[] => {
  const { fromDate, toDate }: any = deduceFromToDates(schemaDates);
  if (!fromDate || !toDate) {
    return [];
  }
  // now generate the raw category names using the "Year Month" generator
  const catStrings = generateYearMonths(fromDate.getFullYear(), fromDate.getMonth(), toDate.getFullYear(), toDate.getMonth());
  // now map each string into aggregation category format with the appropriate config and return
  return catStrings.map(name => ({
    name,
    aggAuto: {
      year: +name.split(" ")[0],
      month: months.indexOf(name.split(" ")[1]),
    },
  }));
};

// automatically generates aggregation categories for "Year Quarter"
const generateAggYearQuarter = (schemaDates: FilteredSchemaDate[]): AggregationCategory[] => {
  const { fromDate, toDate }: any = deduceFromToDates(schemaDates);
  if (!fromDate || !toDate) {
    return [];
  }
  // now generate the raw category names using the "Year Quarter" generator
  const catStrings = generateYearQuarters(fromDate.getFullYear(), monthToQuarter[fromDate.getMonth()], toDate.getFullYear(), monthToQuarter[toDate.getMonth()]);
  // now map each string into aggregation category format with the appropriate config and return
  return catStrings.map(name => ({
    name,
    aggAuto: {
      year: +name.split(" ")[0],
      quarter: quarters.indexOf(name.split(" ")[1]),
    },
  }));
};

// automatically generates aggregation categories for "Year"
const generateAggYear = (schemaDates: FilteredSchemaDate[]): AggregationCategory[] => {
  const { fromDate, toDate }: any = deduceFromToDates(schemaDates);
  if (!fromDate || !toDate) {
    return [];
  }
  // now generate the raw category names using the "Year" generator
  const catStrings = generateYears(fromDate.getFullYear(), toDate.getFullYear());
  // now map each string into aggregation category format with the appropriate config and return
  return catStrings.map(name => ({ name, aggAuto: { year: +name } }));
};

// automatically generates aggregation categories for "Financial Year"
const generateAggFinancialYear = (schemaDates: FilteredSchemaDate[]): AggregationCategory[] => {
  const { fromDate, toDate }: any = deduceFromToDates(schemaDates);
  if (!fromDate || !toDate) {
    return [];
  }
  // first determine the financial year from the date for from/to
  const fromFinancialYear = fromDate.getFullYear() - (fromDate.getMonth() > 6 ? 0 : 1);
  const toFinancialYear = toDate.getFullYear() - (toDate.getMonth() > 6 ? 0 : 1);
  const catStrings = generateYearsFinancial(fromFinancialYear, toFinancialYear);
  // now map each string into aggregation category format with the appropriate config and return
  return catStrings.map(name => ({ name, aggAuto: { year: +name.slice(0, 4) } })); // i.e. for year config we want 2022 from "2022-23"
};

// generate aggregation categories for generations we support
export const generateAggregationCategories = (aggVariable: string, variable: string, schema: Schema): AggregationCategory[] => {
  if (aggVariable === "Date") {
    const schemaDates = getFilteredSchemaDates_Date(schema);
    if (variable === "Year Month") {
      return generateAggYearMonth(schemaDates);
    } else if (variable === "Year Quarter") {
      return generateAggYearQuarter(schemaDates);
    } else if (variable === "Year") {
      return generateAggYear(schemaDates);
    } else if (variable === "Financial Year") {
      return generateAggFinancialYear(schemaDates);
    }
  } else if (aggVariable === "Year Month") {
    const schemaDates = getFilteredSchemaDates_YearMonth(schema);
    if (variable === "Year Quarter") {
      return generateAggYearQuarter(schemaDates);
    } else if (variable === "Year") {
      return generateAggYear(schemaDates);
    } else if (variable === "Financial Year") {
      return generateAggFinancialYear(schemaDates);
    }
  } else if (aggVariable === "Year Quarter") {
    const schemaDates = getFilteredSchemaDates_YearQuarter(schema);
    if (variable === "Year") {
      return generateAggYear(schemaDates);
    } else if (variable === "Financial Year") {
      return generateAggFinancialYear(schemaDates);
    }
  }
  return []; // no generator available for combination
};

// retrieves aggVariable categories from the schema that form a "Year Month" aggregate category
export const getAutoAggCategoriesYearMonth = (aggAuto: AggAutoYearMonth, dates: FilteredSchemaDate[]): string[] => {
  const { year, month } = aggAuto;
  return dates.filter(item => item.date.getFullYear() === year && item.date.getMonth() === month).map(item => item.category);
};

// retrieves aggVariable categories from the schema that form a "Year Quarter" aggregate category
export const getAutoAggCategoriesYearQuarter = (aggAuto: AggAutoYearQuarter, dates: FilteredSchemaDate[]): string[] => {
  const { year, quarter } = aggAuto;
  return dates.filter(item => item.date.getFullYear() === year && monthToQuarter[item.date.getMonth()] === quarter).map(item => item.category);
};

// retrieves aggVariable categories from the schema that form a "Year" aggregate category
export const getAutoAggCategoriesYear = (aggAuto: AggAutoYear, dates: FilteredSchemaDate[]): string[] => {
  const { year } = aggAuto;
  return dates.filter(item => item.date.getFullYear() === year).map(item => item.category);
};

// retrieves aggVariable categories from the schema that form a "Financial Year" aggregate category
export const getAutoAggCategoriesFinancialYear = (aggAuto: AggAutoYear, dates: FilteredSchemaDate[]): string[] => {
  const { year } = aggAuto;
  return dates.filter(item => {
    // filter to date's that are part of the financial year
    const dateYear = item.date.getFullYear();
    const dateMonth = item.date.getMonth();
    if (dateYear === year && dateMonth >= 6) {
      return true; // same year and month is July onwards
    } else if (dateYear === year! + 1 && dateMonth < 6) {
      return true; // year after and month is before July
    }
    return false;
  }).map(item => item.category);
};

// generate aggCategories for a specific aggregation category
export const getAutoAggCategories = (aggAuto: AggAuto, aggVariable: string, variable: string, schema: Schema): string[] => {
  if (aggVariable === "Date") {
    const schemaDates = getFilteredSchemaDates_Date(schema);
    if (variable === "Year Month") {
      return getAutoAggCategoriesYearMonth(aggAuto as AggAutoYearMonth, schemaDates);
    } else if (variable === "Year Quarter") {
      return getAutoAggCategoriesYearQuarter(aggAuto as AggAutoYearQuarter, schemaDates);
    } else if (variable === "Year") {
      return getAutoAggCategoriesYear(aggAuto as AggAutoYear, schemaDates);
    } else if (variable === "Financial Year") {
      return getAutoAggCategoriesFinancialYear(aggAuto as AggAutoYear, schemaDates);
    }
  } else if (aggVariable === "Year Month") {
    const schemaDates = getFilteredSchemaDates_YearMonth(schema);
    if (variable === "Year Quarter") {
      return getAutoAggCategoriesYearQuarter(aggAuto as AggAutoYearQuarter, schemaDates);
    } else if (variable === "Year") {
      return getAutoAggCategoriesYear(aggAuto as AggAutoYear, schemaDates);
    } else if (variable === "Financial Year") {
      return getAutoAggCategoriesFinancialYear(aggAuto as AggAutoYear, schemaDates);
    }
  } else if (aggVariable === "Year Quarter") {
    const schemaDates = getFilteredSchemaDates_YearQuarter(schema);
    if (variable === "Year") {
      return getAutoAggCategoriesYear(aggAuto as AggAutoYear, schemaDates);
    } else if (variable === "Financial Year") {
      return getAutoAggCategoriesFinancialYear(aggAuto as AggAutoYear, schemaDates);
    }
  }
  return []; // unsupported aggregation
};

// returns a schema with rows/cols populated for aggregations
export const getAggregateSchema = (schema: Schema, aggregation: Aggregation): Schema | void => {
  const aggSchema = cloneDeep(schema);
  delete aggSchema.aggregations; // no longer applicable
  const rowIdx = aggSchema.rows.findIndex(item => item.variable === aggregation.aggVariable);
  const colIdx = aggSchema.columns.findIndex(item => item.variable === aggregation.aggVariable);
  const aggVariableSection = rowIdx !== -1
    ? "rows"
    : colIdx !== -1
      ? "columns" : null;
  if (!aggVariableSection) {
    return;
  }
  const actualIdx = rowIdx !== -1 ? rowIdx : colIdx;
  aggSchema[aggVariableSection][actualIdx] = {
    variable: aggregation.variable,
    totals: "none", // @TODO - consider these may be something we want on the aggregate table too
    categories: aggregation.categories.map(cat => cat.name),
  };
  return aggSchema;
};

// aggregation functions, each take a number[] of data points
const aggTypeFunctions = {
  sum: (dataPoints: number[]) => dataPoints.reduce((next, prev) => next + prev, 0),
  average: (dataPoints: number[]) => {
    const length = dataPoints.length;
    const sum = dataPoints.reduce((next, prev) => next + prev, 0);
    return length ? sum / length : null;
  },
  maximum: (dataPoints: number[]) => dataPoints.length ? dataPoints.reduce((next, prev) => next > prev ? next : prev, Number.MIN_SAFE_INTEGER) : null,
  minimum: (dataPoints: number[]) => dataPoints.length ? dataPoints.reduce((next, prev) => next < prev ? next : prev, Number.MAX_SAFE_INTEGER) : null,
  mode: (dataPoints: number[]) => {
    if (!dataPoints.length) {
      return null;
    }
    const sorted = [...dataPoints].sort((a, b) => a - b);
    const counts = sorted.reduce((curr, next) => ({ ...curr, [`${next}`]: (curr[`${next}`] || 0) + 1 }), {});
    const countKeys = Object.keys(counts);
    const modeStr = countKeys.reduce((curr, next) => counts[next] > counts[curr] ? next : curr, countKeys[0]);
    return Number(modeStr);
  },
  median: (dataPoints: number[]) => {
    if (!dataPoints.length) {
      return null;
    }
    const sorted = [...dataPoints].sort((a, b) => a - b);
    const midPoint = sorted.length / 2;
    if (sorted.length % 2 === 0) {
      return (sorted[midPoint] + sorted[midPoint - 1]) / 2;
    }
    return sorted[Math.floor(midPoint)];
  },
  range: (dataPoints: number[]) => {
    if (dataPoints.length < 2) {
      return dataPoints.length ? 0 : null;
    }
    const sorted = [...dataPoints].sort((a, b) => a - b);
    return sorted[sorted.length - 1] - sorted[0];
  },
  "standard deviation": (dataPoints: number[]) => {
    const length = dataPoints.length;
    const sum = dataPoints.reduce((next, prev) => next + prev, 0);
    const mean = length ? sum / length : null;
    if (!mean) {
      return null;
    }
    const deviations = dataPoints.map(point => Math.pow(point - mean, 2));
    const variance = deviations.reduce((next, prev) => next + prev, 0) / deviations.length;
    return Math.sqrt(variance); // population std deviation
  },
  count: (dataPoints: number[]) => dataPoints.length,
};
export const aggTypes = Object.keys(aggTypeFunctions);

// returns the raw data array for an aggregation (@TODO currently only autoAgg but handle manual ones too at some point)
export const getAggregateData = (parentSchema: Schema, rawData: ObjectAny[], aggregation: Aggregation): ObjectAny[] | null => {
  const aggSchema = getAggregateSchema(parentSchema, aggregation);
  if (!aggSchema) {
    return null; // invalid aggregation
  }
  const aggData: ObjectAny[] = [];
  const nonAggVariables = [...aggSchema.rows, ...aggSchema.columns].filter(item => item.variable !== aggregation.variable);
  const nonAggVariablesCombos = getVariableCombinations(nonAggVariables);
  for (const nonAggCombo of nonAggVariablesCombos) {
    for (const aggCat of aggregation.categories) {
      const catsToAggregateOn = getAutoAggCategories(aggCat.aggAuto, aggregation.aggVariable, aggregation.variable, parentSchema);
      const dataPoints: number[] = [];
      for (const catToAggregateOn of catsToAggregateOn) {
        const search = { ...nonAggCombo, [aggregation.aggVariable]: catToAggregateOn };
        const foundDataPoint = find(rawData, search);
        if (typeof foundDataPoint?.Value === "number") {
          dataPoints.push(foundDataPoint.Value);
        }
      }
      const aggTypeFn = aggTypeFunctions[aggregation.type];
      // @TODO - this to be revisited in terms of how we handle missing data
      const Value = aggTypeFn(dataPoints);
      aggData.push({ ...nonAggCombo, [aggregation.variable]: aggCat.name, Value });
    }
  }
  return aggData;
};
