import { COLORS } from "component/UI/common";
import styled from "styled-components";

export const Container = styled.div`
  @media screen and (min-width: 1380px) and (max-width: 4000px) {
    position: relative;
  }
  & img {
    max-width: 100%;
  }
`;

export const Actions = styled.span<{ open: boolean }>`
  position: relative;
  float: right;
  margin-top: -8px;
  ${p =>
    p.open &&
    `
    box-shadow: 0px 2px 4px #C3C3C3;
    height: auto;
    border-radius: 4px;
  `}
`;

export const Box = styled.div`
  display: flex;
`;
export const InfoContainer = styled.div``;

export const TemplateBuilder = styled.div`
  margin: 100px 0 20px 0;
`;
export const TemplateBuilderBox = styled.div`
  position: inherit;
  right: 60px;
  left: 60px;
  background-color: lightblue;
  height: 80vh;
  vertical-align: middle;
  text-align: center;
`;

export const UserInfo = styled.div`
  position: relative;
  margin-bottom: 20px;
`;
export const GridContainerLeft = styled.div`
  justify-items: left;
  align-items: top;
`;
export const LeftContainer = styled.div`
  justify-items: left;
  align-items: center;
`;
export const BottomLeft = styled.div`
  justify-items: left;
  align-items: center;
  margin-left: 0;
`;
export const SuitcaseTitle = styled.div`
  color: ${COLORS.indigo600};
`;
export const NumOfInsights = styled.div``;
export const SuitcaseImgContainer = styled.span``;
export const GridContainerRight = styled.div``;

export const Title = styled.h1`
  display: inline-block;
  color: ${COLORS.indigo600};
  width: 560px;
  font-size: 45px;
  @media screen and (max-width: 777px) {
    margin-bottom: 50px;
    width: 400px;
  }
  @media screen and (max-width: 563px) {
    margin-bottom: 50px;
    width: 300px;
  }
`;
export const SubHeading = styled.h2`
  color: ${COLORS.indigo600};
  position: absolute;
  display: inline-block;
  right: 0;
  margin-bottom: 20px;
  width: 500px;
  @media screen and (max-width: 930px) {
    left: 40px;
  }
  @media screen and (min-width: 1380px) and (max-width: 4000px) {
    right: 0;
  }
`;
export const AboutContent = styled.p`
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  margin: 0;
  transform: translateY(35px);
  position: absolute;
  display: inline-block;
  right: 0;
  width: 500px;
  max-height: 50px;
  @media screen and (max-width: 930px) {
    left: 40px;
  }
  @media screen and (min-width: 1380px) and (max-width: 4000px) {
    right: 0;
  }
`;

export const CommentsHeading = styled.h1`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: ${COLORS.indigo600};
  display: flex;
  margin-bottom: 15px;
`;

export const InsightH2 = styled.h3`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

export const Suitcase = styled.div``;

export const DeleteBtn = styled.button`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
  background: none;
  position: absolute;
  top: -18px;
  right: 40px;
  width: 100px;
  height: 40px;
  cursor: pointer;
  color: ${COLORS.red500};
  border: 1px solid #c3c3c3;
`;

export const SaveButton = styled.button`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
  background: none;
  position: absolute;
  top: -18px;
  right: 150px;
  width: 140px;
  height: 40px;
  cursor: pointer;
  color: ${COLORS.red500};
`;
