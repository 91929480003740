import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import * as qs from "qs";
import Store from "common/store";
import Protected from "component/protected";
import { getMixpanel } from "common/api";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { ExploreCard } from "component/insightBuilder/ExploreCard";
import { Icon, Progress } from "semantic-ui-react";
import { BuilderSelector } from "./Builder/BuilderSelector";
import { ls } from "common/helpers/storage";
import { isAnyFilterSelected } from "common/helpers/explore";
import { Builder } from "common/store/builder";
import { webUrl } from "common/constants";
import { COLORS } from "component/UI/common";
import { Button } from "component/UI/Button";
import { ExploreSwitch } from "component/insightBuilder/ExploreSwitch";

export const StyledProgress = styled(Progress)`
  &.ui.progress {
    border-radius: 9px;
    height: 18px;
    margin-bottom: 40px;
    div.bar {
      border-radius: 9px;
      height: 18px;
      background-color: ${COLORS.indigo600} !important;
    }
    background-color: ${COLORS.indigo100};
  }
`;

type PropsType = RouteComponentProps<{ id?: string; suitcaseId?: any }> & {
  store: Store;
};

interface IState {
  currentFlow: string;
  currentStep: string;
  flowDirection: string;
};

const EXPLORE_FLOWS = {
  "Location Flow": ["Location", "Topic", "Quantity", "Time", "Dataset"],
  "Topic Flow": ["Topic", "Quantity", "Location", "Time", "Dataset"],
  "Dataset FLow": ["Dataset", "Topic", "Quantity", "Location", "Time"],
};

const STEP_TO_TYPE_MAP = {
  "Dataset": "Source",
  "Topic": "What",
  "Quantity": "How",
  "Location": "Where",
  "Time": "When",
};

const defaultState = {
  currentFlow: "",
  currentStep: "Landing Page",
  flowDirection: "",
  capturedStep: "",
};

class Explore extends React.Component<PropsType> {
  state: IState = defaultState;

  exploreCardContent = [
    {
      id: "userflow-element-explore-location",
      iconUrl: "/assets/explore_location_icon.png",
      title: "By Location",
      handleOnClick: () => {
        this.setState({ currentFlow: "Location Flow", currentStep: EXPLORE_FLOWS["Location Flow"][0] });
        getMixpanel(this.props.store).track("Multi-step Explore", { "Select Flow": "Location" });
      },
    },
    {
      iconUrl: "/assets/explore_topics_icon.png",
      title: "By Topic",
      handleOnClick: () => {
        this.setState({ currentFlow: "Topic Flow", currentStep: EXPLORE_FLOWS["Topic Flow"][0] });
        getMixpanel(this.props.store).track("Multi-step Explore", { "Select Flow": "Topic" });
      },
    },
    {
      iconUrl: "/assets/explore_datasets_icon.png",
      title: "By Dataset",
      handleOnClick: () => {
        this.setState({ currentFlow: "Dataset FLow", currentStep: EXPLORE_FLOWS["Dataset FLow"][0] });
        getMixpanel(this.props.store).track("Multi-step Explore", { "Select Flow": "Dataset" });
      },
    },
  ];

  initialise = async () => {
    const { store } = this.props;
    await store.builder.newInsight();
  };

  componentDidMount() {
    const { store, location } = this.props;
    const { search } = location;
    const { from, flow, step } = qs.parse(search.slice(1) || "");
    // Preserve all selections from "manual_explore" and set "flow" and "step" accordingly
    if (from === "manual_explore" && isAnyFilterSelected(this.props.store!.builder)) {
        if (flow && step) {
          this.setState({ currentFlow: flow, currentStep: step });
        } else {
          this.setState({ currentStep: "Landing Page" });
        }
        return;
    }
    getMixpanel(store!.builder.parent).track("Page view", { "Page": "Multi-step Explore" });
    store!.builder.getAllDatasets();
    this.initialise();
  }

  handleSkipAction() {
    const { currentStep, flowDirection } = this.state;
    if (flowDirection === "Forwards") {
      this.handleOnClickNext();
    }
    if (flowDirection === "Backwards") {
      // If go back to Dataset step, reload all datasets
      if (currentStep === "Dataset") {
        this.props.store.builder.getAllDatasets();
        this.setState({
          flowDirection: "",
        });
        return;
      }
      this.handleOnClickBack();
    }
  }

  handleOnClickBack() {
    const { currentFlow, currentStep } = this.state;
    this.setState({
      flowDirection: "Backwards",
    });
    const currentIndex = EXPLORE_FLOWS[currentFlow].indexOf(currentStep);
    if (currentIndex > 0) {
      this.setState({ currentStep: EXPLORE_FLOWS[currentFlow][currentIndex - 1] });
    } else {
      window.history.replaceState(null, "", "/explore");
      this.setState(defaultState);
      this.initialise();
    }
  }

  handleOnClickNext() {
    const { currentFlow, currentStep } = this.state;
    this.setState({
      flowDirection: "Forwards",
    });
    const currentIndex = EXPLORE_FLOWS[currentFlow].indexOf(currentStep);
    if (currentIndex < EXPLORE_FLOWS[currentFlow].length - 1) {
      this.setState({ currentStep: EXPLORE_FLOWS[currentFlow][currentIndex + 1] });
    } else {
      const { suitcaseId } = this.props.match.params;
      this.props.history.push(`${suitcaseId ? `/suitcases/${suitcaseId}` : ""}/builder/new?from=guided_explore&flow=${currentFlow}&step=${currentStep}`);
    }
  }

  handleResetAll = async (currentFlow, currentStep): Promise<void> => {
    getMixpanel(this.props.store).track("Multi-step Explore > Start again", {
      "Flow": currentFlow,
      "Step": currentStep,
    });
    const { location } = this.props;
    const { search } = location;
    const { from, flow, step } = qs.parse(search.slice(1) || "");
    if (from || flow || step) {
      const { suitcaseId } = this.props.match.params;
      window.history.replaceState(null, "", `${suitcaseId ? `/suitcases/${suitcaseId}` : ""}/explore`);
    }
    this.setState(defaultState);
    const store = this.props.store!;
    const { builder } = store;
    Object.assign(builder, new Builder(store));
    await builder.newInsight();
    await builder.getAllDatasets();
  };

  render() {
    const store = this.props.store!;
    const { builder } = store;
    const { suitcaseId } = this.props.match.params;
    const { currentFlow, currentStep } = this.state;
    const showLoader = builder.loading.getAllDatasets ||
      builder.loading.initialiseGlobalDimensions ||
      builder.loading.reloadQuickStat;
    const selectionType = STEP_TO_TYPE_MAP[currentStep];
    let isNextDisabled = true;
    if (selectionType === "Source") {
      isNextDisabled = builder.userSelectedDatasets.length === 0;
    } else if (selectionType === "How") {
      isNextDisabled = (builder.qsAllFilters["Measured quantity"] || []).length === 0;
    } else {
      const selectedVariables = Object.keys(builder.qsAllFilters).filter(variable => builder.dimensions[variable]?.type === selectionType);
      const ifAnyVariablesHaveNoCategories = selectedVariables.some(variable => (builder.qsAllFilters[variable] || []).length === 0);
      isNextDisabled = selectedVariables.length === 0 || ifAnyVariablesHaveNoCategories;
    }

    return (
      <div style={{ overflow: "auto" }}>
        <Helmet>
          <title>Explore Data</title>
        </Helmet>
        {showLoader ? <ShowLoaderGlobal /> : null}
        <div className="my-6 d-flex align-items-center justify-content-center">
          <div className="text-center" style={{width: "940px"}}>
            {/* Landing Page */}
            {(currentStep === "Landing Page" && !showLoader) && (
              <div>
                <h1 className="ff-primary fw-800 text-secondary fs-3000 mb-5">
                  How would you like to explore data?
                </h1>
                <div className="w-100 d-flex justify-content-between mb-6 shadow-hover">
                  {
                    this.exploreCardContent.map((cardItem, index) => (
                      <ExploreCard
                        id={cardItem.id}
                        key={index}
                        iconUrl={cardItem.iconUrl}
                        title={cardItem.title}
                        handleOnClick={cardItem.handleOnClick}
                      />
                    ))
                  }
                </div>
                <div>
                  <h2 className="text-secondary">Advanced user?</h2>
                  <Button icon labelPosition="right" colorConfig="aquaCTA" onClick={() => {
                    window.location.href = `${webUrl}${suitcaseId ? `/suitcases/${suitcaseId}` : ""}/builder/new`;
                    ls.setItem("isAdvancedExploreUser", true);
                    getMixpanel(this.props.store).track("Multi-step Explore", {"Set default": "Manual Explore" });
                  }}>
                    Go to Manual Explore<Icon name="arrow right" />
                  </Button>
                </div>
              </div>
            )}
            {/* Multi-step */}
            {currentStep !== "Landing Page" && (
              <div className="bg-white rounded-3 shadow pt-6 px-6 d-flex justify-content-center" style={{ minHeight: "65vh" }}>
                <div className="d-flex flex-column justify-content-between" style={{ width: "90%" }}>
                  <div>
                    <div className="d-flex justify-content-end mb-4">
                      <ExploreSwitch
                        label="Go to Manual Explore"
                        handleOnClick={() => {
                          this.props.history.push(`${suitcaseId
                            ? `/suitcases/${suitcaseId}`
                            : ""}/builder/new${isAnyFilterSelected(this.props.store!.builder)
                              ? `?from=guided_explore&flow=${currentFlow}&step=${currentStep}`
                              : ""}`
                          );
                          getMixpanel(this.props.store).track("Multi-step Explore", {
                            "From": "Guided Explore",
                            "To": "Manual Explore",
                            "Flow": currentFlow,
                            "Step": currentStep,
                          });
                        }}
                      />
                    </div>
                    <StyledProgress value={EXPLORE_FLOWS[currentFlow].indexOf(currentStep) + 1} total={EXPLORE_FLOWS[currentFlow].length} progress="ratio" size="small" />
                    {currentStep === "Dataset" && (
                      <BuilderSelector
                        selectionGroups={["Source"]}
                        forMultiStep={true}
                        handleSkipAction={() => this.handleSkipAction()}
                      />
                    )}
                    {currentStep === "Topic" && (
                      <BuilderSelector
                        selectionGroups={["What"]}
                        forMultiStep={true}
                        handleSkipAction={() => this.handleSkipAction()}
                      />
                    )}
                    {currentStep === "Quantity" && (
                      <BuilderSelector
                        selectionGroups={["How"]}
                        forMultiStep={true}
                        handleSkipAction={() => this.handleSkipAction()}
                      />
                    )}
                    {currentStep === "Location" && (
                      <BuilderSelector
                        selectionGroups={["Where"]}
                        forMultiStep={true}
                        handleSkipAction={() => this.handleSkipAction()}
                      />
                    )}
                    {currentStep === "Time" && (
                      <BuilderSelector
                        selectionGroups={["When"]}
                        forMultiStep={true}
                        handleSkipAction={() => this.handleSkipAction()}
                      />
                    )}
                  </div>
                  <div className="pt-4 pb-6 d-flex justify-content-between align-items-center">
                    <p className="fw-700 fs-1000 m-0 mr-3 cursor-pointer" style={{ color: COLORS.red500, left: 20, top: 20 }} onClick={() => this.handleResetAll(currentFlow, currentStep)}>Start again</p>
                    <div>
                      <Button onClick={() => this.handleOnClickBack()} className="mr-3">
                        <Icon name="arrow left" />Back
                      </Button>
                      <Button inverted colorConfig="purpleInverted" onClick={() => this.handleOnClickNext()} disabled={isNextDisabled} className="mr-0">
                        Next<Icon name="arrow right" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Protected(inject("store")(observer(Explore))));
