import * as React from "react";
import { Icon, SearchResultProps } from "semantic-ui-react";
import { Avatar } from "component/Avatar";
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from "common/helpers/chart";
import { Link } from "react-router-dom";
import { COLORS } from "component/UI/common";
import { Entity, EntityType } from "../HeaderSearch";


interface CategoryLayoutRenderer {
  categoryContent: JSX.Element;
  resultsContent: JSX.Element;
}

interface CategoryRenderer {
  name: EntityType;
}

export const categoryLayoutRenderer = ({ categoryContent, resultsContent }: CategoryLayoutRenderer): JSX.Element => (
  <div>
    <div className="name">{categoryContent}</div>
    <div className="results">{resultsContent}</div>
  </div>
);

export const categoryRenderer = ({ name }: CategoryRenderer): JSX.Element => (
  <div style={{ display: "flex", alignItems: "center", padding: 10, backgroundColor: COLORS.indigo100 }}>
    <Icon name={name === "suitcases" ? "suitcase" : name === "insights" ? "linegraph" as "line graph" : "window maximize outline"} size="large" className="text-secondary" />
    <span className="text-capitalize fw-600 text-secondary" style={{ fontSize: "14px", marginLeft: "7px" }}>
      {name}
    </span>
  </div>
);

export const resultRenderer = (props: Entity & SearchResultProps): JSX.Element => {
  const { entity_id, title, type, first_name, last_name, avatar, has_child_suitcases } = props;
  return (
    <Link to={`/${type}/${entity_id}`}>
      <span style={{ fontSize: 14, fontWeight: "bold", color: CHART_PRIMARY_COLOR, marginBottom: 10, display: "block" }}>
        {title}
      </span>
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar name={`${first_name} ${last_name}`} image={avatar || ""} size={30} />
          <p style={{ marginLeft: 10, color: CHART_SECONDARY_COLOR, fontSize: 12 }}>{`${first_name} ${last_name}`}</p>
        </div>
        {has_child_suitcases === "true" && <Icon name="folder" color="grey" />}
      </div>
    </Link>
  );
};
