import * as React from "react";
import { Checkbox } from "semantic-ui-react";

export const SuitcaseCheckbox = ({ onSelect, onDeselect, disabled }) => {
  const [checked, setChecked] = React.useState(false);
  const toggle = (e) => {
    e.stopPropagation();
    !checked ? onSelect() : onDeselect();
    setChecked(!checked);
  };

  return (
    <div>
      <Checkbox
        onChange={toggle}
        checked={checked}
        disabled={disabled}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
};
