import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import Store from "common/store";
import { database, getMixpanel } from "common/api";
import { StylePillButton } from "./CensusQueryTool.style";
import { NewBuilderChartPreview } from "component/Explore/includes/NewBuilderChartPreview";
import { getDimType } from "common/helpers/data";
import { Icon } from "semantic-ui-react";

interface IInsightCardView {
  store?: Store;
  builder?: any;
  insightId: number;
  publicKey: string;
  index: number;
  selectTemplateHandler: () => void;
}

const InsightCardViewComponent = (props: IInsightCardView): JSX.Element => {
  const { store, builder, insightId, publicKey, index, selectTemplateHandler } = props;
  const [insightJson, setInsightJson] = React.useState<any>(null);
  const [insightName, setInsightName] = React.useState<any>(null);

  const ifHasCensusAddOn = store?.user
    ? store.user?.group.add_ons.map(addOn => addOn.name).includes("Census Tool")
    : false;
  // Show an overlay on 2nd & 3rd insights for not-logged in users and users have no "Census Tool" add-on
  // TODO: Uncomment and adjust logic to lock for non-logged in users or Standard users after checking with the sales team
  // const isInsightDisabled = index !== 0 && !ifHasCensusAddOn;
  const isInsightDisabled = false;

  const init = async () => {
    const res: any = await database.get(`insights/${insightId}/${publicKey}`, "", undefined);
    const insightData = res.body.data.insight;
    setInsightJson(JSON.parse(insightData.json));
    setInsightName(insightData.name);
  };

  const insightClickHandler = async (insightId) => {
    const res: any = await database.get(`insights/${insightId}/${publicKey}`, "", undefined);
    const insightJson = JSON.parse(res.body?.data?.insight?.json);
    const { columns, filters, rows } = insightJson.tables[0];
    builder.setColumns(columns);
    // Do not pre-populated "Where" for users
    builder.setFilters(filters.filter(dim => getDimType(dim.dimension) !== "Where"));
    builder.setRows(rows);
    await builder.reloadQuickStat();
    getMixpanel(store!).track("Census Query Tool > Topic", { "Button Click": "Select insight", "Insight ID": insightId });
    selectTemplateHandler();
  };

  React.useEffect(() => {
    init();
  }, [insightId]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-between position-relative">
      {/* Insight Overlay */}
      {
        isInsightDisabled &&
        <div className="w-100 h-100 p-4 position-absolute d-flex align-items-center justify-content-center" style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", zIndex: 1 }}>
          <div className="px-3 py-4" style={{ backgroundColor: "rgba(255, 255, 255, 0.9)", border: "1px solid rgba(95, 45, 95, 0.6)" }}>
            <img src="/assets/icon_lock_insight.png" />
            <p className="fs-0875 fw-600 mb-2 text-secondary">A subscription with the "Census Tool" add-on is required to see more pre-built Insights.</p>
            <Link className="fs-0875 fw-600 text-primary" to={{ pathname: "https://seerdata.ai/plans/" }} target="_blank">Find out more</Link>
            <Icon name="unlock alternate" size="small" className="text-primary ml-2 cursor-pointer" />
          </div>
        </div>
      }
      <h4 className="text-left mt-0 mb-2">{insightName || "Loading insight..."}</h4>
      <div className="d-flex align-items-center justify-content-center mb-3" style={{ minHeight: 250 }}>
        <NewBuilderChartPreview
          insightJson={insightJson}
          insightTitle={insightName} // For the calculation of graphHeight
          layout="grid"
        />
      </div>
      <StylePillButton size="mini" disabled={isInsightDisabled} onClick={() => insightClickHandler(insightId)}>
        Explore locations
      </StylePillButton>
    </div>
  );
};

export const InsightCardView = inject((stores: any) => ({
  store: stores.store,
  builder: stores.store.builder,
}))(observer(InsightCardViewComponent));
