import * as React from "react";
import { CHART_FONT_FAMILY, CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR, defaultAngle, defaultLabelFont, generateMetricLegendItems, ifShowTableNames } from "common/helpers/chart";
import { Text } from "recharts";
import { formatValue } from "common/helpers/data";
import { IFilter, TypeDecimalPoints } from "common/store/builder";
import { ifFormattedAsPercentage, isProportionsOrPercentageChart } from "component/Explore/includes/helper";
import { ObjectAny } from "common/helpers/types";
import { Popup } from "semantic-ui-react";

// NOT IN USE: Style for stacked bar charts: divider between bards
export const CustomBarShape = (barRadius, lineHeight, lineColor, direction) => (props) => {
  const { fill, x, y, width, height } = props;
  if (direction === "vertical") {
    return (
      <g>
        <rect x={x} y={y + lineHeight} rx={barRadius} ry={barRadius} width={width} height={Math.max(height - lineHeight, 0)} stroke="none" fill={fill} />
        {height && <line x1={x} x2={x + width} y1={y} y2={y} strokeWidth={lineHeight} stroke={lineColor} />}
      </g>
    );
  } else {
    return (
      <g>
        <rect x={x} y={y + lineHeight} rx={barRadius} ry={barRadius} width={Math.max(width - lineHeight, 0)} height={height} stroke="none" fill={fill} />
        {height && <line x1={x + width} x2={x + width} y1={y} y2={y + height} strokeWidth={lineHeight} stroke={lineColor} />}
      </g>
    );
  }
};

export const CustomBarLabel = (isPercentage: boolean, decimalPoints: TypeDecimalPoints, fontSize: number | undefined, isVertical: boolean ) => (props) => {
  const { value, x, y, width, height, offset } = props;
  let positionX;
  let positionY;
  let textAnchor;
  let verticalAnchor;
  if (isVertical) {
    positionX = x + width / 2;
    positionY = y - offset;
    textAnchor = "middle";
    verticalAnchor = "end";
  } else { // TODO: update code to handle showing values for horizontal bar charts
    // positionX = x + width + offset;
    // positionY = y + height / 2;
    // textAnchor = "start";
    // verticalAnchor = "middle";
  }
  return (
    <g>
      <Text
        x={positionX}
        y={positionY}
        fill={CHART_PRIMARY_COLOR}
        style={{ fontSize: fontSize || 11, fontFamily: CHART_FONT_FAMILY, fontWeight: "bold" }}
        textAnchor={textAnchor}
        verticalAnchor={verticalAnchor}
      >
        {formatValue(value, [isPercentage ? "(%)" : "Decimal"], decimalPoints)}
      </Text>
    </g>
  );
};

const PIE_SLICE_NUMBER_THRESHOLD = 10;
const PIE_SLICE_PERCENT_THRESHOLD = 0.015;
const ifHidePieLabel = (sliceNumber: number, percent: number): boolean => sliceNumber >= PIE_SLICE_NUMBER_THRESHOLD && percent < PIE_SLICE_PERCENT_THRESHOLD;

export const CustomPieLabelLine = (props) => {
  const { lineLength, sliceNumber, cx, cy, midAngle, outerRadius, percent, stroke } = props;
  const RADIAN = Math.PI / 180;
  const x1 = cx + (outerRadius!) * Math.cos(-midAngle * RADIAN);
  const y1 = cy + (outerRadius!) * Math.sin(-midAngle * RADIAN);
  const x2 = cx + (outerRadius! + lineLength) * Math.cos(-midAngle * RADIAN);
  const y2 = cy + (outerRadius! + lineLength) * Math.sin(-midAngle * RADIAN);
  if (ifHidePieLabel(sliceNumber as number, percent! as number)) {
    return null;
  }
  return (
    <g><line x1={x1} x2={x2} y1={y1} y2={y2} strokeWidth={1} stroke={stroke} /></g>
  );
};

export const CustomPieLabel = (props) => {
  const [showBackground, setShowBackground] = React.useState(false);
  const { labelFontSize, lineLength, lineMargin, sliceNumber, cx, cy, midAngle, outerRadius, percent, fill } = props;

  if (ifHidePieLabel(sliceNumber as number, percent! as number)) {
    return null;
  }

  const RADIAN = Math.PI / 180;
  const distance = lineLength + lineMargin!;
  const x = cx + (outerRadius! + distance) * Math.cos(-midAngle * RADIAN);
  const y = cy + (outerRadius! + distance) * Math.sin(-midAngle * RADIAN);

  const formatPercent: number = +(percent * 100).toFixed(0);
  const finalValue = `${formatPercent < 1 ? "<1" : formatPercent}%`;

  const labelTextMargin = 5;
  const labelText = document.createElement("canvas").getContext("2d")!;
  labelText.font = `${labelFontSize}px ${CHART_FONT_FAMILY}`;
  const labelTextWidth = labelText.measureText(finalValue).width;

  return (
    <g>
      {showBackground && <rect className="hover" x={x > cx ? x - labelTextMargin : x - labelTextMargin - labelTextWidth} y={y - labelFontSize / 2 - labelTextMargin} rx={4} ry={4} width={labelTextWidth + labelTextMargin * 2} height={labelFontSize + labelTextMargin * 2} stroke="none" fill={"#c5c5c5"} />}
      <text
        x={x}
        y={y}
        fill={fill}
        className="cursor-pointer"
        style={{ fontSize: labelFontSize, fontFamily: CHART_FONT_FAMILY }}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        onMouseEnter={() => setShowBackground(true)}
        onMouseLeave={() => setShowBackground(false)}
      >
        {finalValue}
      </text>
    </g>
  );
};

export const CustomMetricLabel = (decimalPoints: TypeDecimalPoints, bigLabelFontSize: number | undefined, smallLabelFontSize: number | undefined) => (props: any): JSX.Element => {
  const { cx, cy, value, outerRadius, index } = props;
  // Only the first data will be used for Metric Chart middle label
  if (index !== 0) {
    return <></>;
  }
  const description = (props["Measured quantity"] || props["Calc table name"]).slice(0, 40);
  return (
    <g>
      <Text
        x={cx}
        y={cy}
        fill={CHART_PRIMARY_COLOR}
        style={{ fontSize: bigLabelFontSize || outerRadius / 2, fontFamily: CHART_FONT_FAMILY, fontWeight: "bold" }}
        textAnchor="middle"
        verticalAnchor="end"
      >
        {formatValue(value, [props["Calc format"] || props["Measured quantity"]], decimalPoints)}
      </Text>
      <Text
        x={cx}
        y={cy + outerRadius / 6}
        fill={CHART_PRIMARY_COLOR}
        width={Math.min(outerRadius * 2 - 10, 180)} // When the width exceeds 180px, <Text> does not wrap the text.
        style={{ fontSize: `${smallLabelFontSize || outerRadius / 5}px`, fontFamily: CHART_FONT_FAMILY }}
        textAnchor="middle"
        verticalAnchor="start"
      >
        {description}
      </Text>
      {/* @TODO: Uncomment icon section code when needed */}
      {/* <foreignObject x={cx - 35} y={cy + valueFontSize / 2 + 15} width="100%" height="100%">
        <Icon name="assistive listening systems" style={{ color: "#ffffff" }} size="huge" />
      </foreignObject> */}
    </g>
  );
};

const CustomToolTipLayout = (props) => {
  const { tableName, tooltipName, chartTables, color, value, rawValue } = props;
  return (
    <div style={{ fontFamily: CHART_FONT_FAMILY }} >
      {tableName && ifShowTableNames(chartTables) && <p className="fs-1000 fw-700 mb-2 text-left">{tableName}</p>}
      <div className="fs-0875 d-flex align-items-start mt-0 text-left">
        <div className="mr-2" style={{ minHeight: 16, minWidth: 16, backgroundColor: color }}></div>
        <p>
          <span>{`${tooltipName}: `}</span>
          <span className="fw-bold">{value}</span>
          {rawValue && <span className="fw-bold">{` (${rawValue})`}</span>}
        </p>
      </div>
    </div>
  );
};

export const CustomToolTip = (tooltipDataKey: string | undefined, chartTables: any[], percentage: boolean, decimalPoints: TypeDecimalPoints, showRawValue: boolean, chartType: string) => (props) => {
  const isPercentage = ifFormattedAsPercentage(percentage, chartType, chartTables);
  const { payload } = props;
  if (tooltipDataKey && props.active) {
    return (
      <div className="bg-white border p-2 rounded" style={{ maxWidth: "200px" }}>
        {
          payload?.length &&
          payload.filter(x => x.dataKey === tooltipDataKey)
            .map(({ name, value, color, payload }) => { // payload here is only for Pie Chart color
              let tableName: string = "";
              let tooltipName: string = "";
              if (chartTables.length > 0) {
                const tableID = Number(name.match(/^(\d+)\#/)[1]); // Get table.id from dataKey
                const findTable = chartTables.find(table => table.id === tableID);
                tableName = findTable.name || "";
                if (findTable.type === "result") {
                  name = name.replace(/^\d+[#]/g, "").trim(); // Remove table.id from name and white space
                  if (name) {
                    // For single-row table pie chart, the name looks like this: "(2018)". So needs to remove "()" to show "name" properly
                    if (chartType === "donut" && name.startsWith("(") && name.endsWith(")")) {
                      name = name.slice(1, name.length - 1);
                    }
                    tooltipName = name;
                  } else {
                    tooltipName = payload.label0;
                  }
                  if (tooltipName.toString().includes(":::")) {
                    tooltipName = tooltipName.split(":::")[1];
                  }
                } else {
                  tooltipName = findTable.name || "";
                }
              }
              const formattedValue = formatValue(value, [isPercentage ? "(%)" : "Decimal"], decimalPoints);
              const rawValue = showRawValue && isProportionsOrPercentageChart(chartType) ? formatValue(payload[`${tooltipDataKey}(raw)`], [percentage || chartTables.every(t => t.format === "Percent") ? "(%)" : "Decimal"], decimalPoints) : undefined;
              return (
                <CustomToolTipLayout
                  key={`${name} - ${value}`}
                  tableName={tableName}
                  tooltipName={tooltipName}
                  chartTables={chartTables}
                  color={color || payload.fill}
                  value={formattedValue}
                  rawValue={rawValue}
                />
              );
            })
        }
      </div>
    );
  }
  return null;
};

export const CustomPieToolTip = (chartTables: any[], isPercentage: boolean, decimalPoints: TypeDecimalPoints) => (props) => {
  const { payload } = props;
  if (payload.length) {
    return (
      <div className="bg-white border p-2 rounded" style={{ maxWidth: "200px" }}>
        {payload?.map(({ name, value, color, payload }) => { // payload here is only for Pie Chart color
          const tableName = chartTables[0]?.name;
          const tooltipName = name.toString().includes(":::") ? name.split(":::")[1] : name;
          const formattedValue = formatValue(value, [isPercentage ? "(%)" : "Decimal"], decimalPoints);
          return (
            <CustomToolTipLayout
              key={`${name} - ${value}`}
              tableName={tableName}
              tooltipName={tooltipName}
              chartTables={chartTables}
              color={color || payload.fill}
              value={formattedValue}
            />
          );}
        )}
      </div>
    );
  }
  return null;
};

export const CustomMetricToolTip = (filteredChartTables: ObjectAny[], columns: IFilter[], decimalPoints: TypeDecimalPoints) => (props: any): JSX.Element | null => {
  const { payload } = props;
  if (payload.length && typeof(payload[0].payload.legendItemIdx) === "number") {
    return (
      <div className="bg-white border p-2 rounded" style={{ maxWidth: "200px" }}>
        {payload?.map(({ value, payload }, idx) => {
          const tableName = payload["Result table name"] || payload["Calc table name"];
          const formattedValue = formatValue(value, [payload["Calc format"] || payload["Measured quantity"]], decimalPoints);
          const { legendItemIdx, legendItemChildIdx } = payload;
          const legendItems = generateMetricLegendItems(filteredChartTables, columns);
          let tooltipItems = [...legendItems];
          if (filteredChartTables.length === 1) {
            tooltipItems = [...legendItems[0]];
          }
          const tooltipItem = (typeof (legendItemChildIdx) === "number"
            ? tooltipItems[legendItemIdx][legendItemChildIdx]
            : tooltipItems[legendItemIdx])
              .map(label => label.toString().includes(":::") ? label.split(":::")[1] : label);
          return (
            <CustomToolTipLayout
              key={`${idx} - ${value}`}
              tableName={tableName}
              tooltipName={tooltipItem.join(" | ")}
              chartTables={filteredChartTables}
              color={payload.fill}
              value={formattedValue}
            />
          );
        })}
      </div>
    );
  }
  return null;
};

export const CustomAxisTick = (labelLength: number, angle: number | undefined, labelFont: number | undefined, tickWidth: number) => (axis: "x" | "y") => (props: any): JSX.Element => {
  const { x, y, payload } = props;
  let text = payload.value.toString().includes(":::") ? payload.value.toString().split(":::")[1] : payload.value;

  // User-defined label length
  if (labelLength > 0 && labelLength < text.toString().length) {
    text = `${text.toString().slice(0, labelLength)}...`;
  }

  let textAnchor: "end" | "start" | "middle" = "end";
  if (axis === "x") {
    if (angle && angle > 0) {
      textAnchor = "start";
    }
    if (angle === 0) {
      textAnchor = "middle";
    }
  } else {
    if (angle === -90 || angle === 90) {
      textAnchor = "middle";
    }
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <Popup
        size="mini"
        position="bottom center"
        content={payload.value.toString().includes(":::") ? payload.value.toString().replace(":::", " - ") : payload.value}
        inverted
        trigger={
          <Text
            x={0}
            y={0}
            dy={axis === "y" && (angle && angle < 0 || !angle) ? - (labelFont || defaultLabelFont) : 0}
            textAnchor={textAnchor}
            verticalAnchor="start"
            width={tickWidth}
            fill={CHART_SECONDARY_COLOR}
            transform={`rotate(${(angle !== undefined) ? angle : defaultAngle})`}
            style={{ fontSize: `${labelFont || defaultLabelFont}px`, fontFamily: CHART_FONT_FAMILY, fill: CHART_SECONDARY_COLOR }}
            maxLines={2}
          >
            {text}
          </Text>
        }
      />
    </g>
  );
};
