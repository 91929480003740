import * as React from "react";
import { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  Container as UIContainer,
  Form,
  Button,
  Grid,
  Icon,
  Input,
  Divider,
  Card,
  Label,
  Popup,
  Message,
  Segment,
  SemanticICONS,
  Dropdown,
} from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { includes, cloneDeep, uniq } from "lodash";
import Store from "common/store";
import Protected from "component/protected";
import { Collapsible } from "component/Collapsible";
import { required, composeValidators } from "common/helpers/finalForm";
import { FieldInput } from "./includes/FieldInput";
import { VariableModal } from "./includes/VariableModal";
import { TotalsModal } from "./includes/TotalsModal";
import { CategoryModal } from "./includes/CategoryModal";
import { TableSchemaPreview } from "./includes/TableSchemaPreview";
import { validateSchema, canAggregateOnSchema, getAggregateSchema } from "./includes/helpers";
import { collator, getDimType } from "common/helpers/data";
import { datasets as dataApi } from "common/api";
import { oldToNewVariableTypeTranslator, StyleButtonGroup, TypeTooltip } from "./includes/widgets";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { AggregationModal } from "./includes/AggregationModal";
import { Aggregation } from "common/helpers/dataset";
import { Accordion } from "component/Accordion";
import { FieldTextarea } from "./includes/FieldTextarea";

interface IDatasetTemplatePage {
  store?: Store;
}

interface IVariableAction {
  label: string;
  icon: SemanticICONS;
  action: () => void;
  disabled?: boolean;
}

const Container = ({ store }: IDatasetTemplatePage): JSX.Element | null => {
  const [variables, setVariables] = React.useState([]);
  const loadVariables = async () => {
    const res: any = await dataApi.post("v2/qs", {}, store!.token ?? "");
    const json = res?.body;
    if (json) {
      const dims: never[] = uniq(json.data.datasets.map(ds => ds.dimensions.map(dim => dim.name)).reduce((acc, next) => [...acc, ...next], []));
      dims.sort((a, b) => collator.compare(a, b));
      setVariables(dims);
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    store!.dataset.getDatasets();
    loadVariables();
  }, []);

  const { datasets } = store!.dataset;

  if ((datasets?.length || 0) < 1) {
    return null;
  }
  return <Component store={store} qsVariables={variables} />;
};

// default state for newly created schema
const newSchema = { rows: [{ variable: "Measured quantity", totals: "none", categories: [] }], columns: [], aggregations: [] };

const Component = ({ store, qsVariables }) => {
  const { datasets } = store!.dataset;
  const { datasetID, templateID, tableID } = useParams<{ datasetID: string; templateID: string; tableID: string }>();
  const history = useHistory();

  const dataset = datasets!.find(dataset => dataset.id === Number(datasetID));
  const template = dataset!.templates.find(template => template.id === Number(templateID));
  const table = tableID ? template!.tables.find(item => item.id === Number(tableID)) : null;

  // destructure newSchema for any old schemas that don't have aggregation array
  const [schema, setSchema] = useState({ ...newSchema, ...(table?.schema || {}) });
  const [selected, setSelected] = useState({ type: "", index: 0 }); // state for selected variable
  const [variableModal, setVariableModal] = useState(""); // "rows" || "columns" || ""
  const [categoryModal, setCategoryModal] = useState(false);
  const [totalsModal, setTotalsModal] = useState(false);
  const [aggModal, setAggModal] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const { rows, columns, aggregations } = schema;
  const selectedVariableData = !!selected.type && schema[selected.type][selected.index];
  const selectedVariableName = selectedVariableData?.variable;

  const handleSave = async (values): Promise<any> => {
    const { name: nameStr } = values;
    const name = nameStr.trim();
    let tableNameExists;
    for (const table of template?.tables) {
      // check only applies if testing other tables
      if (table.name === name && table.id !== +tableID) {
        tableNameExists = true;
        break;
      }
    }
    if (tableNameExists) {
      // @TODO - this part of the validation should be moved to the backend
      return { [FORM_ERROR]: "This table name already exists in the template" };
    }
    // last check on schema before request
    const schemaError = validateSchema(schema);
    if (schemaError) {
      return { [FORM_ERROR]: `Failed to save due to invalid schema (${schemaError})` };
    }
    if (table) {
      // update
      const success = await store!.dataset.updateDatasetTemplateTable(table.id, { name, schema });
      if (!success) {
        return { [FORM_ERROR]: "Request to create Dataset Template Table failed." };
      }
    } else {
      // create
      const success = await store!.dataset.createDatasetTemplateTable({ dataset_template_id: template.id, name, schema });
      if (!success) {
        return { [FORM_ERROR]: "Request to create Dataset Template Table failed." };
      }
    }
    history.push(`/datasets/${datasetID}/templates/${templateID}`);
  };
  const onAddVariable = variableStr => {
    const variableName = variableStr.trim();
    if (!variableName) {
      return "This field is required";
    } else if (variableName.toLowerCase() === "value") {
      return `${variableName} cannot be used as a Variable name, internal use only`;
    }
    const variableNames = [...rows, ...columns].map(item => item.variable.toLowerCase());
    if (includes(variableNames, variableName.toLowerCase())) {
      return "The entered variable already exists in the schema"; // for error handling duplicates
    }
    setSchema({ ...schema, [variableModal]: [...schema[variableModal], { variable: variableName, categories: [], totals: "none" }] });
    setSelected({ type: variableModal, index: schema[variableModal].length });
    return;
  };
  const removeSelectedVariable = () => {
    if (selectedVariableName === "Measured quantity") {
      alert("Cannot remove \"Measured quantity\" variable");
      return;
    }
    const next = cloneDeep(schema);
    next[selected.type].splice(selected.index, 1);
    setSchema(next);
    setSelected({ type: "", index: 0 });
  };
  const renameVariable = ({ name }): any => {
    const variableName = name.trim();
    if (selectedVariableName === "Measured quantity") {
      return { name: "Cannot rename \"Measured quantity\" variable" };
    }
    const variableNames = [...rows, ...columns].map(item => item.variable.toLowerCase());
    if (includes(variableNames, variableName.toLowerCase()) && selectedVariableName.toLowerCase() !== variableName.toLowerCase()) {
      return { name: `${variableName} is already set on this schema` };
    }
    const nextSchema = cloneDeep(schema);
    nextSchema[selected.type][selected.index].variable = variableName;
    setSchema(nextSchema);
    setRenaming(false);
  };
  // move from rows to columns and vice versa
  const pivotSelectedVariable = () => {
    const destination = selected.type === "rows" ? "columns" : "rows";
    const next = cloneDeep(schema);
    const [move] = next[selected.type].splice(selected.index, 1);
    next[destination].push(move);
    setSchema(next);
    setSelected({ type: destination, index: next[destination].length - 1 });
  };
  const updateSelectedVariableTotals = nextVal => {
    const next = cloneDeep(schema);
    next[selected.type][selected.index].totals = nextVal;
    setSchema(next);
  };
  const onAddCategory = categoryStrs => {
    const categoryNames = categoryStrs.map(str => str.toString().trim());
    const errorMessage: string[] = [];
    if (categoryNames.length === 0) {
      errorMessage.push("No categories to be added");
    } else {
      let hasTotal = false;
      categoryNames.forEach(categoryName => {
        if (categoryName.toLowerCase() === "total") {
          // "Total" is never added directly as a category but via config
          if (schema[selected.type][selected.index].totals !== "none") {
            hasTotal = true;
          } else {
            updateSelectedVariableTotals("manual");
          }
        }
      });
      if (hasTotal) {
        errorMessage.push("This variable already has totals configured, check the totals configuration.");
      }
    }
    if (errorMessage.length > 0) {
      return errorMessage;
    } else {
      const next = cloneDeep(schema);
      categoryNames.forEach(categoryName => {
        next[selected.type][selected.index].categories.push(categoryName);
      });
      setSchema(next);
      return;
    }
  };
  const removeCategory = deleteIdx => {
    const next = cloneDeep(schema);
    next[selected.type][selected.index].categories.splice(deleteIdx, 1);
    setSchema(next);
  };
  const onGenerateCategories = generatedCategories => {
    const next = cloneDeep(schema);
    next[selected.type][selected.index].categories = generatedCategories;
    setSchema(next);
    return;
  };
  const renameCategory = (renamedCategoryStr, idx): any => {
    const renamedCategory = renamedCategoryStr.trim();
    const existingCategories = selectedVariableData.categories.map(cat => cat.toLowerCase());
    const oldName = selectedVariableData.categories[idx];
    if (includes(existingCategories, renamedCategory.toLowerCase()) && oldName.toLowerCase() !== renamedCategory.toLowerCase()) {
      return { renamedCategory: `This variable already contains the category ${renamedCategory}` };
    }
    const next = cloneDeep(schema);
    next[selected.type][selected.index].categories[idx] = renamedCategory;
    setSchema(next);
  };
  const moveVariable = (type: "rows" | "columns", idx: number, movement: -1 | 1) => {
    const next = cloneDeep(schema);
    // splice out variable and insert at desired index
    const [moving] = next[type].splice(idx, 1);
    next[type].splice(idx + movement, 0, moving);
    setSchema(next);
    setSelected({ type: "", index: 0 }); // reset selected on move variable
  };
  const moveCategory = (idx: number, movement: -1 | 1) => {
    const next = cloneDeep(schema);
    const [moving] = next[selected.type][selected.index].categories.splice(idx, 1);
    next[selected.type][selected.index].categories.splice(idx + movement, 0, moving);
    setSchema(next);
  };
  const variableActions: IVariableAction[] = [
    { label: "Rename", icon: "edit", action: () => setRenaming(true), disabled: selectedVariableName === "Measured quantity" },
    { label: `Move to ${selected.type === "rows" ? "Columns" : "Rows"}`, icon: "retweet", action: () => pivotSelectedVariable() },
    { label: "Configure Totals", icon: "calculator", action: () => setTotalsModal(true) },
    { label: "Delete", icon: "trash alternate", action: () => removeSelectedVariable(), disabled: selectedVariableName === "Measured quantity" },
  ];
  const createAggregation = (aggregation: Aggregation): string | void => {
    // validate that aggregation is not a duplicate
    const isDuplicate = schema.aggregations
      .find(agg => agg.variable === aggregation.variable && agg.aggVariable === aggregation.aggVariable && agg.type === aggregation.type);
    if (isDuplicate) {
      return `"${aggregation.variable}" (${aggregation.type.toUpperCase()}) aggregation on "${aggregation.aggVariable}" already exists on this Schema.`;
    }
    const next = cloneDeep(schema);
    next.aggregations.push(aggregation);
    setSchema(next);
  };
  const removeAggregation = (idx: number): void => {
    const next = cloneDeep(schema);
    next.aggregations.splice(idx, 1);
    setSchema(next);
  };

  return (
    <UIContainer className="seer-colours">
      <Helmet>
        <title>{`${table ? "Edit" : "Add"} Table - ${template.name} #${templateID} - ${dataset.name} #${datasetID}`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { label: "Datasets", pathname: "/datasets" },
          { label: dataset.name, pathname: `/datasets/${datasetID}` },
          { label: template.name, pathname: `/datasets/${datasetID}/templates/${templateID}` },
          { label: table ? table.name : "Add Table", pathname: `/datasets/${datasetID}/templates/${templateID}/tables/${table ? table.id : "new"}` },
        ]}
      />
      <FinalForm
        initialValues={{ name: table?.name || "" }}
        onSubmit={handleSave}
        render={({ handleSubmit, submitError, submitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between mb-5">
              <div style={{ width: "29%" }}>
                <h2 className="text-secondary">Table Name</h2>
                <Field
                  name="name"
                  component={FieldInput}
                  validate={composeValidators(required)}
                />
              </div>
              <div>
                <Link to={`/datasets/${datasetID}/templates/${templateID}`}>
                  <Button className="mr-2" type="button">Cancel</Button>
                </Link>
                <Button className="bg-primary text-white bg-hover-red" type="submit" disabled={submitting}>Save Table <Icon className="save ml-2 mr-0" /></Button>
              </div>
            </div>
            {submitError && (
              <p className="text-danger"><b>{submitError}</b></p>
            )}
          </Form>
        )}
      />

      <div className="mb-5">
        <h2 className="text-secondary">Table Schema</h2>
        <Grid className="rounded-2">
          <Grid.Row stretched>
            <Grid.Column width={5}>
              <Segment className="shadow-none">
                <h3 className="text-secondary">Table Variables</h3>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h4 className="text-muted mb-0">Rows</h4>
                  <Button size="tiny" className="bg-primary text-white bg-hover-red px-3 py-2" onClick={() => setVariableModal("rows")}>Add<Icon name="plus" className="ml-2 mr-0" /></Button>
                </div>
                {rows.map((row, idx) => {
                  const isSelected = selected.type === "rows" && selected.index === idx;
                  const moveUpEnabled = rows.length > 1 && idx > 0;
                  const moveDownEnabled = rows.length > 1 && idx < rows.length - 1;
                  return (
                    <StyleButtonGroup
                      size="small"
                      key={`${row.variable}-${idx}`}
                      fluid
                      className="mb-2"
                      active={isSelected}
                    >
                      <Button
                        onClick={() => setSelected(isSelected ? { type: "", index: 0 } : { type: "rows", index: idx })}
                        style={{ maxWidth: "calc(100% - 60px)" }}
                      >
                        {row.variable}
                        {row.variable === "Measured quantity" && <TypeTooltip type="Measured quantity" icon="lock" className="ml-2" />}
                      </Button>
                      <Button
                        color="grey"
                        icon="arrow up"
                        className="flex-grow-0 px-2"
                        disabled={!moveUpEnabled}
                        onClick={() => moveVariable("rows", idx, -1)}
                      />
                      <Button
                        color="grey"
                        icon="arrow down"
                        className="flex-grow-0 px-2"
                        disabled={!moveDownEnabled}
                        onClick={() => moveVariable("rows", idx, 1)}
                      />
                    </StyleButtonGroup>
                  );
                })}
                <Divider />
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h4 className="text-muted mb-0">Columns</h4>
                  <Button size="tiny" className="bg-primary text-white bg-hover-red px-3 py-2" onClick={() => setVariableModal("columns")}>Add<Icon name="plus" className="ml-2 mr-0" /></Button>
                </div>
                {columns.map((column, idx) => {
                  const isSelected = selected.type === "columns" && selected.index === idx;
                  const moveUpEnabled = columns.length > 1 && idx > 0;
                  const moveDownEnabled = columns.length > 1 && idx < columns.length - 1;
                  return (
                    <StyleButtonGroup
                      size="small"
                      key={`${column.variable}-${idx}`}
                      fluid
                      className="mb-2"
                      active={isSelected}
                    >
                      <Button
                        onClick={() => setSelected(isSelected ? { type: "", index: 0 } : { type: "columns", index: idx })}
                        style={{ maxWidth: "calc(100% - 60px)" }}
                      >
                        {column.variable}
                        {column.variable === "Measured quantity" && <TypeTooltip type="Measured quantity" icon="lock" className="ml-2" />}
                      </Button>
                      <Button
                        color="grey"
                        icon="arrow up"
                        className="flex-grow-0 px-2"
                        disabled={!moveUpEnabled}
                        onClick={() => moveVariable("columns", idx, -1)}
                      />
                      <Button
                        color="grey"
                        icon="arrow down"
                        className="flex-grow-0 px-2"
                        disabled={!moveDownEnabled}
                        onClick={() => moveVariable("columns", idx, 1)}
                      />
                    </StyleButtonGroup>
                  );
                })}
              </Segment>
            </Grid.Column>
            <Grid.Column width={11}>
              <Segment className="shadow-none">
                <div className="d-flex justify-content-between">
                  <h3 className="text-secondary">Variable Configuration</h3>
                  {
                    selected.type &&
                    <div className="d-flex align-items-start justify-content-end fs-1000 fw-600" style={{ marginTop: 3 }}>
                      {
                        variableActions.map((variable, index) => {
                          const { label, icon, action, disabled } = variable;
                          return (
                            <p className={`${disabled ? "text-medium" : "text-dark text-hover-secondary cursor-pointer"} my-0 ml-3`} onClick={disabled ? undefined : action} key={index}>
                              <Popup
                                trigger={<Icon name={icon} />}
                                content={label}
                                position="top center"
                                size="mini"
                                inverted
                              />
                              <span className="d-none d-xl-inline-block">{label}</span>
                            </p>
                          );
                        })
                      }
                    </div>
                  }
                </div>
                {selected.type ? (
                  <div>
                    <h4 className="text-muted">Variable Name</h4>
                    {renaming ? (
                      <FinalForm
                        onSubmit={renameVariable}
                        initialValues={{ name: selectedVariableName }}
                        render={({ handleSubmit }) => (
                          <Form onSubmit={handleSubmit}>
                            <Field
                              name="name"
                              component={FieldInput}
                              validate={composeValidators(required)}
                            />
                            <Button size="mini" type="button" className="mr-2" onClick={() => setRenaming(false)}>Cancel</Button>
                            <Button size="mini" type="submit" className="bg-primary text-white bg-hover-red">Update</Button>
                          </Form>
                        )}
                      />
                    ) : (
                      <Input
                        value={selectedVariableName}
                        disabled
                        fluid
                      />
                    )}
                    <Divider />
                    <h4 className="text-muted mt-0">Variable Type</h4>
                    <h4 className="text-secondary mt-0">
                      {oldToNewVariableTypeTranslator[getDimType(selectedVariableName)]}
                      <TypeTooltip type={getDimType(selectedVariableName)} icon="info circle" className="ml-2" />
                    </h4>
                    <Divider />
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h4 className="text-muted mb-0">Variable Categories</h4>
                      <Button size="tiny" className="bg-primary text-white bg-hover-red px-3 py-2" onClick={() => setCategoryModal(true)}>Add<Icon name="plus" className="ml-2 mr-0" /></Button>
                    </div>
                    <Card className="mb-2 pt-2" fluid>
                      <div className="d-flex flex-column overflow-auto" style={{ minHeight: 30, maxHeight: 250 }}>
                        {selectedVariableData.categories.map((category, idx) => {
                          const moveUpEnabled = selectedVariableData.categories.length > 1 && idx > 0;
                          const moveDownEnabled = selectedVariableData.categories.length > 1 && idx < selectedVariableData.categories.length - 1;
                          return (
                            <Collapsible
                              render={({ isOpen, setIsOpen }) => (
                                <Label
                                  key={`${selectedVariableName}${category}`}
                                  size="medium"
                                  className="mx-2 mb-2"
                                >
                                  {category}
                                  <Icon name="close" className="float-right" onClick={() => removeCategory(idx)} />
                                  <Popup
                                    open={isOpen}
                                    on="click"
                                    onOpen={() => setIsOpen(true)}
                                    onClose={() => setIsOpen(false)}
                                    position="top center"
                                    trigger={<Icon name="pencil" className="float-right mr-2" onClick={() => setIsOpen(true)} />}
                                    content={(
                                      <FinalForm
                                        onSubmit={({ renamedCategory }) => {
                                          const errors = renameCategory(renamedCategory, idx);
                                          if (errors) {
                                            return errors;
                                          }
                                          setIsOpen(false);
                                        }}
                                        initialValues={{ renamedCategory: category }}
                                        render={({ handleSubmit }) => (
                                          <Form onSubmit={handleSubmit}>
                                            <Field
                                              label="Rename Category"
                                              name="renamedCategory"
                                              component={FieldTextarea}
                                              validate={composeValidators(required)}
                                            />
                                            <Button type="button" className="mr-2" onClick={() => setIsOpen(false)}>Cancel</Button>
                                            <Button type="submit" className="bg-primary text-white bg-hover-red">Update</Button>
                                          </Form>
                                        )}
                                      />
                                    )}
                                  />
                                  <Icon name="arrow up" className="float-right" disabled={!moveUpEnabled} onClick={() => moveCategory(idx, -1)} />
                                  <Icon name="arrow down" className="float-right" disabled={!moveDownEnabled} onClick={() => moveCategory(idx, 1)} />
                                </Label>
                              )}
                            />
                          );
                        })}
                        {selectedVariableData.totals !== "none" && (
                          <Label
                            key={`${selectedVariableName}-totals-${selectedVariableData.totals}`}
                            size="medium"
                            className="mx-2 mb-2"
                          >
                            Total
                            <Icon name="close" className="float-right" onClick={() => updateSelectedVariableTotals("none")} />
                            <Icon name="setting" className="float-right" onClick={() => setTotalsModal(true)} />
                          </Label>
                        )}
                      </div>
                    </Card>
                  </div>
                ) : (
                  <Message className="py-2 px-3" compact warning>
                    <Icon name="info circle" />
                    Select a Row or Column Variable to configure
                  </Message>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <VariableModal
          isOpen={!!variableModal}
          type={variableModal}
          close={() => setVariableModal("")}
          onAdd={onAddVariable}
          qsVariables={qsVariables}
        />
        {selected.type && (
          <>
            <TotalsModal
              isOpen={totalsModal}
              close={() => setTotalsModal(false)}
              updateTotals={updateSelectedVariableTotals}
              variableData={selectedVariableData}
            />
            <CategoryModal
              isOpen={categoryModal}
              close={() => setCategoryModal(false)}
              onAdd={onAddCategory}
              variableData={selectedVariableData}
              onGenerate={onGenerateCategories}
            />
          </>
        )}
      </div>
      <div className="mb-6">
        <h2 className="text-secondary">Table Preview</h2>
        <TableSchemaPreview schema={schema} />
      </div>
      <hr />
      <div className="mb-6 pb-6">
        <div className="d-flex justify-content-between mb-3">
          <h2 className="text-secondary">
            Aggregations
            <Popup
              inverted
              content={(
                <div>
                  Schema aggregations are currently only available to certain Variables. These include:
                  <ul>
                    <li>Date</li>
                    <li>Year Month</li>
                    <li>Year Quarter</li>
                  </ul>
                </div>
              )}
              trigger={<Icon size="small" name="info circle" className="ml-2" />}
            />
          </h2>
          <Button
            size="small"
            className="bg-primary text-white bg-hover-red"
            disabled={!canAggregateOnSchema(schema)}
            onClick={() => setAggModal(true)}
          >
            Create aggregation <Icon name="plus" size="small" className="ml-2" />
          </Button>
        </div>
        <AggregationModal
          isOpen={aggModal}
          close={() => setAggModal(false)}
          onSubmit={createAggregation}
          schema={schema}
        />
        <div>
          {!aggregations?.length ? (
            <p className="text-muted">No aggregations configured.</p>
          ) : (
            <div>
              <p className="text-muted">Click the aggregation name to preview it's schema.</p>
              <Accordion
                styled
                fluid
                items={aggregations.map((aggregation, idx) => {
                  const aggSchema = getAggregateSchema(schema, aggregation);
                  return {
                    key: `${aggregation.variable}-${aggregation.aggVariable}-${aggregation.type}-${idx}`,
                    title: (
                      <>{aggregation.variable} <Label className="ml-2" basic size="small">{aggregation.type.toUpperCase()}</Label></>
                    ),
                    content: (
                      <div>
                        {aggSchema ? (
                          <TableSchemaPreview schema={aggSchema} />
                        ) : (
                          <p className="text-muted">The configuration for this aggregation is invalid.</p>
                        )}
                      </div>
                    ),
                    actions: [
                      <Dropdown.Item
                        key="delete"
                        icon="trash alternate outline"
                        text="Delete"
                        onClick={() => {
                          if (window.confirm(`Do you really want to delete the aggregation "${aggregation.variable}"`)) {
                            removeAggregation(idx);
                          }
                        }}
                      />,
                    ],
                  };
                })}
              />
            </div>
          )}
        </div>
      </div>
    </UIContainer>
  );
};

export const DatasetTemplateTable = Protected(inject("store")(observer(Container)));
