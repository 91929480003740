// date and time related helpers
export const dateSecondsFromNow = (seconds: number): Date => {
  const now = new Date();
  const then = now.getTime() + (1000 * seconds);
  return new Date(then);
};

export const seconds = {
  minute: 60,
  hour: 60 * 60,
  day: 60 * 60 * 24,
  week: 60 * 60 * 24 * 7,
  year: 60 * 60 * 24 * 365,
};
