import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Container, Icon, Loader, Dimmer, Segment } from "semantic-ui-react";
import { parse } from "papaparse";
import { backendUrl } from "common/constants";
import Store from "common/store";
import { RecordTable } from "component/RecordTable/RecordTable";
import { Button } from "component/UI/Button";
import { COLORS } from "component/UI/common";

// const PREVIEW_ROW_LIMIT = 1000;
const VISIBLE_ROW_LIMIT = 15; // max number rows rendered at any one time

interface Props {
  datasetID: number;
  store?: Store;
}

const Component = (props: Props) => {
  const store = props.store!;
  const { datasetID } = props;
  const [data, setData] = React.useState<string | null>();
  const [page, setPage] = React.useState(1);
  const [records, setRecords] = React.useState<string[][]>([]); // records to display
  const [loading, setLoading] = React.useState(true);

  // initialise data
  React.useEffect(() => {
    const abortController = new AbortController();
    const init = async () => {
      const blob: Blob | null = await fetch(`${backendUrl}/datasets/${datasetID}/url-download`, {
        headers: { "X-Token": store.token! },
        signal: abortController.signal,
      }).then(res => res.blob()).catch(_e => null);
      if (abortController.signal.aborted) {
        return;
      }
      if (blob) {
        const text = await blob.text();
        setData(text);
      }
      setLoading(false);
    };
    init();
    return () => abortController.abort();
  }, []);

  // on load data and page changes, update displayed records
  React.useEffect(() => {
    if (data) {
      setLoading(true);
      let readCount = 0;
      const rows: string[][] = [];
      const preview = page * VISIBLE_ROW_LIMIT + 1; // how many rows to load for preview
      const skipBelow = preview - VISIBLE_ROW_LIMIT; // only want the actual page of rows
      parse(data, {
        preview: page * VISIBLE_ROW_LIMIT + 1, // add 1 row for header column
        step: (result: any) => {
          readCount++;
          if (readCount === 1 || readCount > skipBelow) {
            rows.push(result.data as string[]);
          }
        },
        complete: () => {
          setRecords(rows);
          setLoading(false);
        },
        worker: true,
        skipEmptyLines: true,
      });
    }
  }, [data, page]);

  const displayRowFrom = page * VISIBLE_ROW_LIMIT - VISIBLE_ROW_LIMIT + 1;
  const displayRowTo = displayRowFrom + records.length - 2;

  return (
    <Container>
      <Segment className={!data || !records.length ? "py-5" : ""} style={{ backgroundColor: COLORS.grey100 }}>
        <Dimmer active={loading} inverted>
          <Loader size='large'>Loading</Loader>
        </Dimmer>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className="m-0">
            {!!records.length && (
              <>
                {records.length === 1 ? (
                  <span className="fw-400">End of table reached.</span>
                ) : (
                  <>Displaying rows {displayRowFrom} - {displayRowTo}</>
                )}
              </>
            )}
          </h4>
          {displayRowTo >= VISIBLE_ROW_LIMIT &&
            <Button.Group basic>
              <Button disabled={loading} icon onClick={() => setPage(Math.max(page - 1, 1))}><Icon name="angle up" /></Button>
              <Button disabled={loading} icon onClick={() => setPage(records.length < VISIBLE_ROW_LIMIT ? page : page + 1)}><Icon name="angle down" /></Button>
            </Button.Group>
          }
        </div>
        {!!records.length && (
          <RecordTable records={records} rowsToDisplay={Math.min(VISIBLE_ROW_LIMIT, records.length - 1)} />
        )}
      </Segment>
    </Container>
  );
};

export const DatasetURLView = inject("store")(observer(Component));
