import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject } from "mobx-react";

import { ColorPalettesList } from "component/ColorPalettesList";

export const ColorPaletteModal = inject("store")(({ store, isOpen, close }) => (
  <Modal open={isOpen} onClose={close}>
    <Modal.Content>
      <h3 className="text-secondary">Colour Palettes</h3>
      <ColorPalettesList store={store} />
    </Modal.Content>
    <Modal.Actions>
      <Button positive onClick={close}>Save</Button>
    </Modal.Actions>
  </Modal>
));
