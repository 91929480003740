import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
// import Store from "common/store/insightbuilder";

import { ExploreTablePrompt } from "./ExploreTablePrompt";
import { MultiIndexTable } from "component/table/MultiIndexTable";
import Store from "common/store";

interface IExploreTable {
  store?: Store;
  update?: boolean;
  suitcaseId?: any;
};

// const ExploreTableComponent : React.FC<IExploreTable> = (props) => {

export const ExploreTable: React.FC<IExploreTable> = inject("store")(
  observer((props) => {
  const { store } = props;
  const { builder: insightbuilder } = store!;
  // const ZD = insightbuilder.values[insightbuilder.makeName("", "", "", true, true)]; // Zero D value
  const insightCode = window.location.pathname.includes("builder/new") ? null : insightbuilder?.parent?.insight?.result?.code;

  const hidePrompt =  (
    insightbuilder.columns.length &&
    insightbuilder.tables.some(t => t.results?.length > 0)
  ) || insightCode;

  // TODO: Refactor the conditional logic when we don't need draft table section. Consider using (showTable)
  // 'ZD !== undefined' enable 'Next' button when the table only has one column(no chart available in this case)
  // '!!insightbuilder.tables.length' enable 'Next' button when the pinned table length is greater than 0
  // if (ZD !== undefined || !!insightbuilder.tables.length) {
  //   handleSetButtonActive(true);
  // } else {
  //   handleSetButtonActive(false);
  // }

  return (
    <div className="h-100 d-flex flex-column">
      {/* TODO: Investigate how to trigger component properly */}
      {/* <DataInfoText style={{ display: "none" }}>{insightbuilder.random}</DataInfoText> */}
      {
        insightbuilder.columns.length > 0 &&
        <div id="explore-table-result">
          <MultiIndexTable />

          {/* Old code: Uncomment for testing if needed */}

          {/* <ExploreTableDraft
            insightbuilder={insightbuilder}
            update={!!update}
            suitcaseId={suitcaseId}
          /> */}

          {/* Pinned table */}
          {/* {
            !!insightbuilder.tables.length ?
            <TableInfoContainerPinned id="resExport">
              {insightCode && (
                <BICodeView dangerouslySetInnerHTML={{ __html: insightCode }}></BICodeView>
              )}
              {
                !insightCode &&
                <ExploreTableView
                  insightbuilder={insightbuilder}
                />
              }
            </TableInfoContainerPinned> :
            <TableInfoContainerPinned>
              <ResultTitle>Insight table</ResultTitle>
              <Message compact warning size="mini" className="mt-0">
                <Message.Header>
                  Add a draft table to your Insight by clicking on the "Add table" button.
                </Message.Header>
              </Message>
            </TableInfoContainerPinned>
          } */}
        </div>
      }
      {!hidePrompt &&
        <ExploreTablePrompt/>
      }
    </div>
  );
}));

// export const ExploreTable : React.FC<IExploreTable> = inject(observer(ExploreTableComponent));
