import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Modal, Button, Form } from "semantic-ui-react";

import { required, composeValidators, mustBeValidEmail, requiredCustomMessage } from "common/helpers/finalForm";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { FieldCheck } from "component/FinalFormFields/FieldCheck";
import { userExists } from "common/helpers/user";
import { sendVerification, quickSignUp } from "common/helpers/signup";
import { database, getMixpanel } from "common/api";
import Store from "common/store";

interface ComponentProps {
  isOpen: boolean;
  onSignUp: (data) => any;
  close: () => any;
  heading?: string;
  store: Store;
}

const Component: React.FunctionComponent<ComponentProps> = (props): JSX.Element => {
  const { isOpen, onSignUp, close, heading, store } = props;
  const [submitOk, setSubmitOk] = React.useState(false);
  const [submitData, setSubmitData] = React.useState<any>({});
  React.useEffect(() => {
    setSubmitOk(false);
    if (isOpen) {
      getMixpanel(store).track("Insight Builder > Quick Signup / Login", {
        "Source": store.builder.source || "default",
        "Step Name": "Open Modal / Save Insight",
      });
    }
  }, [isOpen]);
  const onSubmit = async (values): Promise<any> => {
    // email check
    const { email } = values;
    const exists = await userExists(email);
    getMixpanel(store).track("Insight Builder > Quick Signup / Login", {
      "Source": store.builder.source || "default",
      "Step Name": "Submit Email",
      "User Type": exists ? "Existing User" : "New User",
    });
    if (exists) {
      const verificationError = await sendVerification({ email, disable_magic_button: true });
      if (verificationError) {
        return { [FORM_ERROR]: verificationError };
      }
      setSubmitData({ email, existingUser: true });
    } else {
      setSubmitData({ email, existingUser: false });
    }
    setSubmitOk(true);
  };
  return (
    <Modal open={isOpen} closeOnDimmerClick={false} size="small">
      <Modal.Header className="text-secondary">{heading || "Create Seer Login"}</Modal.Header>
      {!submitOk ? (
        <FinalForm
          onSubmit={onSubmit}
          render={formRenderProps => {
            const { validating, submitting, handleSubmit, submitError } = formRenderProps;
            return (
              <>
                <Modal.Content>
                  <Form onSubmit={handleSubmit}>
                    <Field
                      label="Email"
                      name="email"
                      component={FieldInput}
                      validate={composeValidators(required, mustBeValidEmail)}
                      helperText="We will email you a temporary code to log in."
                    />
                    {submitError && (
                      <p className="text-danger fs-1000">{submitError}</p>
                    )}
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <Button disabled={validating || submitting} onClick={close}>Cancel</Button>
                  <Button disabled={validating || submitting} onClick={handleSubmit} className="bg-primary text-white bg-hover-red">Continue</Button>
                </Modal.Actions>
              </>
            );
          }}
        />
      ) : (
        <>
          {submitData.existingUser ? (
            <ExistingUserForm onLogin={onSignUp} close={close} email={submitData.email} store={store} />
          ) : (
            <NewUserForm onSignUp={onSignUp} close={close} email={submitData.email} store={store} />
          )}
        </>
      )}
    </Modal>
  );
};

interface NewUserFormProps {
  onSignUp: (data) => any;
  close: () => any;
  email: string;
  store: Store;
}

const NewUserForm: React.FunctionComponent<NewUserFormProps> = (props): JSX.Element => {
  const { onSignUp, close, email, store } = props;
  const [step, setStep] = React.useState(1);
  const onSubmit = async values => {
    const errors: any = {};
    if (step === 1) {
      getMixpanel(store).track("Insight Builder > Quick Signup / Login", {
        "Source": store.builder.source || "default",
        "Step Name": "Submit User Details",
        "User Type": "New User",
      });
      // email verification step
      const verificationError = await sendVerification({ email: values.email });
      if (verificationError) {
        errors[FORM_ERROR] = verificationError;
      } else {
        setStep(2);
      }
    } else if (step === 2) {
      getMixpanel(store).track("Insight Builder > Quick Signup / Login", {
        "Source": store.builder.source || "default",
        "Step Name": "Submit Verification Code",
        "User Type": "New User",
      });
      // sign up step
      const body = { ...values };
      delete body.agree_tos; // unwanted data
      const signupRes = await quickSignUp(body);
      if (signupRes.error) {
        errors[FORM_ERROR] = signupRes.error;
      } else {
        // signup successful
        await onSignUp({ formData: values, signupData: signupRes.data.user, userType: "new" });
      }
    }
    return errors;
  };
  return (
    <FinalForm
      initialValues={{
        email,
        agree_tos: false,
        phone: "0", // backend expects to be set
        last_name: "Surname", // backend expects to be set
      }}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { validating, submitting, handleSubmit, submitError } = formRenderProps;
        return (
          <>
            <Modal.Content>
              <Form onSubmit={handleSubmit} id="user-signup-neworg-census">
                <Field
                  label="Email"
                  name="email"
                  component={FieldInput}
                  validate={composeValidators(required, mustBeValidEmail)}
                  disabled
                  helperText="We will email you a temporary code to log in."
                />
                <Field
                  label="First Name"
                  name="first_name"
                  component={FieldInput}
                  validate={composeValidators(required)}
                  disabled={step === 2}
                />
                <Field
                  label="Organisation Name"
                  name="group_name"
                  component={FieldInput}
                  validate={composeValidators(required)}
                  disabled={step === 2}
                />
                <Field
                  label={(
                    <>
                      You agree to the{" "}
                      <a href="https://seerdata.com.au/terms-of-service" target="_blank">Terms of Service</a>
                      {" "}and{" "}
                      <a href="https://seerdata.com.au/privacy-policy" target="_blank">Privacy Policy</a>
                    </>
                  )}
                  name="agree_tos"
                  component={FieldCheck}
                  validate={requiredCustomMessage("Please read over and agree to the Terms of Service and Privacy Policy to continue.")}
                  disabled={step === 2}
                />
                {step === 2 && (
                  <>
                    <Field
                      label="Verification Code"
                      name="verification_code"
                      component={FieldInput}
                      validate={composeValidators(required)}
                    />
                  </>
                )}
                {submitError && (
                  <p className="text-danger fs-1000">{submitError}</p>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button disabled={validating || submitting} onClick={close}>Cancel</Button>
              <Button disabled={validating || submitting} onClick={handleSubmit} className="bg-primary text-white bg-hover-red">{step !== 2 ? "Continue" : "Sign Up"}</Button>
            </Modal.Actions>
          </>
        );
      }}
    />
  );
};

interface ExistingUserFormProps {
  onLogin: (data) => any;
  close: () => any;
  email: string;
  store: Store;
}

const ExistingUserForm: React.FunctionComponent<ExistingUserFormProps> = (props): JSX.Element => {
  const { onLogin, close, email, store } = props;
  const onSubmit = async values => {
    getMixpanel(store).track("Insight Builder > Quick Signup / Login", {
      "Source": store.builder.source || "default",
      "Step Name": "Submit Verification Code",
      "User Type": "Existing User",
    });
    const errors: any = {};
    // log in step
    const loginRes: any = await database.post("users/auth", values);
    const resData = loginRes?.body?.data;
    if (resData) {
      // login success
      await onLogin({
        formData: values,
        signupData: { id: resData.user.id, token: resData.JWTTOKEN },
        userType: "existing",
      });
    } else {
      errors[FORM_ERROR] = "Incorrect verification code";
    }
    return errors;
  };
  return (
    <FinalForm
      initialValues={{ email }}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { validating, submitting, handleSubmit, submitError } = formRenderProps;
        return (
          <>
            <Modal.Content>
              <Form onSubmit={handleSubmit} id="login-user-census">
                <Field
                  label="Email"
                  name="email"
                  component={FieldInput}
                  validate={composeValidators(required, mustBeValidEmail)}
                  disabled
                  helperText="We will email you a temporary code to log in."
                />
                <Field
                  label="Verification Code"
                  name="verification_code"
                  component={FieldInput}
                  validate={composeValidators(required)}
                />
                {submitError && (
                  <p className="text-danger fs-1000">{submitError}</p>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button disabled={validating || submitting} onClick={close}>Cancel</Button>
              <Button disabled={validating || submitting} onClick={handleSubmit} className="bg-primary text-white bg-hover-red">Log in</Button>
            </Modal.Actions>
          </>
        );
      }}
    />
  );
};

export const QuickSignUpForm = Component;
