import styled from "styled-components";
import { Tab, MenuItem, Container } from "semantic-ui-react";
import { $opensans, $radius, $shadow, $white } from "common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

export const StyleHeading = styled.h1`
  font-family: ${$opensans};
  font-weight: bold;
  color: ${COLORS.indigo600};
  font-size: 45px;
  margin-bottom: 0px;
  margin-top: -8px;
`;

export const StyleAddButton = styled.button`
  cursor: pointer;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: ${$radius};
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${$white};
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border: none;
`;

export const StyleTab = styled(Tab)<{ marginbottom?: number}>`
  margin-bottom: ${prop => prop.marginbottom ? prop.marginbottom : 60}px !important;
  .ui.menu {
    font-family: ${$opensans};
    font-size: 20px !important;
  };
  .item {
    color: #7f7f7f !important;
    font-weight: bold !important;
  }
  a.active {
    color: ${COLORS.red500} !important;
    border-width: 4px !important;
  }
  .ui.secondary.pointing.menu .active.item {
    border-color: ${COLORS.red500};
  }
`;

export const StyledControlsFilters = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    right: 0;
    top: 10px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: #7f7f7f !important;
  font-weight: bold !important;
  font-size: 1.25rem !important;
  padding: 0.5rem !important;
  @media(min-width: 576px) {
    padding: 20px 25px !important;
    font-size: 1.425rem !important;
  }
`;

export const StyledContainer = styled(Container)`
  /* high specificity here due to semantic classes not easily overridden */
  &.ui.container {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 100% !important;
      padding: 1em !important;
    }
  }
`;
