// @TODO - this is better done in app theme if we actually want the base elements styled differently by default
import styled from "styled-components";

export const H1 = styled.h1`
  margin: 20px 0;
  font-size: ${props => props.theme.font.size.xxLarge};
  font-weight: bold;
  line-height: ${props => props.theme.font.lineHeight.medium};
  color: ${props => props.theme.color.heading};
`;

export const H2 = styled.h2`
  margin: 20px 0;
  font-size: ${props => props.theme.font.size.xLarge};
  font-weight: bold;
  color: ${props => props.theme.color.heading};
`;

export const H3 = styled.h3`
  margin: 20px 0;
  font-size: ${props => props.theme.font.size.mLarge};
  font-weight: bold;
  color: ${props => props.theme.color.heading};
`;

export const Summary = styled.summary`
  margin: 20px 0;
  font-size: ${props => props.theme.font.size.large};
  font-weight: ${props => props.theme.font.weight.light};
  color: ${props => props.theme.color.text};
  list-style: none;
`;

export const P = styled.p`
  font-size: 15px;
  font-weight: normal;
  color: #322e3b;
  line-height: 1.33;
`;

export const Hr = styled.hr`
  margin: 0;
  border: none;
  opacity: 0.3;
  background-color: ${props => props.theme.color.hr};
  height: 1px;
  position: relative;
  background: #c3c3c3;
`;

export const Label = styled.label<{ htmlFor?: string }>`
  font-weight: bold;
  color: ${props => props.theme.color.label};
  display: block;
  padding: 10px 0;
`;

export const Strong = styled.strong`
  font-size: ${props => props.theme.font.size.medium};
  font-weight: bold;
  color: ${props => props.theme.color.label};
  display: block;
`;

export const PopupHeading = styled.p`
  font-family: Open Sans;

  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: #6c3563;
  position: relative;
  top: -15px;
  left: -5px;
  font-weight: bold;
`;
