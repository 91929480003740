import * as React from "react";
import numeral = require("numeral");
import { COLORS } from "component/UI/common";
import { StyledProgress } from "pages/Dashboard/includes/DashboardCollaborate/includes/Collaborate.style";
import { Cell, Pie, PieChart, ResponsiveContainer, Text } from "recharts";
import { CHART_FONT_FAMILY } from "common/helpers/chart";

interface Props {
  value: number | null;
  label?: string;
  type: "bar" | "donut";
}

const format = "0.[00]";

const customMeasureLabel = (displayValue: string) => (props: any): JSX.Element => {
  const { cx, cy, outerRadius } = props;
  return (
    <g>
      <Text
        x={cx}
        y={cy}
        fill={COLORS.grey800}
        style={{ fontSize: outerRadius / 2, fontFamily: CHART_FONT_FAMILY, fontWeight: "bold" }}
        textAnchor="middle"
        verticalAnchor="middle"
      >
        {displayValue}
      </Text>
    </g>
  );
};

export const CompletenessMeasureVisual = (props: Props): JSX.Element => {
  const { value, label, type } = props;

  if (value === null) {
    return <p className="fs-1000 fw-600 mb-0" style={{ color: COLORS.grey600 }}>Data not available</p>;
  }

  const displayValue = `${numeral(value * 100).format(format)}%`;

  return (
    <>
      {type === "bar" ? (
        <div>
          <div className="d-lg-flex align-items-end justify-content-between">
            <p className="fs-1250 fw-700 mb-0 ff-secondary" style={{ color: COLORS.grey900 }}>{displayValue}</p>
            <p className="fs-1000 fw-700 mb-0 ff-secondary d-none d-md-block" style={{ color: COLORS.grey600 }}>of data collected</p>
          </div>
          <StyledProgress size="small" foreground={COLORS.aqua500} background={COLORS.aqua200} percent={value * 100} />
        </div>
      ) : (
        <>
          <p className="fs-1250 fw-700" style={{ color: COLORS.grey800 }}>{label}</p>
            <ResponsiveContainer aspect={1}>
            <PieChart>
              {/* Middle label */}
              <Pie
                dataKey="value"
                data={[{ value }]} // Do nothing here just for showing the "customMeasureLabel"
                outerRadius="70%"
                labelLine={false}
                label={customMeasureLabel(displayValue)}
                strokeWidth={0}
                fill="none"
                isAnimationActive={false}
              >
              </Pie>
              {/* Donut chart */}
              <Pie
                dataKey="value"
                data={[{ value }, { value: 1 - value }]}
                innerRadius="70%"
                outerRadius="100%"
                startAngle={90}
                endAngle={450}
              >
                <Cell fill={COLORS.aqua500} />
                <Cell fill={COLORS.aqua200} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};
