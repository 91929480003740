import * as React from "react";
import Select from "react-select";
import { Icon, Modal } from "semantic-ui-react";
import { customReactSelectStyles } from "common/helpers/dataset";
import { Button } from "component/UI/Button";
import { COLORS } from "component/UI/common";

interface Props {
  isOpen: boolean;
  contributors: any[];
  name: string;
  users: any[];
  onClose: () => void;
  onSave: (selections: any[]) => void;
}

export const EditContributorsModal = (props: Props): JSX.Element => {
  const { isOpen, contributors, name, users, onClose, onSave } = props;
  const [currentSelections, setCurrentSelections] = React.useState<any[]>(contributors);
  const [selections, setSelections] = React.useState<any[]>([]);

  const contributorsOptions = users?.filter(user => !currentSelections?.find(contributor => contributor.id === user.id))
    .map(contributor => ({
      id: contributor.id,
      label: `${contributor.first_name} ${contributor.last_name}`,
      avatar: contributor.avatar,
      value: contributor.id,
    }));

  React.useEffect(() => {
    setCurrentSelections(contributors);
    setSelections([]);
  }, [isOpen]);

  return (
    <Modal size="small" open={isOpen}>
      <Modal.Header>
        Edit Action Contributors
      </Modal.Header>
      <Modal.Content>
        <h4 className="mb-3" style={{ color: COLORS.indigo600 }}>
          {`Action: ${name}`}
        </h4>
        <p className="mb-3" style={{ color: COLORS.grey900 }}>
          Select or Remove Contributors from this action
        </p>
        <Select
          isMulti
          isClearable={false}
          styles={customReactSelectStyles}
          placeholder="Search and select dashboard editors"
          options={contributorsOptions}
          value={currentSelections}
          onChange={(_selectedOptions, changeData: any) => {
            const { action, option, removedValue } = changeData;
            if (action === "select-option") {
              setCurrentSelections([...currentSelections, option]);
              if (!selections.find(selection => selection.id === option.id)) {
                setSelections([...selections, { action: "add", id: option.id }]);
              } else {
                setSelections([...selections].filter(selection => selection.id !== option.id));
              }
            } else {
              const nextSelections = [...currentSelections].filter(selection => selection.id !== removedValue.id);
              setCurrentSelections(nextSelections);
              if (!selections.find(selection => selection.id === removedValue.id)) {
                setSelections([...selections, { action: "remove", id: removedValue.id, contributorID: contributors.find(c => c.id === removedValue.id).contributorID }]);
              } else {
                setSelections([...selections].filter(selection => selection.id !== removedValue.id));
              }
            }
          }}
        />
        <div className="d-flex mt-2">
          <Icon name="info circle" style={{ color: COLORS.grey800 }} />
          <p className="fs-0875" style={{ color: COLORS.grey800 }}>
            Contributors will have the permission to edit, resolve and delete the action and will receive notifications when changes are made to the action by others.
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div>
          <Button colorConfig="red" onClick={onClose}>Cancel</Button>
          <Button colorConfig="aqua" onClick={() => onSave(selections)}>Save</Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
