import styled from "styled-components";
import { Button } from "./elements";
import {
  $opensans,
  $darkgrey,
  $black,
  $shadow,
  $border,
  $radius,
} from "./_config.style";
import { Form } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
// corner logo
export const StyleSeerLogo = styled.img`
  position: absolute;
  height: 60px;
  width: 100px !important;
  top: 15px;
  left: 30px;
`;

// SignUp Form
export const StyleForm = styled(Form)`
  font-family: ${$opensans};
  width: 320px;
  margin: 60px auto 60px;
`;
// Settings Form
export const StyleSettingsForm = styled.div`
  font-family: ${$opensans};
  margin-top: 30px;
  .error.message {
    margin: 0 !important;
    /* font-weight: bold !important; */
    margin: 0 !important;
  }
  .success.message {
    margin: 0 !important;
    /* font-weight: bold !important; */
    margin: 0 !important;
  }
`;

// Invite Form
export const StyleInviteForm = styled(Form)`
  font-family: ${$opensans};
`;

export const StyleFormHeading = styled.p`
  color: ${COLORS.indigo600};
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  text-align: left;
`;

export const StyleInviteMessage = styled.p`
  color: ${COLORS.indigo600};
  text-align: left;

  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
`;
export const StyleLabel = styled.p`
  color: ${COLORS.indigo600};
  font-weight: bold;
  text-align: left;

  font-size: 18px;
  line-height: 24px;
  margin: 10px 0 10px 0 !important;
`;

export const StyleSmallText = styled.p`
  color: ${$darkgrey};

  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  margin: 10px 0;
`;

export const StyleInputM = styled(Form.Input)`
  width: 74% !important;
  height: 40px !important;
  display: inline-block !important;
  font-family: "Open Sans" !important;
  .ui.input {
    height: 40px !important;
    font-family: "Open Sans" !important;
  }
  /* .prompt.label {
    background-color: black !important;
    border: 1px solid #e0b4b4!important;
    color: ${COLORS.red500} !important;
    width: 490px !important;
} */
`;
export const StyleInput = styled.input`
  padding: 0 10px;
  width: 100%;
  height: 40px;
  background: white;
  border: ${$border};
  border-radius: 4px;
  margin: 0 0 10px 0;
  color: ${$black};
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  ::placeholder {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  }
`;
export const StyleInputSemantic = styled(Form.Input)`
  .prompt.label {
    white-space: normal;
    background: #fff !important;
    border: 1px solid #e0b4b4 !important;
    color: #9f3a38 !important;
    width: 100% !important;
  }
`;

export const StyleInputMultiLine = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 80px;
  background: white;
  border: ${$border};
  border-radius: 4px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  margin: 0 0 10px 0;
`;

export const StyleCTAWrapper = styled.div`
  display: flex;
  color: ${$darkgrey};
  font-size: 12px;
  line-height: 15px;
  margin: 0 0 -10px 0;
`;

export const StyleSubmit = styled(Button)`
  width: 100%;
  margin: 0 0 10px 0;
`;

export const StyleTerms = styled.span`
  color: ${COLORS.red500};
  font-weight: normal;
  cursor: pointer;
`;

export const StyleNameWrapperA = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const StyleNameWrapper = styled.div`
  margin-bottom: 10px;
`;
// SignUp Form End
// ---------------------------------------

// Subscription Options
export const StyleSubOptionsTitle = styled.h1`
  font: ${$opensans};
  color: ${COLORS.indigo600};
  text-align: center;
  margin: 60px auto 30px;

  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
`;

export const StyleSubContainer = styled.div`
  font: ${$opensans};
  display: flex;
  justify-content: space-around;
  width: 600px;
  margin: 15px auto;
`;

export const StyleSubBox = styled.div`
  box-shadow: ${$shadow};
  border-radius: ${$radius};
  width: 150px;
  height: 150px;
`;

export const StyleSubName = styled.h2`
  color: ${COLORS.indigo600};
  font-weight: lighter;
  text-align: center;

  font-size: 18px;
  margin: 30px 0 20px;
`;

export const StyleSubDescription = styled.div`
  color: ${COLORS.indigo600};
  font-weight: bold;
  text-align: center;

  font-size: 20px;
`;

export const StyleStartTrial = styled(Button)`
  width: 320px;
  height: 40px;
  margin: 20px 0 0 0;
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border-radius: 4px;
  border: none;
`;
// Subscription Options End
// ---------------------------------------
// Global
export const iconMargin = "7px";
export const nextIcon = "far fa-arrow-alt-circle-right";
export const left = "0 5px 0 0";
export const right = "0 0 0 5px";
export const ctaBottom = "0 0 15px 0";
