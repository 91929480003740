import * as React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import { Grid, Placeholder } from "semantic-ui-react";
import LazyLoad from "react-lazyload";
import { CardView } from "./CardView";
import { EmptyStateSecondary } from "./EmptyState/EmptyState";

const responsiveColumns = {
  block: { mobile: 1, tablet: 2, computer: 2, largeMonitor: 2, wideScreenMonitor: 2 },
  grid: { mobile: 1, tablet: 2, computer: 3, largeMonitor: 3, wideScreenMonitor: 3 },
};

const NoEditMessage = () => (
  <div
    className="position-absolute text-white d-flex align-items-center justify-content-center p-3"
    style={{
      background: "#000000a3",
      zIndex: 1,
      top: 0,
      bottom: "2rem",
      left: "1rem",
      right: "1rem",
      borderRadius: 4,
      pointerEvents: "none",
      fontSize: "2rem",
      fontWeight: "bold",
    }}
  >
    <div>
      This is no longer editable on your plan.
      {" "}
      <a
        href="mailto:seer.support@seerdata.com.au"
        className="text-white text-decoration-underline"
        style={{ pointerEvents: "auto" }}
      >
        Contact us
      </a>
      {" "}
      for more info.
    </div>
  </div>
);

interface CardAction {
  name: string;
  callback(id: number): void;
}

interface Item {
  id: number;
  name: string;
  itemUrl: string;
  imageUrl: string | undefined;
  type: string;
  created_at: string;
  onClickRedirect?: string;
  showNoEditMessage?: boolean;
}

type CardListViewProps = RouteComponentProps<{}> & {
  layout: "block" | "grid";
  type: "attachment" | "insight" | "suitcase";
  list: Item[];
  cardActions: CardAction[];
  publicKey?: string;
  store?: any;
};

class CardListView extends React.Component<CardListViewProps> {
  render() {
    const { layout, type, list, cardActions, publicKey } = this.props;
    const { breakpoint } = this.props.store!.ui;
    const { suitcase } = this.props.store;

    if (list.length === 0) {
      return (
        <EmptyStateSecondary inverted heading={`No${suitcase.active!.private && type === "insight" ? " Draft" : ""} ${type.charAt(0).toUpperCase()}${type.slice(1)}s to Show`} />
      );
    }
    const columns = responsiveColumns[layout][breakpoint];

    return (
      <Grid columns={columns}>
        <Grid.Row stretched>
          {list.map((item, i) => (
            <Grid.Column key={i}>
              <div className="d-none">
                {/* hidden temporarily */}
                {item.showNoEditMessage && <NoEditMessage />}
              </div>
              <LazyLoad
                placeholder={
                  <Placeholder style={{ width: 300, height: 380 }}>
                    <Placeholder.Image />
                  </Placeholder>
                }
              >
                <CardView
                  index={i}
                  cardItem={item}
                  cardActions={cardActions}
                  type={type}
                  cardWidth="100%"
                  layout={layout}
                  publicKey={publicKey}
                />
              </LazyLoad>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(inject("store")(observer(CardListView)));
