import * as React from "react";
import * as ReactDOM from "react-dom";
import { CustomFormMultiRow } from "./CustomFormMultiRow";
import { insertScript } from "common/helpers/script";
import { ObjectAny } from "common/helpers/types";
import { CustomFormDropdown } from "./CustomFormDropdown";
import { CustomFormAccordion } from "./CustomFormAccordion";

interface Submission {
  title: string;
  value: string | ObjectAny[];
}

const TASMANIAN_GOVERNMENT_LOGO_URL = "https://seer-media.s3.ap-southeast-2.amazonaws.com/images/7bdde403-149b-4f04-913e-24f0173f5564.png"

// Need to specify "crossorigin" when drawing data that was loaded from another origin into a canvas
const loadImage = () => new Promise((resolve) => {
  const img = new Image() as HTMLImageElement;
  img.onload = () => resolve(img);
  img.setAttribute("crossorigin", "anonymous");
  img.src = TASMANIAN_GOVERNMENT_LOGO_URL;
});

const CustomFormDropdownInjection = () => {
  const dropdownElement = document.querySelector(".seer-custom-form-dropdown");
  if (dropdownElement) {
    const title = dropdownElement.getAttribute("title");
    ReactDOM.render(<CustomFormDropdown title={title} />, dropdownElement);
  }
};

const CustomFormMultiRowInjection = () => {
  const multiRowElement = document.querySelector(".seer-custom-form-multi-row");
  if (multiRowElement) {
    const title = multiRowElement.getAttribute("title");
    ReactDOM.render(<CustomFormMultiRow title={title} />, multiRowElement);
  }
};

const CustomFormAccordionInjection = () => {
  const accordionElements = document.querySelectorAll(".seer-custom-form-accordion");
  if (accordionElements.length) {
    accordionElements.forEach((accordionNode, accordionIdx) => {
      const items: any = [];
      const accordionItems = accordionNode.querySelectorAll(".seer-custom-form-accordion-item");
      accordionItems.forEach((itemNode) => {
        const titleNode = itemNode.querySelector(".seer-custom-form-accordion-title") as HTMLElement;
        const contentNode = itemNode.querySelector(".seer-custom-form-accordion-content");
        items.push({
          title: titleNode?.innerText,
          content: contentNode?.innerHTML,
        });
      });
      ReactDOM.render(<CustomFormAccordion items={items} accordionIdx={accordionIdx} />, accordionNode);
    });
  }
};

const PrintResponseEventInjection = (dashboardID: number) => {
  const submitButtons = document.querySelectorAll(".seer-custom-form-submit");
  if (submitButtons.length) {
    submitButtons.forEach(submitButton => {
      const doPrint = submitButton.classList.contains("seer-custom-form-print");
      submitButton.addEventListener("click", async () => {
        // attach loader style during submit (see src/app/component/LoaderGlobal.tsx)
        const loader = document.createElement("style");
        document.head.appendChild(loader);
        loader.appendChild(document.createTextNode(`
          body #loader-global { display: block !important; }
          body #loader-global-backdrop { display: block !important; }
        `));
        // Construct form submission
        const submission: Submission[] = [];
        // - Get responses from all fields
        const fieldList = document.querySelectorAll(".seer-custom-form-field");
        fieldList.forEach(node => {
          submission.push({
            title: node.getAttribute("title") || "",
            value: (node as HTMLTextAreaElement).value,
          });
        });
        // - Get response from multi row table (2nd question)
        const multiRowTableContainer = document.querySelector(".seer-custom-form-multi-row");
        const multiRowTableResponse: Submission = { title: multiRowTableContainer?.getAttribute("title") || "", value: [] };
        const multiRowTable = multiRowTableContainer?.firstChild as HTMLTableElement;
        const multiRowTableHeader = multiRowTable?.rows[0];
        for (let i = 1; i < multiRowTable.rows.length; i++) {
          const currentRow = {};
          for (let j = 0; j < multiRowTableHeader.cells.length - 1; j++) {
            const currentCell = multiRowTable.rows[i].cells[j].firstChild as HTMLTextAreaElement;
            currentRow[multiRowTableHeader.cells[j].innerText] = currentCell.value;
          }
          (multiRowTableResponse.value as ObjectAny[]).push(currentRow);
        }
        submission.splice(4, 0, multiRowTableResponse);
        // print PDF of question responses
        if (doPrint) {
          try {
            await insertScript("PDFKit", { type: "text/javascript", src: "https://cdn.jsdelivr.net/npm/pdfkit@0.15.0/js/pdfkit.standalone.min.js" });
            await insertScript("blob-stream", { type: "text/javascript", src: "https://github.com/devongovett/blob-stream/releases/download/v0.1.3/blob-stream.js" });
            const doc = new window["PDFDocument"]();
            const stream = doc.pipe(window["blobStream"]());
            // Workaround to add image to pdf in browser with PDFKit: convert image data to base64 blob and insert into the pdf
            const imageBase64 = await loadImage().then((img: any) => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx!.drawImage(img, 0, 0);
              return canvas.toDataURL("image/png");
            });
            doc.image(imageBase64, 50, 50, { width: 150 });
            doc.fontSize(22);
            doc.font("Helvetica-Bold").text("Applying a gender lens and making changes", 50, 160).moveDown();
            doc.fontSize(14);
            submission.forEach(s => {
              doc.font("Helvetica-Bold").text(s.title).moveDown(0.4);
              if (Array.isArray(s.value)) {
                s.value.forEach((v, idx) => {
                  doc.font("Helvetica-Bold").text(`Answer ${idx + 1}`).moveDown(0.2);
                  Object.keys(v).forEach(key => {
                    doc.font("Helvetica").text(key, { underline: true, continued: true }).text(`: ${v[key]}`, { underline: false }).moveDown(0.2);
                  });
                  doc.moveDown(0.4);
                });
              } else {
                doc.font("Helvetica").text(s.value);
              }
              doc.moveDown();
            });
            doc.end();
            stream.on("finish", () => {
              const url = stream.toBlobURL("application/pdf");
              const a = document.createElement("a");
              a.href = url;
              a.download = "DPAC GIAT Response.pdf";
              a.click();
            });
          } catch (e) {
            console.error("Download response PDF failed!", e);
          }
        }
        // Submit question responses
        let urlParam = "?";
        submission.forEach((s, idx)=> {
          if (idx === 0) {
            urlParam = urlParam.concat(`${s.title}=${s.value}`);
          } else if (Array.isArray(s.value)) {
            const paramArray = (s.value as ObjectAny[]).map(v => (`${s.title}[]=${JSON.stringify(v)}`));
            urlParam = urlParam.concat(`&${paramArray.join("&")}`);
          } else {
            urlParam = urlParam.concat(`&${s.title}=${s.value}`);
          }
        });
        window.open(`/form-submission/dashboard/${dashboardID}${urlParam}`, "_blank");
        loader.remove(); // end loader
      });
    });
  }
};

export const injectDPACCustomizations = (dashboardID: number) => {
  CustomFormDropdownInjection();
  CustomFormMultiRowInjection();
  CustomFormAccordionInjection();
  PrintResponseEventInjection(dashboardID);
};
