import { fill, cloneDeep, find as lodashFind } from "lodash";
import * as numeral from "numeral";
import cronstrue from "cronstrue";
import cronParser = require("cron-parser");
import { DateTime } from "luxon";
import { ObjectAny } from "common/helpers/types";
import { COLORS } from "component/UI/common";

// TABLE SCHEMA HELPERS
export interface VariableData {
  variable: string;
  totals: "none" | "summed" | "manual";
  categories: string[];
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export type monthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export const quarters = ["Jan-Mar","Apr-Jun","Jul-Sep","Oct-Dec"];
export type quarterNumber = 0 | 1 | 2 | 3;
export const monthToQuarter: quarterNumber[] = [0, 0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3];

// the AggAuto interfaces for each type of aggregation have to have optional props because they are not in union for the aggAuto type
export interface AggAutoYearMonth {
  year: number;
  month: number;
}

export interface AggAutoYearQuarter {
  year: number;
  quarter: number;
}

// Used for Year and Financial Year
export interface AggAutoYear {
  year: number;
}

// aggAuto configuration object is different for each of the aggregations we support, add interface for any new ones created
export type AggAuto = | AggAutoYearMonth | AggAutoYearQuarter | AggAutoYear;

export interface AggregationCategory {
  name: string; // this is the aggregate variables' category name
  aggCategories?: string[]; // this is for manual aggregations and not currently used (it is a list of categories from aggVariable that form AggregationCategory.name)
  aggAuto: AggAuto; // config object for aggregation
}

export type aggregationType = "sum" | "average" | "maximum" | "minimum" | "mode" | "median" | "range" | "standard deviation" | "count";

export interface Aggregation {
  type: aggregationType;
  aggVariable: string; // this is the variable being aggregated on
  variable: string; // this is the aggregate variable we have created
  categories: AggregationCategory[];
}

export interface Schema {
  rows: VariableData[];
  columns: VariableData[];
  aggregations?: Aggregation[]; // Aggregate Schema variation should only have rows/columns
}

export type DataPointValue = string; // always stored as string inside editor, empty string is the equivalent of null/Missing

// reducer function for determining number of combinations for an array of VariableData
export const reduceCategoryCombinations = (items: VariableData[]): number =>
  items.reduce((acc, variableData) => acc * (variableData.categories.length + (variableData.totals !== "none" ? 1 : 0)), 1);

// generates combinations in the appropriate order for an array of variables and their categories
export const getVariableCombinations = (variables: VariableData[]): any[] => {
  const totalCombinations = reduceCategoryCombinations(variables);
  return fill(new Array(totalCombinations), 0).map((_, idx) => {
    const combination = {};
    variables.forEach((variable, vIdx) => {
      const catLength = reduceCategoryCombinations([variable]); // total number of cats in this variable
      const rightCombinations = reduceCategoryCombinations(variables.slice(vIdx + 1)); // number of possible combinations to right of array of variables
      const nextIdx = Math.floor(idx / rightCombinations) % catLength; // next category index to use
      combination[variable.variable] = variable.categories[nextIdx] || "Total"; // because total is not explicitly in the categories array
    });
    return combination;
  });
};

// generate empty 2D datapoint array, use when raw data is not set on a table
export const generateEmpty2DArray = (rows: number, cols: number): DataPointValue[][] => fill(new Array(rows), "").map(() => fill(new Array(cols), ""));

// transforms raw json data for a template table into a 2d array for rendering an editable table for the user (built based on the schema)
export const transformDataTo2DArray = (rawData: ObjectAny[], rowCombinations: ObjectAny[], columnCombinations: ObjectAny[]): DataPointValue[][] => {
  const data2D = rowCombinations.map(() => fill(new Array(columnCombinations.length), ""));
  for (let rIdx = 0; rIdx < rowCombinations.length; rIdx++) {
    for (let cIdx = 0; cIdx < columnCombinations.length; cIdx++) {
      const search = { ...rowCombinations[rIdx], ...columnCombinations[cIdx] };
      const foundDataPoint = lodashFind(rawData, search);
      data2D[rIdx][cIdx] = typeof foundDataPoint?.Value === "number" ? `${foundDataPoint?.Value}` : "";
    }
  }
  return data2D;
};

// transforms a 2D datapoint array back into raw json data array
export const transform2DArrayToData = (data2D: DataPointValue[][], rowCombinations: ObjectAny[], columnCombinations: ObjectAny[]): ObjectAny[] => {
  const rawData: any = [];
  for (let rIdx = 0; rIdx < rowCombinations.length; rIdx++) {
    for (let cIdx = 0; cIdx < columnCombinations.length; cIdx++) {
      const Value = data2D[rIdx][cIdx] ? Number(data2D[rIdx][cIdx]) : null;
      rawData.push({ ...rowCombinations[rIdx], ...columnCombinations[cIdx], Value });
    }
  }
  return rawData;
};

// gets the sum for a specific [row,column] total cell
const getAxisSumValue = (axisType: "row" | "column", rIdx: number, cIdx: number, summedTotalsVariables: string[], axisCombinations: ObjectAny[], data2D: DataPointValue[][]) => {
  // find the axis indexes in the opposite axis type to sum
  const search = cloneDeep(axisCombinations[axisType === "row" ? rIdx : cIdx]);
  summedTotalsVariables.forEach(varName => delete search[varName]);
  const sumIndexes = axisCombinations.map((axisCombo, idx) => {
    const ignore = summedTotalsVariables.some(varName => axisCombo[varName] === "Total");
    const match = !Object.keys(search).some(varName => axisCombo[varName] !== search[varName]);
    return (match && !ignore) ? idx : -1;
  }).filter(idx => idx !== -1);

  // get the values
  const sumValues = sumIndexes.map(idx => data2D[axisType === "row" ? idx : rIdx][axisType === "row" ? cIdx : idx]);
  if (sumValues.some(val => val === "")) {
    return false; // some data is missing meaning we can't sum this total
  }
  return sumValues.reduce((acc, val) => acc.add(Number(val)), numeral(0)).value();
};

// updates the 2D data array with recalculated totals cells
export const recalculateSummedTotals = (data2D: DataPointValue[][], rowCombinations: ObjectAny[], columnCombinations: ObjectAny[], schema: Schema): void => {
  const allVariables = [...schema.rows, ...schema.columns];
  for (let rIdx = 0; rIdx < rowCombinations.length; rIdx++) {
    for (let cIdx = 0; cIdx < columnCombinations.length; cIdx++) {
      // find the variables with summed totals for each axis
      const summedColumnTotals = Object.keys(columnCombinations[cIdx])
        .filter(varName => columnCombinations[cIdx][varName] === "Total")
        .filter(varName => allVariables.filter(varData => varData.variable === varName)[0].totals === "summed");
      const summedRowTotals = Object.keys(rowCombinations[rIdx])
        .filter(varName => rowCombinations[rIdx][varName] === "Total")
        .filter(varName => allVariables.filter(varData => varData.variable === varName)[0].totals === "summed");

      // column sum is always preferred if it exists as it is always the first calculation
      if (summedColumnTotals.length) {
        const sumValue = getAxisSumValue("column", rIdx, cIdx, summedColumnTotals, columnCombinations, data2D);
        if (sumValue === false) {
          data2D[rIdx][cIdx] = ""; // if data is missing for any of the values we cannot accurately sum totals so we unset and skip this total
          continue;
        }
        data2D[rIdx][cIdx] = `${sumValue}`;
      } else if (summedRowTotals.length) {
        const sumValue = getAxisSumValue("row", rIdx, cIdx, summedRowTotals, rowCombinations, data2D);
        if (sumValue === false) {
          data2D[rIdx][cIdx] = ""; // if data is missing for any of the values we cannot accurately sum totals so we unset and skip this total
          continue;
        }
        data2D[rIdx][cIdx] = `${sumValue}`;
      }
    }
  }
};

// indicates whether the specified cell is a summed total cell that is auto-calculated
export const isSummedTotal = (rIdx: number, cIdx: number, rowCombinations: ObjectAny[], columnCombinations: ObjectAny[], schema: Schema): boolean => {
  const allVariables = [...schema.rows, ...schema.columns];
  return !!Object.entries({ ...rowCombinations[rIdx], ...columnCombinations[cIdx] })
    .filter(([varName, category]) => category === "Total" && allVariables.filter(varData => varData.variable === varName)[0].totals === "summed")
    .length;
};

export const sortByDate = (array: any[]): any[] => ([...array]).sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());

// This function only works correctly for "cronstrue". If "/, on /" not found, return original "cronsture" string
export const formatCronString = (reminder_interval: string): string => {
  const cronString = cronstrue.toString(reminder_interval);
  // Remove "time" from string. Example: "At 12:00 AM, on day 1 of the month, every 3 months"
  const regex = /, on /;
  const index = cronString.search(regex);
  if (index >= 0) {
    const formatCronString = cronString.substring(index + 5);
    return formatCronString[0].toUpperCase() + formatCronString.slice(1);
  } else {
    return cronString;
  }
};

// Convert ISO Date to Local Date, e.g.: "2021-11-30T00:00:00+00:00" to "2021-11-30"
export const ISODateToLocalDate = (ISODate: string): string => {
  const date = new Date(ISODate);
  let day: number | string = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let month: number | string = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getScheduledReminderDates = (reminderStartDate: string, reminderInterval: string, number: number): any => {
  // Use "try...catch" here in case some old reminder interval formats not working
  try {
    // As "cron-parser" doesn't handle monthly interval properly. It always does the calculation from January when monthly interval is set. So we need to get the monthly interval value from reminderInterval and replace monthly interval value in reminderInterval with "1" to get the reminder dates for each month and do the monthly interval calculation ourselves.
    const regex = /(?:\/)(\d+)/;
    const monthlyInterval = +reminderInterval.match(regex)![1];
    const convertToMonthlyInterval = reminderInterval.replace(regex, "/1");
    const interval = cronParser.parseExpression(convertToMonthlyInterval, { currentDate: reminderStartDate });

    // Set reminderStartDate as the first reminder date if it matches the reminderInterval, otherwise use "interval.next()" as the first reminder date
    let firstReminderDate = interval.prev();
    const formattedFirstReminderDate = ISODateToLocalDate(firstReminderDate.toISOString());
    if (new Date(formattedFirstReminderDate).getTime() !== new Date(reminderStartDate).getTime()) {
      firstReminderDate = interval.next();
    }

    // Make sure the first displayed reminder date is always the future date
    while (firstReminderDate.getTime() < new Date().getTime()) {
      for (let i = 0; i < monthlyInterval; i++) {
        firstReminderDate = interval.next();
      }
    };

    // Return the scheduled reminder dates array
    const scheduledReminderDates = [DateTime.fromISO(firstReminderDate.toISOString()).setZone("local").toFormat("DDDD")];
    for (let i = 0; i < number - 1; i++) {
      let nextReminderDate;
      for (let i = 0; i < monthlyInterval; i++) {
        nextReminderDate = interval.next();
      }
      scheduledReminderDates.push(DateTime.fromISO(nextReminderDate.toISOString()).setZone("local").toFormat("DDDD"));
    }
    return scheduledReminderDates;
  } catch (e) {
    console.log(e);
  }
};

// return 0-1 number representing what percentage of values in the dataset_template_table are filled with values
export const compileTableCompleteness = (data: ObjectAny[]): number => {
  if (!data?.length) {
    return 0;
  }
  const filled = data.filter(row => typeof row["Value"] === "number").length;
  return filled / data.length;
};


// @TODO - move me, I shouldn't live here
// React Select custom styles configs
export const customReactSelectStyles = {
  multiValue: (base, state) => ({
    ...base,
    background: state.data.isLocked ? "#5f5f5f" : COLORS.indigo600,
    color: "white",
  }),
  singleValue: (base, _state) => ({
    ...base,
    fontSize: "14.5px",
    color: COLORS.indigo600,
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: "white",
    paddingRight: (state.data.isLocked && state.data.isChild) ? "6px" : "3px",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isFocused ? "#f2f2f2" : "white",
    color: state.isDisabled ? "#cccccc" : "#2d2d2d",
    fontSize: "14.5px",
    textAlign: "left",
  }),
  multiValueRemove: (base, state) =>
    (state.data.isLocked && state.data.isChild) ?
      { ...base, display: "none" } : base,
  placeholder: (base) => ({
    ...base,
    fontSize: "14.5px",
  }),
  control: (base, state) => ({
    ...base,
    border: `1px solid ${state.isFocused ? COLORS.indigo600 : "#cccccc"}`,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${COLORS.indigo600}`,
    },
    minWidth: "170px",
  }),
  groupHeading: (base, _state) => ({
    ...base,
    color: COLORS.indigo600,
    fontSize: "14.5px",
    fontWeight: "bold",
    lineHeight: "40px",
  }),
};
