import * as React from "react";
import { inject, observer } from "mobx-react";
import { Search, List, SearchProps } from "semantic-ui-react";
import { includes } from "lodash";
import Store from "common/store";
import { collator, transformResToDims, sortDimensionValues } from "common/helpers/data";
import { getMixpanel } from "common/api";
import { ObjectAny } from "common/helpers/types";
import { hashSHA512 } from "common/helpers/string";
import { dataUrl } from "common/constants";

// filter out selected / excluded
const filterAllowedValues = value => ["optional", "compulsory", "comparable"].some(requirement => requirement === value.requirement);
const defaultSort = (a, b) => collator.compare(a.title, b.title);

const maxResultsPerOptionCategory = 5;
const searchTimeoutMs = 500;

interface IUnifiedSearch extends React.FunctionComponent {
  store?: Store;
}

@inject("store")
@observer
class UnifiedSearch extends React.Component<IUnifiedSearch> {
  abortController: AbortController | null = null;
  _isMounted = false;

  state = {
    searchValue: "",
    loading: false,
    results: {},
    hash: "", // hash to compare builder.qsAllFilters to determine if we need to reset results
  };

  timeoutId: any = null; // used for timeout on query new search results

  // checks if builder qsAllFilters have changed, and resets results if true
  hashCheck = async (qsAllFilters: ObjectAny): Promise<void> => {
    const hash = await hashSHA512(JSON.stringify(qsAllFilters));
    if (hash !== this.state.hash) {
      this.setState({ hash, results: {}, searchValue: "" });
    }
  };

  onResultSelect = (_: unknown, { result }: { result: any }): void => {
    const builder = this.props.store!.builder;
    // more: true used for more labels that have no action, currently unused
    if (!result.more) {
      const source = result.source?.reduce((acc, val) => ({ ...acc, [val.key]: true }), {}) || {};
      if (result.parentDimType) {
        builder.selectValue(result.dimension, result.value, source); // select category
      } else if (result.dimension) {
        builder.selectValue(result.dimension, result.value, source); // select category
      } else if (result.dimType) {
        builder.selectDimension(result.value, source); // select variable
      } else if (result.isSource && (builder.datasetsToQuery.length > 1 || builder.datasetsToQuery[0] !== result.value)) {
        builder.selectDataset(result.key);
      }

      getMixpanel(this.props.store!).track("Insight Builder > Unified Search: Selected Option", {
        ...result.analyticsData,
        "Search Selection Query": this.state.searchValue,
      });

      // reset search query and results
      this.setState({ searchValue: "", results: {} });
    }
  };

  onSearchChange = (_: unknown, data: SearchProps): void => {
    if (this.abortController) {
      this.abortController.abort(); // abort any requests in progress on change value
    }
    this.setState({ loading: false });

    const builder = this.props.store!.builder;
    this.setState({ searchValue: data.value || "", results: {} }); // reset results on query string change

    clearTimeout(this.timeoutId || undefined); // clear current timeout to prevent duplicate requests from occurring
    if (!data.value) {
      return; // don't search empty term
    }

    this.timeoutId = setTimeout(async () => {
      this.abortController = new AbortController(); // instantiate new abort controller for this request
      this.setState({ loading: true });
      const params = {
        filters: builder.qsAllFilters,
        datasets: builder.datasetsToQuery,
      };
      const { res: dataRes, error }: any = await fetch(`${dataUrl}/v2/search`, {
        headers: { "Content-Type": "application/json", "X-Token": this.props.store!.token || "-none-" },
        method: "POST",
        body: JSON.stringify({ ...params, keyword: data.value || "" }),
        signal: this.abortController!.signal,
      })
        .then(res => res.json())
        .then(json => ({ res: json }))
        .catch(e => ({ error: e }));
      this.abortController = null; // reset after request complete
      if (error) {
        this.setState({ loading: false });
        if (error.name !== "AbortError") {
          // when not abort error
          // @TODO - add some error messaging/handling to the autocomplete on qs errors, like try again etc
        }
        return;
      }

      // first we must transform the response into a format compatible with qs
      const resDatasets = dataRes?.data.datasets || {};
      const transform = {
        body: {
          data: {
            datasets: Object.keys(resDatasets).map(key => ({
              dimensions: Object.keys(resDatasets[key]).map(dimension => ({
                name: dimension,
                requirement: "optional",
                values: resDatasets[key][dimension].map(value => ({
                  name: value,
                  requirement: "optional",
                })),
              })),
              key,
              results: [],
            })),
          },
        },
      };
      // now we need to turn the response into dimensions format
      const dimensions = sortDimensionValues(transformResToDims(transform)); // transform data into dimensions

      // next we must copy over any selected requirements from qs variables/values so those options aren't displayed
      const dimKeys = Object.keys(dimensions);
      for (const dimKey of dimKeys) {
        if (!builder.dimensions[dimKey] || builder.dimensions[dimKey].requirement === "excluded") {
          // filter out any unavailable dims as v2 search only filters dims as far as dataset
          delete dimensions[dimKey];
        } else if (builder.dimensions[dimKey]?.requirement === "selected") {
          dimensions[dimKey].requirement = "selected";
          const valueRequirements = builder.dimensions[dimKey].values.reduce((acc, item) => ({
            ...acc,
            [item.name]: item.requirement,
          }), {});
          dimensions[dimKey].values.map(value => ({
            ...value,
            requirement: valueRequirements[value.name]?.requirement === "selected" ? "selected" : value.requirement,
          }));
        }
      }

      const nextResults = this.compileOptions(dimensions, Object.keys(resDatasets)); // compile options
      this.timeoutId = null;
      this.setState({ loading: false, results: nextResults });
    }, searchTimeoutMs);
  };

  // pick out the available options for each dimension, filter sources by search sources, compile to use with semantic search
  compileOptions = (variables, sources): any => {
    const builder = this.props.store!.builder;
    const resultsAvailable = builder.results.length !== 0;
    const { allDatasets } = builder;
    const filteredDatasets = allDatasets.filter(dataset => includes(sources, dataset.key));
    const datasetSelected = builder.datasetsToQuery.length === 1;
    const options: any = {
      What: { name: "What", results: [] },
      How: { name: "How", results: [] },
      Where: { name: "Where", results: [] },
      When: { name: "When", results: [] },
      Source: { name: "Source", results: [] },
    };

    // build source data for option sources
    const sourceData = {};
    if (filteredDatasets.length > 0) {
      filteredDatasets.forEach(dataset => {
        sourceData[dataset.key] = dataset;
      });
    }

    // add source results
    if (filteredDatasets.length && !datasetSelected) {
      options.Source.results = filteredDatasets.map(dataset => ({
        ...dataset,
        title: dataset.name,
        isSource: true,
        analyticsData: {
          "Search Selection Type": "Variable",
          "Search Selection Name": dataset.name,
          "Search Selection Heading": "Source",
        },
      }));
    }

    // add dimension results
    Object.keys(variables).forEach(dimName => {
      const categoriesSelected = builder.qsAllFilters[dimName] || [];
      const variable = variables[dimName];
      // skip excluded
      if (variable.requirement !== "excluded") {
        if (variable.type === "How" || variable.requirement === "selected") {
          // go to sub category (hows treated the same)
          if (!resultsAvailable && ["What", "How"].some(badDim => badDim === variable.type) && categoriesSelected.length !== 0) {
            // if has selection for (What|How) dim and no results available, don't allow selection, to match WhereWhatWhen logic
          } else {
            const values = variable.values.filter(filterAllowedValues);
            if (values.length > 0) {
              values.forEach(value => {
                options[variable.type].results.push({
                  title: `${value.name}`,
                  value: value.name,
                  source: Object.keys(value.source || {}).map(key => sourceData[key]).filter(source => !!source),
                  dimension: dimName, // needed for sorting Whens
                  analyticsData: {
                    "Search Selection Type": "Category",
                    "Search Selection Name": `${value.name}`,
                    "Search Selection Heading": variable.type,
                    "Search Selection Parent Variable": dimName,
                  },
                });
              });
            }
          }
        } else {
          options[variable.type].results.push({
            title: dimName,
            value: dimName,
            source: Object.keys(variable.source || {}).map(key => sourceData[key]).filter(source => !!source),
            dimType: variable.type,
            analyticsData: {
              "Search Selection Type": "Variable",
              "Search Selection Name": dimName,
              "Search Selection Heading": variable.type,
            },
          });

          // now check for categories for the variable too
          if (variable.values?.length > 0) {
            variable.values.forEach(value => {
              options[variable.type].results.push({
                title: `${dimName} > ${value.name}`,
                value: value.name,
                source: Object.keys(value.source || {}).map(key => sourceData[key]).filter(source => !!source),
                dimension: dimName,
                parentDimType: variable.type,
                analyticsData: {
                  "Search Selection Type": "Category",
                  "Search Selection Name": `${value.name}`,
                  "Search Selection Heading": variable.type,
                  "Search Selection Parent Variable": dimName,
                },
              });
            });
          }
        }
      }
    });

    Object.keys(options).forEach(optionKey => {
      const option = options[optionKey];
      // delete any empty option categories
      if (option.results.length === 0) {
        delete options[optionKey];
      } else {
        // sort the options - only default sorting since we can have multiple dimensions in each option group
        option.results.sort(defaultSort);
      }
    });
    return options;
  };

  componentDidMount(): void {
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  render(): JSX.Element {
    this._isMounted && this.hashCheck(this.props.store!.builder.qsAllFilters); // must do in render for mobx to pass observable changes
    const { searchValue, loading, results } = this.state;

    // trim results
    const filteredResults = {};
    Object.keys(results).forEach(key => {
      const keyResults = results[key].results;
      if (keyResults.length > 0) {
        // return limited number of options per option category
        filteredResults[key] = { ...results[key], results: keyResults.slice(0, maxResultsPerOptionCategory) };
      }
    });

    return (
      <div>
        <Search
          category
          fluid
          loading={loading}
          // categoryLayoutRenderer={({ categoryContent, resultsContent }) => ()} // unavailable in v0.84
          categoryRenderer={({ name }) => (<h4>{name}</h4>)}
          onResultSelect={this.onResultSelect}
          onSearchChange={this.onSearchChange}
          resultRenderer={({ title, source, isSource, description, more }) => (
            <div>
              {more ? (
                <p className="m-0 fs-0875 text-right">{title}</p>
              ) : (
                <p className="m-0"><b>{title}</b></p>
              )}
              {isSource && description && (
                <p className="m-0 fs-0875">{description}</p>
              )}
              {source?.length > 0 && (
                <List bulleted size="mini" className="mt-1">
                  {source.slice(0, 2).map(item => (
                    <List.Item key={item.key} title={item.description || item.name}>
                      {item.name}
                    </List.Item>
                  ))}
                  {source.length > 2 && (
                    <List.Item key="more">+ {source.length - 2} more datasets</List.Item>
                  )}
                </List>
              )}
            </div>
          )}
          results={filteredResults}
          value={searchValue}
          minCharacters={1}
          size="mini"
          showNoResults={!!searchValue}
          input={{ className: "w-100", placeholder: "Search keywords across all fields...", name: "unified_search_input", "aria-label": "Unified Search" }}
          noResultsMessage="No options found."
          noResultsDescription={`No options found for the query ${searchValue}.`}
        />
      </div>
    );
  }
}

export { UnifiedSearch };
