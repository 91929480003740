import styled from "styled-components";
import { Form, TextArea } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const TableInfoContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 20px 20px 1px 20px;
  margin-top: 60px;
  border-radius: 5px;
  background: linear-gradient(90deg, #c3c3c3 50%, transparent 50%), linear-gradient(90deg, #c3c3c3 50%, transparent 50%),
    linear-gradient(0deg, #c3c3c3 50%, transparent 50%), linear-gradient(0deg, #c3c3c3 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 1px, 15px 1px, 1px 15px, 1px 15px;
  background-position: 100% 100%, 0px 0px, 99.9% 99.9%, -0.1px -0.1px;
`;

export const BICodeView = styled.div`
  width: 100%;
  height: 600px;
`;

export const TableInfoContainerPinned = styled.div<{ grow: boolean }>`
  position: relative;
  padding: 10px 20px 5px 20px;
  margin-top: 35px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px #c3c3c3;
  ${p => p.grow && "padding-bottom: 35px !important;"}
`;

export const ResultTitle = styled.h2`
  margin-bottom: -65px;
  color: ${COLORS.indigo600};
  padding-top: 15px;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
`;
export const NoResultTitle = styled.h2`
  margin-bottom: -65px;
  color: ${COLORS.indigo600};
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
`;
export const NoResultsMessage = styled.p`
  color: black;
  font-family: "Open Sans";
  font-weight: lighter;
  font-size: 14px;
  padding-bottom: 20px;
`;

export const TContainer = styled.div`
  text-align: right;
  margin: 0;
`;

export const TableComp = styled.table`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

export const Cell = styled.td`
  padding: 10px;
  font-size: 15px;
  text-align: right;
`;

export const KeyRow = styled.td`
  padding: 10px;
  text-align: right;
  color: white;
  background-color: #2d2d2d;
  font-family: "Open Sans", sans-serif !important;

  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.05em;
`;

export const PinHidden = styled.td`
  padding: 10px;
  text-align: right;
  color: white;
  background-color: #2d2d2d;
  font-family: "Open Sans", sans-serif !important;

  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.05em;
`;

export const PinTableSection = styled.tr`
  border-top: 1px solid #c3c3c3;
`;

export const PinnedColumnTitles = styled.tr``;

export const PinTitle = styled.tr`
  width: 100%;
  color: white;
  background-color: #919191;
  border-bottom: 1px solid #919191;
  td {
    padding: 10px;
  }
`;

export const RowTitle = styled.td`
  background-color: #f5f5f5;
  padding: 10px;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-align: left;
  width: 250px;
`;

export const TDB = styled.td`
  background-color: #2d2d2d;
  width: 40px;
`;
export const TDN = styled.td`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 12px;
  padding: 10px;
  white-space: nowrap;
  width: 42px;
`;

export const RowNumber = styled(TDN)`
  color: #919191;
`;

export const TableName = styled.p`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: white;
  line-height: 1.5;
  width: 85%;
  margin-left: 42px;
`;

export const SwapButton = styled.button<{ pivot?: boolean }>`
  margin: 10px 0 10px 10px;
  cursor: pointer;
  display: inline-block;
  line-height: 0 !important;
  padding: 10px 15px;
  text-align: center;
  color: ${COLORS.red500};
  font-weight: normal;
  font-size: 15px;
  border: 1px solid #c3c3c3;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  background: #f5f5f5;
  ${p =>
    p.pivot &&
    `
    color: #919191 !important;
    cursor: not-allowed;
    background: #F5F5F5 !important;
    &:hover {
      background: #F5F5F5 !important;
    }
  `}
`;

export const PinButton = styled(SwapButton)`
  margin: 22px 0 20px 15px;
  line-height: 0 !important;
  box-shadow: 0px 2px 4px #c3c3c3;
  border: none !important;
  padding: 11.5px 15px;
  color: white;
  background: ${COLORS.red500};
  border: none;
`;

export const Calculate = styled(SwapButton)<{ open: boolean }>`
  padding-bottom: 12px;
  ${p =>
    p.open &&
    `
 padding-bottom: 13px;
    background: white;
    border-bottom: none;
    z-index: 12;
    position: relative;
    border-radius: 4px 4px 0 0;
  `}
  float: right;
`;

export const CalculatorContainer = styled.div`
  position: absolute;
  z-index: 11;
  top: 58px;
  right: 20px;
  width: 212px;
  height: 360px;
  border-radius: 4px 0 4px 4px;
  background: white;
  border: 1px solid #c3c3c3;
`;

export const CInput = styled.input`
  width: 190px;
  margin: 11px 10px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  height: 40px;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 28px;
  letter-spacing: 1px;
`;

export const CForm = styled(Form)`
  &.ui.form textarea:focus {
    border-color: ${COLORS.indigo600};
  }
`;

export const CTextArea = styled(TextArea)`
  min-height: 65px;
  font-size: 18px !important;
  font-weight: 600;
  color: #2d2d2d !important;
  padding: 8px 20px 8px 10px !important;
`;

export const CCont = styled.div`
  margin-left: 10px;
`;

export const Bold = styled.p`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;

export const CDecimal = styled.button<{ selected: boolean; small?: boolean }>`
  width: 50%;
  height: 45px;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
  color: ${p => (p.selected ? COLORS.red500 : "#919191")};
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  ${p => p.small && "font-size: 13px;"}
`;

export const CPercent = styled(CDecimal)`
  border-radius: 0px 4px 4px 0px;
  margin-left: -1px;
`;

export const CButton = styled.button`
  width: 100%;
  height: 45px;
  background: #f5f5f5;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  color: #5f5f5f;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
`;

export const CButtonDark = styled(CButton)`
  background: #e0e1e2;
`;

export const Source = styled.h2`
  font-weight: bold;
  font-size: 15px;
  margin-top: -5px;
  margin-bottom: 10px;
  color: ${COLORS.red500};
  font-family: "Open Sans";
  margin-top: 0;
`;

export const SubTitles = styled.h3`
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #2d2d2d;
  margin: 0;
`;
export const Focus = styled.h3`
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #2d2d2d;
  margin-top: 25px;
`;

export const DataInfoText = styled.p`
  font-family: "Open Sans", sans-serif !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  margin: 10px 0;
`;

export const Insight = styled.h2`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: ${COLORS.indigo600};
`;

export const SwapContainer = styled.span`
  float: right;
  margin-top: -10px;
`;

export const StyleTableViewButton = styled(SwapButton)<{ selected: boolean }>`
  position: relative;
  border-radius: 4px;
  border-radius: 0 4px 4px 0;
  display: inline-block;
  height: 40px;
  border-radius: 4px 0 0 4px;
  z-index: 1 !important;
  ${p => p.selected && " background-color: white;"};
  ${p => !p.selected && "color: #919191;"}
  &:hover {
    ${p =>
      !p.selected &&
      `
      background-color: white;
       `};
    ${p =>
      !p.selected &&
      `
      color: ${COLORS.red500};
       `};
  }
`;

export const StyleChartViewButton = styled(StyleTableViewButton)`
  position: relative;
  border-radius: 4px;
  margin-left: -5px;
  border-radius: 0 4px 4px 0;
  display: inline-block;
  height: 40px;
  z-index: 1 !important;
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid #c3c3c3;
  margin: 50px 0 40px;
`;
export const CollapseDataInfo = styled.div`
  display: inline-block;
  padding-left: 95%;
  position: absolute;
  right: 30px;

  cursor: pointer;
`;
export const CollapseDataInfoActive = styled.div`
  display: inline-block;
  padding-left: 95%;
  position: absolute;
  right: 30px;
  cursor: pointer;
`;
export const ActiveCell = styled(Cell)`
  background-color: white;
`;

export const iconMargin = {
  marginLeft: "7px",
};

export const Edit = styled.button`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0px 2px 4px #c3c3c3;
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  border: none;
  width: 140px;
  height: 40px;
  margin: 0 0 0 10px;
  cursor: pointer;
  color: white;
  background-color: ${COLORS.red500};
  z-index: 1 !important;
`;

export const Export = styled.button<{ open: boolean }>`
  border: 1px solid #c3c3c3;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  border: 1px solid #c3c3c3;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  width: 100px;
  height: 40px;
  cursor: pointer;
  color: ${COLORS.red500};
  ${p => p.open && "background: white;"}
`;
export const UpdateButton = styled.button<{ top?: boolean }>`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
  height: 40px;
  width: 160px;
  cursor: pointer;
  color: white;
  border: none;
  background: ${COLORS.red500};
  box-shadow: 0px 2px 4px #c3c3c3;
  ${p => p.top && "margin: 22px 0 20px 15px"};
`;

export const ExTypes = styled.div`
  position: absolute;
  top: 36px;
  left: 0px;
  z-index: 12;
  background: white;
  border: 1px solid #c3c3c3;
  border-top: none;
  border-radius: 0 0 4px 4px;
  width: 100px;
  overflow: hidden;
`;

export const Type = styled.div`
  width: 100px;
  color: #2d2d2d;
  font-size: 15px;
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  padding-left: 15px;
  &:hover {
    background: #f5f5f5;
  }
`;

export const TableCont = styled.div`
  width: 100%;
  overflow-x: auto;
`;
