import * as React from "react";
import { MentionsInput, Mention } from "react-mentions";
import { Icon } from "semantic-ui-react";
import { DisabledMentionsInputStyle, DefaultMentionsInputStyle, DefaultMentionStyle } from "./CommentMentions.style";
import { PillButton } from "./components";
import { COLORS } from "component/UI/common";

interface Props {
  onSave?: (message: string, replyTo?: number) => Promise<boolean>;
  onUpdate?: (message: string) => Promise<void>;
  onCancel?: () => void;
  comment?: string;
  editing?: boolean;
  autoFocus?: boolean;
  mentionUsers: any[];
}

export const CommentMentions = (props: Props): JSX.Element => {
  const { onSave, onUpdate, onCancel, comment, editing, autoFocus, mentionUsers } = props;
  const [currentComment, setCurrentComment] = React.useState<string | undefined>(comment);

  const mentionUserOptions = mentionUsers?.map(user => ({
    id: user.id,
    display: `${user.first_name} ${user.last_name}`,
    avatar: user.avatar || "/assets/default.png",
  }));

  const onSaveClick = async () => {
    if (currentComment) {
      const newComment = currentComment.trim();
      if (onSave) {
        const success = await onSave(newComment);
        if (success) {
          setCurrentComment("");
        }
        // TODO: show errors to handle create dashboard comment fail
      }
    }
  };

  const onUpdateClick = () => {
    if (currentComment) {
      const newComment = currentComment.trim();
      onUpdate && onUpdate(newComment);
    }
  };

  const onCancelClick = () => {
    setCurrentComment(comment);
    onCancel && onCancel();
  };

  const MentionsInputStyle = comment && !editing ? { ...DefaultMentionsInputStyle, ...DisabledMentionsInputStyle } : DefaultMentionsInputStyle;

  return (
    <div className="flex-grow-1 position-relative">
      <MentionsInput
        value={currentComment || ""}
        onChange={event => setCurrentComment(event.target.value)}
        autoFocus={autoFocus}
        placeholder="Type comment"
        disabled={comment && !editing}
        style={MentionsInputStyle}
      >
        <Mention
          trigger="@"
          data={mentionUserOptions}
          markup="<__id__|__display__>"
          displayTransform={(_id, display) => `@${display}`}
          style={DefaultMentionStyle}
          renderSuggestion={(_suggestion, _search, highlightedDisplay) => (
            <div className="user">{highlightedDisplay}</div>
          )}
        />
      </MentionsInput>
      {!comment && (
        <div className="position-absolute d-flex" style={{ right: 6, bottom: 8 }}>
          <div style={{ width: 1, height: 20, marginRight: 4, backgroundColor: COLORS.grey300 }}></div>
          <Icon
            className={currentComment ? "text-secondary cursor-pointer" : "text-grey"}
            name="arrow up"
            onClick={onSaveClick}
            style={{ marginRight: 0, marginTop: -2 }}
          />
        </div>
      )}
      {comment && editing && (
        <div className="d-flex justify-content-end position-absolute" style={{ right: 6, bottom: 6 }}>
          <PillButton size="small" color="red" label="Cancel" onClick={onCancelClick} />
          <PillButton size="small" disabled={!currentComment} color="aqua" label="Update" onClick={onUpdateClick} />
        </div>
      )}
    </div>
  );
};
