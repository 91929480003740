import * as React from "react";
import styled from "styled-components";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

import { renderActionStatus } from "../DPACKCSSearch";

interface Props {
  data: any[];
}

const StyledPieContainer = styled.div`
  height: 280px;
  
  @media (max-width: 900px) {
    height: 280px;
  }
`;

const chart1columns = ["Completed", "In Progress", "In Progress - Delayed", "Not Commenced"];
const chart1colors = {
  "Completed": "#879C81",
  "In Progress": "#DDC88D",
  "In Progress - Delayed": "#9C90AD",
  "Not Commenced": "#3B3835",
};
const chart2columns = ["Phase 1 - 2024", "Phase 2 - 2026", "Phase 3 - 2029"];
const chart2colors = {
  "Interim Actions": "#000000",
  "Phase 1 - 2024": "#9C90AD",
  "Phase 2 - 2026": "#455687",
  "Phase 3 - 2029": "#DDC88D",
};

export const PieCharts = (props: Props) => {
  const { data } = props;
  const coiData = data.filter(item => item.action_type === "Commission of Inquiry Recommendation Action");
  const chart1data = chart1columns.map(name => ({ name, value: coiData.filter(row => row.status === name).length }));
  const chart1total = chart1data.reduce((prev, next) => prev + next.value, 0);
  const chart2data = ["Phase 1 - 2024", "Phase 2 - 2026", "Phase 3 - 2029"].map(name => ({ name, value: coiData.filter(row => row.phase === name).length }));
  const chart2total = chart2data.reduce((prev, next) => prev + next.value, 0);

  return (
    <div className="mt-3 mb-6 d-md-flex">
      <div className="w-100 mb-3 mb-md-0" style={{ padding: "0 16px" }}>
        <h4 style={{ fontSize: 20 }}>Commission of Inquiry recommendation by status</h4>
        <StyledPieContainer aria-hidden="true" tabIndex={-1}>
          <ResponsiveContainer height="100%" width="100%">
            <PieChart>
              <Pie
                data={chart1data}
                dataKey="value"
                stroke="none"
                rootTabIndex={-1}
              >
                {chart1data.map(row => (
                  <Cell key={row.name} fill={chart1colors[row.name]} />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const { value, name } = payload[0];
                    return (
                      <div className="p-3" style={{ maxWidth: 200, backgroundColor: "white", border: "1px solid #D7D7D7", borderRadius: 4 }}>
                        <p style={{ fontSize: 12 }}><b>Status</b></p>
                        <div className="d-flex align-items-center">
                          <div className="mr-2" style={{ height: 15, width: 15, backgroundColor: chart1colors[name as string] }} />
                          <p style={{ fontSize: 12 }}>{name}: <b>{value}</b></p>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </StyledPieContainer>
        <div>
          {chart1columns.map((name, idx) => {
            const number = chart1data[idx].value;
            if (!number) {
              return null; // hide legend for 0 values
            }
            return (
              <div key={name} className="d-flex align-items-center mb-2">
                <div className="mr-2" style={{ height: 15, width: 15, backgroundColor: chart1colors[name] }} />
                <p style={{ fontSize: 12 }}>{renderActionStatus(name)}: <b>{Math.floor(number / chart1total * 100)}% ({number})</b></p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-100" style={{ padding: "0 16px" }}>
        <h4 style={{ fontSize: 20 }}>Commission of Inquiry recommendation by phase</h4>
        <StyledPieContainer aria-hidden="true" tabIndex={-1}>
          <ResponsiveContainer height="100%" width="100%">
            <PieChart>
              <Pie
                data={chart2data}
                dataKey="value"
                stroke="none"
                rootTabIndex={-1}
              >
                {chart2data.map(row => (
                  <Cell key={row.name} fill={chart2colors[row.name]} />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const { value, name } = payload[0];
                    return (
                      <div className="p-3" style={{ maxWidth: 200, backgroundColor: "white", border: "1px solid #D7D7D7", borderRadius: 4 }}>
                        <p style={{ fontSize: 12 }}><b>Status</b></p>
                        <div className="d-flex align-items-center">
                          <div className="mr-2" style={{ height: 15, width: 15, backgroundColor: chart2colors[name as string] }} />
                          <p style={{ fontSize: 12 }}>{name}: <b>{value}</b></p>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </StyledPieContainer>
        <div>
          {chart2columns.map((name, idx) => {
            const number = chart2data[idx].value;
            if (!number) {
              return null; // hide legend for 0 values
            }
            return (
              <div key={name} className="d-flex align-items-center mb-2">
                <div className="mr-2" style={{ height: 15, width: 15, backgroundColor: chart2colors[name] }} />
                <p style={{ fontSize: 12 }}>{name}: <b>{Math.floor(number / chart2total * 100)}% ({number})</b></p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
