// Chart helpers
import * as numeral from "numeral";
import { uniq, uniqWith, isEqual } from "lodash";
import { IFilter } from "common/store/builder";
import { DEFAULT_TABLE_NAME_REGEX, getCombinedDims, getMaxValue, isPercentage, toPercentage, YAXIS_DECIMAL_FORMAT_BENCHMARK } from "./data";
import { buildArrayCombos } from "./dimensions";
import { canChartCalculation, getCNumber } from "common/helpers/explore";
import { numberFormatter } from "./string";
import { ObjectAny } from "./types";
import { ifFormattedAsPercentage } from "component/Explore/includes/helper";
import * as domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

export const DEFAULT_CHART_CONFIG = {
  type: "Vertical bar clustered",
  highlights: [],
  percentage: false,
  xLabel: "",
  yLabel: "",
  angle: undefined,
  labelFont: undefined,
  labelLength: 0,
};

export const defaultAngle: number = -45;
export const defaultLabelFont: number = 12;
export const PREVIEW_AXIS_TICK_LABEL_MAX_WIDTH_PX = 50;
export const PREVIEW_LABEL_FONT_SIZE = 6;
export const CHART_FONT_FAMILY = "Helvetica, sans-serif";
export const CHART_PRIMARY_COLOR = "#504F5C";
export const CHART_SECONDARY_COLOR = "#6F6E82";
export const CHART_AXIS_COLOR = "#B1B1BE";
export const CHART_GRID_COLOR = "#CECEDC";
export const CHART_TOOLTIP_FILL = "rgba(222, 222, 235, 0.3)";
export const PREVIEW_LABEL_STYLING = { fontSize: PREVIEW_LABEL_FONT_SIZE, fontFamily: CHART_FONT_FAMILY, fill: CHART_SECONDARY_COLOR };
export const DEFAULT_LABEL_STYLING = { fontSize: 12, fontFamily: CHART_FONT_FAMILY, fill: CHART_SECONDARY_COLOR };
export const DEFAULT_COLOR_PALETTE = ["#5f2d5f", "#913366", "#c03e61", "#e55650", "#fb7a36", "#ffa600"];

export const generateColorsWithPalette = (number: number, palette?: string[]): string[] => {
  const colorPalette = palette || DEFAULT_COLOR_PALETTE;
  const defaultLength = colorPalette.length;
  if (number <= defaultLength) {
    return colorPalette.slice(0, number);
  } else {
    const multiplier = Math.floor(number / defaultLength);
    const remainder = number % defaultLength;
    return (Array.from(Array(multiplier)).reduce((pre, _curr) => pre.concat(colorPalette), []).concat(colorPalette.slice(0, remainder)));
  }
};

export const generateSingleChartData = (xAxisDims, legendDims, allTables, chartTables, missingFallback: number | undefined, seriesDim?) => {
  let seriesVarCat: any = []; // seriesDim [variable,category]
  if (seriesDim) {
    if (seriesDim.dimension.startsWith("!")) {
      seriesVarCat = seriesDim.values[0].split(":::");
    } else {
      seriesVarCat = [seriesDim.dimension, seriesDim.values[0]];
    }
  }

  const chartDataArr: any[] = [];

  const xAxisCombo = buildArrayCombos(xAxisDims.map(x => x.values));

  xAxisCombo.forEach(xCombo => {
    const comboData = {};

    xCombo.forEach((cat, i) =>
      comboData[`label${xCombo.length - 1 - i}`] = cat
    );

    chartTables.forEach(t => {

      const tableDims = t.type !== "calc" ? [...t.filters, ...t.rows, ...t.columns] : [];
      const tableDimNames = tableDims.map(d => d.dimension);
      const tableLegendDims = legendDims.filter(x => tableDimNames.includes(x.dimension));

      if (tableLegendDims.length > 0) {
        const tableLegendCombo = buildArrayCombos(tableLegendDims.map(x => tableDims.find(d => d.dimension === x.dimension)).map(x => x.values));

        tableLegendCombo.forEach(lCombo => {
          const matchedResult = t.results?.find(res =>
            xCombo.every((category, j) => res[xAxisDims[j].dimension.startsWith("!") ? category.split(":::")[0] : xAxisDims[j].dimension]?.toString() === (
              xAxisDims[j].dimension.startsWith("!") ? category.split(":::")[1] : category.toString()
            ))
            &&
            lCombo.every((category, j) => res[tableLegendDims[j].dimension] === category)
            &&
            (seriesDim ? res[seriesVarCat[0]] === seriesVarCat[1] : true)
          );

          if (matchedResult) {
            comboData[`${t.id}#${lCombo.join(" - ")}`] = matchedResult["Value"];
          } else {
            comboData[`${t.id}#${lCombo.join(" - ")}`] = undefined;
          }
        });

      } else if (t.type === "calc") {
        const value = getCNumber(allTables, t, xCombo, xAxisDims, missingFallback);
        comboData[`${t.id}#`] = isNaN(value) ? null : value; // If the calculation result is 'NaN', we need to set it to 'null' because Recharts doesn't handle 'NaN' properly in stacked charts.
      } else {
        const matchedResult = t.results?.find(res =>
          xCombo.every((category, j) => res[xAxisDims[j].dimension.startsWith("!") ? category.split(":::")[0] : xAxisDims[j].dimension]?.toString() === (
            xAxisDims[j].dimension.startsWith("!") ? category.split(":::")[1] : category.toString()
          ))
          &&
          (seriesDim ? res[seriesVarCat[0]] === seriesVarCat[1] : true)
        );

        if (matchedResult) {
          comboData[`${t.id}#`] = matchedResult["Value"];
        } else {
          comboData[`${t.id}#`] = undefined;
        }
      }

    });

    chartDataArr.push(comboData);
  });

  return chartDataArr;
};

export const getChartData = (chartXAxisArray, chartLegend, chartSeries, columns, allTables, missingFallback: number | undefined) => {
  const xAxisDims = chartXAxisArray;
  const legendDims = chartLegend;
  const seriesDims = chartSeries;

  const chartTables = getChartTables(allTables);

  const filteredChartTables = getFilteredChartTables(chartTables, columns, xAxisDims, chartSeries);
  const allChartData: any[] = [];

  // Return an empty array if no chartTables
  if (filteredChartTables.length === 0) {
    return allChartData;
  }

  if (filteredChartTables.every(table => table.type === "calc")) { // For only displaying calculation tables
    // Need to use "getCombinedDims" to generate combined dims when there are multiple same type dims in the columns
    // e.g. Convert "LGA > Barkly, Country > Australia" to "!Location > LGA:::Barkly, Country:::Australia"
    allChartData.push(generateSingleChartData(getCombinedDims(columns), [], allTables, filteredChartTables, missingFallback));
  } else {
    // Assume a max of one series dimension for now
    if (seriesDims.length > 0) {
      for (const category of seriesDims[0].values) {
        allChartData.push(generateSingleChartData(
          xAxisDims, legendDims, allTables, filteredChartTables, missingFallback, { dimension: seriesDims[0].dimension, values: [category] })
        );
      }
    } else {
      allChartData.push(generateSingleChartData(xAxisDims, legendDims, allTables, filteredChartTables, missingFallback));
    }
  }

  return allChartData;
};

export const getChartTables = (tables): any[] =>
   tables.filter(table => table.graph)
    .filter(table => table.type === "calc" || (table.results && table.results.length > 0)) // remove not completed tables from array
  ;

// Filter out any calculations that can't be charted with the current chart configuration
export const getFilteredChartTables = (chartTables: any[], columns: IFilter[], chartXAxisArray: any, chartSeries: any): ObjectAny[] => chartTables.filter(t => t.type !== "calc" || canChartCalculation(columns, isALLChartTablesCal(chartTables) ? columns : chartXAxisArray, !!chartSeries.length));

export const isALLChartTablesCal = (chartTables): boolean => chartTables.every(table => table.type === "calc");

export const ifShowTableNames = (chartTables: any[]): boolean => (chartTables.length > 1 && !isALLChartTablesCal(chartTables)) || (chartTables.length === 1 && !chartTables[0].name.match(DEFAULT_TABLE_NAME_REGEX) && chartTables[0].type !== "calc");

export const toDonutChartData = (singleChartData, chartKeyValues, chartXAxisCombo) => (
  singleChartData.map((data, index) => {
    // Remove ":::" from the array items
    const formattedChartXAxisCombo = chartXAxisCombo[index].map(item => {
      if (item.toString().includes(":::")) {
        return item.split(":::")[1];
      } else {
        return item;
      }
    });
    const label = formattedChartXAxisCombo.join(" - ");
    return chartKeyValues.map(key => {
      const pieDataItem = {};
      // TODO: Investigate if it is possible to just use "label" when "key" is only the table id. In that case, we won't need to remove the parenthesis when displaying single-row table pie chart tooltip
      pieDataItem["name"] = `${key} (${label})`;
      pieDataItem["value"] = data[key];
      return pieDataItem;
    });
  })
);

export const getSimplePieChartData = (legend, xAxis, chartData, chartTables) => {
  let series;
  const data: any[] = [];
  if (legend.length === 1 && xAxis.length === 1) {
    series = {...xAxis[0]};
    for (let i = 0; i < xAxis[0].values.length; i++) {
      const currentData = chartData[0][i];
      const singleData: any[] = [];
      for (const val of legend[0].values) {
        singleData.push({
          name: val,
          value: currentData[`${chartTables[0].id}#${val}`],
        });
      }
      data.push(singleData);
    }
  } else {
    const singleData = chartData[0]?.map(data => ({
      name: data.label0,
      value: data[`${chartTables[0].id}#`],
    }));
    data.push(singleData);
  }
  return { series, data };
};

// Helper function to generate initial Metric chart data for result table
const generateInitialResultTableMetricData = (combinedVariablesOne: IFilter[], variablesOneArrayCombo: any[], combinedVariablesTwo: IFilter[], variablesTwoArrayCombo: any[], results: ObjectAny[]): ObjectAny[][] => {
  // Filter results
  let filteredResults = [...results];
  for (let i = 0; i < combinedVariablesOne.length; i++) {
    const columnDim = combinedVariablesOne[i]["dimension"];
    filteredResults = [...filteredResults].filter(res => res[columnDim] === variablesOneArrayCombo[0][i]);
  }
  // Sort results
  let sortedResults: any[] = [];
  if (combinedVariablesTwo.length > 0) {
    for (const currentRowComboValues of variablesTwoArrayCombo) {
      let unorderedResults = [...filteredResults];
      for (let k = 0; k < currentRowComboValues.length; k++) {
        const rowValue = currentRowComboValues[k];
        if (rowValue.toString().includes(":::")) {
          unorderedResults = unorderedResults.filter(res => res[rowValue.split(":::")[0]] === rowValue.split(":::")[1]);
        } else {
          unorderedResults = unorderedResults.filter(res => res[combinedVariablesTwo[k].dimension] === rowValue);
        }
      }
      if (unorderedResults[0]) {
        sortedResults.push(unorderedResults[0]);
      }
    }
  } else {
    sortedResults = [...filteredResults];
  }
  return sortedResults;
};

// Helper function to generate final Metric chart data for result table
const generateFinalResultTableMetricData = (chartTable: ObjectAny, multiCategoryCombinedColumns: IFilter[], columns: IFilter[], forMultipleChartTables: boolean, chartTableIdx?: number): any[] => {
  let resultTableMetricData: any[] = [];
  const tableResult: ObjectAny[] = chartTable.results;
  const rows = chartTable.rows;
  const multiCategoryRows: IFilter[] = rows.filter(row => row.values.length > 1);
  const rowArrayCombo: any[] = rows.length > 0 ? buildArrayCombos(multiCategoryRows.map(row => row.values)) : [];
  const columnArrayCombo = getColumnArrayCombo(columns);
  if (columnArrayCombo.length <= 1 || forMultipleChartTables) {
    // use the first column data when column items length <= 1 or using for showing multiple chart tables
    resultTableMetricData = generateInitialResultTableMetricData(multiCategoryCombinedColumns, columnArrayCombo, multiCategoryRows, rowArrayCombo, tableResult);
  } else {
    // use the first row data
    resultTableMetricData = generateInitialResultTableMetricData(multiCategoryRows, rowArrayCombo, multiCategoryCombinedColumns, columnArrayCombo, tableResult);
  }
  // If data is percentage, need to compute the other part of the ring data to show two colors in each ring
  resultTableMetricData = [...resultTableMetricData].map((res, idx) => {
    // Create new res and use lowercase "value" to make it consistent with all other types of charts
    const newRes = Object.assign({}, res, {
      value: res["Value"],
      legendItemIdx: chartTableIdx === undefined ? idx : chartTableIdx, // Used for getting the tooltip label in "CustomMetricToolTip"
      legendItemChildIdx: forMultipleChartTables ? idx : undefined, // Used for getting the tooltip label in the nested legend items when there are multiple chart tables in "CustomMetricToolTip"
      "Result table name": chartTable.name,
    });
    delete newRes["Value"];
    if (isPercentage(newRes["Measured quantity"] as string)) {
      return [{ ...newRes }, { value: 1 - newRes["value"] }];
    } else {
      return [{ ...newRes }];
    }
  });
  return resultTableMetricData;
};

// Helper function to generate ring data for single calc table
const generateCalcTableSingleCircleData = (chartTable: ObjectAny, calcChartData: any[], multiCategoryCombinedColumns: IFilter[], idx: number): any[] => {
  const singleCircleData: ObjectAny[] = [];
  const fillCircleData = {};
  fillCircleData["value"] = calcChartData[`${chartTable.id}#`];
  multiCategoryCombinedColumns.forEach((col, colIdx) => {
    const currentColValue = calcChartData[`label${colIdx}`].toString();
    if (col.dimension.startsWith("!")) {
      fillCircleData[currentColValue.split(":::")[0]] = currentColValue.split(":::")[1];
    } else {
      fillCircleData[col.dimension] = currentColValue;
    }
  });
  fillCircleData["legendItemIdx"] = idx; // Used for getting the tooltip label in "CustomMetricToolTip"
  fillCircleData["Calc format"] = chartTable.format;
  fillCircleData["Calc table name"] = chartTable.name;
  singleCircleData.push(fillCircleData);
  if (chartTable.format === "Percent") { // If the calc table is percentage, need to compute the other part of the circle
    singleCircleData.push({ value: 1 - fillCircleData["value"] });
  }
  return singleCircleData;
};

export const getMetricChartData = (formatChartData: any[], chartTables: ObjectAny[], columns: IFilter[]): any[] => {
  if (formatChartData.length === 0) {
    return [];
  }
  let metricData: any[] = [];
  const originChartData: any[][] = formatChartData[0];
  const combinedColumns: IFilter[] = getCombinedDims(columns);
  const multiCategoryCombinedColumns = combinedColumns.filter(col => col.values.length > 1);
  // Display data logic for metricData:
  // If there is only one chartTable
    // If chartTable is calc: use the data of the first row
    // If chartTable is result
      // If column items length <= 1: use the data of the first column
      // If column items length > 1: use the data of the first row
  // If there are multiple chartTables: use the data of the first column
  if (chartTables.length === 1) {
    const chartTable: ObjectAny = chartTables[0];
    if (chartTable.type === "calc") {
      originChartData.forEach((data, idx) => {
        metricData.push(generateCalcTableSingleCircleData(chartTable, data, multiCategoryCombinedColumns, idx));
      });
    } else {
      metricData = generateFinalResultTableMetricData(chartTable, multiCategoryCombinedColumns, columns, false);
    }
  } else {
    chartTables.forEach((chartTable, idx) => {
      if (chartTable.type === "calc") {
        metricData.push(generateCalcTableSingleCircleData(chartTable, originChartData[0], multiCategoryCombinedColumns, idx));
      } else {
        metricData.push(...generateFinalResultTableMetricData(chartTable, multiCategoryCombinedColumns, columns, true, idx) as []);
      }
    });
  }
  return [metricData];
};

// Helper function to generate Metric chart legend items array
// Used in Metric chart legend and Metric chart tooltip
export const generateMetricLegendItems = (filteredChartTables: ObjectAny[], columns: IFilter[]): any[] => {
  const legendItems: any[][] = [];
  const ifMultipleChartTables = filteredChartTables.length > 1;
  const columnArrayCombo = getColumnArrayCombo(columns);
  filteredChartTables.forEach(chartTable => {
    const rowArrayCombo: any[] = getRowArrayCombo(chartTable.rows as IFilter[]);
    let displayTableValues: { valueOne: any; valueTwo: any;  valueThree: any };
    if (chartTable.type === "calc") {
      displayTableValues = {
        valueOne: [[chartTable.name]],
        valueTwo: [[chartTable.name]],
        valueThree: columnArrayCombo,
      };
    } else {
      displayTableValues = {
        valueOne: rowArrayCombo,
        valueTwo: chartTable.rows.length > 0 ? [chartTable.rows[0].values] : [columns[0].values],
        valueThree: columnArrayCombo,
      };
    }
    legendItems.push(metricDataDisplayLogicHelper(chartTable as ObjectAny, ifMultipleChartTables, columnArrayCombo, displayTableValues) as any[]);
  });
  return legendItems;
};

// Helper function for generating Metric chart colors and legend items
export const metricDataDisplayLogicHelper = (chartTable: ObjectAny, ifMultipleChartTables: boolean, columnArrayCombo: any[], { valueOne, valueTwo, valueThree } ): string | string[] => {
  if (chartTable.type === "calc") {
    if (ifMultipleChartTables) {
      return valueOne;
    } else if (columnArrayCombo.length <= 1) {
      return valueTwo;
    } else {
      return valueThree;
    }
  } else {
    const rows = chartTable.rows;
    const multiCategoryRows = rows.filter(row => row.values.length > 1);
    if (columnArrayCombo.length <= 1 || ifMultipleChartTables) {
      if (multiCategoryRows.length > 0) {
        return valueOne;
      } else {
        return valueTwo;
      }
    } else {
      return valueThree;
    }
  }
};

export const getRowArrayCombo = (rows: IFilter[]): any[] => {
  const multiCategoryRows = rows?.filter(row => row.values.length > 1);
  return rows?.length > 0 ? buildArrayCombos(multiCategoryRows?.map(row => row.values)) : [];
};

export const getColumnArrayCombo = (columns: IFilter[]): any[] => {
  const multiCategoryCombinedColumns = getCombinedDims(columns).filter(col => col.values.length > 1);
  return columns.length > 0 ? buildArrayCombos(multiCategoryCombinedColumns.map(column => column.values)) : [];
};

export const getChartKey = (chartData): any => {
  const chartKeys: Array<any> = [];

  chartData.forEach(series => {
    series.forEach(x => chartKeys.push(Object.keys(x)));
  });

  return {
    dimension: "Combined",
    values: uniq(chartKeys.flat()).filter(k => !k.startsWith("label")),
  };
};

export const getChartXAxisCombo = (chartXAxisArray) => buildArrayCombos(chartXAxisArray.map(x => x.values));

export const ifValidSimplePieChart = (chartType: string, chartXAxisArray: any[], chartLegend: any[], chartSeries: any[]): boolean => chartLegend.length === 0 && chartXAxisArray.length === 1 && chartSeries.length === 0 && chartType.includes("Pie");

export const getDefaultMetricChartColors = (chartTables: any[], columns: IFilter[], chartXAxisArray: any[], chartSeries: any[]): string[] => {
  let metricChartColors: string[] = [];
  const filteredChartTables = getFilteredChartTables(chartTables, columns, chartXAxisArray, chartSeries);
  const ifMultipleChartTables = filteredChartTables.length > 1;
  filteredChartTables.forEach(chartTable => {
    const chartTableColors: string[] = chartTable.colors;
    const rowArrayCombo: any[] = getRowArrayCombo(chartTable.rows as IFilter[]);
    let displayTableValues: { valueOne: any; valueTwo: any;  valueThree: any };
    if (chartTable.type === "calc") {
      displayTableValues = {
        valueOne: chartTableColors[0],
        valueTwo: chartTableColors,
        valueThree: chartTableColors,
      };
    } else {
      displayTableValues = {
        valueOne: chartTableColors.slice(0, rowArrayCombo.length),
        valueTwo: chartTableColors[0],
        valueThree: chartTableColors,
      };
    }
    metricChartColors = metricChartColors.concat(metricDataDisplayLogicHelper(chartTable, ifMultipleChartTables, getColumnArrayCombo(columns), displayTableValues));
  });
  return metricChartColors;
};

// Generate the number of custom chart colors according to the chart types
export const getCustomChartColorNumber = (chartType: string, chartXAxisArray: any[], chartKey: any[], chartTables: any[], columns: IFilter[], chartSeries: any[], chartLegend: any[]): number => {
  const isValidSimplePieChart = ifValidSimplePieChart(chartType, chartXAxisArray, chartLegend, chartSeries);
  const isMetricChart = chartType.includes("Metric");
  const colorNumber = isValidSimplePieChart
    ? chartXAxisArray[0].values.length
    : isMetricChart
      ? getDefaultMetricChartColors(chartTables, columns, chartXAxisArray, chartSeries).length
      : chartKey.values.length;
  return colorNumber;
};

export const getChartColors = (customChartColors: string[] | [], chartType: string, chartTables: any[], columns: IFilter[], chartXAxisArray: any[], chartSeries: any[]): string[] => {
  if (customChartColors.length > 0) {
    return customChartColors;
  }
  if (chartTables.length > 0) {
    if (chartType === "Metric") { // Need to generate colors for Metric chart based on columns and rows as it's not using all colors of each table
      return getDefaultMetricChartColors(chartTables, columns, chartXAxisArray, chartSeries);
    }
    return chartTables.reduce((prev, curr) => prev.concat(curr.colors), []);
  } else {
    return [];
  }
};

export const getTablesSelectedDimensions = (tables) => tables.reduce((acc, table) => [...acc, ...table.filters, ...table.rows, ...table.columns], []);

export const getMultiCategoryColumns = (columns) => columns.filter(d => d.values.length > 1);

export const getMultiCategoryRows = (tables) => tables.reduce((acc, table) => [...acc, ...table.rows], []).filter(d => d.values.length > 1);

export const getSingleCategoryDimensions = (tablesSelectedDimensions) => tablesSelectedDimensions.filter(d => d.values.length === 1);

export const getAllTablesRowsAndCols = (tables) => tables.reduce((acc, table) => [...acc, ...table.rows, ...table.columns], []);

// More detail for this function: https://www.notion.so/seerdata/New-Builder-Bug-Time-and-Location-not-displaying-correctly-d9565309123d4b44a0990999fc7de08f
export const getLogicalChartDefaults = (chartTables: any[], insightColumns: any[]) => {
  const tables = chartTables.filter(table => table.type === "result"); // don't use "calc" type in this computation
  if (tables.length === 0) {
    // see getChartData implementation, calc tables logic, currently for calc all column dims thrown into xAxis, ensure this is kept consistent
    const xAxis = chartTables.length ? getCombinedDims(insightColumns) : [];
    return {
      xAxis,
      legend: [],
      series: [],
    };
  }
  const allTablesRowsAndCols: IFilter[] = tables.reduce((acc, table) => [...acc, ...table.rows, ...table.columns], []);
  const multiCategoryRows: IFilter[] = tables.reduce((acc, table) => [...acc, ...table.rows], [])
    .filter(d => d.values.length > 1);
  const columns: IFilter[] = tables[0].columns;
  const uniqueMultiCategoryVariables: IFilter[] = getUniqueMultiCategoryVariables(columns, multiCategoryRows);

  const combinedMultiCategoryVariables = uniqueMultiCategoryVariables.filter( c => c.dimension.startsWith("!"));
  const nonCombinedMultiCategoryVariables = uniqueMultiCategoryVariables.filter( c => !c.dimension.startsWith("!"));

  let xAxisDefault: any = [];
  const legendDefault: any = [];
  const seriesDefault: any = [];

  if (uniqueMultiCategoryVariables.length === 0) {
    xAxisDefault = [...xAxisDefault, allTablesRowsAndCols[0]];
  }

  if (combinedMultiCategoryVariables.length === 0) {
    nonCombinedMultiCategoryVariables[0] && xAxisDefault.push(nonCombinedMultiCategoryVariables[0]);
    nonCombinedMultiCategoryVariables[1] && legendDefault.push(nonCombinedMultiCategoryVariables[1]);
    nonCombinedMultiCategoryVariables[2] && seriesDefault.push(nonCombinedMultiCategoryVariables[2]);
    nonCombinedMultiCategoryVariables[3] && xAxisDefault.push(nonCombinedMultiCategoryVariables[3]);
  } else {
    // Always allocate combinedMultiCategoryVariables to xAxis
    xAxisDefault = [...xAxisDefault, ...combinedMultiCategoryVariables];
    nonCombinedMultiCategoryVariables[0] && legendDefault.push(nonCombinedMultiCategoryVariables[0]);
    nonCombinedMultiCategoryVariables[1] && seriesDefault.push(nonCombinedMultiCategoryVariables[1]);
    nonCombinedMultiCategoryVariables[2] && legendDefault.push(nonCombinedMultiCategoryVariables[2]);
  }

  return {
    xAxis: xAxisDefault,
    legend: legendDefault,
    series: seriesDefault,
  };
};

export const getChartConfiguration = (userDefinedCharts: any, chartTables: any[], columns: IFilter[]) => {
  let legend: IFilter[];
  let xAxis: IFilter[];
  let series: IFilter[];
  if (userDefinedCharts) {
    legend = userDefinedCharts.legend;
    xAxis = userDefinedCharts.xAxis;
    series = userDefinedCharts.series;
  } else {
    const logicalChartDefaults = getLogicalChartDefaults(chartTables, columns);
    legend = logicalChartDefaults.legend;
    xAxis = logicalChartDefaults.xAxis;
    series = logicalChartDefaults.series;
  }
  return ({ legend, xAxis, series });
};

export const getPercentData = (chartKey, chartData) => chartData.map(singleChartData => singleChartData.map(data => {
  if (chartKey.values.length === 1) {
    return data;
  }
  let total = numeral(0);
  for (const value of chartKey.values) {
    const nextVal = isNaN(data[value]) || data[value] === null ? 0 : data[value];
    total = total.add(nextVal);
  }
  const formatData = {...data};
  for (const value of chartKey.values) {
    const nextVal = isNaN(formatData[value]) || formatData[value] === null ? 0 : formatData[value];
    formatData[value] = total.value()! === 0 ? 0 : numeral(nextVal).divide(total.value()).value();
    formatData[`${value}(raw)`] = nextVal; // For showing raw values in the tooltips
  }
  // @TODO - should use something like bignumber.js or decimal.js for manipulating numbers where arbitrary precision is required
  // Sometimes the total would be slightly higher than 1 e.g. 1.00000000002
  // If this is the case it just takes that extra bit off the first number so that the chart doesn't show percentages over 100%
  let newTotal = 0;
  for (const value of chartKey.values) {
    newTotal += formatData[value];
  }
  if (newTotal > 1) {
    const extra = newTotal - 1;
    for (const value of chartKey.values) {
      if (formatData[value] > extra) {
        formatData[value] -= extra;
        break;
      }
    }
  }
  return formatData;
}));

export const includes = (source, target) => source.toLowerCase().includes(target);

export const showExtraXAxis = (filter) => {
  if (filter) {
    const { dimension, values } = filter;
    if (dimension.startsWith("!")) {
      const uniqueDimensions = uniq(values.map(value => value.split(":::")[0]));
      if (uniqueDimensions.length > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getExtraXAxisConfig = (XAxisLabel0Values) => {
  const extraXAxisConfig: any = {};
  const textIndex: number[] = [];
  const startDividerIndex: number[] = [];
  const endDividerIndex: number[] = [];
  // Remove ":::" from array items
  const formattedValues: string[] = XAxisLabel0Values.map(value => value.split(":::")[0]);
  const uniqueDimensions: string[] = uniq(formattedValues);
  const dimensionsFirstAndLastIndex: any = [];
  uniqueDimensions.forEach(dimension => {
    const firstIndex = formattedValues.indexOf(dimension);
    const lastIndex = formattedValues.lastIndexOf(dimension);
    // Get each dimension's first and last displaying indexes. Example: [[0, 3], [4, 4]]
    dimensionsFirstAndLastIndex.push([firstIndex, lastIndex]);
    // Get start/end dividers indexes
    startDividerIndex.push(firstIndex);
    endDividerIndex.push(lastIndex);
  });
  // Get each dimension's middle displaying indexes
  dimensionsFirstAndLastIndex.forEach(indexArray => {
    if (indexArray[0] === indexArray[1]) {
      textIndex.push(indexArray[0]);
    } else {
      textIndex.push(Math.floor((indexArray[0] + indexArray[1]) / 2));
    }
  });
  extraXAxisConfig.text = textIndex;
  extraXAxisConfig.startDivider = startDividerIndex;
  extraXAxisConfig.endDivider = endDividerIndex;
  return extraXAxisConfig;
};

export const getNumColoursForTable = (t, legend: IFilter[]): number => legend.reduce((acc, l) => {
  const foundDim = t.rows.find(x => x.dimension === l.dimension) ||
    t.columns.find(x => x.dimension === l.dimension) ||
    getCombinedDims(t.rows).find(x => x.dimension === l.dimension) ||
    getCombinedDims(t.columns).find(x => x.dimension === l.dimension) ||
    t.filters.find(x => x.dimension === l.dimension);

  if (foundDim && foundDim.values.length > 0) {
    return acc * foundDim.values.length;
  } else {
    return acc;
  }
}, 1);

export const getNumColoursToGenerate = (tables, legend: IFilter[]): number => tables.reduce((acc, t) => {
  if (t.type === "calc") {
    return acc + 1; // Calculation table only has one row
  } else {
    return acc + getNumColoursForTable(t, legend);
  }
}, 0);

export const getCategory = (dimString) => {
  const splitStr = dimString.toString().split(":::");
  if (splitStr.length > 1) {
    return splitStr[1];
  } else {
    return splitStr[0];
  }
};

export const getVariable = (dimString) => {
  const splitStr = dimString.split(":::");
  if (splitStr.length > 1) {
    return splitStr[0];
  } else {
    return undefined;
  }
};

export const getUniqueMultiCategoryVariables = (columns: IFilter[], multiCategoryRows: IFilter[]): IFilter[] => {
  const combinedCols = getCombinedDims(columns);
  const uniqueMultiCategoryCols = combinedCols.filter(c => c.values.length > 1);
  // When there are multiple tables:
  // 1.We need to only keep unique variables
  const uniqueMultiCategoryRows = uniqWith(multiCategoryRows, isEqual);
  // 2.We also need to combine variables if two variables have the same "dimension" name but different "values"
  const uniqueMultiCategoryRowsDimensions = uniq(uniqueMultiCategoryRows.map(d => d.dimension)); // Get all unique variable dimensions
  const combinedUniqueMultiCategoryRows: IFilter[] = uniqueMultiCategoryRowsDimensions.map(d => {
    const combinedRowVariable: any = { dimension: d, values: [] };
    uniqueMultiCategoryRows.forEach(row => {
      if (row.dimension === d) {
        combinedRowVariable.values = [...combinedRowVariable.values, ...row.values];
      }
    });
    return combinedRowVariable;
  });
  return [...uniqueMultiCategoryCols, ...combinedUniqueMultiCategoryRows];
};

// Use in Rechart X/YAxis "tickFormatter" to format axis tick
export const formatter = (percentage: boolean, type: string, chartData: any[], chartTables: any[]) => (val: any, _index?, full?: boolean) => {
  const ifPercentage = ifFormattedAsPercentage(percentage, type, chartTables);
  if (ifPercentage) {
    const allChartsLabels = chartData.map(singleChartData => singleChartData
      .map(data => data.label0)).reduce((prev, curr) => prev.concat(curr), []);
    // decimal places are unnecessary (0) on axis labels if:
    // - chart is percentage or proportion/percentage chart or all chart tables are percentage calc
    // - and maxValue is larger than 4%
    const maxValue = getMaxValue(chartData);
    const fractionDigitsOverride = (!full && maxValue > YAXIS_DECIMAL_FORMAT_BENCHMARK && ifPercentage) ? 0 : undefined;
    return toPercentage(val, allChartsLabels, fractionDigitsOverride);
  } else {
    if (full) {
      return numeral(val).format("0,0.[00]");
    } else {
      return numberFormatter(val, 3);
    }
  }
};

export const downloadInsightChart = async (name: string) => {
  await new Promise(r => setTimeout(r, 500)); // Wait until Source table open
  const chartNode = document.getElementById("chartWithLegend");
  if (chartNode) {
    // Add Insight title node
    const titleNode = document.createElement("p");
    titleNode.style.fontWeight = "bold";
    titleNode.style.fontSize = "22px";
    titleNode.style.marginBottom = "10px";
    titleNode.appendChild(document.createTextNode(name));
    chartNode.prepend(titleNode);
    // Download chart image
    await domtoimage.toPng(chartNode)
      .then(async (img) => {
        saveAs(img, `Insight_Chart_${name}.png`);
      });
    // Remove Insight title node
    titleNode.remove();
  } else {
    throw new Error("Insight chart doesn't exist");
  }
};
