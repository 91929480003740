import { Checkbox, Label, List, Tab } from "semantic-ui-react";
import styled from "styled-components";
import { $opensans } from "common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

export const COLOURS = {
  seerPurple: COLORS.indigo600,
  seerPurpleTint100: "#FAF7FA",
  seerAqua: "#2dc3c3",
  newAqua: "#038386",
  newAquaTint200: "#E4F2F1",
  greyText700: "#5F5F5F",
  greyText800: "#504F5B",
};

// Add prop "menu={{ secondary: true, pointing: true }}" when using StyledTab
export const StyledTab = styled(Tab)`
  height: calc(100% - 35px);
  .ui.menu {
    font-family: ${$opensans};
    font-size: 14px !important;
  };
  .item {
    color: #836B86 !important;
    font-weight: 700 !important;
    border-color: #f5f5f5 !important;
    display: flex !important;
    justify-content: center;
    transition: 0.5s !important;
    &:hover {
      background-color: #FAF7FA !important;
      border-color: ${COLORS.indigo600} !important;
    }
  }
  a.active {
    color: #504F5B !important;
  }
  .ui.secondary.pointing.menu .active.item {
    border-color: ${COLORS.indigo600} !important;
  }
`;

export const PaneMenu = styled.div`
  margin-bottom: 15px;
`;

export const PaneContent = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: calc(100% - 300px);
  @media (min-width: 992px) {
    max-width: calc(100% - 400px);
  }
`;

export const PaneSidebar = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 300px;
  }
  @media (min-width: 992px) {
    width: 400px;
  }

  padding: 1rem 0 1rem 2rem;
  overflow-x: hidden;
  /* Body Small */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */


  /* Grey/Text grey 800 */

  color: ${COLOURS.greyText800};
`;

export const PaneSidebarTab = styled(StyledTab)`
  height: 100%;
`;

export const PaneSidebarActions = styled.div`
  height: calc(100% - 40px);
`;

export const SeerLargeTitle = styled.h2`
  color: ${COLOURS.seerPurple};
  line-height: 24px;
`;

export const StyleToggle = styled(Checkbox)`
  &.ui.toggle.checkbox input:focus:checked ~ .box:before, &.ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #2dc3c3 !important;
  }
  &.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #2dc3c3 !important;
  }
`;

export const StyledList = styled(List)`
  counter-reset: li;
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
  padding-left: 10px;

  li {
    counter-increment: li;
  }

  .item {
    // padding: 5px 0 5px 30px !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: capitalize;
    color: ${COLOURS.greyText700}
    justify-content: left !important;
  }

  .item::before {
    height: 20px;
    width: 20px;
    background-color: ${COLOURS.greyText700}
    border-radius: 50%;
    color: white !important;
    text-align: center !important;
    margin-left: -25px !important;
    font-size: smaller !important;
    font-weight: 700 !important;
  }
`;

export const SidebarTabPaneContent = styled(Tab.Pane)`
  background: ${COLOURS.seerPurpleTint100} !important;
  border: none !important;
  border-radius: 5px !important;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 70px);

  /* Inside auto layout */

  flex: none !important;
  order: 1 !important;
  flex-grow: 1 !important;
`;

export const StyledCircularLabel = styled(Label)`
  background-color: ${COLOURS.greyText700};
  color: #ffffff;
`;
