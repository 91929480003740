import React = require("react");
import { Link } from "react-router-dom";

// -=-=-=-=Store=-=-=-=-=-=-
import { observer } from "mobx-react";
import Store from "../../common/store";

// -=-=-=-=Styles-=-=-=-=-=-
import {
  StyleButton,
  StylePlanCard,
  StylePlanCardContent,
  StylePlanCardHeader,
  StylePlanCardDescription,
  StyleList,
  StyleListItem,
  StyleTagCurrent,
  StyleTagRecommended,
} from "./popupStyles/upgrade.style";

import { getMixpanel } from "../../common/api";

interface ICardStyleType {
  border?: string;
  boxShadow?: string;
}

interface IPlanCardProps {
  header: string;
  description: string;
  lists: string[];
  planId: number;
  button: string;
  link?: string;
  style: ICardStyleType;
  store: Store;
  mixpanelId: string;
}

const PlanCardComponent: React.FC<IPlanCardProps> = props => {
  const { header, description, lists, planId, button, link, style, store, mixpanelId } = props;

  const userPlanId = store.user!.group.group_type_id;

  const trackMixpanel = () => {
    getMixpanel(store).track(mixpanelId);
  };

  return (
    <StylePlanCard style={style}>
      <StylePlanCardContent>
        <StylePlanCardHeader>
          {header}
          {userPlanId === planId ? (
            <StyleTagCurrent>Current plan</StyleTagCurrent>
          ) : (
            header === "Plus" && userPlanId !== 4 && <StyleTagRecommended>Recommended</StyleTagRecommended>
          )}
        </StylePlanCardHeader>
        <StylePlanCardDescription>{description}</StylePlanCardDescription>
        <StyleList>
          {lists.map(list => <StyleListItem icon="check" content={list} />)}
        </StyleList>

        <Link to={{ pathname: link }} target="_blank">
          <StyleButton onClick={trackMixpanel}>{button}</StyleButton>
        </Link>
      </StylePlanCardContent>
    </StylePlanCard>
  );
};

export const PlanCard = observer(PlanCardComponent);
