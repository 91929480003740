import * as math from "mathjs";
import * as _ from "lodash";
import { isEqual, uniq } from "lodash";
import { Builder } from "common/store/builder";
import { buildArrayCombos } from "common/helpers/dimensions";
import { getCombinedDims } from "./data";

export const getCategorySelectionState = (builder: Builder, selectedTab: string): string => {
  // If no table is actively being edited, there's no state to show
  if (!builder.activeTableId) {
    return TABLE_SELECTION_STATE_OPTIONS.NO_DOT;
  }

  const isDataLoaded = Object.keys(builder.dimensions).length !== 0;

  // Available category options: array
  // const availableCategoryOptions = insightbuilder[`${selectedTab.toLowerCase()}`];
  const availableCategoryOptions = Object.entries(builder.dimensions).filter(([_,v]) =>
    v.type === selectedTab
  );

  // Selected category options: array
  // const selectedCategoryOptions = insightbuilder[`selected${selectedTab}`];

  const selectedCategoryOptions = Object.fromEntries(Object.entries(builder.qsAllFilters! || {}).filter(
    ([k,_]) => builder.dimensions[k] && builder.dimensions[k].type === selectedTab
  ))
    ||
  {};


  // Available sub-category options: array of array
  // const allAvailableSubCategoryOptions = selectedCategoryOptions
  //   .map(option => insightbuilder.dimensions[option].values
  //   .filter(value => value.requirement !== "excluded")
  //   .map(value => value.name));
  const allAvailableSubCategoryOptions = Object.entries(selectedCategoryOptions)
    .map(([k,_]) =>
      builder.dimensions[k].values.filter(value => value.requirement !== "excluded")
    .map(value => value.name));

  // Selected sub-category options: array of arrays
  // const allSelectedSubCategoryOptions = selectedCategoryOptions.map(dim => insightbuilder.getSelected(dim));
  const allSelectedSubCategoryOptions = Object.entries(selectedCategoryOptions).map(([_,v]) =>
    v
  );

  // Constrain selection logic
  // Constrain logic only for 'what' and 'how'
  const isWhatsOrHows = _.includes(["What", "How"], selectedTab);
  const isHowsWhensOrWheres = _.includes(["When", "Where", "How"], selectedTab);
  const areResultsAvailable = builder.results.length !== 0;

  if (isDataLoaded && availableCategoryOptions.length === 0) {
    // No options available: if data loaded and availableCategoryOptions.length === 0
    return "no options available";
  } else if (Object.keys(selectedCategoryOptions).length === 0 || allSelectedSubCategoryOptions.some(options => options.length === 0)) {
    // Missing selection: if selectedCategoryOptions.length === 0 (no categories selected) or selectedSubCategoryOption includes an empty array (one sub category has no value being selected)
    return "missing selection";
  } else if (builder.tables.length > 1 &&
      (isHowsWhensOrWheres ?
        builder.tables[0].columns.some(c => Object.keys(selectedCategoryOptions).includes(c.dimension))
        : availableCategoryOptions.every(([k, _]) => builder.tables[0].columns.some(c => c.dimension === k))) ) {
    return "locked";
  } else if ((availableCategoryOptions.length - Object.keys(selectedCategoryOptions).length === 0) && isWhatsOrHows && !areResultsAvailable && !allSelectedSubCategoryOptions.some(options => options.length === 0)) {
    // Constrain logic: disable sub category multi-selection for 'what' and 'how' before table is displayed
    // As the constrain selection & all options selected behave the same in UI, they both return "all options selected"
    return "all options selected";
  } else if ((availableCategoryOptions.length - Object.keys(selectedCategoryOptions).length > 0) || (_.flatten(allAvailableSubCategoryOptions).length - _.flatten(allSelectedSubCategoryOptions).length > 0)) {
    // More options available
    return "more options available";
  } else {
    // As the constrain selection & all options selected behave the same in UI, they both return "all options selected"
    return "all options selected";
  }
};

export const TABLE_SELECTION_STATE_OPTIONS = {
  GREY_LABEL: "no options available",
  RED_DOT: "missing selection",
  GREEN_DOT: "more options available",
  NO_DOT: "all options selected",
  LOCK_ICON: "locked",
};

// TODO: Need to handle datasets in this check as well
export const isAnyFilterSelected = (builder: Builder): boolean => Object.keys(builder.qsAllFilters).length > 0 || builder.userSelectedDatasets.length > 0;

export const tableContainsComparable = (builder: Builder): boolean =>
  builder.selectedDimensionsByType["Where"]?.length > 1 || builder.selectedDimensionsByType["When"]?.length > 1;

// get a single value for a row column included in a calculation
export const getCNumber = (allTables: any, calcTable: any, colCombo: any, combinedDimensions: any, missingFallback: number | undefined, recursiveCalcIds: number[] = []): any => {
  const { eq: eqs, tables: calcTableIds } = calcTable;
  let counter = 0; // result value counter for picking out correct calculated table id
  const formula: (string | null)[] = eqs.map(eq => {
    if (typeof eq === "string" && eq !== "calc") {
      return eq;
    }
    let value: null | number = null;
    const matchedTable = allTables.find(table => table.id === calcTableIds[counter]);
    counter++;
    if (matchedTable) {
      if (eq === "calc") {
        if (recursiveCalcIds.includes(matchedTable.id)) {
          return null; // invalid reference to self in calculation on calculation
        }
        return getCNumber(allTables, matchedTable, colCombo, combinedDimensions, missingFallback, [...recursiveCalcIds, matchedTable.id]);
      } else if (eq.type === "Table") {
        // for table type we must compile a list of row category combinations (these include filters), then match up all
        // the values for the column combinations if any values are not found which shouldn't occur unless there is a bug
        // with the insight, then they are null value and null should be returned, same as with missing value
        // @TODO - consider a major refactor and overhaul regarding the following comment block
        /*
        * the choice to group variables/categories by introducing the "!{groupname}" and "{variable}:::{category}" paradigm
        * has introduced unnecessary complexity, it means that now we have to handle for the transformations back and forth
        * between it and the regular IFilter format to get the proper values anywhere we need them.
        * for whoever gets a chance to do a refactor, please take this into consideration when finding a better solution
        * */
        const rowsAndFilters = [...(matchedTable.rows || []), ...(matchedTable.filters || [])];
        const rowsAndFiltersVariables = rowsAndFilters.map(item => item.dimension);
        const rowCategoryCombos = buildArrayCombos(rowsAndFilters.map(item => item.values));
        const matchedRowValues: any = rowCategoryCombos.map(rowCombo => {
          const matchedResult = matchedTable.results.find(result =>
            // every column combo (var/cat) is in this result
            // when combinedDimensions.dimension starts with ! i.e. "!Location", _colCombo looks like 'State or Territory:::Northern Territory'
            // when combinedDimensions.dimension is normal, i.e. "State or Territory", _colCombo looks like 'Northern Territory'
            colCombo.every((_colCombo, i) =>
                result[
                  combinedDimensions[i].dimension.startsWith("!") // grouped variable
                    ? _colCombo.split(":::")[0] // variable name
                    : combinedDimensions[i].dimension // variable name
                  ]?.toString() === (
                  combinedDimensions[i].dimension.startsWith("!") // grouped variable
                    ? _colCombo.split(":::")[1] // category
                    : _colCombo.toString() // category
                )
            ) && (
              // rowCombo must be on the result too
              rowCombo.every((cat, varIdx) => result[rowsAndFiltersVariables[varIdx]] === cat)
            )
          );
          if (matchedResult) {
            return matchedResult["Value"];
          } else if (typeof missingFallback === "number") {
            return missingFallback;
          } else {
            return null;
          }
        });
        // value of table is a set of numbers for each of the rows, i.e. Rn,Rn+1... so join with "," to string
        return matchedRowValues.includes(null) ? null : matchedRowValues.join(",");
      }
      // eq.type === "Row"
      const matchedResult = matchedTable.results.find(result =>
          // every column combo (var/cat) is in this result
          // when combinedDimensions.dimension starts with ! i.e. "!Location", _colCombo looks like 'State or Territory:::Northern Territory'
          // when combinedDimensions.dimension is normal, i.e. "State or Territory", _colCombo looks like 'Northern Territory'
          colCombo.every((_colCombo, i) =>
              result[
                combinedDimensions[i].dimension.startsWith("!") // grouped variable
                  ? _colCombo.split(":::")[0] // variable name
                  : combinedDimensions[i].dimension // variable name
                ]?.toString() === (
                combinedDimensions[i].dimension.startsWith("!") // grouped variable
                  ? _colCombo.split(":::")[1] // category
                  : _colCombo.toString() // category
              )
          ) && (
            // every calc filter is in this result
            eq.filters.every((variable, varIdx) => result[variable] === eq.filterValues[varIdx])
          )
      );
      if (matchedResult) {
        value = matchedResult["Value"];
      } else if (typeof missingFallback === "number") {
        value = missingFallback;
      }
    }
    return value;
  });
  // if there are any null values we return a null value as the calculation is not currently valid
  try {
    return formula.includes(null) ? null : math.evaluate(formula.join(""));
  } catch (e) {
    return null; // errors can still occur if a variable has its categories temporarily cleared on a table calc
  }
};

export const getMissingFallback = (calcMissingFallback: boolean | undefined, calcMissingFallbackValue: number | undefined): number | undefined => {
  if (calcMissingFallback && typeof calcMissingFallbackValue === "number") {
    return calcMissingFallbackValue;
  } else {
    return undefined;
  }
};

export const canChartCalculation = (columns: any[], xAxis: any[], hasSeries: boolean): boolean => {
  // if any of the xAxis dims are comparable (!Location, !Time), then have to split these out to match normal filters format
  const splitComparable = xAxis.map(item => {
    if (item.dimension?.startsWith("!")) {
      const splitDims = {};
      for (const combo of item.values) {
        const [variable, category] = combo.split(":::");
        splitDims[variable] = uniq([...(splitDims[variable] || []), category]); // should be no duplicates but just to be safe
      }
      return Object.entries(splitDims).map(([variable, categories]) => ({ dimension: variable, values: categories }));
    }
    return item;
  }).flat();
  if (hasSeries) {
    return false; // cannot chart calc across series
  } else if (!isEqual(columns, splitComparable)) {
    return false; // x-axis variables must match insight columns otherwise can't chart. BUT we need to pass "builder.columns" as "xAxis" to let users chart calculations tables only
  }
  return true;
};

// transform calc table to calc formula (the data stored in builder.calc.eq while calc is being created, i.e. ["Row1","+","Row2"])
// fails gracefully on invalid calc with empty []
export const calcToFormula = (calcTable: any, allTables: any): string[] => {
  const formula: string[] = [];
  const { tables: calcTableIds } = calcTable;
  const eqs: any[] = calcTable.eq;

  let counter = 0; // result value counter for picking out correct calculated table id

  // compile first row index of each table grouped by table id
  const tableRowTotals = getTableRowTotals(allTables); // total rows for each table
  const rowStartIndex = tableRowTotals.map((_, idx) => tableRowTotals.slice(0, idx).reduce((acc, next) => acc + next, 0));
  const startIndexGrouped = rowStartIndex.reduce((acc, next, idx) => ({
    ...acc,
    [allTables[idx].id]: next,
  }), {});

  if (eqs && calcTableIds) {
    for (const eq of eqs) {
      if (typeof eq === "string" && eq !== "calc") {
        formula.push(eq);
        continue;
      }
      const matchedTable = allTables.find(table => table.id === calcTableIds[counter]);
      if (!matchedTable) {
        return []; // all tables used must exist
      }
      counter++;
      if (matchedTable.type === "calc") {
        const idx = startIndexGrouped[matchedTable.id];
        formula.push(isNaN(idx) ? "INVALID_ROW" : `Row${1 + startIndexGrouped[matchedTable.id]}`); // only one row in calc
        continue;
      }
      if (eq.type === "Table") {
        formula.push(`Table${matchedTable.id}`);
        continue;
      }
      // eq.type === "Row"
      const { rows, filters } = matchedTable;
      const reducedFilters = filters.reduce((acc, next) => ({...acc, [next.dimension]: next.values[0] }), {});
      const rowCatCombos = buildArrayCombos(rows.map(row => row.values));
      const rowFilters = rowCatCombos.map(combo => ({
        ...reducedFilters,
        ...combo.reduce((acc, next, idx) => ({ ...acc, [rows[idx].dimension]: next }), {}),
      }));
      // note, if there are no row filters, there is only 1 row of data
      const foundRowIdx = !rowFilters.length ? 0 : rowFilters.findIndex(row =>
        Object.keys(row).length === eq.filters.length // mismatched length happens when a table is modified and variables added/removed)
        && eq.filters.every((dimension, idx) => row[dimension] === eq.filterValues[idx])
      );
      if (foundRowIdx === -1) {
        return []; // all rows must be matched
      }
      formula.push(`Row${1 + startIndexGrouped[matchedTable.id] + foundRowIdx}`);
    }
  }
  return formula;
};

export const isEqStringValid = (eqString: string, lastRowIndex: number, insightTableIds: number[]): boolean => {
  if (eqString.match(/\d+row\d+/i) || eqString.match(/[^(]table\d+/i) || eqString.match(/table\d+[^)\d]/i) || eqString.match(/table\d+$/i)) {
    // #Row# or table not wrapped in parentheses is not valid, check is not exhaustive, evaluate will catch other issues
    return false;
  }
  const rowRegExp = /row(\d+)/gi;
  const rowMatches = eqString.match(rowRegExp); // allow case-insensitive
  if (rowMatches) {
    // ensure row indexes are within valid bounds
    const rowIndexes = rowMatches.map(str => +str.slice(3));
    for (const rIdx of rowIndexes) {
      if (rIdx === 0 || rIdx > lastRowIndex) {
        return false;
      }
    }
  }
  const tableRegExp = /table(\d+)/gi;
  const tableMatches = eqString.match(tableRegExp);
  if (tableMatches) {
    // ensure table ids exist on insight
    const calcTableIds = tableMatches.map(str => +str.slice(5));
    for (const tId of calcTableIds) {
      if (!insightTableIds.includes(tId)) {
        return false;
      }
    }
  }
  // replace any row data with an integer, any table data with a set of integers, and test if evaluation would work
  const _eqString = eqString.replace(rowRegExp, "1").replace(tableRegExp, "1,1");
  try {
    math.evaluate(_eqString);
  } catch (e) {
    return false;
  }
  return true;
};

// Convert calculator "eqString" to "eq" string array with Row# entries split out, rest left intact
export const eqStringToEq = (eqString: string): any[] => {
  let _eqString = eqString.trim(); // trim trailing whitespace only
  const parts: string[] = [];
  while(_eqString) {
    const nextRowOrTable = _eqString.match(/(row|table)\d+/i);
    if (nextRowOrTable) {
      if (nextRowOrTable.index !== 0) {
        parts.push(_eqString.slice(0, nextRowOrTable.index));
      }
      const prefix = nextRowOrTable[0].toLowerCase().startsWith("row") ? "Row" : "Table";
      parts.push(`${prefix}${nextRowOrTable[0].slice(prefix.length)}`); // update casing
      _eqString = _eqString.slice(nextRowOrTable.index! + nextRowOrTable[0].length); // trim string
    } else {
      parts.push(_eqString);
      _eqString = ""; // end reached
    }
  }
  return parts;
};

export const getTableRowTotals = (tables: any[]): number[] => tables.map(t => t.type === "calc" ? 1 : (t?.rows?.map(r => r?.values?.length || 1).reduce((acc, next) => acc * next, 1)));

// Table colors used for all types of charts (except for simple pie chart) are generated based on "rowArrayCombo"
// For simple pie chart: we need to use the larger number between "rowArrayCombo" or "columnArrayCombo" to generate table colors
// So compare "rowArrayCombo" and "columnArrayCombo" and generate tables colors based on the larger number
export const getResultTableColourNumber = (rows, columns): number => {
  const rowArrayCombo = rows.length > 0 ? buildArrayCombos(rows.map(row => row.values)) : [];
  const columnArrayCombo = columns.length > 0 ? buildArrayCombos(getCombinedDims(columns).map(column => column.values)) : [];
  return (rowArrayCombo.length >= columnArrayCombo.length ? rowArrayCombo : columnArrayCombo).length;
};

export const getCalcTableColourNumber = (columns): number => buildArrayCombos(getCombinedDims(columns).map(column => column.values)).length;
