import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { getMixpanel } from "common/api";
import { categoryLayoutRenderer, categoryRenderer, resultRenderer } from "./includes/ResultsComponents";
import { StyledDropDown, StyledHeaderSearchWrapper, StyledSearchBox } from "./includes/headerSearch.style";

interface HeaderSearchComponent {
  store?: Store;
  className?: string;
}

export type EntityType = "suitcases" | "insights" | "dashboards";

interface Category {
  name: EntityType;
  results: Entity[];
}

type Results = {
  [key in EntityType]?: Category;
};

export interface Entity {
  entity_id: number; // Can't use 'id' here because Semantic overwrites 'id' with the item index when passing search results to the "resultRenderer" component.
  title: string;
  type: EntityType;
  first_name: string;
  last_name: string;
  avatar: string | null;
  has_child_suitcases?: string; // Semantic writes these keys to the DOM, so we need to convert booleans to strings to avoid warnings.
}

const SEARCH_DROPDOWN_OPTIONS = [
  { key: 1, text: "All", value: "all"},
  { key: 2, text: "Suitcases", value: "suitcases"},
  { key: 3, text: "Insights", value: "insights"},
  { key: 4, text: "Dashboards", value: "dashboards"},
] as const;

const HeaderSearchComponent = (props: HeaderSearchComponent) => {
  const store = props.store!;
  const className = props.className;
  const { suitcase, insight, dashboard } = store;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchResults, setSearchResults] = React.useState<Results | null>(null);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchType, setSearchType] = React.useState<typeof SEARCH_DROPDOWN_OPTIONS[number]["value"]>("all");

  const timeoutRef: any = React.useRef();

  const handleResultSelect = (_e, { result }) => {
    const { title, entity_id, type } = result;
    setSearchResults(null);
    setSearchValue("");
    getMixpanel(store).track("Header Search > Click", { Type: type, id: entity_id, name: title });
  };

  const handleSearchChange = (_, data) => {
    const value: string = data.value;
    setSearchResults(null);
    setSearchValue(value);
    if (value.trim().length <= 1) { // Only search results when there are more than 1 character in search field
      return;
    }
    setIsLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      let suitcasesRes: any;
      let insightsRes: any;
      let dashboardsRes: any;
      // Limiting the number of search results:
      // - When searching a single type, show 8 results for that type.
      // - When searching "All", show 4 results for each type.
      let searchLimit = 8;
      if (searchType === "suitcases") {
        suitcasesRes = await suitcase.searchSuitcases(value, searchLimit);
      } else if (searchType === "insights") {
        insightsRes = await insight.searchInsights(value, searchLimit);
      } else if (searchType === "dashboards") {
        dashboardsRes = await dashboard.searchDashboards(value, searchLimit);
      } else {
        searchLimit = 4;
        const allRes = await Promise.all([
          suitcase.searchSuitcases(value, searchLimit),
          insight.searchInsights(value, searchLimit),
          dashboard.searchDashboards(value, searchLimit),
        ]);
        suitcasesRes = allRes[0];
        insightsRes = allRes[1];
        dashboardsRes = allRes[2];
      }
      const results: Results = {};
      if (suitcasesRes?.data?.suitcases?.length) {
        results["suitcases"] = {
          name: "suitcases",
          results: suitcasesRes?.data?.suitcases.map(sc => {
            const { id, name, first_name, last_name, avatar, has_child_suitcases } = sc;
            return ({
              entity_id: id,
              title: name,
              type: "suitcases",
              first_name,
              last_name,
              avatar,
              has_child_suitcases: has_child_suitcases.toString(),
            });
          }),
        };
      }
      if (insightsRes?.data?.insights?.length) {
        results["insights"] = {
          name: "insights",
          results: insightsRes?.data?.insights.map(insight => {
            const { id, name, first_name, last_name, avatar } = insight;
            return ({
              entity_id: id,
              title: name,
              type: "insights",
              first_name,
              last_name,
              avatar,
            });
          }),
        };
      }
      if (dashboardsRes?.data?.dashboards?.length) {
        results["dashboards"] = {
          name: "dashboards",
          results: dashboardsRes?.data?.dashboards.map(dashboard => {
            const { id, name, first_name, last_name, avatar } = dashboard;
            return ({
              entity_id: id,
              title: name,
              type: "dashboards",
              first_name,
              last_name,
              avatar,
            });
          }),
        };
      }
      getMixpanel(store).track("Header Search > Search", { Type: searchType, keywords: value });
      setIsLoading(false);
      setSearchResults(results);
    }, 800);
  };

  React.useEffect(() => () => clearTimeout(timeoutRef.current), []);

  return (
    <StyledHeaderSearchWrapper className={className}>
      <StyledSearchBox
        id="header_search"
        name="sitewide_search"
        role="search"
        aria-label="Search Suitcases, Insights and Dashboards"
        category={true}
        size="mini"
        aligned="left"
        minCharacters={2}
        categoryLayoutRenderer={categoryLayoutRenderer}
        categoryRenderer={categoryRenderer}
        loading={isLoading}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        resultRenderer={resultRenderer}
        results={searchResults}
        value={searchValue}
        placeholder={"Type to search..."}
        input={{ icon: "search", iconPosition: "left" }}
        showNoResults={isLoading ? false : true}
      />
      <StyledDropDown
        value={searchType}
        options={SEARCH_DROPDOWN_OPTIONS}
        direction="left"
        onChange={(_, { value }) => {
          setSearchType(value);
          setSearchResults(null);
          setSearchValue("");
        }}
      />
    </StyledHeaderSearchWrapper>
  );
};

export const HeaderSearch = inject((stores: any) => ({
  store: stores.store,
}))(observer(HeaderSearchComponent));
