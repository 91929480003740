import * as React from "react";
import { Form } from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";
import { COLORS } from "component/UI/common";

interface Props extends FieldRenderProps<any> {
  label: string | Node;
  disabled?: boolean;
  helperText?: string;
  className?: string;
}

export const FieldCheck = ({ input, meta, label, disabled, toggle, helperText, className = "" }: Props): JSX.Element => {
  const showError = ((meta.error || meta.submitError) && meta.touched);
  const error = showError ? (meta.error || meta.submitError) : null;
  return (
    <Form.Field>
      <Form.Checkbox
        checked={input.value || false}
        onChange={(_e, data) => input.onChange(data.checked)}
        label={<label className="fs-1000 text-dark fw-700">{label}</label>}
        type="checkbox"
        className={`${className} ${error ? "mb-2" : ""}`}
        disabled={!!disabled}
        toggle={toggle}
        error={showError}
      />
      {helperText && <p className="fs-1000 mb-2" style={{ color: COLORS.grey800 }}>{helperText}</p>}
      {error && <p className="fs-1000 mb-0" style={{ color: COLORS.red500 }}>{error}</p>}
    </Form.Field>
  );
};
