import * as React from "react";
import { Modal } from "semantic-ui-react";
import { SeerButton } from "./PartnerOrganisationsButtons";

interface IPartnershipConfirmModal {
  isOpen: boolean;
  closeModal: () => void;
  confirmModal: () => void;
  header: string;
  content: string;
  confirmButtonText: string;
}

export const PartnershipConfirmModal = ({ isOpen, closeModal, confirmModal, header, content, confirmButtonText }: IPartnershipConfirmModal): JSX.Element => {
  const confirmHandler = () => {
    confirmModal();
    closeModal();
  };

  return (
    <Modal open={isOpen} onClose={closeModal} closeOnDimmerClick={false} size="small">
      <Modal.Header className="fs-2000 fw-700 text-secondary">
        {header}
      </Modal.Header>
      <Modal.Content>
        <p className="fs-1250">{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <SeerButton
          category="secondary"
          label="Cancel"
          onClick={closeModal}
        />
        <SeerButton
          category="primary"
          label={confirmButtonText}
          onClick={confirmHandler}
        />
      </Modal.Actions>
    </Modal>
  );
};
