import * as React from "react";
import ReactPlayer from "react-player";
import { Icon, Modal, ModalContent, ModalProps } from "semantic-ui-react";
import { Button } from "component/UI/Button";
import { COLORS } from "component/UI/common";

interface Props extends ModalProps {
  onClose: () => void;
  videoLink: string;
  title?: string;
  description?: string;
  buttonLabel: string;
  buttonClick: () => void;
}

export const SpotlightVideoModal = (props: Props): JSX.Element => {
  const { open, onClose, videoLink, title, description, buttonLabel, buttonClick } = props;

  return (
    <Modal open={open} onClose={(() => onClose())}>
      <ModalContent>
        <ReactPlayer
          url={videoLink || ""}
          playing={true}
          controls={true}
          width="100%"
        />
        <div className="mt-3 mb-5">
          <p className="fs-1500 fw-700 mb-3" style={{ color: COLORS.indigo600 }}>{title}</p>
          <p className="fs-1125 fw-400" style={{ color: COLORS.grey800 }}>{description}</p>
        </div>
        <div className="d-flex justify-content-between">
          <Button colorConfig="purpleInverted" className="mr-3" onClick={() => buttonClick()}>
            {buttonLabel}<Icon name="arrow right" className="mr-0" />
          </Button>
          <Button onClick={() => onClose()}>
            Close <Icon name="close" />
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
