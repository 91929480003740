import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";
import { InsightsList } from "./InsightsList";
import { ContentPlaceholder } from "component/ContentPlaceholder";

interface ISavedInsights {
  favouriteStore?: any;
  clickInsightHandler: (insight) => Promise<void>;
  unsaveInsightHandler: (id) => Promise<void>;
}

const SavedInsightsComponent = (props: ISavedInsights): JSX.Element => {
  const { favouriteStore, clickInsightHandler, unsaveInsightHandler } = props;
  const savedInsights = favouriteStore.favourites["smart-insight"] || [];
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<any>(null);

  const loadSmartInsights = async () => {
    setIsLoading(true);
    const error = await favouriteStore.getFavourites("smart-insight");
    if (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadSmartInsights();
  }, []);

  if (error) {
    return (
      <div className="mt-4 d-flex">
        <Icon name="info circle" />
        <p className="text-muted fs-1125">{error}</p>
      </div>
    );
  }

  return (
    <>
      {isLoading ? <ContentPlaceholder /> : (
        <>
          {savedInsights?.length === 0 ? (
            <div className="mt-4 d-flex">
              <Icon name="info circle" />
              <p className="text-muted fs-1125">
                No saved insights yet. Use Query Tool to search your location and then save insights.
              </p>
            </div>
          ) : (
            <InsightsList
              insights={savedInsights}
              clickInsightHandler={(insight) => clickInsightHandler(insight)}
              unsaveClickHandler={(id) => unsaveInsightHandler(id)}
            />
          )}
        </>
      )}
    </>
  );
};

export const SavedInsights = inject((stores: any) => ({
  favouriteStore: stores.store.favourite,
}))(observer(SavedInsightsComponent));
