import * as React from "react";
import { useParams, Link } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, Button, Message, Container, Form, Card } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { Field, Form as FinalForm } from "react-final-form";
import Store from "common/store";
import { FieldInput } from "pages/DatasetTemplateTable/includes/FieldInput";
import { composeValidators, required } from "common/helpers/finalForm";
import { TableCompletenessChart } from "pages/ContributorDatasetWholeTemplate/includes/TableCompletenessChart";
import { SemanticToast } from "component/SemanticToast";

interface IContainer {
  store: Store;
}

const Component = (props: IContainer): JSX.Element => {
  const [loginStep, setLoginStep] = React.useState(1);
  const [data, setData]: any = React.useState(null);
  const { store } = props;
  const { contributor_token } = store!;

  const { templateID }: any = useParams();

  // login / email check handler
  const onSubmit = async values => {
    if (loginStep === 1) {
      const ok = await store!.dataset.contributorEmailCheck(values?.email?.trim());
      if (ok) {
        return setLoginStep(2);
      }
      return { email: "Email invalid or not found. Please check the entered email." };
    } else {
      const ok = await store!.dataset.contributorLogin(values?.email?.trim(), values?.verification_code);
      if (!ok) {
        return { verification_code: "Please check the log in code has been entered correctly." };
      }
      // do nothing - login success will trigger rerender with template flow
    }
  };

  // load template data
  const loadData = async () => {
    const nextData: any = await store.dataset.contributorDatasetTemplateGet(templateID);
    setData(nextData);
  };

  React.useEffect(() => {
    if (contributor_token && !data) {
      store.dataset.setShowSessionExpired(false);
      loadData();
    }
  }, [contributor_token]);

  if (!contributor_token) {
    return (
      <Container style={{ width: 320 }}>
        {store.dataset.showSessionExpired && (
          <SemanticToast duration={3000} size="mini" compact content="Session Expired, Please log in again." icon="info circle" />
        )}
        <Helmet><title>Contributor Log In</title></Helmet>
        <h1 className="text-secondary">Contributor Log in</h1>
        <FinalForm
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} id="login-ssdc-contributor">
              <Field
                name="email"
                label="Email"
                component={FieldInput}
                validate={composeValidators(required)}
                disabled={loginStep === 2}
              />
              {loginStep === 2 ? (
                <>
                  <Field
                    name="verification_code"
                    label="Log in code"
                    component={FieldInput}
                    validate={composeValidators(required)}
                  />
                  <p className="fs-0875 fw-bold">We just sent you a temporary log in code. Please check your inbox.</p>
                </>
              ) : (
                <p className="fs-0875 fw-bold">We’ll email you a temporary log in code to log in.</p>
              )}
              <Button type="submit" className="bg-primary text-white bg-hover-red" fluid>
                {loginStep === 1 ? "Continue" : "Log in"}
                <Icon
                  className="ml-2"
                  name={loginStep === 1 ? "magic" : "sign-in alternate"}
                  style={{ opacity: 1 }}
                />
              </Button>
            </Form>
          )}
        />
      </Container>
    );
  }

  const { error, template } = data || {};
  const sortedTemplateTables = template && template.tables && [...template.tables].sort((a, b) => a.name.localeCompare(b.name)) || [];
  return (
    <Container>
      <Helmet><title>Dataset Contributor</title></Helmet>
      {error && (
        <Message error content={error} />
      )}
      {!error && template && (
        <>
          <h1 className="text-secondary">Dataset Contributor</h1>
          <h2>Update: {template.name}</h2>
          <p>Follow the link to the table you wish to make a contribution for</p>
          {sortedTemplateTables.map(table => (
            <Card
              key={table.id}
              as={Link}
              to={`/dataset-contribution/${templateID}/${table.id}`}
              fluid
              className="text-secondary"
            >
              <Card.Content className="py-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div><Icon name="edit" className="mr-2" /><b>{table.name}</b></div>
                  <div style={{ width: 60, fontSize: "0.875rem" }}>
                    <TableCompletenessChart completeness={table.completeness} />
                  </div>
                </div>
              </Card.Content>
            </Card>
          ))}
        </>
      )}
    </Container>
  );
};

export const ContributorDatasetTemplate = inject("store")(observer(Component));
