import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import { Checkbox, Form, Grid } from "semantic-ui-react";

interface Props extends FieldRenderProps < any > {
  items: { label: string; value: string }[];
  disabled?: boolean;
  helperText?: string;
  className?: string;
}

export const FieldRadio = (props: Props): JSX.Element => {
  const { input, label, meta, items, disabled, helperText, className = "" } = props;
  const showError = ((meta.error || meta.submitError) && meta.touched);
  const error = showError ? (meta.error || meta.submitError) : null;
  return (
    <div className={className}>
      {label && <p className="fw-700 mb-1">{label}</p>}
      <Grid columns="equal">
        <Grid.Row>
          {items.map((item, idx) => (
            <Grid.Column key={`${item.label}-${idx}`}>
              <Form.Field>
                <Checkbox
                  radio
                  label={item.label}
                  name={item.label}
                  value={item.value}
                  checked={input.value === item.value}
                  onChange={(_e, data) => input.onChange(data.value)}
                  disabled={!!disabled}
                />
              </Form.Field>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      {helperText && <small className="text-muted">{helperText}</small>}
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
};
