import * as React from "react";
import { parse } from "papaparse";
import { Checkbox, Input, Icon, Accordion } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

import { environment } from "common/constants";
import { insertScript } from "common/helpers/script";
import { StyledPage, StyledPanes, StyledInput, StyledAccordion, StyledHits, StyledNoHits, StyledClearFilterButton, GlobalStyle } from "pages/bespoke/dpac/kcs/DPACKCSSearch/DPACKCSSearch.style";
import { CollapsibleContent } from "./includes/CollapsibleContent";
import { PieCharts } from "./includes/PieCharts";
import { WCAGListBox } from "./includes/WCAGListBox";
import { BackToTop } from "./includes/BackToTop";
import Store from "common/store";

const s3env = environment === "production" ? "prod" : "staging";

interface Props {
  store?: Store;
}

const filterableColumns = [
  { label: "Themes", value: "primary_response_theme" },
  { label: "Lead", value: "lead" },
  { label: "Priority area", value: "workstream" },
  { label: "Status", value: "status" },
  { label: "Phase", value: "phase" },
  { label: "Recommendation Type", value: "action_type" },
];

const sortOpts = ["Number (default)", "Earliest phase", "Latest phase", "Theme"];

const actionTypeRemap = {
  "Interim Response Action" :"Interim response action",
  "Commission of Inquiry Recommendation Action": "Commission of Inquiry recommendation",
};
const statusRemap = {
  "Not Commenced": "Not commenced",
  "In Progress": "In progress",
  "Completed": "Completed",
  "In Progress - Delayed": "In progress - delayed",
};
const renderActionType = type => actionTypeRemap[type] ?? type;
export const renderActionStatus = status => statusRemap[status] ?? status;

export const Component = (props: Props) => {
  const { windowWidth } = props.store!.ui;
  const [ready, setReady] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<any>(null);
  const [sortColumn, setSortColumn] = React.useState("Number (default)");
  const [lunr, setLunr] = React.useState<any>(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState<Record<string, string[]>>({}); // e.g. { action_type: ["type1", "type2"], ... }
  const [openFilters, setOpenFilters] = React.useState<string[]>([]);

  const init = async () => {
    // init supporting libs
    await insertScript("lunr", { type: "text/javascript", src: "https://unpkg.com/lunr/lunr.js" });
    // load static action content and status data
    const actionsCsv = await fetch(`https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/dpac/${s3env}/kcs/actions.csv`, { cache: "no-cache" })
      .then(res => res.text()).catch(() => null);
    const statusRes = await fetch(`https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/dpac/${s3env}/kcs/uploads/report_status.csv`, { cache: "no-cache" })
      .catch(() => null);
    let statusCsv;
    let lastUpdated; // get last updated from status csv modified time
    if (statusRes && statusRes.ok) {
      lastUpdated = new Date(statusRes.headers.get("Last-Modified") as string);
      statusCsv = await statusRes.text();
    }
    if (!actionsCsv || !statusCsv) {
      return setError(true);
    }
    // parse csvs and merge on action type/number match to produce data
    const actions: any = parse(actionsCsv as string, { skipEmptyLines: true, header: true }).data;
    const status: any = parse(statusCsv as string, { skipEmptyLines: true, header: true }).data;
    const filterOptions = filterableColumns.reduce((prev, next) => ({ ...prev, [next.value]: new Set() }), {});
    for (const aRow of actions) {
      const foundStatus = status.find(sRow => sRow.action_type === aRow.action_type && sRow.action_number === aRow.action_number);
      aRow.status = foundStatus?.status || "Missing";
      aRow.sequence_number = Number(aRow.sequence_number);
      // gathering possible filter options from static content
      for (const filterableColumn of filterableColumns) {
        if (aRow[filterableColumn.value]) {
          filterOptions[filterableColumn.value].add(aRow[filterableColumn.value]);
        }
      }
    }
    // update filterOptions to sorted array type to finish
    for (const key of Object.keys(filterOptions)) {
      filterOptions[key] = Array.from(filterOptions[key]).sort(); // default sort for now
    }
    // prepare search index
    const index = window["lunr"](builder => {
      builder.field("action_number");
      builder.ref("sequence_number");
      for (const row of actions) {
        builder.add(row);
      }
    });
    setData({ rows: actions, lastUpdated, filterOptions });
    setLunr(index);
    setReady(true);
  };
  React.useEffect(() => {
    init();
  }, []);

  // apply sorts
  const sortedRows = ready && [...data.rows].sort((prev, next) => {
    if (sortColumn === "Number (default)") {
      return prev.sequence_number - next.sequence_number;
    } else if (sortColumn === "Earliest phase") {
      return prev.phase.localeCompare(next.phase);
    } else if (sortColumn === "Latest phase") {
      return next.phase.localeCompare(prev.phase);
    } else if (sortColumn === "Theme") {
      if (!prev.primary_response_theme) {
        return 1;
      }
      return prev.primary_response_theme.localeCompare(next.primary_response_theme);
    }
    return 0;
  });
  // apply filters
  const filteredRows = sortedRows && sortedRows.filter(row => {
    for (const [fKey, fVal] of Object.entries(filters)) {
      if (fVal?.length && !fVal.includes(row[fKey])) {
        return false;
      }
    }
    return true;
  });
  // compile search results if searchTerm is set
  const searchResults = searchTerm && lunr && lunr.query(qb => {
    qb.term(searchTerm, { boost: 10 }); // exact match whole string gets highest boost
    qb.term(searchTerm, { boost: 7, wildcard: window["lunr"].Query.wildcard.TRAILING }); // whole string with trailing wildcard i.e. "term*"
    qb.term(window["lunr"].tokenizer(searchTerm), { boost: 5 }); // exact match on one or more tokenized strings
    qb.term(window["lunr"].tokenizer(searchTerm), { boost: 3, wildcard: window["lunr"].Query.wildcard.TRAILING }); // tokenized strings with trailing wildcard
    qb.term(window["lunr"].tokenizer(searchTerm), { editDistance: 1 }); // fuzzy match on tokenized strings
  }).map(hit => data.rows.find(dRow => dRow.sequence_number === Number(hit.ref)))
    .filter(row => !!row);
  const hasFilterOrSearchApplied = !!Object.values(filters).flat().length || !!searchTerm;

  return (
    <StyledPage>
      <GlobalStyle />
      <Helmet>
        <body className="hide-header-and-hubspot" />
        <title>DPAC KCS Interim Reporting Solution</title>
        <link href="https://fonts.googleapis.com/css?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />
      </Helmet>
      {!ready && <>{error ? "Error during data load. Please try again later." : "Loading..."}</>}
      {ready && (
        <>
          <PieCharts data={data.rows} />
          <p
            className="fw-bold d-inline-block mb-5"
            style={{ fontSize: 20, padding: "8px 12px", backgroundColor: "#D3C7BB", borderRadius: 4 }}
          >
            Last updated: {data.lastUpdated.toLocaleDateString("en-AU")}
          </p>
          {/* search content */}
          <StyledPanes>
            {/* sort and hits */}
            <div className="left">
              <WCAGListBox options={sortOpts} onSelectOpt={setSortColumn} />
              <section id="recommendations">
                {!(searchResults || filteredRows)?.length ? (
                  <StyledNoHits className="text-center">
                    <p style={{ fontSize: 50, color: "#455687", marginBottom: 24 }}>
                      <Icon name="exclamation circle" />
                    </p>
                    <p style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Sorry, no results found</p>
                    <p style={{ fontSize: 16 }}>
                      There are no recommendations and/or actions that match your{" "}
                      {searchTerm ? "search term" : "conditions"}. Please try a different{" "}
                      {searchTerm ? "search term" : "combination of filters"}.
                    </p>
                  </StyledNoHits>
                ) : (
                  <div>
                    <StyledHits>
                      {(searchResults || filteredRows).map(row => {
                        const {
                          sequence_number,
                          action_type,
                          action_number,
                          action_detail,
                          lead,
                          primary_response_theme,
                          workstream,
                          phase,
                          status,
                        } = row;
                        return (
                          <div
                            key={sequence_number}
                            className={`hit ${action_type === "Interim Response Action" ? "interim" : "recommendation"}`}
                            role="article"
                            aria-labelledby={`recommendation_number_${action_number}`}
                            aria-describedby={`action_summary_${action_number}`}
                            tabIndex={0}
                          >
                            <div className={`top ${status.toLowerCase().replace(/\W/g, "_")}`}>
                              <div className="d-lg-flex justify-content-between" style={{ marginBottom: 24 }}>
                                <div className="mb-2 mb-lg-0 mr-3" style={{ fontSize: 24 }} id={`recommendation_number_${action_number}`}>
                                  {action_number}
                                </div>
                                <div className="d-xl-flex align-items-center justify-content-between">
                                  <div className="status mr-3 mb-2 mb-xl-0">
                                    <Icon name="circle" /> {renderActionStatus(status)}
                                  </div>
                                  <div className="type">{renderActionType(action_type)}</div>
                                </div>
                              </div>
                              {primary_response_theme && (
                                <div style={{ fontSize: 18, marginBottom: 8 }}>{primary_response_theme}</div>
                              )}
                              {phase && <div>Phase: {phase}</div>}
                            </div>
                            <div className="bottom">
                              <CollapsibleContent action_detail={action_detail} id={`action_summary_${action_number}`} windowWidth={windowWidth} />
                              <div style={{ fontSize: 16 }}>
                                <div className="d-lg-flex info mb-2">
                                  <div className="mr-2 d-inline d-lg-block">Lead:</div>
                                  <div className="d-inline d-lg-block">{lead}</div>
                                </div>
                                {workstream && (
                                  <div className="d-lg-flex info">
                                    <div className="mr-2 d-inline d-lg-block">Priority area:</div>
                                    <div className="d-inline d-lg-block">{workstream}</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </StyledHits>
                  </div>
                )}
              </section>
            </div>
            {/* filters and search */}
            <div className="right">
              <div className="filters mb-4">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 style={{ fontSize: 18, marginBottom: "1rem" }}><Icon name="sliders" /> Filter by</h4>
                    <StyledClearFilterButton
                      type="button"
                      tabIndex={1}
                      className="mb-3"
                      onClick={() => {
                        setFilters({});
                        setSearchTerm("");
                      }}
                      disabled={!hasFilterOrSearchApplied}
                      aria-disabled={!hasFilterOrSearchApplied}
                    >
                      Clear all
                    </StyledClearFilterButton>
                  </div>
                  <StyledAccordion className="mb-3">
                    <Accordion>
                      {filterableColumns.map(col => {
                        const active = openFilters.includes(col.value);
                        return (
                          <React.Fragment key={col.value}>
                            <Accordion.Title
                              active={active}
                              index={col.value}
                              onClick={() => {
                                if (active) {
                                  setOpenFilters(openFilters.filter(item => item !== col.value));
                                } else {
                                  setOpenFilters([...openFilters, col.value]);
                                }
                              }}
                              as="button"
                              className="w-100"
                              tabIndex={1}
                            >
                              {col.label}
                              <Icon name={`chevron ${active ? "up" : "down"}`} className="float-right" />
                            </Accordion.Title>
                            <Accordion.Content active={active}>
                              {data.filterOptions[col.value]?.map((opt: string, idx) => {
                                let label = opt;
                                if (col.value === "action_type") {
                                  label = renderActionType(opt);
                                } else if (col.value === "status") {
                                  label = renderActionStatus(opt);
                                }
                                return (
                                  <div key={opt}>
                                    <Checkbox
                                      label={label}
                                      aria-label={label}
                                      tabIndex={1}
                                      name={`${col.value}_${idx}`}
                                      checked={(filters[col.value] || []).includes(opt)}
                                      onChange={(_e, { checked }) => {
                                        const oldColFilters = filters[col.value] || [];
                                        if (checked) {
                                          setFilters({ ...filters, [col.value]: [...oldColFilters, opt] });
                                        } else {
                                          setFilters({
                                            ...filters,
                                            [col.value]: oldColFilters.filter(item => item !== opt),
                                          });
                                        }
                                      }}
                                      onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                          e.target.click();
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Accordion.Content>
                          </React.Fragment>
                        );
                      })}
                    </Accordion>
                  </StyledAccordion>
                  <div className="d-flex align-items-center my-3">
                    <hr className="w-100" />
                    <p className="m-0 px-2">
                      <b>OR</b>
                    </p>
                    <hr className="w-100" />
                  </div>
                </div>
                <div>
                  <h4 style={{ fontSize: 18 }}>
                    <Icon name="search" />
                    Search by number
                  </h4>
                  <StyledInput>
                    <Input
                      fluid
                      name="search"
                      placeholder="Type to search..."
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value || "")}
                      tabIndex={1}
                    />
                  </StyledInput>
                </div>
              </div>
              <div className="help mb-3">
                <div className="top" style={{ fontSize: 20, padding: 16 }}>
                  <Link to="/bespoke/dpac/kcs-search/help" tabIndex={1}>
                    Help <Icon name="info circle" className="float-right" />
                  </Link>
                </div>
                <div style={{ padding: 8 }}>
                  <Link to="/bespoke/dpac/kcs-search/help#how-to-read-the-data" tabIndex={1}>
                    <div className="d-flex w-100" style={{ padding: 16 }}>
                      <div className="w-100 pr-2">How to read the report</div>
                      <Icon name="chevron right" />
                    </div>
                  </Link>
                  <Link to="/bespoke/dpac/kcs-search/help#understanding-themes-outcomes" tabIndex={1}>
                    <div className="d-flex w-100" style={{ padding: 16 }}>
                      <div className="w-100 pr-2">Understanding themes and outcomes</div>
                      <Icon name="chevron right" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </StyledPanes>
          <BackToTop />
        </>
      )}
    </StyledPage>
  );
};

export const DPACKCSReportSearch = inject("store")(observer(Component));
