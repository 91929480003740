import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import { insertScript } from "common/helpers/script";
import { BackToTop } from "../DPACKCSSearch/includes/BackToTop";

const DPACCharcoal = "#333132";

const HelpWrapper = styled.div`
  color: #333132;
  font-family: 'Noto Sans', sans-serif !important;
  p, h1, h2, h3, h4, h5, h6 {
    font-family: 'Noto Sans', sans-serif !important;
    color: #333132;
  }
  font-size: 18px;
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  
  >.toc {
    border: 1px solid ${DPACCharcoal};
    .top {
      border-radius: 0 0 6px 6px;
      background-color: ${DPACCharcoal};
      color: #C0CEBA;
    }
    a {
      color: ${DPACCharcoal};
      &:hover, &:focus, &:active {
        text-decoration: underline;
      }
    }
  }
  
  .outcome {
    background-color: #C0CEBA;
    padding: 24px;
  }
  
  .back > a {
    color: ${DPACCharcoal};
    &:hover, &:focus, &:active {
      text-decoration: underline;
    }
  }
  
  img {
    max-width: 1440px;
  }
`;

export const DPACKCSSearchHelp = (props: any) => {
  const initZoom = async () => {
    await insertScript("zoomist", { type: "text/javascript", src: "https://cdn.jsdelivr.net/npm/zoomist@2/zoomist.umd.js" });
    new window["Zoomist"](".zoomist-container", {
      maxScale: 5,
      slider: true,
      zoomer: true,
    });
  };
  React.useEffect(() => {
    initZoom();
    // scroll to section if it has # in path - handling for react router scroll issues
    if (!!props.location.hash) {
      document.querySelector(props.location.hash).scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <HelpWrapper>
      <Helmet>
        <body className="hide-header-and-hubspot" />
        <title>DPAC KCS Interim Reporting Solution - Help</title>
        <link href="https://fonts.googleapis.com/css?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/zoomist@2/zoomist.css" />
      </Helmet>
      <div className="mb-5 back">
        <Link to="/bespoke/dpac/kcs-search" tabIndex={0}>
          <p><Icon name="chevron left" /> Return to report</p>
        </Link>
      </div>
      <h1>Help</h1>
      <div className="toc mb-5">
        <div className="top" style={{ fontSize: 20, padding: 16 }}>On this page</div>
        <div style={{ padding: 8 }}>
          <a href="#how-to-read-the-data" tabIndex={0}>
            <div className="d-flex w-100" style={{ padding: 16 }}>
              <div className="w-100 pr-2">How to read the data</div>
            </div>
          </a>
          <a href="#understanding-themes-outcomes" tabIndex={0}>
            <div className="d-flex w-100" style={{ padding: 16 }}>
              <div className="w-100 pr-2">Understanding themes and outcomes</div>
            </div>
          </a>
        </div>
      </div>
      <h2 id="how-to-read-the-data">How to read the data</h2>
      <section className="mb-6">
        <p>Below you will find an explanation on each of the elements to help you to find the information you need.</p>
        {/* content in place of images for sr */}
        <div className="sr-only">
          <dl>
            <dt>Recommendation number</dt>
            <dd>
              The <em>recommendation number</em> is the number assigned in the Commission's report, eg 6.4, 19.1 etc.
              Interim response actions are numbered 1 to 30.
            </dd>

            <dt>Status</dt>
            <dd>
              Recommendation status shows if an action is 'In progress', 'Completed' or 'Not commenced.
              If the action is in progress but the due date has passed, it will be marked as 'In progress - delayed'.
            </dd>

            <dt>Action type</dt>
            <dd>
              Actions are either interim response actions or commission of inquiry actions.
              Interim response actions are the immediate steps the Government took to improve children's safety.
              Commission of inquiry actions are the 191 recommendations made in the Commission's report.
            </dd>

            <dt>Theme</dt>
            <dd>The Commission of Inquiry actions have been grouped into six themes. <em>Themes</em> show how efforts are focused.</dd>

            <dt>Phase</dt>
            <dd>The <em>phase</em> is when the recommendation or action is due to be completed.</dd>

            <dt>Action summary</dt>
            <dd>
              The <em>action summary</em> details the action that the Government will take to keep children safe.
              For ‘Commission of Inquiry actions’, this information is a copy of the recommendation directly from the Government's response.
              For ‘Interim response actions’, this outlines the action the Government committed to before the Commission released its final report.
            </dd>

            <dt>Lead</dt>
            <dd>The <em>lead</em> is the Government Department or independent body that is in charge of the recommendation or action.</dd>

            <dt>Priority area</dt>
            <dd>
              <em>Priority areas</em> are groups of related recommendations linked together into a program of work.
              Priority areas need Government Departments and the community services sector to work together to
              fix common issues across services and systems in a coordinated and consistent way.
            </dd>
          </dl>
        </div>
        {/* images */}
        <div>
          <div className="d-lg-none">
            <div className="zoomist-container">
              <div className="zoomist-wrapper">
                <div className="zoomist-image">
                  <img
                    width="100%"
                    src="https://seer-media.s3.ap-southeast-2.amazonaws.com/insight-files/cbca43cc-918e-495f-a604-5e3895e973bc/How%20to%20read%20the%20report.png"
                    alt="Element explanation"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <img
              width="100%"
              alt="Element explanation"
              src="https://seer-media.s3.ap-southeast-2.amazonaws.com/insight-files/cbca43cc-918e-495f-a604-5e3895e973bc/How%20to%20read%20the%20report.png"
              aria-hidden="true"
            />
          </div>
        </div>
      </section>
      <h2 id="understanding-themes-outcomes">Understanding themes and outcomes</h2>
      <section className="mb-5">
        <h3>Theme 1: Accountability and integrity - taking responsibility, acting with honesty</h3>
        <div className="outcome">
          <h4>RESPONSE THEME 1 OUTCOME</h4>
          <p>
            Increased accountability and integrity across public institutions will improve the safety of children and young people by
            ensuring that policies, resources and actions are focused on, and prioritise the safety and wellbeing of children and young people.
          </p>
        </div>
      </section>
      <section className="mb-5">
        <h3>Theme 2: Collaboration and integration - working together and sharing information</h3>
        <div className="outcome">
          <h4>RESPONSE THEME 2 OUTCOME</h4>
          <p>
            Improved collaboration and integration, emphasising coordinated actions and resources, will ensure that children, young people
            and families receive maximum benefit from the actions and resources of government services. It will also ensure that concerns
            do not fall through the cracks in the system, placing children and young people at risk.
          </p>
        </div>
      </section>
      <section className="mb-5">
        <h3>Theme 3: Participation and empowerment - believing and involving children and young people</h3>
        <div className="outcome">
          <h4>RESPONSE THEME 3 OUTCOME</h4>
          <p>
            The contributions of children, young people and adult victim-survivors of child sexual abuse will help improve the services
            that keep them safe and well, and support them to heal.
          </p>
        </div>
      </section>
      <section className="mb-5">
        <h3>Theme 4: Prevention and protection - keeping children and young people safe</h3>
        <div className="outcome">
          <h4>RESPONSE THEME 4 OUTCOME</h4>
          <p>
            The delivery of the very broad set of actions to prevent incidents of child sexual abuse and to protect children and young
            people at risk will significantly reduce harm and increase the wellbeing of young people in Tasmania. The reduction of
            incidents of child sexual abuse in public institutions recognises the fundamental right of all children and young people to
            be protected from harm and to be safe. Where there are incidents, ensure transparency and accountability, a quick and active
            response and support.
          </p>
        </div>
      </section>
      <section className="mb-5">
        <h3>Theme 5: Workforce expertise and capability - building the capability of our people</h3>
        <div className="outcome">
          <h4>RESPONSE THEME 5 OUTCOME</h4>
          <p>
            The risk to children and young people will be reduced through a workplace culture that understands the boundaries of
            professional conduct and empowers its workforce to identify, report and respond to address risk to the safety of children
            and young people.
          </p>
        </div>
      </section>
      <section className="mb-5">
        <h3>Theme 6: Child safe cultures and awareness-raising - understanding and creating a safer future</h3>
        <div className="outcome">
          <h4>RESPONSE THEME 6 OUTCOME</h4>
          <p>
            Government employees will be better equipped to prevent child sexual abuse, including by identifying and managing risks
            for children and young people. The workforce will also be better equipped to work with children, young people and adult
            victim-survivors in a way that is more trauma informed, thereby reducing ongoing harm.
          </p>
        </div>
      </section>
      <BackToTop />
    </HelpWrapper>
  );
};
