import Store from "common/store";
import { environment, webUrl } from "common/constants";
import { database } from "common/api";
import { ObjectAny } from "common/helpers/types";
import { getInsightDetail, getSuitcaseDetail } from "common/helpers/mixpanel";
import { getMixpanel } from "common/api";

// these point to templates that should not have the replacement sections modified
const censusTemplateConstants = (existingUser = false): any => {
  let suitcaseId = environment === "production" ? 7543 : 941;
  let suitcaseKey = environment === "production" ? "61acfe4b-a791-46a9-8eb0-869757cea508b5d729be-3304-4325-8a5b-435d6a42d5a4" : "933253d2-3657-415f-baf9-5150d14b4144ab13cd5d-c702-4a96-8c59-8c64122ace8a";
  let dashboardId = environment === "production" ? 37 : 46;
  let dashboardKey = environment === "production" ? "562bcc41-4a22-4a50-b56b-955bcabf50e516385aec-f6ce-42db-8988-79492f6421f0" : "c6f53e29-5747-43c2-bb38-a0e09d5c3844d977dd9d-a77c-4734-9ed7-c0fb33655713";
  if (existingUser) {
    suitcaseId = environment === "production" ? 7544 : 947;
    suitcaseKey = environment === "production" ? "60bcc7b2-e34b-4e7f-8dec-8ed8a85247e9d33fe5a2-beb3-444a-a2ba-b123da373254" : "5863e94a-71ec-403b-9751-90dfde6cd15e5623c00c-72da-43e2-84b7-12fce775fb82";
    dashboardId = environment === "production" ? 38 : 49;
    dashboardKey = environment === "production" ? "4c60a24f-a22a-4671-889c-15eb74e53103f432e026-bf2d-458f-a291-230bd3966980" : "bd1e6a3a-eda0-4395-9f36-be6a08df80d3d160fdeb-f38a-4113-b0da-d60f583f9ea2";
  }
  return {
    suitcaseId,
    suitcaseKey,
    dashboardId,
    dashboardKey,
    strReplace: {
      suitcaseLink: "https://seerplatform.com/suitcases/7458",
      suitcaseName: "Lavinia's 2021 Census Insights Suitcase",
      embed: environment === "production" ?
        '"insightData":"{\\"id\\":8047,\\"name\\":\\"2016 Census General Community Profiles (ABS)\\",\\"url\\":\\"https://seerplatform.com/insights/8047/3c59db75-e280-4e17-b77f-30fe6b06da11268899f8-d7a7-4445-87b5-dfedc20ca0f2\\",\\"isPublic\\":true,\\"suitcase_id\\":2251,\\"webUrl\\":\\"https://seerplatform.com\\"}"' :
        '"insightData":"{\\"id\\":1408,\\"name\\":\\"Untitled insight\\",\\"url\\":\\"https://staging.seerplatform.com/insights/1408/9e1723cd-b681-4655-b2f5-798c57165018449b48c3-6b0d-4d12-813f-fd9932d7567a\\",\\"isPublic\\":true,\\"suitcase_id\\":396,\\"webUrl\\":\\"https://staging.seerplatform.com\\"}"',
      embedHTML: environment === "production"
        ? "url=https://seerplatform.com/insights/8047/3c59db75-e280-4e17-b77f-30fe6b06da11268899f8-d7a7-4445-87b5-dfedc20ca0f2"
        : "url=https://staging.seerplatform.com/insights/1408/9e1723cd-b681-4655-b2f5-798c57165018449b48c3-6b0d-4d12-813f-fd9932d7567a",
    },
  };
};

interface SignupData {
  formData: {
    first_name: string;
    // other fields will be here, but we only care about the ones we need at this point, fields will vary depending on new user signup vs existing login
  };
  signupData: any;
  userType: "existing" | "new";
}

export const handleSignup = async (data: SignupData, store: Store): Promise<void> => {
  const { builder } = store;
  const { formData, signupData, userType } = data;
  // @TODO - investigate - strange that user.token doesn't match JWTTOKEN in the signupData returned (new user signup only), and that JWTTOKEN is not valid for normal auth
  const { id, token: tempToken } = signupData;
  await store.logInUser({ token: tempToken, user_id: userType !== "existing" ? id : undefined }); // get the full token and store it in localstorage
  const token = store.token || "";
  const insightBody = builder.getInsightBody(undefined, undefined);
  let first_name = formData.first_name;
  // determine flow to use
  let flow = "default";
  let userCensusSuitcase: any; // for "census exists" flow
  if (userType !== "existing" && builder.source === "census") {
    flow = "census";
  } else if (userType === "existing" && builder.source === "census") {
    // first check if already has copy of suitcase template created, otherwise census flow
    first_name = store.user?.first_name || ""; // only available on formData when new user so grab from store for existing user
    userCensusSuitcase = await getUserCensusSuitcase(store);
    if (!userCensusSuitcase) {
      flow = "census";
    } else {
      flow = "census exists";
    }
  }

  let flowDetails: any = {};
  if (flow === "census") {
    flowDetails = await censusFlow(userType, token, first_name, insightBody, id);
  } else if (flow === "census exists") {
    flowDetails = await censusExistsFlow(userCensusSuitcase, insightBody, token);
  } else {
    // flow === "default" - save the insight in drafts by default
    const insightRes: any = await database.post("insights", insightBody, token);
    const insightId = insightRes?.body?.data?.insight?.id;
    const suitcaseId = insightRes?.body?.data?.insight?.suitcase_id;
    if (userType === "existing") {
      flowDetails = { redirectURL: `${webUrl}/insights/${insightId}`, insightId, suitcaseId };
    } else {
      // normal on-boarding flow for new signups
      flowDetails = { redirectURL: `${webUrl}/welcome`, insightId, suitcaseId };
    }
  }

  const insight = await getInsightDetail(flowDetails.insightId, token);
  const suitcase = await getSuitcaseDetail(flowDetails.suitcaseId, token);
  const mixpanelData = {
    "Type": "Create",
    "Insight ID": flowDetails.insightId,
    "Insight Name": insight.name,
    "Insight Owner Name": insight.ownerName,
    "Source": store.builder.source || "default",
    "Suitcase ID": insight.suitcaseID,
    "Suitcase Name": suitcase.name,
    "Suitcase Owner Name": suitcase.ownerName,
    "Suitcase Owner Organisation": suitcase.ownerOrg,
  };
  if (flow === "census") {
    mixpanelData["Census Flow"] = "Create";
  } else if (flow === "census exists") {
    mixpanelData["Census Flow"] = "Append";
  }
  getMixpanel(store).track("Insight Builder > Save Insight", mixpanelData);
  window.location.href = flowDetails.redirectURL;
};

// custom behaviour for users coming from census form
export const censusFlow = async (
  userType: "existing" | "new", token: string, first_name: string, insightBody: ObjectAny, user_id: number
): Promise<{ redirectURL: string; insightId: number; suitcaseId: number }> => {
  const censusConstants = censusTemplateConstants(userType === "existing");
  await database.put(`users/${user_id}`, { show_homepage_help: false }, token);
  // make copy of template suitcase
  const templateSuitcaseRes: any = await database.get(`suitcases/${censusConstants.suitcaseId}/${censusConstants.suitcaseKey}`);
  const copySuitcaseRes: any = await database.post("suitcases", {
    name: `${first_name}'s Census 2021 Insights`,
    description: templateSuitcaseRes?.body?.data?.suitcase?.description || "",
  }, token);
  const copySuitcaseId = copySuitcaseRes?.body?.data?.suitcase?.id;
  const copySuitcaseUpdateRes: any = await database.put(`suitcases/${copySuitcaseId}`, { link_share: true }, token);
  const copySuitcaseKey = copySuitcaseUpdateRes?.body?.data.suitcase.key;
  // save insight in new suitcase
  insightBody.suitcase_id = copySuitcaseId; // save insight to copied suitcase
  const insightRes: any = await database.post("insights", insightBody, token);
  const insightId = insightRes?.body?.data?.insight?.id;
  const insightName = insightRes?.body?.data?.insight?.name;
  // make copy of template dashboard and update dashboard html/unlayer_config with new users data
  const templateDashboardRes: any = await database.get(`dashboards/${censusConstants.dashboardId}/${censusConstants.dashboardKey}`);
  let unlayerConfigStr = JSON.stringify(templateDashboardRes?.body?.data?.dashboard?.unlayer_config || []);
  let htmlStr = templateDashboardRes?.body?.data?.dashboard?.html || "";
  unlayerConfigStr = unlayerConfigStr.replace(censusConstants.strReplace.suitcaseLink, `${webUrl}/suitcases/${copySuitcaseId}`);
  unlayerConfigStr = unlayerConfigStr.replace(censusConstants.strReplace.suitcaseName, `${first_name}'s 2021 Census Insights Suitcase`);
  unlayerConfigStr = unlayerConfigStr.replace(
    censusConstants.strReplace.embed,
    `"insightData":"{\\"id\\":${insightId},\\"name\\":\\"${insightName}\\",\\"url\\":\\"${webUrl}/insights/${insightId}/${copySuitcaseKey}\\",\\"isPublic\\":true,\\"suitcase_id\\":${copySuitcaseId},\\"webUrl\\":\\"${webUrl}\\"}"`
  );
  htmlStr = htmlStr.replace(censusConstants.strReplace.suitcaseLink, `${webUrl}/suitcases/${copySuitcaseId}`);
  htmlStr = htmlStr.replace(censusConstants.strReplace.suitcaseName, `${first_name}'s 2021 Census Insights Suitcase`);
  htmlStr = htmlStr.replace(
    censusConstants.strReplace.embedHTML,
    `url=${webUrl}/insights/${insightId}/${copySuitcaseKey}`,
  );
  const copyDashboardRes: any = await database.post("dashboards", {
    name: `${first_name}'s Census 2021 Dashboard`,
    unlayer_config: JSON.parse(unlayerConfigStr),
    html: htmlStr,
  }, token);
  const copyDashboardId = copyDashboardRes?.body?.data?.dashboard?.id;
  await database.put(`dashboards/${copyDashboardId}`, { link_share: true }, token); // make public
  return {
    redirectURL: `${webUrl}/dashboards/${copyDashboardId}`,
    insightId,
    suitcaseId: copySuitcaseId,
  };
};

// custom behaviour for existing user that already has census suitcase, returns redirect URL
export const censusExistsFlow = async (userCensusSuitcase: ObjectAny, insightBody: ObjectAny, token: string): Promise<{ redirectURL: string; insightId: number; suitcaseId: number }> => {
  insightBody.suitcase_id = userCensusSuitcase.id; // save insight to existing census suitcase
  const insightRes: any = await database.post("insights", insightBody, token);
  const insightId = insightRes?.body?.data?.insight?.id;
  return {
    redirectURL: `${webUrl}/insights/${insightId}`,
    insightId,
    suitcaseId: insightBody.suitcase_id,
  };
};

// find users census suitcase by suitcase name - XX's Census 2021 Insights
export const getUserCensusSuitcase = async (store: Store): Promise<any> => {
  const suitcaseName = `${store.user?.first_name || ""}'s Census 2021 Insights`;
  const res: any = await store.suitcase.searchSuitcases(suitcaseName, 1);
  return res?.data?.suitcases[0];
};
