import styled from "styled-components";
import { COLORS } from "component/UI/common";
import { Card } from "semantic-ui-react";

export const Container = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding-top: 30px;
  @media screen and (max-width: 940px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Heading = styled.h2`
  font-family: "Open Sans", Helvetica, sans-serif;
  color: ${COLORS.indigo600};
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
  margin-bottom: 48px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

export const StyledCardGroup = styled(Card.Group)`
  &.ui.cards {
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
  }
  .ui.card:first-child {
    margin-left: 1px !important;
  }
  .ui.card:last-child {
    margin-right: 1px !important;
  }
  &.ui.cards>.card {
    flex-grow: 1;
    box-shadow: none;
  }
  margin-bottom: 24px !important;
`;

export const StyledCard = styled(Card)`
  padding: 16px !important;
  margin-bottom: 0 !important;
  .header {
    font-size: 20px;
    font-weight: 700;
    color: ${COLORS.grey700};
    margin-bottom: 8px;
    font-family: "Open Sans", Helvetica, sans-serif;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    color: ${COLORS.grey500};
    margin-bottom: 16px;
    font-family:  Helvetica, sans-serif;
  }
  .meta {
    font-size: 14px !important;
    font-weight: 400;
    color: ${COLORS.grey500} !important;
    font-family:  Helvetica, sans-serif;
  }
`;
