import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Icon, Container } from "semantic-ui-react";
import Protected from "component/protected";
import { getMixpanel } from "common/api";
import { DashboardsList } from "./includes/DashboardsList";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { StyleDropdown, StylePagination, StyledTabActions } from "./includes/Dashboard.style";
import { StyleTab } from "component/Suitcases/SuitcasesContent.style";
import { StylePillButton } from "pages/CensusQueryTool/includes/CensusQueryTool.style";
import { Button } from "component/UI/Button";
import * as qs from "qs";
import Store from "common/store";
import { EmptyStatePrimary, EmptyStateSecondary, ListPageEmptyStateDescription } from "component/EmptyState/EmptyState";

interface ComponentProps {
  store: Store;
  dashboardStore: any;
}

const DASHBOARDS_TYPES = ["all", "seer", "owned", "shared", "shared_with_me"] as const;

type DashboardType = typeof DASHBOARDS_TYPES[number];

const DASHBOARDS_FILTERS = [
  { label: "All", value: "all" },
  { label: "Private", value: "owned" },
  { label: "Shared", value: "shared" },
  { label: "Shared with me", value: "shared_with_me" },
];
const myDashboardsHeaderItems = [
  {
    name: "Name",
    sortColumn: "name",
    className: "",
  },
  {
    name: "Public",
    className: "header-100",
  },
  {
    name: "Created at",
    sortColumn: "created_at",
    className: "d-none d-md-table-cell",
  },
  {
    name: "Updated at",
    sortColumn: "updated_at",
    className: "d-none d-md-table-cell",
  },
];
const sharedDashboardsHeaderItems = [
  {
    name: "Name",
    sortColumn: "name",
    className: "",
  },
  {
    name: "Owner",
    className: "header-100",
  },
  {
    name: "Created at",
    sortColumn: "created_at",
    className: "d-none d-md-table-cell",
  },
  {
    name: "Updated at",
    sortColumn: "updated_at",
    className: "d-none d-md-table-cell",
  },
];
const seerDashboardsHeaderItems = [
  {
    name: "Name",
    sortColumn: "name",
    className: "",
  },
  {
    name: "Created at",
    sortColumn: "created_at",
    className: "d-none d-md-table-cell",
  },
  {
    name: "Updated at",
    sortColumn: "updated_at",
    className: "d-none d-md-table-cell",
  },
];
const numberResultsOptions = [
  { key: 1, text: 10, value: 10 },
  { key: 2, text: 20, value: 20 },
  { key: 3, text: 50, value: 50 },
  { key: 4, text: 100, value: 100 },
];

const DashboardsComponent = ({ store, dashboardStore }: ComponentProps): JSX.Element => {
  const { userPlan, user } = store;
  const canUseDashboardFunctions = (userPlan === "Plus" || userPlan === "Unlimited" || user?.group.add_ons.map(addOn => addOn.name).includes("Dashboards"));

  const location = useLocation();
  const { search } = location;
  const { active_type } = qs.parse(search.slice(1) || "");
  // TODO: Look into better solutions to make "includes()" accepting parameters with no type assertions in Typescript
  // Reference: https://stackoverflow.com/questions/56565528/typescript-const-assertions-how-to-use-array-prototype-includes
  const ifValidDashboardType = active_type && (DASHBOARDS_TYPES as ReadonlyArray<string>).includes(active_type as string);

  const [data, setData] = React.useState<any>(null);
  const [currentDashboardType, setCurrentDashboardType] = React.useState<DashboardType>(ifValidDashboardType ? active_type as DashboardType : "all"); // This is used by "Dashboards Tab" and "Dashboards Filter"
  const [pageLength, setPageLength] = React.useState(10); // how many dashboards per page
  const [sort, setSort] = React.useState("");
  const [activePage, setActivePage] = React.useState(1); // Page number for pagination
  const [loading, setLoading] = React.useState(false);
  // Set active tab to "Dashboards"(activeIndex = 0) when "currentDashboardType" is "owned" | "shared" | "shared_with_me"
  const activeTabIndex = DASHBOARDS_TYPES.indexOf(currentDashboardType) > 1 ? 0 : DASHBOARDS_TYPES.indexOf(currentDashboardType);

  const { data: dashboardData, meta } = data || {};
  const currentDashboards = dashboardData?.dashboards?.map(dashboard => {
    const { id, name, link_share, created_at, updated_at, owner } = dashboard;
    const singleDashboard = {};
    singleDashboard["id"] = id;
    singleDashboard["name"] = name;
    singleDashboard["created_at"] = created_at;
    singleDashboard["updated_at"] = updated_at;
    if (currentDashboardType === "owned" || currentDashboardType === "shared") {
      singleDashboard["public_shared"] = link_share;
    } else if (currentDashboardType === "all" || currentDashboardType === "shared_with_me") {
      singleDashboard["owner"] = `${owner.first_name} ${owner.last_name}`;
    }
    return singleDashboard;
  });
  const totalDashboards = meta?.total || 0;
  const totalPages = Math.ceil((totalDashboards)/pageLength);
  // Numbers displayed in "Showing 1-5 of 14" section
  const currentStartDashboardNumber = (activePage - 1) * pageLength + 1;
  let currentEndDashboardNumber = activePage * pageLength;
  if (totalDashboards && activePage === totalPages) {
    const leftNumber = totalDashboards - (totalPages - 1) * pageLength;
    currentEndDashboardNumber = currentStartDashboardNumber + leftNumber - 1;
  }

  const history = useHistory();

  const createDashboard = async () => {
    const success = await dashboardStore.createDashboard();
    if (success?.dashboard?.id) {
      getMixpanel(store).track("Create Dashboard", { "Page": "Dashboards" });
      history.push(`/dashboards/${success.dashboard.id}?openUnlayer=true`);
    }
  };

  const upgradePlan = () => {
    getMixpanel(store).track("Click Upgrade Plan", { "Page": "Dashboards" });
    window.open("https://seerdata.ai/plus-plan/", "_blank");
  };

  const tabChangeHandler = (activeTabIndex: 0 | 1) => {
    getMixpanel(store).track("Change Dashboards Tab", { "Type": DASHBOARDS_TYPES[activeTabIndex].toUpperCase() });
    setCurrentDashboardType(DASHBOARDS_TYPES[activeTabIndex]);
    setPageLength(10);
    setActivePage(1);
  };

  const filterClickHandler = (value: DashboardType) => {
    getMixpanel(store).track("Change Dashboards Filter", { "Type": DASHBOARDS_FILTERS.find(item => item.value === value)?.label });
    setCurrentDashboardType(value);
    setActivePage(1);
  };

  const paginationClickHandler = (activePage: number) => {
    setActivePage(activePage);
  };

  const dropdownChangeHandler = (value: number) => {
    getMixpanel(store).track("Change Dashboards Per Page", { "Number": value });
    setActivePage(1);
    setPageLength(+value);
  };

  const loadDashboards = async () => {
    setLoading(true);
    const res: any = await dashboardStore.getDashboards(pageLength, pageLength * (activePage - 1), currentDashboardType, sort);
    if (res) {
      setData(res);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getMixpanel(store).track("Page view", { "Page": "Dashboards" });
  }, []);

  React.useEffect(() => {
    loadDashboards();
  }, [currentDashboardType, activePage, pageLength, sort]);

  {/* Dropdown for "? per page" */ }
  const PerPageComponent = () => (
    <div>
      <StyleDropdown
        selection
        options={numberResultsOptions}
        defaultValue={pageLength}
        onChange={(_, { value }) => dropdownChangeHandler(value as number)}
      />
      <span className="ml-2 fs-1000 fw-600 text-muted">per page</span>
    </div>
  );

  const tabItems = [
    {
      menuItem: "Dashboards",
      render: () => (
        <>
          <div className="d-flex align-items-center justify-content-between">
            {currentDashboardType !== "seer" && (
              <div className="mr-3" style={{ height: 38 }}>
                {DASHBOARDS_FILTERS.map(item => {
                  const { label, value } = item;
                  return (
                    <StylePillButton
                      key={value}
                      size="tiny"
                      active={currentDashboardType === value}
                      className="cursor-pointer mr-2 mb-2 mb-md-0"
                      onClick={() => filterClickHandler(value as DashboardType)}
                      disabled={loading}
                    >
                      {label}
                    </StylePillButton>
                  );
                })}
              </div>
            )}
            {currentDashboards?.length > 0 && <PerPageComponent />}
          </div>
          {loading ? <ContentPlaceholder /> : (
            <>
              {currentDashboards?.length === 0 ? (
                <>
                  {canUseDashboardFunctions ? (
                    <>
                      {currentDashboardType === "all" ? (
                        <EmptyStatePrimary
                          heading="Get Started and Create Your First Dashboard"
                          description="A Dashboard is a page built with darg and drop action that lets you add charts from your Suitcases and edit the styling of content. It allows you to embed videos, add images and links to bolster your narrative. Get started and create your first Seer Dashboard!"
                          image="/assets/dashboard_empty_state_image.png"
                          primaryButton={{
                            label: "Create Dashboard",
                            icon: "plus",
                            onClick: createDashboard,
                          }}
                        />
                      ) : currentDashboardType === "owned" ? (
                        <EmptyStateSecondary
                          heading="No Private Dashboards to Show"
                        />
                      ) : currentDashboardType === "shared" ? (
                        <EmptyStateSecondary
                          heading="No Dashboards Shared Yet"
                          description={(
                            <ListPageEmptyStateDescription
                              description="Here is where you will find Dashboards you have shared."
                              link="https://knowledge.seerdata.ai/how-do-i-share-my-dashboard"
                              text="sharing Dashboard"
                            />
                          )}
                        />
                      ) : (
                        <EmptyStateSecondary
                          heading="No Dashboards Shared With You Yet"
                          description={(
                            <ListPageEmptyStateDescription
                              description="Here is where you will find Dashboards others have shared with you."
                              link="https://knowledge.seerdata.ai/what-is-a-shared-dashboard"
                              text="shared Dashboard"
                            />
                          )}
                        />
                      )}
                    </>
                  ) : (
                    <EmptyStatePrimary
                      heading="Data Story Telling Dashboards Available on Our Seer Plus Plan"
                      description="Seer Data Storytelling Dashboard helps to build context around your Insights, highlight key observations, and add visual storytelling elements to your Suitcase. It allows you to embed videos, add images and links to bolster your narrative."
                      image="/assets/dashboard_empty_state_image.png"
                      primaryButton={{
                        label: "Upgrade Your Plan",
                        icon: "arrow circle up",
                        onClick: upgradePlan,
                      }}
                      secondaryButton={{
                        label: "Lean more about your plans",
                        icon: "external alternate",
                        onClick: () => store!.handleUpgradeShow(true),
                      }}
                    />
                  )}
                </>
              ) : (
                <DashboardsList
                  headerItems={currentDashboardType === "owned" || currentDashboardType === "shared" ? myDashboardsHeaderItems : sharedDashboardsHeaderItems}
                  dashboards={currentDashboards}
                  applySort={nextSort => setSort(nextSort)}
                  currentSort={sort}
                />
              )}
            </>
          )}
        </>
      ),
    },
    {
      menuItem: { key: "Seer Data Curated", id: "userflow-element-dashboards-curated-tab", content: (<div><span className="d-none d-sm-inline">Seer Data </span>Curated</div>) },
      render: () => (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center text-medium">
            {userPlan !== "Unlimited" && (
              <>
                <Icon name="info circle" className="mr-2" />
                <p className="fs-1000 fw-600 mb-0 mt-1">Upgrade your plan to explore more Dashboards created by Seer Data</p>
              </>
            )}
            </div>
            <PerPageComponent />
          </div>
          {loading ? <ContentPlaceholder /> : (
            <DashboardsList
              id="userflow-element-dashboards-curated-list"
              headerItems={seerDashboardsHeaderItems}
              dashboards={currentDashboards}
              applySort={nextSort => setSort(nextSort)}
              currentSort={sort}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Helmet>
          <title>Dashboards</title>
        </Helmet>
        <div className="position-relative">
          <StyledTabActions>
            {canUseDashboardFunctions ? (
              <Button
                className="bg-primary text-white w-100"
                colorConfig="redInverted"
                onClick={createDashboard}
              >
                Create Dashboard<Icon className="plus ml-2 mr-0" />
              </Button>
            ) : (
              <Button
                inverted
                colorConfig="purpleInverted"
                onClick={upgradePlan}
              >
                Upgrade Plan<Icon name="arrow circle up" className="ml-2 mr-0" />
              </Button>
            )}
          </StyledTabActions>
          <StyleTab
            menu={{ secondary: true, pointing: true }}
            panes={tabItems}
            activeIndex={activeTabIndex}
            onTabChange={(_e, { activeIndex }) => tabChangeHandler(activeIndex as 0 | 1)}
            marginbottom={10}
          />
          {!loading && currentDashboards?.length !== 0 && canUseDashboardFunctions && (
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between w-100 mb-5">
              {/* Total number */}
              <span className="fs-1000 text-medium">
                {`Showing ${totalDashboards < pageLength
                  ? totalDashboards
                  : `${currentStartDashboardNumber}${
                    currentStartDashboardNumber === currentEndDashboardNumber
                    ? ""
                    : `-${currentEndDashboardNumber}`}`}
                of ${totalDashboards} dashboards`}
              </span>
              {/* Pagination */}
              {totalPages > 0 && (
                <div className="d-flex justify-content-center">
                  <StylePagination
                    pointing
                    secondary
                    size="large"
                    activePage={activePage}
                    boundaryRange={1}
                    onPageChange={(_e, { activePage }) => paginationClickHandler(activePage as number)}
                    siblingRange={2}
                    totalPages={totalPages}
                    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export const Dashboards = Protected(inject((stores: any) => ({
  store: stores.store,
  dashboardStore: stores.store.dashboard,
}))(observer(DashboardsComponent)));
