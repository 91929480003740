import styled from "styled-components";
import { Confirm, Dropdown, Radio } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const StyleConfirm = styled(Confirm)`
&.ui.modal>.header {
  font-size: 22px !important;
  color: ${COLORS.indigo600};
};
&.ui.modal>.content {
  font-size: 16px !important;
  font-weight: 600 !important;
};
.ui.button {
  border: 1px solid ${COLORS.red500};
  background-color: #FFFFFF;
  color: ${COLORS.red500};
};
.ui.primary.button, .ui.primary.buttons .button {
  background-color: ${COLORS.red500};
  color: #FFFFFF;
};
`;

export const TableInfoContainer = styled.div`
  margin-right: -16px;
  position: relative;
`;

export const TableInfoContainerPinned = styled.div`
  margin-top: 40px;
  margin-right: -16px;
  position: relative;
`;

export const ResultTitle = styled.h2`
  color: ${COLORS.indigo600};
  padding-top: 15px;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
`;

export const DataInfoTextBold = styled.p`
  font-family: "Open Sans", sans-serif !important;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  margin-bottom: 0px;
  margin-right: 5px;
  font-weight: bold;
  display: inline-block;
`;

export const DataInfoText = styled.p`
  font-family: "Open Sans", sans-serif !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  margin-bottom: 0px;
  display: inline-block;
`;

export const SubTitles = styled.h3`
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: ${COLORS.indigo600};
  margin-bottom: 0px;
`;

export const Focus = styled.h3`
  margin-top: 10px;
`;

export const DD = styled(Dropdown)<{ ready?: boolean }>`
  border: 1px solid #c3c3c3 !important;
  min-height: 40 !important;
  display: block !important;
  height: auto !important;
  font-size: 14.5px !important;
  color: green !important;
  padding-right: 50px !important;
  &.has-action [role="option"]:first-child > span.text {
    color: ${COLORS.red500} !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
  }
  &:not(.subcat):not(.subcat-viz) {
    border-radius: 4px !important;
  }
  &.is-measured-quantity-sub {
    border-radius: 4px !important;
  }
  .menu {
    .item {
      .text {
        color: #2d2d2d !important;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
  .default {
    color: #919191 !important;
    opacity: 1;
  }
  .text[role="alert"] {
    width: 100%;
    line-height: 20px !important;
    font-size: 14.5px !important;
    letter-spacing: 0.05em !important;
    color: rgba(0, 0, 0, 0.87);
    .default-text {
      color: #919191 !important;
      letter-spacing: 0 !important;
    }
    .volume-count {
      position: absolute;
      right: 34px;
      color: #919191 !important;
      font-weight: bold !important;
      font-size: 12px !important;
      font-family: Open Sans !important;
    }
  }
  .label {
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 15px !important;
    color: white !important;
    border-radius: 4px;
    padding: 6px 8px 7px 8px !important;
    margin: 0.5px 0;
    background-color: ${COLORS.indigo600};
    box-shadow: none !important;
    &:hover {
      color: white !important;
    }
    &:active {
      background: ${COLORS.indigo600} !important;
      color: white !important;
    }
  }
  .dropdown {
    &:before {
      ${p => p.ready && "content: \"\" !important;"}
    }
    &.icon {
      color: #404040 !important;
      .volume-count {
        font-weight: bold;
        padding-left: 3px;
        font-family: Open Sans !important;
      }
    }
    &.light {
      color: #cccccc !important;
    }
  }
  ${p => (p.selected ? COLORS.indigo600 : "#919191")};
`;

export const StyleIcon = styled.i`
  font-size: 19px;
`;

export const StyleRadio = styled(Radio)`
  font-size: 1rem !important;
  label:after {
    background-color: ${COLORS.indigo600} !important;
  }
`;
