import { inject, observer } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router";
import Protected from "../component/protected";
import Store from "common/store";
import { getMixpanel } from "common/api";
import { Helmet } from "react-helmet";
import { SuitcasesContent } from "component/Suitcases/SuitcasesContent";
import { NewFeatureBanner } from "component/NewFeatures/NewFeaturesBanner";
import { ObjectAny } from "common/helpers/types";

type PropsType = RouteComponentProps<ObjectAny> & {
  store: Store;
};

export default Protected(
  inject("store")(
    observer(
      class Suitcases extends React.Component<PropsType> {
        store = this.props.store!;

        componentDidMount(): void {
          getMixpanel(this.store).track("Page view", { Page: "Suitcases" });
          this.store.suitcase.getSuitcases(null, true);
        }

        render() {
          return (
            <>
              <Helmet>
                <title>Suitcase</title>
              </Helmet>

              <NewFeatureBanner />
              <SuitcasesContent />
            </>
          );
        }
      }
    )
  )
);
