import * as React from "react";
import { Icon, Popup } from "semantic-ui-react";

interface IEditablePaletteName {
  name: string;
  paletteID: number;
  colors: string[];
  updateName: (paletteID: number, name: string, colors: string[]) => void;
};

const EditablePaletteNameComponent = ({ name, paletteID, colors, updateName }: IEditablePaletteName): JSX.Element => {
  const [isInputActive, setIsInputActive] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [showInputError, setShowInputError] = React.useState(false);

  const inputRef: any = React.useRef(null);

  const isInputValueValid = (inputValue.length >= 1 && inputValue.length <= 40) ? true : false;

  const savePaletteName = async () => {
    if (isInputValueValid) {
      await updateName(paletteID, inputValue, colors);
      setIsInputActive(false);
    } else {
      setShowInputError(true);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      savePaletteName();
    }
  };

  const handleEditIconOnClick = async () => {
    setInputValue(name);
    setShowInputError(false);
    await setIsInputActive(true);
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  };

  const handleOnBlur = () => {
    savePaletteName();
  };

  React.useEffect(() => {
    if (isInputValueValid) {
      setShowInputError(false);
    } else {
      setShowInputError(true);
    }
  }, [inputValue]);

  return (
    <div className="d-flex align-items-center w-75 mb-3">
      {
        isInputActive ?
        <div className="d-flex flex-column w-100">
          <input
            ref={inputRef}
            defaultValue={name}
            onBlur={handleOnBlur}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleOnKeyDown}
            className="w-100 rounded-1 fs-1125"
            style={{border: "1px solid #C3C3C3"}}
          />
          {
            showInputError ?
            <p className="fs-0875 text-danger">
              Palette name is required with min length of 1 and max of 40.
            </p> : null
          }
        </div>
        :
        <>
          <p className="text-dark fs-1125 fw-600 mb-0">
            {inputValue ? inputValue : name}
          </p>
          <Popup content="Edit" position="top center" size="mini" trigger={
            <div>
              <Icon
                onClick={handleEditIconOnClick}
                name="edit outline"
                color="grey"
                className="mb-1 mx-2 cursor-pointer"
              />
              <span
                className="sr-only"
                onClick={handleEditIconOnClick}
              >
                Edit name
              </span>
            </div>
            }
          />
        </>
      }
    </div>
  );
};

export const EditablePaletteName = EditablePaletteNameComponent;
