import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import Protected from "component/protected";
import { getMixpanel } from "common/api";
import { IFilter } from "common/store/builder";
import { ls } from "common/helpers/storage";
import { QueryTool } from "./includes/QueryTool";
import { SavedInsights } from "./includes/SavedInsights";
import { StyleHeading, StyleTab } from "component/Suitcases/SuitcasesContent.style";
import { Container, Label, Menu } from "semantic-ui-react";
import { EmptyStatePrimary } from "component/EmptyState/EmptyState";
import { COLORS } from "component/UI/common";

const SmartInsightsComponent = ({ store, builder, favouriteStore }) => {
  const isStandard = store.userPlan === "Standard";

  const clickInsight = async (insight) => {
    const dimensions: IFilter[] = Object.keys(insight)
      .filter(key => key !== "percentile" && key !== "dataset" && key !== "Value" && key !== "id")
      .reduce((prev: any[], curr) => {
        prev.push({ dimension: curr, values: [insight[curr]] });
        return prev;
      }, []);
    ls.setItem("smartInsight", JSON.stringify({ dataset: insight?.dataset, dimensions }));
    getMixpanel(store!).track("Create Insight", { "Page": "Smart Insights", "Insight Data": insight, "From": insight.id ? "Saved Insights" : "Query Tool" });
    window.open("/builder/new?source=smart_insights", "_blank");
  };

  const unsaveSmartInsight = async (id: number) => {
    getMixpanel(store!).track("Unsave Smart Insight", { id });
    return await favouriteStore.unsaveFavourite(id);
  };

  const upgradePlan = () => {
    getMixpanel(store).track("Click Upgrade Plan", { "Page": "Smart Insights" });
    window.open("https://seerdata.ai/plus-plan/", "_blank");
  };

  const emptyStateDescription = (
    <p className="fs-1125 mb-5" style={{ color: COLORS.grey800 }}>
      <span>Smart Insights is a feature to produce automated Insights specific to your chosen locations. </span>
      <span><a target="_blank" href="https://knowledge.seerdata.ai/how-to-use-the-smart-insights" className="text-decoration-underline" style={{ color: COLORS.blue }}>Click here</a></span>
      <span> to learn more about Smart insights.</span>
    </p>
  );

  const tabItems = [
    {
      menuItem: (
        <Menu.Item key="query-tool">
          Query Tool
        </Menu.Item>
      ),
      render: () => (
        <QueryTool
          clickInsightHandler={(insight) => clickInsight(insight)}
          unsaveInsightHandler={(id) => unsaveSmartInsight(id)}
        />
      ),
    },
    {
      menuItem: (
        <Menu.Item key="saved-insights">
          Saved Insights
          <Label circular className="bg-grey">{favouriteStore.favourites["smart-insight"]?.length || 0}</Label>
        </Menu.Item>
      ),
      render: () => (
        <SavedInsights
          clickInsightHandler={(insight) => clickInsight(insight)}
          unsaveInsightHandler={(id) => unsaveSmartInsight(id)}
        />
      ),
    },
  ];

  React.useEffect(() => {
    getMixpanel(store!).track("Page view", { "Page": "Smart Insights" });
    if (!isStandard) {
      builder.initSmartInsight();
    }
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Smart Insight</title>
      </Helmet>
      <StyleHeading>Smart Insights</StyleHeading>
      {isStandard ? (
        <EmptyStatePrimary
          heading="Smart Insights is an Add On for organisations that have a Seer Data Plus plan"
          description={emptyStateDescription}
          image="/assets/dashboard_empty_state_image.png"
          primaryButton={{
            label: "Upgrade Your Plan",
            icon: "arrow circle up",
            onClick: upgradePlan,
          }}
          secondaryButton={{
            label: "Lean more about your plans",
            icon: "external alternate",
            onClick: () => store.handleUpgradeShow(true),
          }}
        />
      ) : (
        <StyleTab
          menu={{ secondary: true, pointing: true }}
          panes={tabItems}
          marginbottom={20}
        />
      )}
    </Container>
  );
};

export const SmartInsights = Protected(inject((stores: any) => ({
  store: stores.store,
  builder: stores.store.builder,
  favouriteStore: stores.store.favourite,
}))(observer(SmartInsightsComponent)));
