import * as React from "react";
import * as ReactDOM from "react-dom";
import { CustomFormMultiRow } from "./CustomFormMultiRow";
import { insertScript } from "common/helpers/script";
import { ObjectAny } from "common/helpers/types";
import { CustomFormDropdown } from "./CustomFormDropdown";
import { CustomFormAccordion } from "./CustomFormAccordion";

interface Submission {
  title: string;
  value: string | ObjectAny[];
}

interface Checklist {
  title: string;
  items: ChecklistItem[];
}

interface ChecklistItem {
  checked: boolean;
  label: string;
}

const FILE_NAME = "DPAC GIAT Response";
const FILE_TITLE = "Applying a gender lens and making changes";
const TASMANIAN_GOVERNMENT_LOGO_URL = "https://seer-media.s3.ap-southeast-2.amazonaws.com/images/7bdde403-149b-4f04-913e-24f0173f5564.png";

// Need to specify "crossorigin" when drawing data that was loaded from another origin into a canvas
const loadImage = () => new Promise((resolve) => {
  const img = new Image() as HTMLImageElement;
  img.onload = () => resolve(img);
  img.setAttribute("crossorigin", "anonymous");
  img.src = TASMANIAN_GOVERNMENT_LOGO_URL;
});

const CustomFormDropdownInjection = () => {
  const dropdownElement = document.querySelector(".seer-custom-form-dropdown");
  if (dropdownElement) {
    const title = dropdownElement.getAttribute("title");
    ReactDOM.render(<CustomFormDropdown title={title} />, dropdownElement);
  }
};

const CustomFormMultiRowInjection = () => {
  const multiRowElement = document.querySelector(".seer-custom-form-multi-row");
  if (multiRowElement) {
    const title = multiRowElement.getAttribute("title");
    ReactDOM.render(<CustomFormMultiRow title={title} />, multiRowElement);
  }
};

const CustomFormAccordionInjection = () => {
  const accordionElements = document.querySelectorAll(".seer-custom-form-accordion");
  if (accordionElements.length) {
    accordionElements.forEach((accordionNode, accordionIdx) => {
      const items: any = [];
      const accordionItems = accordionNode.querySelectorAll(".seer-custom-form-accordion-item");
      accordionItems.forEach((itemNode) => {
        const titleNode = itemNode.querySelector(".seer-custom-form-accordion-title") as HTMLElement;
        const contentNode = itemNode.querySelector(".seer-custom-form-accordion-content");
        items.push({
          title: titleNode?.innerText,
          content: contentNode?.innerHTML,
        });
      });
      ReactDOM.render(<CustomFormAccordion items={items} accordionIdx={accordionIdx} />, accordionNode);
    });
  }
};

// print PDF of question responses
const printToPDF = async (submission, imageBase64) => {
  try {
    await insertScript("PDFKit", { type: "text/javascript", src: "https://cdn.jsdelivr.net/npm/pdfkit@0.15.0/js/pdfkit.standalone.min.js" });
    await insertScript("blob-stream", { type: "text/javascript", src: "https://github.com/devongovett/blob-stream/releases/download/v0.1.3/blob-stream.js" });
    const doc = new window["PDFDocument"]();
    const stream = doc.pipe(window["blobStream"]());
    doc.image(imageBase64, 50, 50, { width: 150 });
    doc.fontSize(22);
    doc.font("Helvetica-Bold").text(FILE_TITLE, 50, 160).moveDown();
    doc.fontSize(14);
    submission.forEach(s => {
      doc.font("Helvetica-Bold").text(s.title).moveDown(0.4);
      if (Array.isArray(s.value)) {
        s.value.forEach((v, idx) => {
          doc.font("Helvetica-Bold").text(`Answer ${idx + 1}`).moveDown(0.2);
          Object.keys(v).forEach(key => {
            doc.font("Helvetica").text(key, { underline: true, continued: true }).text(`: ${v[key]}`, { underline: false }).moveDown(0.2);
          });
          doc.moveDown(0.4);
        });
      } else {
        doc.font("Helvetica").text(s.value);
      }
      doc.moveDown();
    });
    doc.end();
    stream.on("finish", () => {
      const url = stream.toBlobURL("application/pdf");
      const a = document.createElement("a");
      a.href = url;
      a.download = `${FILE_NAME}.pdf`;
      a.click();
    });
  } catch (e) {
    console.error("Download response PDF failed!", e);
  }
};

// print docx of question responses
const printToDocx = async (submission, imageBase64) => {
  try {
    await insertScript("docx", { type: "text/javascript", src: "https://cdn.jsdelivr.net/npm/docx@8.5.0/build/index.umd.min.js" });
    const docx = window["docx"];
    const { Document, Paragraph, ImageRun, TextRun, Packer, SectionType, HeadingLevel, UnderlineType } = docx;
    const styles = {
      default: {
        heading1: {
          run: {
            size: "22pt",
            bold: true,
          },
          paragraph: {
            spacing: {
              after: 600,
            },
          },
        },
        heading2: {
          run: {
            size: "14pt",
            bold: true,
          },
          paragraph: {
            spacing: {
              after: 80,
            },
          },
        },
        document: {
          run: {
            size: "14pt",
            font: "Helvetica",
            color: "#000000",
          },
        },
      },
    };
    const sections: any[] = [];
    // Add logo
    sections.push({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              type: "png",
              data: imageBase64,
              transformation: {
                width: 200,
                height: 75,
              },
            }),
          ],
          spacing: {
            after: 1000,
          },
        }),
      ],
      properties: {
        type: SectionType.CONTINUOUS,
        page: {
          margin: {
            top: "50pt",
            left: "50pt",
            right: "50pt",
          },
        },
      },
    });
    // Add file title
    sections.push({
      children: [
        new Paragraph({
          text: FILE_TITLE,
          heading: HeadingLevel.HEADING_1,
        }),
      ],
      properties: {
        type: SectionType.CONTINUOUS,
        page: {
          margin: {
            left: "50pt",
            right: "50pt",
          },
        },
      },
    });
    // Add all submission
    submission.forEach(s => {
      const { title, value } = s;
      const children: any[] = [];
      children.push(new Paragraph({
        text: title,
        heading: HeadingLevel.HEADING_2,
      }));
      if (Array.isArray(value)) {
        value.forEach((v, idx) => {
          children.push(new Paragraph({
            text: `Answer ${idx + 1}`,
            heading: HeadingLevel.HEADING_2,
            spacing: {
              after: 40,
            },
          }));
          Object.keys(v).forEach((key, keyIdx) => {
            let spacingAfter = 30;
            if (keyIdx === Object.keys(v).length - 1) {
              if (idx === value.length - 1) {
                spacingAfter = 320; // Final line of the whole question
              } else {
                spacingAfter = 120; // Final line of each sub-question
              }
            }
            children.push(new Paragraph({
              children: [
                new TextRun({
                  text: key,
                  underline: {
                    type: UnderlineType.SINGLE,
                  },
                }),
                new TextRun(`: ${v[key]}`),
              ],
              spacing: {
                after: spacingAfter,
              },
            }));
          });
        });
      } else {
        children.push(new Paragraph({
          children: [new TextRun(value)],
          spacing: {
            after: 320,
          },
        }));
      }
      sections.push({
        children,
        properties: {
          type: SectionType.CONTINUOUS,
          page: {
            margin: {
              left: "50pt",
              right: "50pt",
            },
          },
        },
      });
    });
    const doc = new Document({ styles, sections });
    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${FILE_NAME}.docx`;
      a.click();
      URL.revokeObjectURL(url);
    });
  } catch (e) {
    console.error("Download response docx failed!", e);
  }
};

const PrintResponseEventInjection = (dashboardID: number) => {
  const submitButtons = document.querySelectorAll(".seer-custom-form-submit");
  if (submitButtons.length) {
    submitButtons.forEach(submitButton => {
      const doPrint = submitButton.classList.contains("seer-custom-form-print");
      submitButton.addEventListener("click", async () => {
        // attach loader style during submit (see src/app/component/LoaderGlobal.tsx)
        const loader = document.createElement("style");
        document.head.appendChild(loader);
        loader.appendChild(document.createTextNode(`
          body #loader-global { display: block !important; }
          body #loader-global-backdrop { display: block !important; }
        `));
        // Construct form submission
        const submission: Submission[] = [];
        // - Get responses from all fields
        const fieldList = document.querySelectorAll(".seer-custom-form-field");
        fieldList.forEach(node => {
          submission.push({
            title: node.getAttribute("title") || "",
            value: (node as HTMLTextAreaElement).value,
          });
        });
        // - Get response from multi row table (2nd question)
        const multiRowTableContainer = document.querySelector(".seer-custom-form-multi-row");
        const multiRowTableResponse: Submission = { title: multiRowTableContainer?.getAttribute("title") || "", value: [] };
        const multiRowTable = multiRowTableContainer?.firstChild as HTMLTableElement;
        const multiRowTableHeader = multiRowTable?.rows[0];
        for (let i = 1; i < multiRowTable.rows.length; i++) {
          const currentRow = {};
          for (let j = 0; j < multiRowTableHeader.cells.length - 1; j++) {
            const currentCell = multiRowTable.rows[i].cells[j].firstChild as HTMLTextAreaElement;
            currentRow[multiRowTableHeader.cells[j].innerText] = currentCell.value;
          }
          (multiRowTableResponse.value as ObjectAny[]).push(currentRow);
        }
        submission.splice(4, 0, multiRowTableResponse);
        if (doPrint) {
          // Convert image data to base64
          const imageBase64 = await loadImage().then((img: any) => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0);
            return canvas.toDataURL("image/png");
          });
          await Promise.all([
            printToPDF(submission, imageBase64),
            printToDocx(submission, imageBase64),
          ]);
        }
        // Submit question responses
        let urlParam = "?";
        submission.forEach((s, idx)=> {
          if (idx === 0) {
            urlParam = urlParam.concat(`${s.title}=${s.value}`);
          } else if (Array.isArray(s.value)) {
            const paramArray = (s.value as ObjectAny[]).map(v => (`${s.title}[]=${JSON.stringify(v)}`));
            urlParam = urlParam.concat(`&${paramArray.join("&")}`);
          } else {
            urlParam = urlParam.concat(`&${s.title}=${s.value}`);
          }
        });
        window.open(`/form-submission/dashboard/${dashboardID}${urlParam}`, "_blank");
        loader.remove(); // end loader
      });
    });
  }
};

const PrintChecklistsInjection = () => {
  const downloadChecklistsButtons = document.querySelectorAll(".seer-custom-form-checklists-download");
  if (downloadChecklistsButtons.length) {
    downloadChecklistsButtons.forEach(button => {
      button.addEventListener("click", async () => {
        // attach loader style during submit (see src/app/component/LoaderGlobal.tsx)
        const loader = document.createElement("style");
        document.head.appendChild(loader);
        loader.appendChild(document.createTextNode(`
          body #loader-global { display: block !important; }
          body #loader-global-backdrop { display: block !important; }
        `));
        // Construct checklists
        const checklists: Checklist[] = [];
        const checklistNodes = document.querySelectorAll(".seer-custom-checklist");
        if (checklistNodes.length) {
          checklistNodes.forEach(node => {
            const checklist = {} as Checklist;
            checklist["title"] = node.getAttribute("title") || "";
            const checklistItemNodes = Array.from(node.children);
            if (checklistItemNodes.length) {
              const items: ChecklistItem[] = [];
              checklistItemNodes.forEach(itemNode => {
                const itemChildrenNodes = Array.from(itemNode.children);
                const checkboxNode = itemChildrenNodes.find(child => child.classList.contains("seer-checkbox")) as HTMLInputElement;
                const checked = checkboxNode.checked;
                const labelNode = itemChildrenNodes.find(child => child.classList.contains("seer-checkbox-label")) as HTMLElement;
                const label = labelNode?.innerText;
                items.push({ label, checked });
              });
              checklist["items"] = items;
            }
            checklists.push(checklist);
          });
        }
        // print checklists to pdf
        try {
          await insertScript("PDFKit", { type: "text/javascript", src: "https://cdn.jsdelivr.net/npm/pdfkit@0.15.0/js/pdfkit.standalone.min.js" });
          await insertScript("blob-stream", { type: "text/javascript", src: "https://github.com/devongovett/blob-stream/releases/download/v0.1.3/blob-stream.js" });
          const doc = new window["PDFDocument"]();
          const stream = doc.pipe(window["blobStream"]());
          doc.initForm();
          doc.fontSize(26);
          doc.font("Helvetica-Bold").text("Checklist of actions", 70).moveDown(0.4);
          doc.fontSize(14);
          doc.font("Helvetica").text("The following checklist can be used as a starting point to consider actions that could be applied to your policy, program or service to improve gender equality. This is in no way an exhaustive list and you will need to consider actions in the context of the work that you are doing.", 70).moveDown(1);
          checklists.forEach((checklist, checklistIdx) => {
            const { title, items } = checklist;
            doc.fontSize(18);
            doc.font("Helvetica-Bold").text(title, 70).moveDown(0.5);
            doc.fontSize(14);
            items.forEach((item, itemIdex) => {
              const { checked, label } = item;
              doc.formCheckbox(`checkbox-${checklistIdx}-${itemIdex}`, 70, doc.y, 14, 14, {
                backgroundColor: "#cfcfcf",
                readOnly: true,
              });
              if (checked) { // Workaround: Display another checkbox inside the current one when it is checked, as PDFKit lacks support for checkbox states in the document.
                doc.formCheckbox(`checkbox-${checklistIdx}-${itemIdex}`, 73, doc.y + 3, 8, 8, {
                  backgroundColor: "#000000",
                  readOnly: true,
                });
              }
              doc.font("Helvetica").text(label, 90, doc.y).moveDown(0.5);
            });
            doc.moveDown(1);
          });
          doc.end();
          stream.on("finish", () => {
            const url = stream.toBlobURL("application/pdf");
            const a = document.createElement("a");
            a.href = url;
            a.download = "Checklist of actions.pdf";
            a.click();
          });
        } catch (e) {
          console.error("Download checklists PDF failed", e);
        }
        loader.remove(); // end loader
      });
    });
  }
};

export const injectDPACCustomizations = (dashboardID: number) => {
  CustomFormDropdownInjection();
  CustomFormMultiRowInjection();
  CustomFormAccordionInjection();
  PrintResponseEventInjection(dashboardID);
  // PrintChecklistsInjection();
};
