import * as React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { inject } from "mobx-react";
import { Container as SUIContainer, Message, Icon } from "semantic-ui-react";
import { TemplateTable } from "pages/DatasetTemplateTableDataModerator/includes/TemplateTable";
import { Schema, compileTableCompleteness } from "common/helpers/dataset";
import Store from "common/store";
import { ShowLoaderGlobal } from "component/LoaderGlobal";

interface IContributorDatasetTemplateTable {
  store?: Store;
}

export const Container = (props: IContributorDatasetTemplateTable): JSX.Element => {
  const { store } = props;
  const { contributor_token } = store!;
  const { templateID, tableID }: any = useParams();
  const history = useHistory();
  const [data, setData]: any = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { dataset_template_table: table, error: dataError } = data || {};
  const schema: Schema = table?.schema || null;

  const reloadData = async () => {
    setLoading(true);
    const next: any = await store!.dataset.contributorDatasetTemplateTableGet(tableID);
    setData(next);
    setLoading(false);
  };
  const updateData = async (jsonData) => {
    setLoading(true);
    const completeness = compileTableCompleteness(jsonData);
    const success: any = await store!.dataset.contributorDatasetTemplateTableUpdate(tableID, { data: JSON.stringify(jsonData), completeness });
    setLoading(false);
    if (!success) {
      return alert("Failed to save data. Try again later.");
    }
    reloadData();
  };
  React.useEffect(() => {
    if (contributor_token && !data) {
      reloadData(); // reload data for table if tableID has changed
    }
    if (!contributor_token) {
      // push to template contribution page if no token set so user can log in
      history.push(`/dataset-contribution/${templateID}`);
    }
  }, [tableID, contributor_token]);

  return (
    <SUIContainer>
      {loading && <ShowLoaderGlobal />}
      {dataError && (
        <Message error content={dataError} />
      )}
      {!dataError && table && (
        <div>
          <h1 className="text-secondary">Dataset Contributor</h1>
          <h2 className="mt-0">{table.name}</h2>
          <Link to={`/dataset-contribution/${templateID}`} className="text-primary">
            <small><Icon name="arrow left" className="mr-2" />Back to tables list</small>
          </Link>
          <h3 className="mb-2">Editing Table Data</h3>
          <TemplateTable
            schema={schema}
            rawData={table.data || null}
            handleSave={updateData}
            handleCancel={() => history.push(`/dataset-contribution/${templateID}`)}
          />
        </div>
      )}
    </SUIContainer>
  );
};

export const ContributorDatasetTemplateTable = inject("store")(Container);
