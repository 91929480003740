import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";
import { Builder } from "common/store/builder";
import { database } from "common/api";

interface IExplorePreviewTab {
  id: string | undefined;
  suitcaseId: any;
  insightbuilder?: any;
  builder?: Builder;
};

const ExplorePreviewTabComponent = ({ id, suitcaseId, insightbuilder, builder }: IExplorePreviewTab): JSX.Element => {
  const [suitcaseName, setSuitcaseName] = React.useState();
  const { leftTabFolded } = builder!.ui;

  const explorePreviewTips = [
    {
      show: true,
      message: "Preview how your insight will look here before you save.",
    },
    {
      show: true,
      message: "Click on the title to edit your Insight name.",
    },
    {
      show: true,
      message: "Click on the summary to use the editor and further describe your Insight.",
    },
    {
      // Hide this tip for Editing Insight
      show: id ? false : true,
      suitcaseName: suitcaseId ? suitcaseName : "Draft Insight",
      message: "When saved your Insight will be stored in the",
    },
  ];

  const getSuitcase = async (suitcaseId) => {
    const res: any = await database.get(`suitcases/${suitcaseId}`, "", insightbuilder.parent.token!);
    if (res.body) {
      setSuitcaseName(res.body.data.suitcase.name);
    }
  };

  React.useEffect(() => {
    if (suitcaseId) {
      getSuitcase(suitcaseId);
    }
  }, []);

  return (
    <>
      {/* Tab container */}
      <div style={{ minHeight: 485, margin: 20 }}>
        {
          !leftTabFolded &&
          <h3 className="ff-primary text-secondary">Insight tips</h3>
        }
        {
          explorePreviewTips.map((tip, index) => {
            if (!tip.show) {
              return <></>;
            }

            return (
              leftTabFolded ?
              <div
                key={index}
                className="mb-4 bg-light rounded-3 cursor-pointer d-flex align-items-center justify-content-center"
                style={{ height: 40 }}
                onClick={() => builder!.ui.setLeftTabFolded(!leftTabFolded)}
              >
                <Icon
                  name="info circle"
                  color="grey"
                  className="mr-0"
                  style={{ marginTop: -5 }}
                />
              </div>
             :
              <div key={index} className="d-flex bg-light ff-primary rounded-3 p-3 mb-4">
                <Icon
                  name="info circle"
                  size="small"
                  color="grey"
                  className="m-1"
                />
                <p className="fs-1000 text-muted ml-1">
                  {
                    tip.suitcaseName ?
                    <>
                      {tip.message} <p className="fw-600 d-inline">{tip.suitcaseName}</p> suitcase.
                    </>
                    : tip.message
                  }
                </p>
              </div>
            );
          })
        }
      </div>
    </>
  );
};

export const ExplorePreviewTab = inject((stores: any) => ({
  insightbuilder: stores.store.insightbuilder,
  builder: stores.store.builder,
}))(observer(ExplorePreviewTabComponent));
