import styled from "styled-components";
import { Dropdown, Popup } from "semantic-ui-react";
import { Strong } from "../../../common/styledComponents/typography";
import { COLORS } from "component/UI/common";

export const Gap = styled.div`
  display: block;
  height: 10px !important;
  width: 20px;
`;

export const Title = styled(Strong)`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const VTitle = styled.div`
  background: #919191;
  border-left: 1px solid #c3c3c3 !important;
  border-right: 1px solid #c3c3c3 !important;
  width: 100%;
  color: white;
  padding: 10px 10px 10px 12.5px;
  line-height: 15px;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  z-index: -1;
`;

export const DD = styled(Dropdown)<{ ready?: boolean }>`
  border: 1px solid #c3c3c3 !important;
  min-height: 40 !important;
  display: block !important;
  height: auto !important;
  font-size: 14.5px !important;
  color: green !important;
  padding-right: 50px !important;
  &.has-action [role="option"]:first-child > span.text {
    color: ${COLORS.red500} !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
  }
  &:not(.subcat):not(.subcat-viz) {
    border-radius: 4px !important;
  }
  &.is-measured-quantity-sub {
    border-radius: 4px !important;
  }
  .menu {
    .item {
      .text {
        color: #2d2d2d !important;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
  .default {
    color: #919191 !important;
    opacity: 1;
  }
  .text[role="alert"] {
    width: 100%;
    line-height: 20px !important;
    font-size: 14.5px !important;
    letter-spacing: 0.05em !important;
    color: rgba(0, 0, 0, 0.87);
    .default-text {
      color: #919191 !important;
      letter-spacing: 0 !important;
    }
    .volume-count {
      position: absolute;
      right: 34px;
      color: #919191 !important;
      font-weight: bold !important;
      font-size: 12px !important;
      font-family: Open Sans !important;
    }
  }
  .label {
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 15px !important;
    color: white !important;
    border-radius: 4px;
    padding: 6px 8px 7px 8px !important;
    margin: 0.5px 0;
    background-color: ${COLORS.red500};
    box-shadow: none !important;
    &:hover {
      color: white !important;
    }
    &:active {
      background: ${COLORS.red500} !important;
      color: white !important;
    }
  }
  .dropdown {
    &:before {
      ${p => p.ready && "content: \"\" !important;"}
    }
    &.icon {
      color: #404040 !important;
      .volume-count {
        font-weight: bold;
        padding-left: 3px;
        font-family: Open Sans !important;
      }
    }
    &.light {
      color: #cccccc !important;
    }
  }
  ${p => (p.selected ? COLORS.red500 : "#919191")};
`;

export const DCarret = styled.i`
  background-color: white;
  width: 18px;
  height: 18px;
  position: absolute;
  display: none;
  right: 7px;
  top: 46px;
  color: #919191;
  font-size: 15px;
  z-index: 1 !important;
  &:before {
    z-index: 1 !important;
  }
`;

export const Headline = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: #592d5f;
  margin-bottom: 15px;
`;

export const StylePopup = styled(Popup)`
  background-color: #2d2d2d !important;
  :before {
    background-color: #2d2d2d !important;
  }
`;
