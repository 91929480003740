import * as React from "react";
import { useLocation } from "react-router-dom";
import { Dashboard } from "pages/Dashboard/Dashboard";

const removeColorsStyleHtml = `
  <style>
    html body { background: none !important; }
    html body div[role="application"] > div[class^="Container"] { background: none !important; }
    html body div[role="main"] { background: none !important; }
    html body div[role="main"] > main { background: none !important; }
  </style>
`;

export const EmbedDashboard = (): JSX.Element => {
  const dashRef: any = React.useRef(null);
  const location = useLocation();
  const { pathname } = location;
  // send message to parent to scroll to top of page on pathname changes
  React.useEffect(() => window.parent.postMessage({ seerDataMessage: "scroll top" }, "*"), [pathname]);
  // send dashboard resize events
  React.useEffect(() => {
    if (dashRef.current) {
      const observer = new ResizeObserver(entries => {
        if (entries.find(entry => entry.target === dashRef.current)) {
          // 90 for header padding, 20 to pass iframe border if need be
          window.parent.postMessage({ seerDataMessage: "embed iframe resize", seerDataBody: dashRef.current.scrollHeight + 90 + 20 }, "*");
        }
      });
      observer.observe(dashRef.current);
    }
  }, [dashRef]);
  return (
    <div ref={dashRef}>
      <Dashboard />
      <div dangerouslySetInnerHTML={{ __html: removeColorsStyleHtml }} />
    </div>
  );
};
