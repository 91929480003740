import styled from "styled-components";

export const Container = styled.div`
  font: normal normal ${props => props.theme.font.size.medium} / 1.3 ${props => props.theme.font.family};
  background-color: ${props => props.theme.color.background};
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

export const Header = styled.header`
  flex-basis: auto;
`;

export const Body = styled.div`
  display: flex;
  flex: 2 90%;
  background-color: whitesmoke;
`;

export const Main = styled.main`
  flex: 2 0px;
  padding: 90px 0 20px;
  background-color: whitesmoke;
  margin: 0 auto;
  max-width: 100%;
`;
