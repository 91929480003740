import React = require("react");
// -=-=-=-=Store=-=-=-=-=-=-
import { inject, observer } from "mobx-react";
import Store from "common/store";
// -=-=-=-=Libraries=-=-=-=-
import { Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
// -=-=-=-=Styles-=-=-=-=-=-
import {
  StyleOrganisation,
  StyleUserInfo,
  StyleTile,
  StylePendingInviteContainer,
} from "./addMember.style";
import { Label } from "component/UI/Label";
import { COLORS } from "component/UI/common";

export const StylePendingAvatarWrapper = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

const PendingInvites: React.FC<{ store: Store }> = ({ store }) => {
  const handleUpdate = (value: string, user_id: number) => {
    value === "remove" && store.removeInvite(user_id);
  };

  const actionButtonOptions: any = [{ key: 2, text: "Remove", value: "remove" }];

  return (
    <>
      <StylePendingInviteContainer>
        {store.user!.group.invitations.map((pending, i) => (
            <StyleTile key={i}>
              <StyleUserInfo>
                <div style={{ display: "flex" }}>
                  <StylePendingAvatarWrapper>
                    <Icon name="send" size="large" style={{ color: COLORS.aqua500 }} />
                    <span className="sr-only">Paper plane icon</span>
                  </StylePendingAvatarWrapper>
                  <div>
                    <Label colorConfig="aqua">INVITE SENT</Label>
                    <StyleOrganisation>{pending.email} </StyleOrganisation>
                  </div>
                </div>
                <Dropdown icon="ellipsis vertical" direction="left" style={{ color: COLORS.grey900, fontSize: 18 }}>
                  <Dropdown.Menu>
                    {actionButtonOptions.map((item, idx) => {
                      const { text, value } = item;
                      return <Dropdown.Item key={`${text}-${idx}`} text={text} onClick={() => handleUpdate(value, pending.id as number)} />;
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </StyleUserInfo>
            </StyleTile>
          ))}
      </StylePendingInviteContainer>
    </>
  );
};

export default inject("store")(observer(PendingInvites));
