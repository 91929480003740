import * as React from "react";
import { observer } from "mobx-react-lite";
import { Builder } from "common/store/builder";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import { inject } from "mobx-react";
import { ChartDraggableLists } from "./ChartDragableList";
import { StyleTitle } from "component/insightBuilder/insightBuilderStyles/chart.style";
import { getCombinedDims } from "common/helpers/data";
import { canChartCalculation } from "common/helpers/explore";
import { isALLChartTablesCal } from "common/helpers/chart";

interface IExploreChartAdvanced {
  builder?: Builder;
};

// TODO: Clean up unused code once certain all logic has been handled
export const ExploreChartAdvancedComponent = ({ builder }: IExploreChartAdvanced): JSX.Element => {
  // const [ tabShow, setTabShow ] = React.useState("tables");
  const { tables, chartTables, chart } = builder!;
  const isMetricChart = chart.type.includes("Metric");
  // const generateChartsDisabled = chartKey.dimension === "" || chartXAxisArray.length === 0;

  // const advancedDropdownOptions = chartVariables.map((item, index) => ({
  //   key: index,
  //   text: item.dimension,
  //   value: index,
  // }));

  // React.useEffect(() => {
  //   if (chartTables.length === 0 && (chartKey.values.length > 0 || chartXAxisArray.length > 0 || chartSeries.values.length > 0)) {
  //     setTabShow("data");
  //   } else {
  //     setTabShow("table");
  //   }
  // }, [chartTables]);

  return (
    <>
      <StyleTitle>Chart Data</StyleTitle>
      <p className="text-secondary fs-1125 fw-600">Customise the structure of your charts below</p>

      <div className="mt-4 mb-5">
        {
          tables.filter(table => table.type === "calc" || (table.results && table.results.length > 0)) // Hide uncompleted tables
          .map(table => {
            const { id, name, graph, type } = table;
            // determine whether a calculation can be displayed at all with the current configuration
            const calculationUnavailable = type !== "calc"
              ? false
              : !canChartCalculation(builder!.columns, isALLChartTablesCal(chartTables) ? builder!.columns : builder!.chartXAxisArray, !!builder!.chartSeries?.length);
            return (
              <Popup
                disabled={!calculationUnavailable}
                trigger={(
                  <div className="d-flex align-items-center mb-3">
                    <Checkbox
                      toggle
                      checked={graph}
                      className="mr-2"
                      size="small"
                      onChange={() => builder!.updateTableGraph(id)}
                      disabled={calculationUnavailable}
                    />
                    <p className={`fs-1125 fw-600 ${calculationUnavailable ? "text-muted" : ""}`} style={{ flex: 1 }}>
                      {type === "calc" && <Icon name="calculator" className="mr-2" />}{name}
                    </p>
                  </div>
                )}
              >
                <p>The current chart configuration is not valid for this calculation.</p>
                <p>A calculation can only be charted when all of the following is true:</p>
                <ul>
                  <li>Primary Axis configuration matches that of the Rows configuration from the table step.</li>
                  <li>Series configuration is empty as calculation's cannot be split by series.</li>
                </ul>
              </Popup>
            );
          })
        }
        <div className="d-flex mt-2 text-dark">
          <Icon name="info circle" className="mr-2 fs-1000" /><p className="fs-0875">Charts will be reset if you toggle on/off tables.</p>
        </div>
        {isMetricChart && (
          <div className="d-flex mt-2 text-dark">
            <Icon name="info circle" className="mr-2 fs-1000" /><p className="fs-0875">The Metric chart will show both result tables and calculation tables together when there are less than two multi-category variables in any result table rows and columns</p>
          </div>
        )}
      </div>

      {getCombinedDims(tables.filter(table => table.type === "result").flatMap(t => [...t.rows, ...t.columns])).length >= 2
        && !builder!.isValidSimplePieChart()
        && !isMetricChart
        && <ChartDraggableLists />
      }

      {/* TODO: Remove sections below once certain that all previous logic has been handled */}

      {/* <p className="text-secondary fs-1125 fw-600">Select variables to create customised charts</p>
      <h3 className="text-secondary mb-1">
        {chart.type.includes("bar") ? "Bar" : chart.type.includes("Line") ? "Line" : "Pie"}
      </h3>
      <p className="fs-1000 text-dark"><b>Mandatory.</b> You are only able to select one variable.</p>
      <Dropdown
        placeholder='Select and search'
        fluid
        search
        selection
        clearable
        onChange={(_e, val) => {
          console.log(val);
          builder!.updateTableGraph();
          if (val.value === "") {
            builder!.setChartKey();
          } else {
            const selectedItem = chartVariables[Number(val.value)];
            console.log(selectedItem);
            builder!.setChartKey(selectedItem);

            if (_.isEqual(selectedItem, chartSeries)) {
              builder!.setChartSeries();
            }

            if (_.some(chartXAxisArray, selectedItem)) {
              const newChartXAxisLabelObjArray = _.differenceWith(chartXAxisArray, [selectedItem], _.isEqual);
              builder!.setChartXAxisArray(newChartXAxisLabelObjArray);
            }
          }
        }}
        value={chartTables.length > 0 ? undefined : _.findIndex(chartVariables, chartKey)}
        options={advancedDropdownOptions}
      />
      <h3 className="text-secondary mb-1">
        XAxis
      </h3>
      <p className="fs-1000 text-dark"><b>Mandatory.</b> You are able to select multiple variables.</p>
      <Dropdown
        placeholder='Select and search'
        fluid
        search
        selection
        multiple
        size="small"
        onChange={(_e, val) => {
          console.log(val);
          builder!.updateTableGraph();
          if (Array.isArray(val.value)) {
            const selectedItems = val.value?.map(index => chartVariables[Number(index)]);
            console.log(selectedItems);
            builder!.setChartXAxisArray(selectedItems);

            selectedItems.forEach(selectedItem => {
              if (_.isEqual(selectedItem, chartKey)) {
                builder!.setChartKey();
              }

              if (_.isEqual(selectedItem, chartSeries)) {
                builder!.setChartSeries();
              }
            });
          }
        }}
        value={chartTables.length > 0 ? undefined : chartXAxisArray.map(item => _.findIndex(chartVariables, item))}
        options={advancedDropdownOptions}
      />
      <h3 className="text-secondary mb-1">
        Series
      </h3>
      <p className="fs-1000 text-dark">Show multiple charts based on "Series" selection</p>
      <Dropdown
        placeholder='Select and search'
        fluid
        search
        selection
        clearable
        onChange={(_e, val) => {
          console.log(val);
          builder!.updateTableGraph();
          if (val.value === "") {
            builder!.setChartSeries();
          } else {
            const selectedItem = chartVariables[Number(val.value)];
            console.log(selectedItem);
            builder!.setChartSeries(selectedItem);

            if (_.isEqual(selectedItem, chartKey)) {
              builder!.setChartKey();
            }

            if (_.some(chartXAxisArray, selectedItem)) {
              const newChartXAxisLabelObjArray = _.differenceWith(chartXAxisArray, [selectedItem], _.isEqual);
              builder!.setChartXAxisArray(newChartXAxisLabelObjArray);
            }
          }
          builder!.setChartLayout(2);
        }}
        value={chartTables.length > 0 ? undefined : _.findIndex(chartVariables, chartSeries)}
        options={advancedDropdownOptions}
      /> */}
      {/* <div style={{ height: 20 }}></div>
      <Button
        disabled={generateChartsDisabled}
        className="bg-secondary text-white bg-hover-red"
        onClick={() => {
          builder!.updateTableGraph();
          builder!.getCustomCharts();
          }}
          size="small"
        >
        Generate charts
      </Button> */}

    </>
  );
};

export const ExploreChartAdvanced = inject((stores: any) => ({
  builder: stores.store.builder,
}))(observer(ExploreChartAdvancedComponent));
