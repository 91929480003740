import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Table, Image, Label } from "semantic-ui-react";
import { Avatar } from "component/Avatar";
import { SeerButton } from "./includes/PartnerOrganisationsButtons";
import { getMixpanel } from "common/api";

interface IPartnerOrganisationsList {
  store?: any;
};

const PartnerOrganisationsListComponent = ({ store }: IPartnerOrganisationsList): JSX.Element => {
  const [ selectedGroupID, setSelectedGroupID ] = React.useState<number | null>(null);
  const [ selectedGroupMembers, setSelectedGroupMembers ] = React.useState<any[] | null>(null);

  const { partnerOrganisations } = store.group;

  const showGroupMembers = groupID => {
    setSelectedGroupID(groupID);
    setSelectedGroupMembers(partnerOrganisations.find(partner => partner.id === groupID).users);
    getMixpanel(store).track("Show Members", { "Page": "Settings > Organisation > Current Partners" });
  };

  const hideGroupMembers = () => {
    setSelectedGroupID(null);
    setSelectedGroupMembers(null);
    getMixpanel(store).track("Hide Members", { "Page": "Settings > Organisation > Current Partners" });
  };

  React.useEffect(() => {
    getMixpanel(store).track("Page view", { "Page": "Settings > Organisation > Current Partners" });
  }, []);

  return (
    <>
      {
        partnerOrganisations.length > 0 ?
        <>
          <Table basic="very">
            <Table.Header className="fs-0875">
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Members</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className="fs-1125">
              {
                partnerOrganisations.map(orgnisation => {
                  const { id, logo, name, owner, users } = orgnisation;
                  return (
                    <>
                      <Table.Row>
                        <Table.Cell collapsing>
                          <Image src={logo || "/assets/seer-default-company-logo.png"} size="mini" circular style={{ width: 40 }} />
                        </Table.Cell>
                        <Table.Cell className="fw-700 text-secondary">{name || "-"}</Table.Cell>
                        <Table.Cell>{users.length}</Table.Cell>
                        <Table.Cell collapsing>
                          {
                            selectedGroupID && selectedGroupID === id ?
                            <SeerButton
                              category="secondary"
                              label="Hide Members"
                              icon="angle up"
                              onClick={() => hideGroupMembers()}
                            /> :
                            <SeerButton
                              category="secondary"
                              label="Show Members"
                              icon="angle down"
                              onClick={() => showGroupMembers(id)}
                            />
                          }
                        </Table.Cell>
                      </Table.Row>
                      {
                        selectedGroupMembers && selectedGroupID === id &&
                        selectedGroupMembers.map(member => {
                          const { id, admin, avatar, first_name, last_name, email } = member;
                          return (
                            <Table.Row>
                              <Table.Cell></Table.Cell>
                              <Table.Cell colSpan="3">
                                <div className="d-flex flex-row align-items-center">
                                  <Avatar name={`${first_name} ${last_name}`} image={avatar} style={{ marginRight: 20 }} />
                                  <div>
                                    <p className="mb-0 fw-700 text-secondary d-inline-block">{`${first_name} ${last_name}`}</p>
                                    {
                                      admin &&
                                      <Label className="text-medium ml-2" style={{ padding: "5px 10px !important"}} horizontal>
                                        {id === owner.id ? "Owner" : "Admin"}
                                      </Label>
                                    }
                                    <p>{email}</p>
                                  </div>
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      }
                    </>
                  );
                })
              }
            </Table.Body>
          </Table>
        </> :
        <p className="fs-1125 text-muted">No partner organisations yet.</p>
      }
    </>
  );
};

export const PartnerOrganisationsList = inject((stores: any) => ({
  store: stores.store,
}))(observer(PartnerOrganisationsListComponent));
