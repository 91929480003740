import * as React from "react";
import styled from "styled-components";
import { useSelect } from "downshift";
import { Icon } from "semantic-ui-react";

const Wrapper = styled.div`
  >div:focus {
    box-shadow: 0px 0px 5px 3px #5E9ED6;
  }
`;

export const WCAGListBox = (props: any) => {
  const { options, onSelectOpt } = props;
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({ items: options, initialSelectedItem: options[0] });

  React.useEffect(() => {
    onSelectOpt(selectedItem);
  }, [selectedItem]);

  return (
    <Wrapper className={`ui dropdown mb-4 ${isOpen ? "active visible" : ""}`}>
      <label {...getLabelProps()} className="sr-only">Sort by</label>
      <div {...getToggleButtonProps()} className="fw-700" style={{ fontSize: 18 }} tabIndex={1}>
        Sort by <Icon name="chevron down" />
      </div>
      <ul {...getMenuProps()} className={`menu transition ${isOpen ? "visible" : ""}`}>
        {options.map((item, index) => (
          <li
            key={`${item}${index}`}
            style={{
              fontWeight: selectedItem === item ? "bold" : "normal",
              backgroundColor: highlightedIndex === index ? "#f3f3f3" : undefined,
            }}
            className={`item ${selectedItem === item ? "active" : ""}`}
            {...getItemProps({ item, index })}
          >
            {item}
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};
