import * as React from "react";
import { StyleInput } from "./includes/InlineEdit.style";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSave: (value) => void;
}

export const InlineEdit = (props: IProps): JSX.Element => {
  const { value, onSave, ...rest } = props;
  const [editingValue, setEditingValue] = React.useState(value);
  const [cancelEdit, setCancelEdit] = React.useState(false); // Cancel edit and reset to previous value when uses press "Escape"
  const inputRef: any = React.useRef(null);

  const onChange = (e) => {
    if (e.target.value.length <= 99) {
      setEditingValue(e.target.value);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    } else if (e.key === "Escape") {
      setEditingValue(value);
      setCancelEdit(true);
    }
  };

  const onBlur = (e) => {
    if (e.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      onSave(editingValue);
    }
  };

  React.useEffect(() => {
    const input = inputRef.current;
    input.style.width = "1px";
    input.style.width = `${input.scrollWidth + 4}px`;
  }, [editingValue]);

  React.useEffect(() => {
    setEditingValue(value);
    setCancelEdit(false);
  }, [value]);

  React.useEffect(() => {
    const input = inputRef.current;
    input.blur();
  }, [cancelEdit]);

  return (
    <StyleInput
      {...rest}
      type="text"
      ref={inputRef}
      value={editingValue}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
