import * as React from "react";
import { Button } from "../common/styledComponents/elements";
import { useHistory } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

const SUPPORT_EMAIL_ADDRESS = "seer.support@seerdata.com.au";

const Unsubscribe = (props: any) => {
  const { token, store } = props;
  const history = useHistory();
  const [user, setUser] = React.useState<any>();
  const [unsubscribed, setUnsubscribed] = React.useState<boolean>();

  React.useEffect(() => {
    store.notificationsProvider.getUser(token).then(user => {
      setUser(user);
    });
  }, []);

  const handleClick = async () => {
    const result = await store.notificationsProvider.unsubscribe(token);
    if (result) {
      setUnsubscribed(true);
    } else {
      setUnsubscribed(false);
    }
  };

  return (
    <>
      <h1>Unsubscribe</h1>
      {user === undefined && <p>Loading...</p>}

      {/* Invalid Token or API Error */}
      {user !== undefined && !user.id && (
        <>
          <h3>Error</h3>
          <p>We could not retrieve your subscription details. Please check the link above or try again later.</p>
          <p>You can also contact us by clicking on Support and submitting a new request.</p>
          <p>Thank you for your patience and for supporting the Seer Data journey!</p>
        </>
      )}

      {/* Unsubscribe Prompt */}
      {user && user.id && unsubscribed === undefined && (
        <>
          <p>
            <strong>Are you sure you want to unsubscribe from user activity notifications?</strong>
          </p>
          <p>
            You will still receive emails required for you to be able to login, plus emails alerting you to new features
            that may be of value.
          </p>
          <p>
            If you don't want to receive emails about new features, you can let us know by sending us an email:{" "}
            <a href={"mailto:" + SUPPORT_EMAIL_ADDRESS}>{SUPPORT_EMAIL_ADDRESS}</a>
          </p>
          <div style={{ textAlign: "center" }}>
            <Button onClick={handleClick} style={{ margin: "50px 50px" }}>
              Yes, unsubscribe
            </Button>
            <Button onClick={() => history.push("/")} style={{ margin: "50px 50px", backgroundColor: "grey" }}>
              Cancel
            </Button>
          </div>
        </>
      )}

      {/* Unsubscribe Confirmation Message */}
      {user && user.id && unsubscribed === true && (
        <>
          <p>
            You have been <strong>unsubscribed</strong> from user activity notifications.
          </p>
          <p>
            You will continue to receive other types of emails from Seer Data. If this was a mistake, you can let us know by
            sending an email to <a href={"mailto:" + SUPPORT_EMAIL_ADDRESS}>{SUPPORT_EMAIL_ADDRESS}</a>
          </p>
          <p>Thank you for your patience and for supporting the Seer Data journey!</p>
        </>
      )}

      {/* Unsubscribe Error Message */}
      {user && user.id && unsubscribed === false && (
        <>
          <h3>Unexpected Error</h3>
          <p>We could not process your action at this time.</p>
          <p>Please try again later or contact us by clicking on Support and submitting a new request.</p>
        </>
      )}
    </>
  );
};

export const UnsubscribePage = inject("store")(observer(Unsubscribe));
