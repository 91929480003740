import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";
import Store from "common/store";
import { getMixpanel } from "common/api";
import { BuilderSelector } from "pages/Builder/BuilderSelector";
import { MultiIndexTable } from "component/table/MultiIndexTable";
import { StylePillButton, StylePillLabel } from "./CensusQueryTool.style";
import { ObjectAny } from "common/helpers/types";
import { DATASET_KEY } from "../CensusQueryTool";

interface IComponent {
  store?: Store;
  builder?: any;
  hideButtons: (value) => void;
  noMaxWidth?: boolean; // @TODO - remove this when component is refactored
}

// TODO: refactor the code to create a helper map
const typeMap = {
  "What": "Topic",
  "How": "Quantity",
  "When": "Time",
  "Where": "Location",
};

// Get all variables that are "optional" or have "optional" categories and group variables by type
export const getMoreOptions = (dimensions: ObjectAny): ObjectAny => {
  // Group variables by types
  const dimensionsByType = Object.keys(dimensions).reduce((prev, curr) => {
    const dimension = dimensions[curr];
    dimension.name = curr;
    if (dimension.requirement !== "comparable") {
      prev[dimension.type] = [...prev[dimension.type] || [], dimension];
    }
    return prev;
  }, {});
  Object.keys(dimensionsByType).forEach(type => {
    const dimensionArray = dimensionsByType[type];
    // Remove "type" from "dimensionsByType" if there is no more options available for that "type"
    if (!dimensionArray.some(dim => dim.requirement === "optional") && !dimensionArray.some(dim => dim.requirement === "selected" && dim.values.some(val => val.requirement === "optional"))) {
      delete dimensionsByType[type];
    } else { // Move all "selected" before "optional"
      dimensionsByType[type].sort((a, b) => a.requirement < b.requirement ? 1 : -1);
    }
  });
  return dimensionsByType;
};

const Component = ({ store, builder, hideButtons, noMaxWidth }: IComponent): JSX.Element => {
  const [showMoreOptions, setShowMoreOptions] = React.useState<boolean>(false);
  const [selectedType, setSelectedType] = React.useState<any>(undefined);
  const moreOptions = getMoreOptions(builder.dimensions);

  // Not showing "More options" when there are more than one data point in the table or
  // there are no more available options
  const shouldShowMoreOptions = () => {
    const enoughData = builder.tables[0].results.length > 1;
    const noMoreCategoryOptions = Object.keys(moreOptions).length === 0;
    return !(enoughData || noMoreCategoryOptions);
  };

  const selectHandler = async (type, variable, addVariable?) => {
    await builder.setActiveTableId(1);
    if (addVariable) {
      await builder.selectDimension(variable, {[DATASET_KEY]: true});
    }
    setSelectedType(type);
    hideButtons(true);
    getMixpanel(store!).track("Census Query Tool > More", { "Button Click": `Add ${addVariable ? "Variable" : "Category"}`, "Variable": variable });
  };

  const clickBackHandler = () => {
    builder.saveTableQuery(1);
    builder.setActiveTableId(undefined);
    setSelectedType(undefined);
    hideButtons(false);
  };

  React.useEffect(() => {
    // Check if show "More options" or mot after mounted
    if (shouldShowMoreOptions()) {
      setShowMoreOptions(true);
    }
  }, []);

  return (
    <>
      {
        selectedType
          // Show relevant NIB selectors based on "selectedType"
          ? <>
              <p className="text-primary fw-600 cursor-pointer text-left fs-1000" onClick={clickBackHandler}>&lt; Back</p>
              <BuilderSelector selectionGroups={[selectedType]} />
            </>
          : <>
              {
                showMoreOptions &&
                <>
                  <h2 className="ff-primary fw-800 text-secondary fs-2000 mb-4">
                    Looks like you've selected a single data point. Would you like to add more variables to your table?
                  </h2>
                  {
                    Object.keys(moreOptions)?.map(type => {
                      const selectedVariables = moreOptions[type].filter(variable => variable.requirement === "selected");
                      return (
                        <div className="text-left mb-4" key={type}>
                          <h2>{`${typeMap[type]} (${selectedVariables.length}/${moreOptions[type].length})`}</h2>
                          {
                            moreOptions[type]?.map(dim => {
                              const { name, requirement, values } = dim;
                              if (requirement === "selected") {
                                const selectedCategories = values.filter(val => val.requirement === "selected");
                                return (
                                  <div key={name}>
                                    <StylePillLabel key={name} size="tiny" bg_color="#5f5f5f" text_color="#e5e5e5" circular className="mr-2 mb-2">
                                      {`${name} (${selectedCategories.length}/${values.length})`}
                                    </StylePillLabel>
                                    {
                                      selectedCategories?.map(val => (
                                        <StylePillLabel key={val} size="tiny" bg_color="#919191" text_color="#f5f5f5" circular className="mr-2 mb-2">
                                          {val.name}
                                        </StylePillLabel>
                                      ))
                                    }
                                    {
                                      selectedCategories.length < values.length &&
                                      <span className="cursor-pointer fs-0875 fw-600 text-secondary ml-2" onClick={() => selectHandler(type, name)}>
                                        Add More<Icon name="plus" className="ml-1 mr-0" />
                                      </span>
                                    }
                                  </div>
                                );
                              } else {
                                // TODO: add limitation for the number of displayed unselected variables when we use "Census Tool" for other datasets
                                return (
                                  <StylePillButton key={name} size="tiny" className="mr-2 mb-2 mt-2" onClick={() => selectHandler(type, name, true)}>
                                    {name}<Icon name="plus" className="ml-2 mr-0" />
                                  </StylePillButton>
                                );
                              }
                            })
                          }
                        </div>
                      );
                    })
                  }
                </>
              }
              {/* Table section */}
              <h1 className="ff-primary fw-800 text-secondary fs-2000 mb-2">Insight table</h1>
              <div style={{ margin: -20 }}><MultiIndexTable editMode={false} noMaxWidth={noMaxWidth} /></div>
            </>
      }
    </>
  );
};

export const TableComponent = inject((stores: any) => ({
  store: stores.store,
  builder: stores.store.builder,
}))(observer(Component));
