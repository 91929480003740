import * as React from "react";
import { Feed } from "semantic-ui-react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Moment from "react-moment";
import styled from "styled-components";
import * as UserAvatar from "react-user-avatar";
import { useHistory } from "react-router-dom";
import { CHART_PRIMARY_COLOR } from "common/helpers/chart";
import { COLORS } from "component/UI/common";

export const StyledLink = styled.a`
  color: ${COLORS.indigo600};
  font-weight: 600;
  &:hover {
    color: ${COLORS.indigo600};
    border-bottom: 1px solid ${COLORS.indigo600};
    padding-bottom: 1px;
  }
`;

export const StyledLinkBlack = styled.a`
  color: ${COLORS.indigo600};
  font-weight: 600;
  line-height: 2em;
  &:hover {
    color: ${COLORS.indigo600};
    border-bottom: 1px solid ${COLORS.indigo600};
    padding-bottom: 1px;
  }
`;

export const StyledFeedEvent = styled(Feed.Event)`
  padding-left: 10px !important;
  padding-right: 10px !important;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: ${COLORS.indigo100} !important;
  }
`;

const defaultDisplayLimit = 100;

export const NotificationFeed = inject("store")(
  observer((props: any) => {
    const history = useHistory();
    const { store, handleClose, displayLimit } = props;
    const { notifications } = store;

    const unreadNotificationIds = notifications
      .slice(0, displayLimit ?? defaultDisplayLimit)
      .reduce((acc, notification) => {
        !notification.viewed && acc.push(notification.id);
        return acc;
      }, []);

    // Add a delay of a few seconds before marking notifications as read so that the unread count in the header does not change abruptly
    if (unreadNotificationIds.length > 0) {
      setTimeout(() => store.notificationsProvider.markNotificationsAsRead(unreadNotificationIds), 2000);
    }

    const handleClick = (target, e) => {
      history.push(target, { background: history.location });
      // TODO: This only for invitee gets invitation notification and clicks the link to go to Pending Invitations. We do refresh to get the updated data. We can refactor to only call "/users/auth" to get the update data instead when we get time
      window.location.reload();
      e.preventDefault();
      handleClose(e);
    };

    return notifications.length > 0 ? (
      <>
        <Feed>
          {notifications.slice(0, displayLimit ?? defaultDisplayLimit).map(notification => (
            <StyledFeedEvent onClick={e => handleClick(notification.link, e)} key={notification.id}>
              <Feed.Label>
                <UserAvatar
                  style={{ marginTop: "10px" }}
                  size={35}
                  name={notification.sender_name}
                  src={notification.sender_image}
                  color="#eeeeee"
                />
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 600, color: CHART_PRIMARY_COLOR }}>{notification.sender_name}</span> {notification.message + " "}
                  <StyledLink href={notification.link ?? ""} onClick={e => handleClick(notification.link, e)}>
                    {notification.target_name ?? ""}
                  </StyledLink>
                  .
                </Feed.Summary>
                <Feed.Meta>
                  <Feed.Date>
                    <Moment fromNow>{notification.created_at}</Moment>
                  </Feed.Date>
                </Feed.Meta>
              </Feed.Content>
            </StyledFeedEvent>
          ))}
          {displayLimit && notifications.length > displayLimit && (
            <div style={{ textAlign: "center" }}>
              <StyledLinkBlack href="/notifications" onClick={e => handleClick("/notifications", e)}>
                View all notifications
              </StyledLinkBlack>
            </div>
          )}
        </Feed>
      </>
    ) : (
      <h5>No notifications to show.</h5>
    );
  })
);
