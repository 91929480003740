import { observable, action } from "mobx";
import Store from "../store";
import { database } from "../api";

interface IColorPalette {
  id?: number;
  name: string;
  group_id: number | null;
  colors: string[];
  locked: boolean;
}
export default class ColorPalette {
  parent: Store;
  constructor(parent: Store) {
    this.parent = parent;
  }

  @observable allColorPalettes: IColorPalette[] = [];
  @observable customColorPalettes: IColorPalette[] = [];
  @observable defaultColorPalettes: IColorPalette[] = [
    {
      name: "Seer Data Indigo",
      group_id: null,
      colors: ["#5f2d5f", "#913366", "#c03e61", "#e55650", "#fb7a36", "#ffa600"],
      locked: true,
    },
    {
      name: "Seer Data Aqua",
      group_id: null,
      colors: ["#3dc3c3", "#00a8c5", "#008ddb", "#3c6ca8", "#574d88", "#5f2d5f"],
      locked: true,
    },
    {
      name: "Seer Data Modern",
      group_id: null,
      colors: ["#e74c3c", "#e67e22", "#f1c40f", "#2ecc71", "#3498db", "#9b59b6"],
      locked: true,
    },
  ];

  @action async addColorPalette(groupID: number, name: string, colors: string[], locked = false): Promise<void> {
    const res: any = await database.post(
      `groups/${groupID}/palettes`,
      { name, colors, locked },
      this.parent.token!,
    );
    const newColorPalette = res.body.data.color_palette;
    this.customColorPalettes.push(newColorPalette);
    this.allColorPalettes = [...this.customColorPalettes, ...this.defaultColorPalettes];
  }

  @action async deleteColorPalette(paletteID: number): Promise<void> {
    await database.delete(
      `palettes/${paletteID}`,
      {},
      this.parent.token!,
    );
    this.customColorPalettes.map((colorPalette, index) => {
      if (colorPalette.id === paletteID) {
        this.customColorPalettes.splice(index, 1);
      }
    });
    this.allColorPalettes = [...this.customColorPalettes, ...this.defaultColorPalettes];
  }

  @action async updateColorPalette(paletteID: number, name: string, colors: string[], locked = false): Promise<void> {
    await database.put(
      `palettes/${paletteID}`,
      { name, colors, locked },
      this.parent.token!,
    );
    this.allColorPalettes.map(colorPalette => {
      if (colorPalette.id === paletteID) {
        colorPalette.name = name;
        colorPalette.colors = [...colors];
        colorPalette.locked = locked;
        return;
      }
    });
  }
}
