import * as React from "react";
import { Helmet } from "react-helmet";
import { inject } from "mobx-react";
import { Card, Loader, Statistic } from "semantic-ui-react";
import Store from "common/store";
import { database } from "common/api";
import Protected from "component/protected";
import { StyleHeading } from "component/Suitcases/SuitcasesContent.style";

interface IInsightsRefreshStatus {
  store: Store;
}

const InsightsRefreshStatusComponent = ({ store }: IInsightsRefreshStatus ): JSX.Element => {
  const [ beingProcessedInsightsNum, setBeingProcessedInsightsNum ] = React.useState(null);
  const [ failedInsightsNum, setFailedInsightsNum ] = React.useState(null);

  const getInsightsRefreshStatus = async () => {
    const res: any = await database.get("insights-refresh-status", "", store!.token!);
    if (res.body) {
      setBeingProcessedInsightsNum(res.body.data.being_processed_insights_num);
      setFailedInsightsNum(res.body.data.failed_insights_num);
    }
  };

  React.useEffect(() => {
    getInsightsRefreshStatus();
  }, []);

  const cardItems = [
    {
      label: "Number of insights that are being processed",
      value: beingProcessedInsightsNum,
    },
    {
      label: "Number of insights that failed",
      value: failedInsightsNum,
    },
  ];

  return (
    <div className="my-0 mx-auto" style={{ width: "940px" }}>
      <Helmet>
        <title>Insights refresh status</title>
      </Helmet>
      <StyleHeading>Insights refresh status</StyleHeading>
      <p className="text-muted fs-1250 mt-3 mb-4">Refresh the page to see the latest data.</p>
      <Card.Group itemsPerRow={2}>
        {
          cardItems.map(item => {
            const { label, value } = item;
            return (
              <Card className="text-center">
                <Card.Content>
                  <Statistic size="huge" className="w-100">
                    {
                      value === null ?
                      <Loader active size="medium" inline="centered" style={{ margin: "30px auto" }} /> :
                      <Statistic.Value className="text-secondary fw-600 mb-2">{value}</Statistic.Value>
                    }
                    <Statistic.Label className="text-none fw-500">{label}</Statistic.Label>
                  </Statistic>
                </Card.Content>
              </Card>
            );
          })
        }
      </Card.Group>
    </div>
  );
};

export const InsightsRefreshStatus = Protected(inject((stores: any) => ({
  store: stores.store,
}))(InsightsRefreshStatusComponent));
