import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, List } from "semantic-ui-react";
import Store from "common/store";
import { useHistory, useParams } from "react-router-dom";
import { FileUploader } from "./includes/FileUploader";
import { COLOURS } from "pages/DatasetURLUpload/DatasetURLUpload.style";

interface Props {
  store?: Store;
  stepHandler?: Function;
}

export const Component = (props: Props) => {
  const { store } = props;
  const history = useHistory();
  const { datasetID } = useParams<{ datasetID: string }>();

  const handleFileUpload = async (files) => {
    if (files.length) {
      store!.dataset.urlupload.files = files;
      history.push(`/url-dataset-contribution/${datasetID}`);
    }
  };

  return (
    <>
      <h4>Collect New Data &gt; Upload Excel File</h4>

      <div className="py-4">
        <div className="d-flex border rounded-3 p-3 mb-4">
          <Icon name="info circle" className="text-primary mr-2" size="large" />
          <div style={{ color: COLOURS.greyText800 }}>
            <h3 className="mb-2">Data Pre-processing Format Requirements</h3>
            <List as="ol" className="mt-0">
              <List.Item as="li">First row must contain the name of each column.</List.Item>
              <List.Item as="li">Column headers must be unique.</List.Item>
              <List.Item as="li">No additional surrounding text (i.e. no footers).</List.Item>
            </List>
          </div>
        </div>
        <FileUploader fileHandler={handleFileUpload} />
      </div>
    </>
  );
};

export const NewDataSourceExcelUpload = inject("store")(observer(Component));
