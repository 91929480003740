import * as React from "react";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";

interface IButtons {
  resetClickHandler: () => void;
  backClickHandler: () => void;
  nextClickHandler: () => void;
  nextDisabled: boolean;
  nextLabel: string;
}

export const ButtonsComponent = (props: IButtons ): JSX.Element => {
  const { resetClickHandler, backClickHandler, nextDisabled, nextClickHandler, nextLabel = "Next" } = props;
  return (
    <div className="d-flex justify-content-between">
      <SeerButton
        category="tertiary"
        label="Start again"
        onClick={resetClickHandler}
      />
      <div>
        <SeerButton
          className="mb-2 mb-sm-0"
          category="secondary"
          label="Back"
          onClick={backClickHandler}
          style={{ marginRight: 10 }}
        />
        <SeerButton
          category="primary"
          label={nextLabel}
          onClick={nextClickHandler}
          disabled={nextDisabled}
        />
      </div>
    </div>
  );
};
