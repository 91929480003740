import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { SectionHeader } from "./SectionHeader";
import styled from "styled-components";
import { Avatar } from "component/Avatar";
import { DateTime } from "luxon";
import { Button } from "component/UI/Button";
import { Icon } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
import { IMember } from "common/store/suitcase";
import { webUrl } from "common/constants";
import { getMixpanel } from "common/api";
import Store from "common/store";

const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ItemCard = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
  flex: 1 1 0px;
  cursor: pointer;
  &:hover {
    box-shadow: 4px 4px 8px rgba(33, 33, 33, 0.15), -4px -4px 8px rgba(33, 33, 33, 0.15);
  }
`;

interface Props {
  store?: Store;
  type: "suitcases" | "dashboards";
  heading: string;
  items: FeaturedItem[];
  buttonClick: () => void;
}

export interface FeaturedItem {
  id: number;
  name: string;
  description?: string;
  image: string;
  owner: IMember;
  updated: string;
}

// Image component to show placeholder image when img src is invalid
const Image = (props): JSX.Element => {
  const { src } = props;
  const [imgSrc, setImgSrc] = React.useState(src);
  const handleError = () => {
    setImgSrc("/assets/homepage/homepage_featured_placeholder.png");
  };
  return (
    <img src={imgSrc} onError={handleError} className="w-100 mb-4" />
  );
};

const Component = (props: Props): JSX.Element => {
  const { store, type, heading, items, buttonClick } = props;
  return (
    <div className="mb-5">
      <SectionHeader heading={heading} />
      <ContentWrapper>
        {items.map(item => {
          const { id, name, description, image, owner, updated } = item;
          return (
            <ItemCard
              key={id}
              onClick={() => {
                window.open(`${webUrl}/${type}/${id}`, "_blank");
                getMixpanel(store!).track(`Go to Featured ${type}`, { Page: "Homepage", ID: id, Name: name });
              }}
            >
              {type === "suitcases" && (
                <>
                  <p className="fs-1375 fw-700 mb-2" style={{ color: COLORS.grey700 }}>{name}</p>
                  <p className="fs-1000 fw-400 mb-4" style={{ color: COLORS.grey500 }}>{description}</p>
                </>
              )}
              <Image src={image} />
              {type === "dashboards" && (
                <p className="fs-1375 fw-700 mb-2" style={{ color: COLORS.grey700 }}>{name}</p>
              )}
              <div className="d-flex justify-content-between align-items-center">
                <Avatar name={`${owner!.first_name} ${owner!.last_name}`} size={30} image={owner!.avatar || ""} />
                <p className="fs-1000 fw-400 mb-0" style={{ color: COLORS.grey500 }}>{DateTime.fromISO(updated).setZone("local").toFormat("DD")}</p>
              </div>
            </ItemCard>
          );
        })}
      </ContentWrapper>
      <div className="d-flex justify-content-end">
        <Button icon colorConfig="purpleCTA" labelPosition="right" className="flex-grow-1 flex-sm-grow-0" onClick={buttonClick}>
          {`View all featured ${type}`} <Icon name="arrow alternate circle right outline" />
        </Button>
      </div>
    </div>
  );
};

export const FeaturedContent = inject("store")(observer(Component));
