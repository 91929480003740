import * as React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";

import Store from "../../common/store";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { database } from "common/api";

import { Redirect, useLocation } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";

import { userExists } from "common/helpers/user";
import { COLORS } from "component/UI/common";
import { BottomSection } from "./includes/BottomSection";
import { composeValidators, mustBeValidEmail, required } from "common/helpers/finalForm";
import { Button } from "component/UI/Button";
import { FORM_ERROR } from "final-form";
import { FormHeading, FormWrapper } from "./includes/Components.style";
import { FieldInput } from "./includes/FieldInput";

interface Props {
  store: Store;
}

const Component = (props: Props): JSX.Element => {
  const { store } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const user_id = urlParams.get("id");
  const redirectUrl = urlParams.get("redirect");
  const location: any = useLocation();

  const [loginStep, setLoginStep] = useState("inputEmail");

  const onSubmit = async (values): Promise<any> => {
    const { email, verification_code } = values;
    if (loginStep === "inputEmail") {
      if (await userExists(email!)) {
        await database.post("emails", { email, type: "verification" });
        setLoginStep("inputCode");
      } else {
        return { [FORM_ERROR]: "Email does not exist" };
      }
    } else {
      const loggedIn = await store.logInUser({ email, verification_code });
      if (!loggedIn) {
        return { [FORM_ERROR]: "Incorrect verification code" };
      }
    }
  };

  React.useEffect(() => {
    // if user clicks email link to sign in login with token + id
    if (token && user_id) {
      store.logInUser({ token, user_id });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Log In</title>
      </Helmet>

      {store.authenticated && !location.state && !redirectUrl && <Redirect to="/" />}
      {store.authenticated && redirectUrl && <Redirect to={redirectUrl} />}
      {store.authenticated && location.state && <Redirect to={location.state.from.pathname} />}

      <FormWrapper>
        <FinalForm
          onSubmit={onSubmit}
          render={formRenderProps => {
            const { handleSubmit, submitting, submitError } = formRenderProps;
            return (
              <Form onSubmit={handleSubmit} id="login-user">
                <div className="mb-4 text-center text-md-left">
                  <FormHeading>Log in</FormHeading>
                  <p className="fs-1250 fw-600 my-0" style={{ color: COLORS.grey800 }}>A temporary verification code will be sent to your email.</p>
                </div>
                <Field
                  id="email-label"
                  label="Your work email"
                  name="email"
                  component={FieldInput}
                  placeholder="E.g user@company.com"
                  validate={composeValidators(required, mustBeValidEmail)}
                  disabled={loginStep === "inputCode"}
                  className="mb-4"
                />
                {loginStep === "inputCode" && (
                  <Field
                    id="login-code-label"
                    label="Verification code"
                    name="verification_code"
                    type="password"
                    component={FieldInput}
                    validate={composeValidators(required)}
                    helperText="We just sent you a temporary verification code, please check your inbox."
                    className="mb-4"
                  />
                )}
                <Button fluid colorConfig="purpleInverted" type="submit" disabled={submitting}>
                  {loginStep === "inputEmail" ? "Send temporary login code" : "Log in"}
                </Button>
                {submitError && <p role="alert" style={{ color: COLORS.red500 }}><b>{submitError}</b></p>}
              </Form>
            );
          }}
        />
      </FormWrapper>
      <BottomSection text="Don't have an account?" label="Get started for free" link="/signup" />
    </>
  );
};

export const Login = inject("store")(observer(Component));
