import * as React from "react";
import { useState } from "react";
import { Form } from "semantic-ui-react";

interface ISimpleRadioFormGroupProps {
  options: (string | number)[];
  setValue: Function;
  labelID: string;
}

export const FormRadioGroupSimple = ({ labelID, options, setValue }: ISimpleRadioFormGroupProps): JSX.Element => {
  const [selected, setSelected] = useState();

  const handleChange = (_: any, { value }: any) => {
    setSelected(value);
    setValue(value);
  };

  return (
    <>
      <Form>
        <Form.Group role="radiogroup" aria-labelledby={labelID}>
          {options.map(option => (
            <Form.Radio
              role="radio"
              aria-checked={option === selected}
              aria-label={option}
              label={option}
              value={option}
              onChange={handleChange}
              checked={option === selected}
              className="fw-600"
            />
          ))}
        </Form.Group>
      </Form>
    </>
  );
};
