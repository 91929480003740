import * as React from "react";
import { components } from "react-select";
import { Popup } from "semantic-ui-react";

export const CustomMultiValueLabel = (props): JSX.Element => {
  const { data } = props;
  return (
    <components.MultiValueLabel {...props}>
      <Popup
        content={data.label}
        position="bottom left"
        size="mini"
        trigger={<span>{data.label}</span>}
        inverted
      />
    </components.MultiValueLabel>
  );
};
