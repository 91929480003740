import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { Avatar } from "component/Avatar";
import styled from "styled-components";
import { DraftInfo, DraftLink, DraftName, AccessPopup, NumberOnFolderIcon } from "./SuitcasesElement";
import { Icon, Table } from "semantic-ui-react";
import { StyleTableCellHeading, StyleTableCellText, StyleTable, StyleTableHeader, StyleTableHeaderCell, StyleTableRow } from "./SuitcasesList.style";
import { IListProject } from "common/store/suitcase";
import { SuitcaseCheckbox } from "./SuitcaseCheckbox";

const Wrapper = styled.div`
  .header-50 {
    width: 50px;
  }
  .header-100 {
    width: 100px;
  }
  .header-125 {
    width: 125px;
  }
`;

interface ISuitcasesList {
  publicKey?: string;
  suitcases: any;
  draft?: any;
  showAccess?: boolean;
  showFolder?: boolean;
  suitcaseClickCallback?: () => void;
  showCheckboxes?: boolean;
  selectSuitcase?: Function;
  deselectSuitcase?: Function;
}

const tableHeaderItems = [
  {
    name: "Select",
    className: "header-50",
  },
  {
    name: "Folder",
    className: "header-50 cursor-default",
  },
  {
    name: "Name",
    sortBy: "name",
    className: "",
  },
  {
    name: "Updated at",
    sortBy: "updated_at",
    className: "header-125 d-none d-md-table-cell",
  },
  {
    name: "Owner",
    sortBy: "owner",
    className: "header-100 d-none d-md-table-cell",
  },
  {
    name: "Insights",
    sortBy: "insights",
    className: "header-100 d-none d-sm-table-cell",
  },
  {
    name: "Access",
    sortBy: "user_access",
    className: "header-100 d-none d-md-table-cell",
  },
];

const init = (suitcases) => (
  {
    column: null,
    tableData: suitcases,
    direction: null,
  }
);

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          tableData: state.tableData.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }
      return {
        column: action.column,
        tableData: _.sortBy(state.tableData, [action.column]),
        direction: "ascending",
      };
    case "RE_RENDER":
      return {
        column: null,
        tableData: action.newSuitcases,
        direction: null,
      };
    default:
      throw new Error();
  }
};

export const SuitcasesList = ({ draft, suitcases, showAccess, showFolder, suitcaseClickCallback, publicKey, showCheckboxes, selectSuitcase, deselectSuitcase }: ISuitcasesList): JSX.Element => {
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, suitcases, init);
  const { column, tableData, direction } = state;
  // Show Sort icon to let users know that the list is sortable. After sorting hide Sort icon.
  const [showSortIcon, setShowSortIcon] = React.useState(true);

  const handleRowClick = (id: number) => {
    suitcaseClickCallback && suitcaseClickCallback();
    history.push(`/suitcases/${id}${publicKey ? `/${publicKey}` : ""}`);
  };

  const handleNameClick = (e) => {
    suitcaseClickCallback && suitcaseClickCallback();
    e.stopPropagation();
  };

  // Trigger re-render when list changes & update list
  React.useEffect( () => {
    dispatch({ type: "RE_RENDER", newSuitcases: suitcases });
    setShowSortIcon(true);
  }, [suitcases]);

  return (
    <Wrapper>
      {/* 'Draft Insight' suitcase under Private Suitcases tab */}
      {draft && draft.length > 0 && (
        <StyleTable>
          <Table.Body>
            {draft.map((suitcase: IListProject, index: number) => {
              const { id, name } = suitcase;
              return (
                <StyleTableRow key={index} onClick={() => handleRowClick(id)}>
                  <StyleTableCellHeading className="w-35">
                    <Link
                      to={`/suitcases/${id}`}
                      className="text-secondary"
                      onClick={(e) => handleNameClick(e)}
                    >
                      <DraftName name={name} />
                    </Link>
                  </StyleTableCellHeading>
                  <StyleTableCellText>
                    <DraftInfo />
                  </StyleTableCellText>
                  <StyleTableCellText className="text-right">
                    <DraftLink />
                  </StyleTableCellText>
                </StyleTableRow>
              );
            })}
          </Table.Body>
        </StyleTable>
      )}

      {/* Suitcases list */}
      <StyleTable sortable celled fixed unstackable>
        <StyleTableHeader>
          <StyleTableRow>
            {
              tableHeaderItems.map((item, index) => {
                const { name, sortBy, className } = item;
                // Hide Owner column for Private Suitcases tab
                if (name === "Owner" && (draft || publicKey)) {
                  return null;
                }
                // Hide Access column for Private & Pre-packed Suitcases tab
                if (!showAccess && name === "Access") {
                  return null;
                }
                if (name === "Insights" && publicKey) {
                  return null;
                }
                // Hide Access column for Pre-packed Suitcases tab
                if (!showFolder && name === "Folder") {
                  return null;
                }

                // Hide checkboxes
                if (!showCheckboxes && name === "Select") {
                  return null;
                }

                // Hide label for "Folder icon"
                if (showFolder && name === "Folder" || name === "Select") {
                  return (
                    <StyleTableHeaderCell
                      className={className}
                      key={index}
                    >
                      {""}
                    </StyleTableHeaderCell>
                  );
                }
                return (
                  <StyleTableHeaderCell
                    sorted={column === sortBy ? direction : null}
                    onClick={() => {
                      dispatch({ type: "CHANGE_SORT", column: sortBy});
                      setShowSortIcon(false);
                    }}
                    className={className}
                    key={index}
                  >
                    {name}
                    {showSortIcon && <Icon name="sort" className="ml-1" />}
                  </StyleTableHeaderCell>
                );
              })
            }
          </StyleTableRow>
        </StyleTableHeader>
        <Table.Body>
          {
            tableData.length > 0 &&
            tableData.map((suitcase: IListProject, index: number) => {
              const { id, name, updated_at, owner: ownerData, number_insights_recursive: insights, user_access: access, highlight, number_child_suitcases: subSuitcases = 0 } = suitcase;
              const owner = ownerData?.first_name ? `${ownerData.first_name} ${ownerData.last_name}` : undefined;
              const { avatar } = ownerData || {};

              return (
                <StyleTableRow
                  key={index}
                  onClick={() => handleRowClick(id)}
                  // Show purple outline when the Pre-packed suitcase is highlighted
                  highlight={highlight ? highlight.toString() : "false"}
                >
                  {showCheckboxes &&
                    <StyleTableCellText onClick={(e) => e.stopPropagation()}>
                      {(access === "owner" || access === "write") &&
                        <SuitcaseCheckbox
                          onSelect={() => selectSuitcase!(id)}
                          onDeselect={() => deselectSuitcase!(id)}
                          disabled={!(access === "owner" || access === "write")}
                        />
                      }
                    </StyleTableCellText>
                  }

                  {/* Hide folder column for Private Suitcases tab */}
                  {
                    showFolder &&
                    <StyleTableCellText>
                      <NumberOnFolderIcon subSuitcases={subSuitcases} />
                    </StyleTableCellText>
                  }
                  <StyleTableCellHeading>
                    <Link
                      to={`/suitcases/${id}${publicKey ? `/${publicKey}` : ""}`}
                      className="text-secondary"
                      onClick={(e) => handleNameClick(e)}
                    >
                      {name}
                    </Link>
                  </StyleTableCellHeading>
                  {
                    updated_at &&
                    <StyleTableCellText className="d-none d-md-table-cell">
                      {DateTime.fromISO(updated_at).setZone("local").toFormat("DD")}
                    </StyleTableCellText>
                  }
                  {/* Hide Owner column for Private Suitcases tab */}
                  {
                    !publicKey && !draft && owner &&
                    <StyleTableCellText className="d-none d-md-table-cell">
                      <Avatar
                        name={owner}
                        image={avatar ? avatar : ""}
                      />
                    </StyleTableCellText>
                  }
                  {!publicKey && (
                    <StyleTableCellText className="d-none d-sm-table-cell">{insights}</StyleTableCellText>
                  )}
                  {/* Show Access column for Shared Suitcases tab */}
                  {
                    showAccess &&
                    <StyleTableCellText className="d-none d-md-table-cell">
                      <AccessPopup access={access} />
                    </StyleTableCellText>
                  }
                </StyleTableRow>
              );
            })
          }
        </Table.Body>
      </StyleTable>
    </Wrapper>
  );
};
