import * as React from "react";
import { inject, observer } from "mobx-react";
import Store from "common/store";
import Comment from "component/Comment/comment";
import { RichMentionEditor } from "component/richMentionEditor";
import { CommentsContainer, AllComments, CommentContainer, InputContainer } from "./comments.style";
import { Avatar } from "component/Avatar";

export default inject("store")(
  observer(
    class Comments extends React.Component<{ store: Store; readonly?: boolean }> {
      store = this.props.store.insight;
      user = this.store.parent.user!;
      owner = this.store.parent.user!.id;
      comments = this.store.comments;

      render() {
        return (
          <CommentsContainer>
            {/* All Comments */}

            <AllComments>
              <>
                {this.comments.mainComments.map((comment, i) => {
                  const owner = comment.user_id === this.owner;
                  const edit = comment.id === this.comments.ids.edit;
                  const reply = comment.id === this.comments.ids.reply && !this.props.readonly;
                  return (
                    <CommentContainer key={i}>
                      <Comment
                        insight={this.store}
                        comment={comment}
                        owner={owner}
                        edit={edit}
                        reply={reply}
                        depth={0}
                        store={this.store.parent}
                        readonly={this.props.readonly}
                      />
                    </CommentContainer>
                  );
                })}
              </>
            </AllComments>

            {/* New Comment */}

            {!this.props.readonly && (
                <InputContainer className="pdf-hide">
                  <Avatar
                    name={`${this.user.first_name} ${this.user.last_name}`}
                    image={this.user.avatar}
                    style={{ marginRight: "20px" }}
                  />
                  <RichMentionEditor
                    editable={true}
                    save={commentText => {
                      this.store.comments.message = commentText;
                      this.store.comments.addComment();
                    }}
                    saveButtonText="Comment"
                    clearOnSave={true}
                    mentionUsers={this.store.parent.suitcase.active!.users.filter(user => user.id !== this.user.id)}
                    mentionOrgs={this.store.parent!.group.partnerOrganisations.filter(org => {
                      const userIdsWithSuitcaseAccess = this.store.parent.suitcase.active!.users.map(user => user.id);
                      return org.users.some(orgUser => userIdsWithSuitcaseAccess.includes(orgUser.id));
                    })}
                  />
                </InputContainer>
              )}
          </CommentsContainer>
        );
      }
    }
  )
);
