import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Protected from "component/protected";
import Store from "common/store";
import SuitcaseStore from "common/store/suitcase";
import { getMixpanel } from "common/api";
import { Helmet } from "react-helmet";
import { Container, Heading } from "./includes/Homepage.style";
import { Footer } from "./includes/Footer";
import { Spotlight } from "component/Spotlight/Spotlight";
import { HelpfulLinks } from "./includes/HelpfulLinks";
import { DashboardExamples } from "./includes/DashboardExamples";
import { FeaturedContent, FeaturedItem } from "./includes/FeaturedContent";
import { useHistory } from "react-router-dom";
import { SUITCASES_TAB } from "common/helpers/suitcases";
import { RecentActivity } from "./includes/RecentActivity";
import { environment } from "common/constants";

interface Props {
  store?: Store;
  suitcaseStore?: SuitcaseStore;
};

const FEATURED_SUITCASES = [8494, 14806, 14808]; // Pre-packed suitcases ids. Remember to also update the images in "assets/featured_suitcases"
const FEATURED_DASHBOARDS = environment === "production"
  ? [719, 720, 721] // Curated dashboard ids in prod
  : [4, 67, 7]; // Curated dashboard ids in staging

const STANDARD_FILTERS = [
  { label: "Datasets", value: "datasets" },
  { label: "Suitcases", value: "suitcases" },
];
const PLUS_FILTERS = [...STANDARD_FILTERS].concat({ label: "Dashboards", value: "dashboards" });

const ONE_MONTH_TIME = 30 * 24 * 60 * 60 * 1000; // 30 days

const Component = (props: Props) => {
  const { store, suitcaseStore } = props;
  const { user, userPlan } = store!;
  const isStandardUser = userPlan === "Standard";
  // Treat user as new user when sign date is less than 30 days
  const isNewUser = new Date().getTime() - new Date(user!.created_at).getTime() < ONE_MONTH_TIME;
  const history = useHistory();
  const [featuredDashboards, setFeaturedDashboards] = React.useState<FeaturedItem[]>([]);

  const featuredSuitcases = suitcaseStore!.prepackedprojects.filter(s => FEATURED_SUITCASES.includes(s.id))
    .map(s => ({
      ...s,
      description: `${s.number_child_suitcases} Suitcases | ${s.number_insights_recursive} Insights`,
      image: `/assets/featured_suitcases/suitcase_${s.id}.jpg`,
      updated: s.updated_at,
    }));

  React.useEffect(() => {
    getMixpanel(store!).track("Page view", { Page: "Homepage" });
    suitcaseStore!.getSuitcases(null, true);
    if (!isStandardUser) {
      const getFeaturedDashboards = async () => {
        const dashboards = (await Promise.all(FEATURED_DASHBOARDS.map(async id => await store!.dashboard.getDashboard(id))))
          .map(res => {
            const { id, name, owner, updated_at } = res?.dashboard || {};
            return ({
              id,
              name,
              image: `https://seer-media.s3.ap-southeast-2.amazonaws.com/images/dashboard-thumbnails/prod/${id}.jpeg`, // Note: thumbnails won't be displayed properly for staging curated dashboards. As we only generate thumbnails for prod data
              owner,
              updated: updated_at,
            });
          });
        if (dashboards.every(dashboard => dashboard.id)) {
          setFeaturedDashboards(dashboards);
        }
      };
      getFeaturedDashboards();
    }
  }, []);

  return (
    <>
      <Container>
        <Helmet>
          <title>Seer Data - Homepage</title>
        </Helmet>
        <Heading>
          Welcome {isNewUser ? "to Seer Data" : "Back"}{user?.first_name && `, ${user?.first_name}`}!
        </Heading>
        {!isNewUser && (
          <>
            <Spotlight />
            <RecentActivity filters={isStandardUser ? STANDARD_FILTERS : PLUS_FILTERS} />
          </>
        )}
        {!isStandardUser && (
          <FeaturedContent
            type="dashboards"
            heading="Template Dashboards"
            items={featuredDashboards}
            buttonClick={() => {
              history.push("/dashboards?active_type=seer");
              getMixpanel(store!).track("Go to Featured Dashboards", { Page: "Homepage" });
            }}
          />
        )}
        <FeaturedContent
          type="suitcases"
          heading="Featured Suitcases and Insights"
          items={featuredSuitcases as FeaturedItem[]}
          buttonClick={() => {
            history.push("/suitcases");
            suitcaseStore!.setActiveSuitcasesTab(SUITCASES_TAB.PRE_PACKED);
            getMixpanel(store!).track("Go to Featured Suitcases", { Page: "Homepage" });
          }}
        />
        {(isNewUser || isStandardUser) && <DashboardExamples />}
        <HelpfulLinks />
      </Container>
      <Footer />
    </>
  );
};

export const Homepage = Protected(inject((stores: any) => ({
  store: stores.store,
  suitcaseStore: stores.store.suitcase,
}))(observer(Component)));
