import * as React from "react";
import { Container, Form, Icon } from "semantic-ui-react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { parse } from "papaparse";
import * as Excel from "exceljs/dist/exceljs.min.js";
import { difference } from "lodash";

import Protected from "component/protected";
import Store from "common/store";
import { Button } from "component/UI/Button";
import { backendUrl, environment } from "common/constants";
import { ShowLoaderGlobal } from "component/LoaderGlobal";

const s3env = environment === "production" ? "prod" : "staging";

interface Props {
  store?: Store;
}

const excelToCsvFile = async (file: File) => {
  if (file.type && file.type !== "text/csv") {
    const workbook = new Excel.Workbook();
    const inBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(inBuffer);
    const outBuffer: ArrayBuffer = await workbook.csv.writeBuffer();
    return new File([outBuffer], "data.csv", { type: "text/csv" });
  } else {
    return file;
  }
};

export const Component = (props: Props) => {
  const store = props.store!;
  const [error, setError] = React.useState<string>("");
  const [validated, setValidated] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [actionNumberList, setActionNumberList] = React.useState<string[]>([]);

  // it's ok for this to happen after page load for now, we only need it for validation so should finish well before form submission and avoiding increasing page load time
  const init = async () => {
    const actionsCsv = await fetch(`https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/dpac/${s3env}/kcs/actions.csv`, { cache: "no-cache" })
      .then(res => res.text()).catch(() => null);
    const actions: any[] = parse(actionsCsv as string, { skipEmptyLines: true, header: true }).data;
    setActionNumberList(actions.map(row => row.action_number));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setValidated(false);
    const { target } = e;
    if (!target.files?.length) {
      return setError("No file selected");
    }
    const file = await excelToCsvFile(target.files[0]);
    if (file.type !== "text/csv") {
      return setError("Only CSV/Excel files accepted");
    }
    // parse CSV and validate information is set as required
    const result: any = await new Promise(resolve => {
      parse(file, { complete: resolve, skipEmptyLines: true });
    });
    if (!result || result.errors?.length || !result.data?.length) {
      return setError("Errors parsing the attached file");
    }
    const { data } = result;
    if (data[0].join(",") !== ["action_type", "action_number", "status"].join(",")) {
      return setError("Invalid heading columns");
    }
    // row validation
    for (let i = 1; i < data.length; i++) {
      const next: any[] = data[i];
      if (!["Interim Response Action", "Commission of Inquiry Recommendation Action"].includes(next[0])) {
        return setError(`Error on row ${i + 1}. Invalid action_type value`);
      } else if (isNaN(Number(next[1]))) {
        return setError(`Error on row ${i + 1}. Invalid action_number value`);
      } else if (!["Not Commenced", "In Progress", "Completed", "In Progress - Delayed"].includes(next[2])) {
        return setError(`Error on row ${i + 1}. Invalid status value`);
      }
    }
    const actionNumbersSubmitted = data.slice(1).map((row) => row[1]);
    const missingActions = difference(actionNumberList, actionNumbersSubmitted);
    if (missingActions.length !== 0) {
      return setError(`Missing rows for action numbers: [${missingActions.join(", ")}]`);
    }
    setValidated(true);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true);
    e.preventDefault();
    const input: HTMLInputElement | null = document.querySelector('input[name="report_status"]');
    if (!input) {
      return; // this shouldn't happen
    }
    const file = await excelToCsvFile(input.files![0]);
    const formData = new FormData();
    formData.append("report_status", file);
    const ok = await fetch(`${backendUrl}/bespoke/dpac/kcs-report-upload`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-Token": store.token || "",
      },
      body: formData,
    }).then(res => res.ok).catch(() => false);
    setSubmitting(false);
    if (!ok) {
      return setError("Upload failed. Please try again later");
    }
    setSuccess(true);
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <Container>
      <h1>DPAC KCS Report Upload</h1>
      {submitting && <ShowLoaderGlobal />}
      {!success ? (
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Report Status CSV/Excel</label>
            <Form.Input
              type="file"
              name="report_status"
              accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              onChange={handleFileChange} error={error || undefined}
            />
          </Form.Field>
          <Button type="submit" disabled={!validated || submitting}>Upload Report</Button>
        </Form>
      ) : (
        <h3 className="text-secondary">Upload completed successfully <Icon name="check circle" /></h3>
      )}
    </Container>
  );
};

export const DPACKCSReportUpload = Protected(inject("store")(observer(Component)));
