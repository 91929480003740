import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Editor } from "@tinymce/tinymce-react";
import { imageUploadHandler } from "common/helpers/tinymce";

interface ITinymceEditor {
  description: string;
  initHandler: () => void;
  store?: any;
}

const TinymceEditorComponent = ({ description, initHandler, store }: ITinymceEditor): JSX.Element => {
  const onChange = content => content;

  return (
    <div style={{ marginLeft: -17, marginRight: -33 }}>
      <Editor
        // apiKey="rj7kv6bik477twb98avlvf4z5oq1642q2f93aak8bjnms5a5" // our cloud hosted account that we are not using anymore due to limits
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        licenseKey="gpl"
        id="suitcaseDescription"
        initialValue={description}
        init={{
          promotion: false,
          placeholder: "Write a summary...",
          // skin_url: "/assets/skins/ui/seer", // @TODO - delete the asset/skins dir if we are happy not to worry about skinning tinymce
          plugins: "image code link lists media table autoresize",
          toolbar:
            "formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link image media",
          file_picker_types: "image",
          images_upload_credentials: true,
          images_upload_handler: imageUploadHandler(store.token) as any,
          menubar: "edit insert format table view",
          content_style: "img { max-width: 100%; height: auto }",
          min_height: 400,
          valid_children: "+body[style]", // to ensure opening unlayer editor html works fine
          relative_urls: false,
          convert_urls: false,
        }}
        onEditorChange={onChange}
        onInit={initHandler}
      />
    </div>
  );
};

export const TinymceEditor = inject((stores: any) => ({
  store: stores.store,
}))(observer(TinymceEditorComponent));
