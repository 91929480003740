import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";
import { Button, User } from "../../common/styledComponents/elements";

export const Container = styled.div``;

export const CommentAvatar = styled(User)`
  width: 40px;
  height: 40px;
  font-size: 16px;
`;

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c3c3c3;
`;

export const DateDiv = styled.div``;

// export const EditComment = styled.div<{ right?: boolean }>`
//   margin: 0;
//   width: 17px;
//   height: 17px;
//   cursor: pointer;
//   border-radius: 30%;
//   position: relative;
//   margin-top: -13px;
//   left: ${props => (props.right ? 4 : -4)}px;
//   background-color: #dbdbdb;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   transform: scale(-1, 1);
//   float: ${props => (props.right ? "right" : "none")};
//   z-index: 10;
// `;

// export const ReplyButton = styled.button<{ owner: boolean }>`
//   width: 95px;
//   height: 40px;
//   cursor: pointer;
//   font-family: Open Sans;
//
//   font-weight: bold;
//   font-size: 15px;
//   line-height: 20px;
//   position: relative;
//   color: #c6344d;
//   float: ${props => (props.owner ? "none" : "right")};
//   z-index: 11;
// `;

export const ReplyButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  margin: 10px 10px 10px 0;
  width: 95px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  color: #c6344d;
`;

export const EditComment = styled.button`
  background-color: rgba(0, 0, 0, 0);
  margin: 10px 10px 10px 0;
  width: 80px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  color: #c6344d;
`;
export const DeleteComment = styled.button`
  background-color: rgba(0, 0, 0, 0);
  width: 95px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  color: #c6344d;
  margin: 0 0 0 0;
`;
// It doesnt like owner as a boolean here for some reason
export const EditText = styled(TextareaAutosize)<{ owner?: string }>`
  resize: none;
  background-color: #fcfcfc;
  border: 1px solid #c3c3c3;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  display: block;
  width: ${props => (props.owner === "true" ? 95.4 : 100)}%;
  margin: 2px 0 10px 0;
`;

export const UpdateComment = styled(Button)`
  float: right;
  height: 40px;
  width: 95px;
  line-height: 7px;
  padding: 0;
  margin-bottom: 6px;
  position: relative;
  z-index: 11;
`;

export const CancelEdit = styled.span`
  background-color: rgba(0, 0, 0, 0);
  margin: 15px 10px 15px 0;
  width: 95px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  color: rgb(145, 145, 145);
  z-index: 11;
  float: right;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #c6344d;
  }
`;

export const AvatarContainer = styled.div<{ owner: boolean }>`
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50px;
  float: ${props => (props.owner ? "none" : "none")};
  vertical-align: top;
`;

export const CommentDetails = styled.div<{ owner: boolean }>`
  margin: 0;
  text-align: ${props => (props.owner ? "left" : "left")};
  margin-top: 9px;
`;
export const CommentDetailContainer = styled.p`
  font-family: Open Sans;
  font-size: 15px;
  line-height: 21px;
  color: #2d2d2d;
  float: right;
`;

/* Replaced with Rich Mention Text Editor */

// export const Message = styled.p<{ owner: boolean }>`
//   background: ${props => props.theme.color.comment};
//   padding: 10px;
//   margin: 1px 1px 1px -10px;
//   border-radius: 10px;
//   font-size: 14px;
//   display: inline-block;
//   margin-bottom: 0;
//   float: ${props => (props.owner ? "none" : "left")};
//   position: relative;
//   z-index: 9;
// `;

// export const ReplyMessage = styled.div`
//   overflow: hidden;
// `;

export const LikeContainer = styled.div<{
  owner: boolean;
  response: boolean;
  like: boolean;
  hover: boolean;
}>`
  font-size: 30px;
  position: relative;
  text-align: ${props => (props.owner ? "left" : "right")};
  height: ${props => (props.owner ? 0 : props.like ? 40 : 28)}px;
  margin-top: ${props => (!props.owner ? (!props.response ? 17 : 4) : 0)}px;
`;

export const LikeType = styled.span`
  font-size: 20px;
  vertical-align: top;
  position: relative;
  z-index: 10;
`;

export const LikeNumber = styled.span`
  font-size: 10px;
  color: red;
  font-weight: bold;
  margin-right: 5px;
  position: relative;
  top: 15px;
  vertical-align: top;
`;
export const Like = styled.span<{ current: boolean }>`
  cursor: pointer;
  margin-right: 5px;
  font-size: ${props => (props.current ? 40 : 30)}px;
`;

export const LikedAvatars = styled.span`
  margin-left: 3px;
`;

export const MainContainer = styled.div<{ owner: boolean }>`
  display: inline-block;
  width: calc(100% - 60px);
  float: ${props => (props.owner ? "none" : "none")};
  margin-bottom: 5px;
  margin-left: 10px;
`;

export const ReactContainer = styled.span`
  margin-right: 4px;
  position: relative;
  top: -6px;
`;

export const PostReply = styled(Button)`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  width: 95px;
  height: 40px;
  float: right;
  padding: 0;
  z-index: 11;
  margin-left: 10px;
  position: relative;
  top: 10px;
  border-radius: 4px;
  margin: 0 0 25px 0;
`;

export const CancelReply = styled.button`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  width: 95px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  color: #c6344d;
  cursor: pointer;
  float: right;
  background-color: rgba(0, 0, 0, 0);
  z-index: 11;
  position: relative;
  top: 10px;
  margin-bottom: 25px;
`;

// @TODO - fix me with correct typing for the react-autosize-textarea module
export const ReplyComment = styled<any>(TextareaAutosize)`
  outline: none;
  resize: none;
  background-color: #fcfcfc;
  border: 1px solid #bcbcbc;
  font-size: 15px;
  padding: 10px;
  display: block;
  min-height: 80px;
  position: relative;
  border-radius: 4px;
  width: 100%;
`;

export const I = styled.i`
  margin-left: 7px;
  color: #c6344d;
`;
