import * as React from "react";
import { components } from "react-select";
import { COLORS } from "component/UI/common";
import { Icon } from "semantic-ui-react";

export const MultiValueRemove = props => {
  if (props.data.isLocked) {
    return (
      <components.MultiValueRemove {...props}>
        <Icon name="lock" size="tiny" />
      </components.MultiValueRemove>
    );
  } else {
    return (
      <components.MultiValueRemove {...props} />
    );
  }
};

export const customReactSelectStyles = {
  multiValue: (base, state) => ({
    ...base,
    background: (state.data.isLocked && !state.data.isChild) ? "#5f5f5f" : COLORS.indigo600,
    color: "white",
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: "white",
    paddingRight: (state.data.isLocked && state.data.isChild) ? "6px" : "3px",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isFocused ? "#f2f2f2" : "white",
    fontSize: "14.5px",
    textAlign: "left",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "14.5px",
  }),
  control: (base, state) => ({
    ...base,
    border: `1px solid ${state.isFocused ? COLORS.indigo600 : "#cccccc"}`,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${COLORS.indigo600}`,
    },
  }),
};
