import * as React from "react";
import { isPublicURL } from "common/helpers/route";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const StyleSeerLogo = styled.img`
  height: 60px;
  width: 100px !important;
`;

export const Footer = withRouter((): JSX.Element => (
  <>
    {isPublicURL() &&
      <div className="text-center pt-3 pb-5 bg-whitesmoke">
        <a href="https://seerdata.com.au">
          <p className="text-primary fs-1000">Powered by</p>
          <StyleSeerLogo src="/assets/loginlogo.png" alt="seer data logo" />
        </a>
      </div>
    }
  </>
));
