import * as React from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";
import { StylePillButton, StyleButtonGroup } from "./CensusQueryTool.style";
import { InsightCardView } from "./InsightCardView";
import { templateInsights } from "./templateInsights";

interface IComponent {
  topics: any[];
  selectedTopic: string | undefined;
  selectHandler: (value) => void;
  selectTemplateHandler: () => void;
}

export const TopicComponent = (props: IComponent): JSX.Element => {
  const { topics, selectedTopic, selectHandler, selectTemplateHandler } = props;
  const insightIds = selectedTopic ? templateInsights[selectedTopic] : [];
  const insightNumber: SemanticWIDTHS | any = insightIds.length;

  const clickHandler = async (value) => {
    if (value === selectedTopic) {
      selectHandler(undefined);
    } else {
      selectHandler(value);
    }
  };

  return (
    <>
      <div>
        <h1 className="ff-primary fw-800 text-secondary fs-2000 mb-3">
          What topic are you interested in?
        </h1>
        <StyleButtonGroup size="tiny" className="d-block text-left">
          {topics.map(topic => {
            const { name } = topic;
            return (
              <StylePillButton
                key={name}
                active={selectedTopic === name}
                className="cursor-pointer mr-2 mb-2"
                onClick={() => clickHandler(name)}
              >
                {name}
              </StylePillButton>
            );
          })}
        </StyleButtonGroup>
        {selectedTopic &&
          <>
            <h2 className="text-secondary my-3">Insights that might be of interest</h2>
            <Grid columns={insightNumber} doubling stackable>
              <Grid.Row stretched>
                {insightIds.map((insight, idx) => {
                  const { id, key } = insight;
                  return (
                    <Grid.Column key={id}>
                      <div className="mb-5 mb-md-0">
                        <InsightCardView insightId={id} publicKey={key} index={idx} selectTemplateHandler={selectTemplateHandler} />
                      </div>
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            </Grid>
          </>}
      </div>
    </>
  );
};
