import * as React from "react";
import { Heading } from "common/styledComponents/elements";
import { Button, Divider, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { uniqueId } from "lodash";
import { RSTOStyleAccordion } from "./modules.style";

const BorderRightDiv = styled.div`
  position: relative;
  @media(min-width: 992px) {
    &::after {
      content: "";
      border-right: 1px solid #e5e5e5;
      height: 450px;
      max-height: 80%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

interface IChartExplainer {
  shows: string;
  why?: string;
}

export interface IChartDetails {
  id?: number | string;
  title?: string;
  hideLegend?: boolean;
  image?: string;
}

export interface ISingleIndicator {
  indicatorID?: number;
  indicatorTitle?: string;
  chart1: IChartDetails;
  chart2: IChartDetails;
  calcLink: string;
  suitcaseLink: string;
  chart1Explainer?: IChartExplainer;
  chart2Explainer?: IChartExplainer;
  why?: string;
}

export interface IIndicatorModuleData {
  id?: number;
  heading: string;
  subheading: string;
  description: string;
  indicators: ISingleIndicator[];
}

interface IIndicatorModule {
  data?: IIndicatorModuleData;
}

export const IndicatorModule = (props: IIndicatorModule) => {

  const { id, heading, subheading, description, indicators } = props.data!;
  const hideTitles = false;
  const enableInsightClickThrough = true;

  return (
    <div className="d-flex justify-content-center my-5">
      <div className="bg-white h-100 rounded-4 p-5 mx-4" style={{ width: "85%" }}>
        <div id={`u_content_indicator_heading_${id}`}>
          <div className="d-flex justify-content-between">
            <Heading className="fs-2500 fw-400 mb-2" style={{ fontFamily: "Bebas Neue", textTransform: "uppercase", color: "#65C4DB" }}>
              {heading}
            </Heading>
          </div>
          <p className="fs-1000 mb-5 text-muted">
            {description}
          </p>
        </div>

        {indicators.length === 0 &&
          <div className="text-center" id={`u_content_indicator_none_${id}`}>
            <p className="text-muted">No indicators available yet.</p>
          </div>
        }

        {indicators.map((indicator) => {

          const { indicatorID, indicatorTitle, chart1, chart2, calcLink, suitcaseLink, chart1Explainer, chart2Explainer, why } = indicator;
          const accordionId = uniqueId("rsto-module-accordion-");

          return (<>
            <div>
              <Divider className="mt-5" />
              <Heading className="fs-1500 fw-400 mt-5" style={{ fontFamily: "Bebas Neue", color: "#faab70" }} id={`u_content_indicator_chart_heading_${id}_${indicatorID}`}>
                {indicatorTitle}
              </Heading>
            </div>
            <div className="d-flex justify-content-between">
              <p className="w-50 fs-1000 fw-600">
                {/* {chart1.title} */}
              </p>
              <p className="w-35 fs-1000 fw-600">
                {/* {chart2.title} */}
              </p>
            </div>
            <div className="d-flex flex-column flex-lg-row w-100 align-items-center justify-content-center">
              <div className={`pr-lg-2 ${chart1.id && !chart2.id ? "w-75" : "w-100"}`} style={{ height: "35vw", minWidth: "60%", border: "1px solid #e5e5e5" }} id={`u_content_indicator_chart_${chart1.id}`}>
                {chart1.id ?
                  <iframe
                    style={{ width: "100%", height: "100%", border: "none" }}
                    className="seer-dashboards-insight"
                    src={`/embed/insight?url=https://seerplatform.com/insights/${chart1.id}/1ae988bb-85ba-44bc-b9b4-5cfc6b32705e64eaa2f6-e843-4de9-9452-800838cb36e6&columns=1&hideLegend=${!!chart1.hideLegend}&hideTitle=${hideTitles}&enableInsightClickThrough=${enableInsightClickThrough}`}
                  />
                  :
                  (chart1.image ?
                    <div className="w-100 h-100 d-flex flex-column align-items-center py-2 px-4">
                      <h3 className="mb-1 fs-1125 w-100 text-muted">{chart1.title}</h3>
                      <div className="position-relative w-100" style={{ flex: 1 }}>
                        <img className="position-absolute h-100" src={chart1.image} />
                      </div>
                    </div>
                    :
                    <div className="text-center d-flex flex-column h-100 justify-content-center" style={{ height: "100" }}>
                      <p className="text-muted fw-600">
                        Data not provided or available for this indicator
                      </p>
                    </div>
                  )
                }
              </div>
              {chart2.id &&
                <div className="ml-lg-4 align-self-start w-100">
                  <div style={{ height: "360px" }} id={`u_content_indicator_chart_${chart2.id}`}>
                    {chart2.id &&
                      <iframe
                        style={{ width: "100%", height: "100%", border: "none" }}
                        className="seer-dashboards-insight"
                      src={`/embed/insight?url=https://seerplatform.com/insights/${chart2.id}/1ae988bb-85ba-44bc-b9b4-5cfc6b32705e64eaa2f6-e843-4de9-9452-800838cb36e6&columns=1&hideLegend=${!!chart2.hideLegend}&hideTitle=${hideTitles}&enableInsightClickThrough=${enableInsightClickThrough}`}
                      />
                    }
                  </div>
                  <p className="text-muted fw-600 fs-1000">Indicator calculations and charts</p>
                  <div className="d-flex">
                    <div className="w-50 mr-2" id={`u_content_indicator_button_1_${id}_${indicatorID}`}>
                      <Button as="a" href={calcLink} target="_blank" className="w-100 text-left px-3 text-white" style={{ backgroundColor: "#84d1de" }} >
                        <div className="d-flex">
                          <Icon name="calculator" size="large" className="mr-2 mt-1" />
                          <div>
                            <span>Indicator</span>
                            <p className="fs-0875 fw-400 mt-1">View calculation details</p>
                          </div>
                        </div>
                      </Button>
                    </div>
                    <div className="w-50 ml-2" id={`u_content_indicator_button_2_${id}_${indicatorID}`}>
                      <Button as="a" href={suitcaseLink} target="_blank" className="w-100 text-left px-3 text-white" style={{ backgroundColor: "#84d1de" }} >
                        <div className="d-flex">
                          <Icon name="cubes" size="big" className="mr-2" />
                          <div>
                            <span>Charts</span>
                            <p className="fs-0875 fw-400 mt-1">View detailed charts</p>
                          </div>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              }
            </div>

            {(chart1Explainer !== undefined || chart2Explainer !== undefined) &&
              <div style={chart1.hideLegend ? {} : { position: "relative", marginTop: "0px" }} id={`u_content_indicator_accordion_${id}_${indicatorID}`}>
                <div id={accordionId}>
                  <div className="seer-custom-tabs">
                    <div className="tabs">
                      <div className="tab">
                        <input type="checkbox" id={`${accordionId}-check`} />
                        <label className="tab-label" htmlFor={`${accordionId}-check`}
                        >Understand what this is showing you and why it matters
                        </label>
                        <div className="tab-content">
                          <div className="d-flex w-100 ml-3 justify-content-between">
                            <BorderRightDiv style={{ width: "59%" }}>
                              <div className="w-75">
                                {chart1Explainer ? (
                                  <>
                                    <Heading className="fs-1000 mb-0">What This Shows You</Heading>
                                    <p className="fs-1000" style={{ whiteSpace: "pre-line" }}>{chart1Explainer.shows}</p>
                                  </>
                                ) : <></>}
                              </div>
                            </BorderRightDiv>
                            <div className="w-35 pr-4">
                              {chart2Explainer ? (
                                <>
                                  <Heading className="fs-1000 mb-0">What This Shows You</Heading>
                                  <p className="fs-1000" style={{ whiteSpace: "pre-line" }}>{chart2Explainer.shows}</p>
                                </>
                              ) : <></>}
                            </div>
                          </div>
                          {why && (
                            <div className="ml-3 mt-2">
                              <Heading className="fs-1000 mb-0">Why It Matters</Heading>
                              <p className="fs-1000" style={{ whiteSpace: "pre-line" }}>{why}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <RSTOStyleAccordion id={accordionId} />
                </div>
              </div>
            }
          </>
          );
        })}
      </div>
    </div>
  );
};
