import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Dropdown } from "semantic-ui-react";
import { StyleSelect } from "./UIComponents";

interface IDataCleaningEdit {
  store?: Store;
}

const DataCleaningEditComponent = (props: IDataCleaningEdit): JSX.Element => {

  const { store } = props;
  const urlupload = store!.dataset.urlupload;

  const [cleanColSelectedOpt, setCleanColSelectedOpt] = React.useState<any | null>(null);

  const handleRename = async () => {
    const res = prompt(`Enter a new name for column "${cleanColSelectedOpt.label}"`);
    if (res?.trim()) {
      if (
        urlupload.staged![0]
          .filter(col => col !== cleanColSelectedOpt.label)
          .map(col => col.toLowerCase())
          .includes(res.trim().toLowerCase())
      ) {
        return alert(`The column "${res.trim()}" already exists. Rename aborted.`);
      }
      await urlupload.applyTransformOpsPreview([{
        type: "column rename",
        config: [cleanColSelectedOpt.label, res.trim()],
      }]);
      setCleanColSelectedOpt(null);
    }
  };

  const handleRemove = async () => {
    const doRemove = confirm(`Are you sure you want to remove the column "${cleanColSelectedOpt.label}"?`);
    if (doRemove) {
      await urlupload.applyTransformOpsPreview([{
        type: "column drop",
        config: cleanColSelectedOpt.label,
      }]);
      setCleanColSelectedOpt(null);
    }
  };

  return (
    <>
      <h4>Polish Your Data</h4>
      <p>Select a column you would like to rename or remove</p>
      <div className="mb-3">
        <StyleSelect
          isClearable
          name="clean_column"
          value={cleanColSelectedOpt}
          placeholder="Select or search"
          options={urlupload.staged![0].map((col, idx) => ({ value: idx, label: col }))}
          onChange={(selection) => setCleanColSelectedOpt(selection)}
        />
      </div>
      <Dropdown text="Select Action" basic button disabled={!cleanColSelectedOpt}>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={handleRename}
          >
            Rename
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleRemove}
          >
            Remove
          </Dropdown.Item>
          {/* @TODO - edit data type - for generating seer dates on valid columns etc */}
          {/* <Dropdown.Item>Edit Data Type</Dropdown.Item> */}
          {/* @TODO - might not need merge action here yet as this was more for use with the data privacy bit but in an automated way */}
          {/* <Dropdown.Item>Merge</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export const DataCleaningEdit = inject("store")(observer(DataCleaningEditComponent));
