import styled from "styled-components";
import { COLORS } from "component/UI/common";

export const SpotlightContainer = styled.div`
  background-color: #fff;
  border: 1px solid ${COLORS.indigo600};
  border-radius: 5px;
  display: flex;
  padding: 24px;
  gap: 24px;
  margin-bottom: 48px;
  min-height: 308px;
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
`;

export const SpotlightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ImageWrapper = styled.img`
  width: 400px;
  border: 1px solid #D7D7D7;
  @media screen and (max-width: 850px) {
    width: 100%;
  }
`;
