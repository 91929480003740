import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";
import { ColorPicker } from "component/ColorPicker";

interface IEditColor {
  tableID: number;
  colorIdxInTableColors: number;
  color: string;
  colorIdxInAllColors: number;
}

export const withColorPicker = (WrappedComponent) => inject("store")(
  observer((props: any): JSX.Element => {
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const [editColor, setEditColor] = React.useState<IEditColor | null>(null);

    const { builder } = props.store!;
    const hasCustomChartColors = builder.customChartColors?.length > 0;

    const colorSquareClickHandler = (tableID: number, colorIdxInTableColors: number, color: string, colorIdxInAllColors: number) => {
      setColorPickerOpen(true);
      setEditColor({ tableID, colorIdxInTableColors, color, colorIdxInAllColors });
    };

    React.useEffect(() => {
      if (colorPickerOpen === false) {
        setEditColor(null);
      }
    }, [colorPickerOpen]);

    return (
      <>
        <ColorPicker
          open={colorPickerOpen}
          setColorPickerOpen={newState => setColorPickerOpen(newState)}
          onSave={color => {
            if (editColor) {
              const { tableID, colorIdxInTableColors, colorIdxInAllColors } = editColor;
              if (hasCustomChartColors) {
                const customChartColors = [...builder!.customChartColors];
                customChartColors[colorIdxInAllColors!] = color;
                builder!.setCustomChartColors(customChartColors);
              } else {
                builder!.updateTableColor(tableID, colorIdxInTableColors, color);
              }
            }
          }}
          headerText="Update Colour"
          saveText={<>Update <Icon name="save" /></>}
          initialColor={editColor?.color}
        />
        <WrappedComponent
          {...props}
          colorSquareClickHandler={colorSquareClickHandler}
        />
      </>
    );
  })
);
