import * as React from "react";
import styled from "styled-components";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Icon } from "semantic-ui-react";
import { pad } from "common/helpers/string";

interface Props {
  store?: Store;
  lockData: {
    user_id: number;
    lock_ends_at: string;
    lock_id: string;
  };
  lockId: string;
}

const Wrapper = styled.div`
  background-color: #DBF2FF;
  position: relative;
  .banner {
    padding: 12px 24px;
    .icon.warning.sign {
      color: #D71D4B;
    }
  }
  .progress {
    background-color: #2DC3C2;
    transition: width 0.5s;
    position: absolute;
    top: 0;
    right: 0;
  }
  &.self.inactive {
    background-color: #F2C04C;
    .progress {
      height: 8px;
    }
  }
`;

const padTime = pad(2);
const tenMinsMs = 1000 * 60 * 10;
const msToTimeData = (ms: number) => {
  const mins = Math.floor(ms / (1000 * 60));
  const secs = Math.floor((ms - (mins * 1000 * 60)) / 1000);
  return { mins: padTime(mins), secs: padTime(secs) };
};

export const Component = (props: Props): JSX.Element => {
  const store = props.store!; // group / partner users lists..
  const { lockData, lockId } = props;
  const userId = store.user!.id;
  const getLockUserInfo = () => {
    if (userId === lockData.user_id) {
      return { type: "self", matchingLock: lockId === lockData.lock_id };
    }
    // now check if user is in group or partners users
    const knownUsers = [
      ...store.user!.group.users.map(user => ({ id: user.id, first_name: user.first_name, last_name: user.last_name, organisation: null })), // null org treated as same org
      ...store.user!.group.partners.reduce((prev, next) => [
        ...prev,
        ...next.users.map(user => ({ id: user.id, first_name: user.first_name, last_name: user.last_name, organisation: next.name })),
      ], []),
    ];
    const knownUser = knownUsers.find(user => user.id === lockData.user_id);
    if (knownUser) {
      return { ...knownUser, type: "other_user" };
    }
    return { type: "unknown_user" };
  };
  const lockUserInfo = getLockUserInfo();
  const { type, matchingLock, first_name, last_name, organisation } = lockUserInfo;
  // activity logic
  const [msLeft, setMsLeft] = React.useState<number>(tenMinsMs);
  const active = !matchingLock || msLeft > (1000 * 60 * 9); // less than a minute since lock refreshed is treated as active
  React.useEffect(() => {
    const interval = setInterval(() => {
      setMsLeft(Math.max(0, new Date(lockData.lock_ends_at).getTime() - Date.now()));
    }, 1000);
    return () => clearInterval(interval);
  }, [lockData]);
  const timerData = msToTimeData(msLeft);

  return (
    <Wrapper className={`${lockUserInfo.type} ${!active ? "inactive" : ""}`}>
      <div className="progress" style={{ width: `calc(${Math.round(msLeft / tenMinsMs * 100 * 100) / 100}%)` }} />
      <div className="banner">
        <Icon name={(type === "self" && !matchingLock) ? "warning sign" : "lock"} />{" "}
        <span>
          {type === "self" && (
            <>
              {!!matchingLock ? (
                <>
                  {active ? (
                    <>
                      Editing is <b>locked for other users</b> while you are actively editing.
                      This dashboard will unlock and you will <b>lose unsaved work if you are inactive</b> for longer than <b>10 minutes</b>.
                    </>
                  ) : (
                    <>
                      We've noticed you are <b>currently inactive</b> in edit mode.
                      This dashboard will unlock and you will <b>lose unsaved work if you are inactive</b> for another <b>{timerData.mins}:{timerData.secs}</b>
                    </>
                  )}
                </>
              ) : (
                <>You are currently editing this dashboard on another tab or device, you will <b>lose unsaved edits</b> if you choose to edit here.</>
              )}
            </>
          )}
          {type === "other_user" && (
            <>You are in view only mode as <b>{first_name} {last_name}</b> from <b>{organisation || "your organisation"}</b> is <b>currently editing</b>.</>
          )}
          {type === "unknown_user" && (
            <>You are in view only mode as someone from outside of your organisation is <b>currently editing</b>.</>
          )}
        </span>
      </div>
    </Wrapper>
  );
};

export const LockBanner = inject("store")(observer(Component));
