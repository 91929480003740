import * as React from "react";

// -=-=-=-=Store=-=-=-=-=-=-
import { inject, observer } from "mobx-react";
import Store from "../../common/store";

// -=-=-=-=Components=-=-=-=-

import Protected from "../../component/protected";
import { ShowLoaderGlobal } from "component/LoaderGlobal";

import InsightOverview from "../../component/overview/InsightOverview";
// -=-=-=-=Libraries=-=-=-=-
import { withRouter, RouteComponentProps } from "react-router";
import { Helmet } from "react-helmet";

// -=-=-=-=Styles-=-=-=-=-=-
import { Main } from "./Insight.style";
import { shortenString } from "common/helpers/string";
import { getReducedSuitcaseUsers } from "common/helpers/suitcases";
import { hideCTABanner } from "common/helpers/communityHubs";

type PropsType = RouteComponentProps<{ id: string; key?: string }> & {
  store: Store;
};

class Insight extends React.Component<PropsType> {
  store = this.props.store;
  insight = this.store.insight;
  builder = this.store.builder;

  async componentDidMount() {
    const { id, key: publicUrlKey } = this.props.match.params;

    await this.insight.load(parseInt(id, 10), false, publicUrlKey);
    await this.builder.loadInsight(parseInt(id, 10), false, publicUrlKey, this.insight.result);

    this.insight.comments.type = "insights";
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)) {
      const { id, key: publicUrlKey } = this.props.match.params;
      await this.insight.load(parseInt(id, 10), false, publicUrlKey);
      await this.builder.loadInsight(parseInt(id, 10), false, publicUrlKey, this.insight.result);
    }
  }

  render() {
    const { key: publicUrlKey } = this.props.match.params;
    const isPublicUrl = !!publicUrlKey;
    const activeSuitcase = this.store.suitcase.active;

    if (this.store.loading || this.builder.loading.loadInsight) {
      return <ShowLoaderGlobal />;
    }
    if (!isPublicUrl && activeSuitcase?.id !== this.insight.result.suitcase_id) {
      return <h2 className="text-center">You do not have access to this Insight.</h2>;
    }
    const insight = this.insight;

    const suitcaseUser = activeSuitcase && getReducedSuitcaseUsers(activeSuitcase).find(user => user.id === this.store?.user?.id);
    const bannerVersion =
      activeSuitcase && isPublicUrl && suitcaseUser
        ? "fullAccess"
        : this.store.authenticated || (this.insight?.result?.suitcase_org_id ? hideCTABanner(this.insight.result.suitcase_org_id) : true )
        ? ""
        : "signUp";

    return (
      <>
        {/* TODO: Uncomment and change behaviour to not display banner for Data Space/Unlimited plans */}
        {/* {bannerVersion && (
          <Banner version={bannerVersion} fullAccessButtonLink={`/insights/${this.store.insight.result.id}`} />
        )} */}
        <Main id="insight" style={{ overflow: "auto" }}>
          <Helmet>
            <title>{`${shortenString(this.insight.result.name, 5)} - Insight #${this.insight.result.id}`}</title>
          </Helmet>
          <div style={{ position: "relative" }}>
            <InsightOverview store={insight.parent} publicUrlKey={publicUrlKey || false} />
          </div>
        </Main>
      </>
    );
  }
}

export default withRouter(Protected(inject("store")(observer(Insight))));
