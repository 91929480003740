import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "component/UI/common";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 972px;
  font-size: 1rem;
  padding: 20px;
  padding-bottom: 0;
  background-color: whitesmoke;
  @media only screen and (max-width: 972px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.blue};
  font-weight: 700;
  &:hover {
    color: ${COLORS.blue};
  }
`;

interface BottomSectionProps {
  text: string;
  link: string;
}

export const BottomSection = (props: BottomSectionProps): JSX.Element => {
  const { text, link } = props;
  return (
    <Wrapper>
      <Content>
        <div className="mb-1" style={{ height: 1, backgroundColor: COLORS.grey400 }}></div>
        <div className="text-center text-md-left d-md-flex align-items-center justify-content-between">
          <p className="mb-3 mb-md-0" style={{ color: COLORS.indigo500 }}>Don't have an account? <StyledLink to={link}>{text}</StyledLink></p>
          <div className="d-flex justify-content-center">
            <StyledLink className="mr-4" to={{ pathname: "https://seerdata.ai/terms-of-service/" }} target="_blank">Terms of Service</StyledLink>
            <StyledLink to={{ pathname: "https://seerdata.ai/privacy-policy/" }} target="_blank">Privacy Policy</StyledLink>
          </div>
        </div>
      </Content>
    </Wrapper>
  );
};
