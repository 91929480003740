import React = require("react");
import { observer } from "mobx-react";
import { Icon, Input, Button } from "semantic-ui-react";
import styled from "styled-components";
import { webUrl } from "common/constants";
import { $opensans } from "common/styledComponents/_config.style";
import { Theme } from "common/styledComponents/theme";
import {
  StyleNameSuitcase,
  StyleTileSuitcase,
  StyleUserInfoSuitcase,
  AccessDropdown,
} from "component/Members/includes/addMember.style";
import { COLORS } from "component/UI/common";

const accessTypes = [
  { key: 1, text: "No access", value: "false" },
  { key: 2, text: "Can View", value: "true" },
];

const StyleTileSuitcaseCustom = styled(StyleTileSuitcase)`
  flex-wrap: wrap;
  height: inherit;
  padding-bottom: 1rem;
  border-top: none;
`;

const SeerButton = styled(Button)`
  white-space: nowrap;
  margin: 0 0 0 1rem !important;
  font-family: ${$opensans} !important;
  font-size: ${Theme.font.size.small};
`;

const PublicLinkComponent: React.FC<{ suitcase: any; onChange(val: string): void }> = ({ suitcase, onChange }) => {
  const { link_share, key, id } = suitcase;
  const shareKey = link_share && key;

  // copy public link button click handler
  const onCopy = () => {
    const elem: HTMLInputElement | null = document.querySelector('input[name="public_link"]');
    if (elem) {
      try {
        elem.focus();
        elem.select();
        document.execCommand("copy");
      } catch (err) {
        console.error("Failed to copy", err);
      }
    }
  };

  return (
    <>
      <StyleTileSuitcaseCustom>
        <StyleUserInfoSuitcase>
          <div>
            <Icon name="linkify" size="big" style={{ color: `${COLORS.indigo600}`, width: 40, marginRight: 20 }} />
          </div>
          <div>
            <StyleNameSuitcase style={{ color: `${COLORS.indigo600}` }}>Anyone with the link</StyleNameSuitcase>
          </div>
        </StyleUserInfoSuitcase>
        <AccessDropdown
          options={accessTypes}
          defaultValue={!!shareKey ? "true" : "false"}
          selectOnBlur={false}
          selection
          onChange={(_, { value }) => onChange(value)}
        />
        {shareKey ? (
          <div style={{ width: "100%", display: "flex" }}>
            <Input
              name="public_link"
              type="text"
              onChange={() => null}
              value={`${webUrl}/suitcases/${id}/${key}`}
              style={{ width: "100%" }}
            />
            <SeerButton basic onClick={onCopy}>
              <span style={{ color: COLORS.red500 }}>
                <b>Copy <Icon name="linkify" /></b>
              </span>
            </SeerButton>
          </div>
        ) : null}
      </StyleTileSuitcaseCustom>
    </>
  );
};

export const PublicLink = observer(PublicLinkComponent);
