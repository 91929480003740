import * as React from "react";
import { Divider, Dropdown, Grid, Icon, SemanticICONS } from "semantic-ui-react";
import { CreateModal } from "./CreateModal";

export interface IItem {
  type: "link" | "button-primary" | "button-secondary";
  label: string;
  icon: SemanticICONS;
  link?: string | null;
  action?: () => void;
}

interface IHeaderSection {
  name: string;
  onNameChange?: (name) => void;
  items: IItem[];
  actionsDropdown?: any[];
}

// TODO: This component was used by both the 'Dataset' page and the 'Dataset Template' page but is not used in the redesign for the 'Dataset' page on Dec 2023 anymore. We need to consider whether we still need this component when we undertake the redesign of the 'Dataset Template' page.
export const HeadingSection = ({ name, onNameChange, items, actionsDropdown }: IHeaderSection): JSX.Element => {
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);
  return (
    <>
      {/* Modal to update template name */}
      <CreateModal
        name={name}
        heading="Template"
        isOpen={showEditNameModal}
        closeModal={() => setShowEditNameModal(false)}
        saveModal={onNameChange!}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <div className="d-flex align-items-center">
              <h1 className="ff-primary text-secondary my-0 mr-3" style={{ fontSize: 30 }}>{name}</h1>
              {onNameChange && (
                <Icon name="edit" className="cursor-pointer text-secondary" onClick={() => setShowEditNameModal(true)} />
              )}
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="d-flex align-items-center justify-content-end mt-3">
              {
                items.map((item, index) => {
                  const { type, label, icon, link, action } = item;
                  if (type === "link") {
                    return (
                      link && <a href={link} target="_blank" key={index}>
                        <h4 className="text-dark text-hover-secondary my-0 ml-3">
                          <Icon name={icon} />{label}
                        </h4>
                      </a>
                    );
                  } else {
                    return (
                      <h4
                        className={`${type === "button-primary" ? "text-primary" : "text-dark text-hover-secondary"} my-0 cursor-pointer ml-3`}
                        key={index}
                        onClick={action}
                      >
                        <Icon name={icon} />{label}
                      </h4>
                    );
                  }
                })
              }
              {
                actionsDropdown &&
                <Dropdown
                  className="ml-3 fs-1125"
                  icon="ellipsis vertical"
                  text=" "
                  options={actionsDropdown}
                  pointing="top right"
                  item
                />
              }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  );
};
