import * as React from "react";
import { Checkbox } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
import { IconButton, PillButton } from "./components";
import { TaskInput } from "./Collaborate.style";

interface Props {
  disabled?: boolean;
  value?: string;
  resolved?: boolean;
  editMode?: boolean;
  onCancel?: () => void;
  onSave: (value: string) => void;
  onDelete?: () => void;
  onCheck?: (value: boolean) => void;
}

export const ActionTaskInput = (props: Props): JSX.Element => {
  const { disabled, value, resolved, onCancel, onSave, onDelete, onCheck, editMode } = props;

  const [currentValue, setCurrentValue] = React.useState(value);
  const [editing, setEditing] = React.useState(editMode);
  const [showActions, setShowActions] = React.useState(false);

  const inputRef: any = React.useRef(null);

  const editHandler = () => {
    setEditing(true);
  };

  const cancelEditHandler = () => {
    setCurrentValue(value);
    setEditing(false);
    onCancel && onCancel();
  };

  const saveHandler = () => {
    if (currentValue) {
      onSave(currentValue.trim());
      setCurrentValue("");
    }
  };

  React.useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  return (
    <>
      {editing ? (
        <div className="d-flex justify-content-between mt-2" style={{ marginBottom: 11 }}>
          <TaskInput ref={inputRef} value={currentValue} onChange={(e) => setCurrentValue(e.target.value)} />
          <div className="d-flex">
            <PillButton size="small" color="red" label="Cancel" onClick={cancelEditHandler} />
            <PillButton size="small" disabled={!currentValue} color="aqua" label="Save" onClick={saveHandler} />
          </div>
        </div>
      ) : (
        <div
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
          className="d-flex align-items-start justify-content-between mb-2"
        >
            <div className="d-flex align-items-start" style={{ marginTop: 3, minHeight: 26 }}>
            {!disabled && (
              <Checkbox
                className="mr-2"
                checked={resolved}
                onChange={(_e, data) => onCheck && onCheck(data.checked as boolean)}
                style={{ marginTop: 2 }}
              />
            )}
            <p className={`fs-1000 ${resolved ? "text-decoration-line-through" : ""}`} style={{ color: resolved ? COLORS.grey600 : COLORS.grey900 }}>{currentValue}</p>
          </div>
          <div className="d-flex flex-shrink-0 justify-content-end" style={{ width: 50 }}>
            {showActions && !disabled && (
              <>
                <IconButton onClick={onDelete} icon="trash alternate outline" />
                {!resolved && (
                  <IconButton onClick={editHandler} icon="edit outline" />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
