import * as React from "react";
import { COLORS } from "component/UI/common";
import styled from "styled-components";

const SectionHeading = styled.p`
  font-family: "Open Sans", Helvetica, sans-serif;
  color: ${COLORS.grey900 };
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

interface Props {
  heading: string;
}

export const SectionHeader = (props: Props): JSX.Element => {
  const { heading } = props;
  return (
    <div className="mb-4">
      <SectionHeading style={{ color: COLORS.grey900 }}>{heading}</SectionHeading>
      <div style={{ backgroundColor: COLORS.indigo400, height: 2, width: "100%" }}></div>
    </div>
  );
};
