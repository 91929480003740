import * as React from "react";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";

interface IIntro {
  nextClickHandler: () => void;
}

export const IntroComponent = ({ nextClickHandler }: IIntro): JSX.Element => (
  <div>
    <h1 className="text-secondary">Census 2021 Insight Builder</h1>
    <img className="w-100" src="/assets/census_query_tool_hero_image.gif" />
    <h2>Instructions</h2>
    <p>Explore the dataset by working through the wizard and then hitting visualise to create an Insight.</p>
    <SeerButton
      category="primary"
      label="Start Exploring"
      onClick={nextClickHandler}
    />
  </div>
);
