import styled from "styled-components";
import { H2 } from "../../common/styledComponents/typography";

export const Main = styled.div`
  width: 940px;
  max-width: 100%;
  padding: 0 10px;
  padding-bottom: 20px;
  margin: 0 auto;
  position: relative;
`;

export const Icon = styled.div`
  display: inline-block;
  margin-right: 10px;
  & > svg {
    width: 40px;
    height: 35px;
  }
`;

export const Subheading = styled(H2)`
  font-size: ${props => props.theme.font.size.large};
`;

export const Suitcase = styled.div`
  margin: 0 0 20px -212px;
`;
