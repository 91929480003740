import { observable, action, computed } from "mobx";
import Store from "../store";

// global UI store
export class UI {
  /* observables */
  @observable windowWidth: number = 0;
  @observable windowHeight: number = 0;

  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  /* actions - do something and update observables */
  @action setWindowSize(size: number[]): void {
    const [width, height] = size;
    this.windowHeight = height;
    this.windowWidth = width;
  }

  /* computed values- return something computed without modifying observable state */
  // returns a breakpoint string, specific to semantic
  @computed get breakpoint(): "mobile" | "tablet" | "computer" | "largeMonitor" | "wideScreenMonitor" {
    const { windowWidth: width } = this;
    if (width >= 1920) {
      return "wideScreenMonitor";
    } else if (width >= 1280) {
      return "largeMonitor";
    } else if (width >= 992) {
      return "computer";
    } else if (width >= 768) {
      return "tablet";
    }
    return "mobile";
  }

  /* any functions that are also needed but don't affect mobx state but can't be moved to helper files */
  /* somethingElse() {
  } */
}
