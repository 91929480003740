import * as React from "react";
import { AnimateKeyframes } from "react-simple-animate";

const RED = "#C52B2E";
const PURPLE = "#5F2E60";
const TEAL = "#2FBFC0";

const Circle = (props): JSX.Element => {
  const { radius, stroke, color } = props;
  return (
    <svg width={(radius + stroke) * 4} height={(radius + stroke) * 4}>
      <circle cx={(radius + stroke) * 2} cy={(radius + stroke) * 2} r={radius} stroke={color} strokeWidth={stroke} fill="none" />
    </svg>
  );
};

const AnimatedCircle = (props) => {
  const { position, animation, circle } = props;
  const { left, right, top, bottom } = position;
  const { duration, yPosition, scale, opacity } = animation;
  const { radius, stroke, color } = circle;
  return (
    <div className="position-absolute" style={{ zIndex: 0, left: left || "auto", right: right || "auto", top: top || "auto", bottom: bottom || "auto" }}>
      <AnimateKeyframes
        play
        iterationCount="infinite"
        duration={duration}
        keyframes={[
          "transform: translateY(0px); opacity: 0",
          `transform: translateY(${yPosition / 2}px) scale(${1 + (scale - 1) / 2}); opacity: ${opacity}`,
          `transform: translateY(${yPosition}px) scale(${scale}); opacity: 0`,
        ]}
      >
        <Circle radius={radius} stroke={stroke} color={color} />
      </AnimateKeyframes>
    </div>
  );
};

export const AnimatedBackground = (): JSX.Element => (
  <>
    {/* Left */}
    <AnimatedCircle
      position={{ left: "5%", right: "auto", top: "20%", bottom: "auto" }}
      animation={{ duration: 5, yPosition: "-60", scale: 1.2, opacity: 0.5 }}
      circle={{ radius: 30, stroke: 7, color: PURPLE }}
    />
    <AnimatedCircle
      position={{ left: "1%", right: "auto", top: "45%", bottom: "auto" }}
      animation={{ duration: 8, yPosition: "-60", scale: 1.2, opacity: 0.6 }}
      circle={{ radius: 20, stroke: 5, color: TEAL }}
    />
    <AnimatedCircle
      position={{ left: "10%", right: "auto", top: "60%", bottom: "auto" }}
      animation={{ duration: 7, yPosition: "-90", scale: 1.4, opacity: 0.6 }}
      circle={{ radius: 30, stroke: 7, color: RED }}
    />
    <AnimatedCircle
      position={{ left: "0%", right: "auto", top: "auto", bottom: "2%" }}
      animation={{ duration: 6, yPosition: "-80", scale: 1.7, opacity: 0.7 }}
      circle={{ radius: 40, stroke: 10, color: PURPLE }}
    />
    {/* Middle */}
    <AnimatedCircle
      position={{ left: "20%", right: "auto", top: "10%", bottom: "auto" }}
      animation={{ duration: 5, yPosition: "-40", scale: 1.2, opacity: 0.8 }}
      circle={{ radius: 20, stroke: 6, color: PURPLE }}
    />
    <AnimatedCircle
      position={{ left: "40%", right: "auto", top: "10%", bottom: "auto" }}
      animation={{ duration: 5, yPosition: "-40", scale: 1.2, opacity: 0.5 }}
      circle={{ radius: 40, stroke: 8, color: RED }}
    />
    <AnimatedCircle
      position={{ left: "auto", right: "30%", top: "15%", bottom: "auto" }}
      animation={{ duration: 7, yPosition: "-40", scale: 1.2, opacity: 0.7 }}
      circle={{ radius: 14, stroke: 4, color: PURPLE }}
    />
    <AnimatedCircle
      position={{ left: "35%", right: "auto", top: "auto", bottom: "1%" }}
      animation={{ duration: 8, yPosition: "-60", scale: 1.4, opacity: 0.6 }}
      circle={{ radius: 14, stroke: 4, color: TEAL }}
    />
    <AnimatedCircle
      position={{ left: "auto", right: "25%", top: "auto", bottom: "1%" }}
      animation={{ duration: 7, yPosition: "-60", scale: 1.3, opacity: 0.5 }}
      circle={{ radius: 40, stroke: 8, color: PURPLE }}
    />
    {/* Right */}
    <AnimatedCircle
      position={{ left: "auto", right: "2%", top: "10%", bottom: "auto" }}
      animation={{ duration: 6, yPosition: "-40", scale: 1.2, opacity: 0.8 }}
      circle={{ radius: 20, stroke: 6, color: RED }}
    />
    <AnimatedCircle
      position={{ left: "auto", right: "12%", top: "25%", bottom: "auto" }}
      animation={{ duration: 4, yPosition: "-40", scale: 1.2, opacity: 0.8 }}
      circle={{ radius: 14, stroke: 4, color: PURPLE }}
    />
    <AnimatedCircle
      position={{ left: "auto", right: "3%", top: "auto", bottom: "35%" }}
      animation={{ duration: 7, yPosition: "-60", scale: 1.2, opacity: 0.5 }}
      circle={{ radius: 40, stroke: 9, color: PURPLE }}
    />
    <AnimatedCircle
      position={{ left: "auto", right: "6%", top: "auto", bottom: "1%" }}
      animation={{ duration: 8, yPosition: "-60", scale: 1.4, opacity: 0.6 }}
      circle={{ radius: 30, stroke: 7, color: TEAL }}
    />
  </>
);
