import * as React from "react";
import { Modal, Button, Input, Icon } from "semantic-ui-react";
import { backendUrl } from "common/constants";
// import { insertScript } from "common/helpers/script";
// import { inject, observer } from "mobx-react";

// upload handler generator function
export const imageUploadHandler = (token: string) => async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("image", file);
  return await fetch(`${backendUrl}/image-upload`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Token": token || "-none-",
    },
    body: formData,
  }).then(async res => {
    const json = await res.json();
    return res.ok ? json : { error: json?.error?.message || "Image Upload Error" };
  }).catch(e => ({ error: e }));
};

interface CustomImageLibraryProps {
  isOpen: boolean;
  done: (args) => any;
  close: () => any;
  handleUpload: (args) => any;
}

// modal for uploading images for use in unlayer editor
export const CustomImageLibrary = (props: CustomImageLibraryProps): JSX.Element => {
  const { isOpen, done, close, handleUpload } = props;
  const inputRef: any = React.useRef(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    const files = inputRef.current?.inputRef?.current?.files;
    if (files && files.length === 1) {
      const res = await handleUpload(files[0]);
      if (!res || res.error) {
        setError(res?.error || "Image Upload Error");
      } else {
        done({ url: res.location });
        close();
      }
    }
    setLoading(false);
  };
  return (
    <Modal open={isOpen} onClose={close}>
      <Modal.Header>Upload Image</Modal.Header>
      <Modal.Content>
        <p>Select an image to upload</p>
        <Input type="file" ref={inputRef} accept="image/*" disabled={loading} />
        {error && (
          <p className="text-danger">{error}</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Close</Button>
        <Button className="bg-primary text-white bg-hover-red" onClick={onSubmit} disabled={loading}>
          Upload {loading && <Icon className="ml-2" name="circle notch" loading />}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

/* COMPONENT FOR DEMONSTRATION / HOW TO USE */
/*

export const DemoComponent = (props): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [doneFn, setDoneFn]: any = React.useState(null);
  const init = async () => {
    // insert unlayer on mount
    await insertScript("unlayer", { type: "text/javascript", src: "https://editor.unlayer.com/embed.js" });
    const editor = window["unlayer"].createEditor({
      id: "customTest",
      projectId: 69944,
      displayMode: "web",
    });
    editor.registerCallback("selectImage", (_data, done) => {
      setIsOpen(true);
      setDoneFn(() => args => done(args)); // have to set this in state etc. so that we can use external to callback function
    });
  };
  React.useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div id="customTest" style={{ height: "80vh" }} />
      <CustomImageLibrary
        isOpen={isOpen}
        done={doneFn}
        close={() => setIsOpen(false)}
        handleUpload={imageUploadHandler(props.store.token)}
      />
    </div>
  );
};
class DemoWrapper extends React.Component {
  render() {
    return (
      <DemoComponent {...this.props} />
    );
  }
}

export const Demo = inject("store")(observer(DemoWrapper));
*/

