import styled from "styled-components";
import { Strong } from "../../../common/styledComponents/typography";
import { Dropdown } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const StyleColorPicker = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 150px;
  top: 15px;
  right: 15px;
  z-index: 5;
`;

export const StyleLegendInfo = styled.div`
  text-align: left;
  margin: 40px 10px 0 10px;
  width: 100%;
`;

export const StyleLegendItem = styled.div`
  vertical-align: middle;
  margin-bottom: 5px;
`;

export const StyleLegendChunk = styled.div`
  width: 250px;
  vertical-align: middle;
  margin-right: 15px;
  float: left;
`;

export const StyleBackdrop = styled.div`
  position: absolute;
  width: 330px;
  height: 350px;
  bottom: 60px;
  left: 35%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
  z-index: 4;
`;

export const StyleUpdateColor = styled.button`
  position: absolute;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
  height: 40px;
  width: 300px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  border: none;
  background: ${COLORS.red500};
  right: 15px;
  bottom: 15px;
  z-index: 6;
  box-shadow: 0px 2px 4px #c3c3c3;
`;

export const StyleThird = styled.div`
  width: 30%;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
`;

export const StyleGap = styled.div`
  display: inline-block;
  width: 5%;
`;

export const StyleTitle = styled(Strong)`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const StyleDD = styled(Dropdown)`
  border: 1px solid #c3c3c3 !important;
  min-height: 40 !important;
  height: auto !important;
  font-size: 14.5px !important;
  .default {
    color: #919191 !important;
    opacity: 1;
  }
  .label {
    font-family: "Open Sans";

    font-weight: normal;
    font-size: 15px !important;
    color: white;
    border-radius: 4px;
    padding: 6px 8px 7px 8px !important;
    margin: 0.5px 0;
    background-color: ${COLORS.red500};
    &:hover {
      background: ${COLORS.red500} !important;
      color: white !important;
    }
    &:active {
      background: ${COLORS.red500} !important;
      color: white !important;
    }
  }
  ${p => (p.selected ? COLORS.red500 : "#919191")};
`;

export const StyleShowAdvanced = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.red500};
  cursor: pointer;
  font-weight: bold;
`;

export const StyleHR = styled.div`
  width: 100%;
  border: 0.5px solid #c3c3c3;
  margin: 20px 0;
`;

export const StyleDDType = styled(Dropdown)`
  width: 100%;
  height: 40px;
  border: 1px solid #c3c3c3 !important;
  border-radius: 4px;
  font-size: 15px;
  color: #2d2d2d !important;
  padding-left: 10px;
  line-height: 40px;
  .icon {
    float: right !important;
    margin-right: 10px !important;
    line-height: 40px !important;
  }
  .label {
    color: white !important;
    &:hover {
      color: white !important;
    }
  }
`;

export const StyleDDHeader = styled(Dropdown.Header)`
  background: #919191;
  color: white !important;
  margin: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 12px !important;
`;

export const StyleIcon = styled.i`
  float: right;
  margin-top: 5px;
  margin-right: -10px;
  font-size: 19px;
`;

export const StyleDecimal = styled.button<{ selected: boolean }>`
  width: 105px;
  position: relative;
  z-index: 2;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c3c3c3;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  color: ${p => (p.selected ? `${COLORS.red500} !important` : "  #919191 !important")};
  background-color: ${p => (p.selected ? " #F5F5F5 !important" : " white !important")};
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: ${p => !p.selected && "red !important"};
    background-color: ${p => !p.selected && "#F5F5F5 !important"};
  }
`;

export const StylePercent = styled.button<{ selected: boolean }>`
  position: relative;
  z-index: 1;
  left: 1px;
  width: 105px;
  height: 40px;
  border: 1px solid #c3c3c3;
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  color: ${p => (p.selected ? `${COLORS.red500} !important` : "  #919191 !important")};
  background-color: ${p => (p.selected ? " #F5F5F5 !important" : " white !important")};
  &:hover {
    color: ${p => !p.selected && "red"};
    background-color: ${p => !p.selected && "#F5F5F5 !important"};
  }
`;
export const StyleToggle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`;
