import * as React from "react";

import { ParsedRecords, SSDCConfig, processRecords } from "./helpers";
import { RecordTable } from "component/RecordTable/RecordTable";
import { Icon, Button } from "semantic-ui-react";

interface Props {
  rawData: ParsedRecords;
  config: SSDCConfig;
}

export const ProcessedPreview = (props: Props): JSX.Element => {
  const { rawData, config } = props;
  const { columnHash, columnRename, columnDrop, columnMerge } = config;
  const [processedRecords, setProcessedRecords] = React.useState<ParsedRecords>([]);
  const [displaySection, setDisplaySection] = React.useState("processedRecords");
  const init = async () => {
    const next: ParsedRecords = await processRecords(rawData, config);
    setProcessedRecords(next);
  };
  React.useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Button.Group size="tiny" className="mb-3">
        <Button type="button" active={displaySection === "processedRecords"} onClick={() => setDisplaySection("processedRecords")}>Processed Data</Button>
        <Button type="button" active={displaySection === "rawData"} onClick={() => setDisplaySection("rawData")}>Original Data</Button>
        <Button type="button" active={displaySection === "config"} onClick={() => setDisplaySection("config")}>Transformation Configuration</Button>
      </Button.Group>
      {displaySection === "processedRecords" && !!processedRecords.length && (
        <>
          <p>This is the processed data that will be uploaded.</p>
          <RecordTable records={processedRecords} rowsToDisplay={5} />
        </>
      )}
      {displaySection === "rawData" && (
        <>
          <p>This is the original data for reference only.</p>
          <RecordTable records={rawData} rowsToDisplay={5} />
        </>
      )}
      {displaySection === "config" && (
        <>
          <p>These actions were applied to the attached data during processing.</p>
          {!!columnDrop?.length && (
            <>
              <p><b>Dropped Columns</b></p>
              <ul>
                {columnDrop.map(name => <li key={name}>{name}</li>)}
              </ul>
            </>
          )}
          {!!columnRename?.length && (
            <>
              <p><b>Renamed Columns</b></p>
              <ul>
                {columnRename.map(([oldName, newName]) => <li key={oldName}>"{oldName}" <Icon name="angle double right" /> "{newName}"</li>)}
              </ul>
            </>
          )}
          {!!columnHash?.length && (
            <>
              <p><b>De-Identified Columns</b></p>
              <ul>
                {columnHash.map(name => <li key={name}>{name}</li>)}
              </ul>
            </>
          )}
          {!!columnMerge?.length && (
            <>
              <p><b>Merged Columns</b></p>
              <ul>
                {columnMerge.map(mergeOp => {
                  const { name: mergedName, columns: mergedCols } = mergeOp;
                  return (
                    <li key={mergedName}>{mergedCols.join(", ")} <Icon name="angle double right" /> {mergedName}</li>
                  );
                })}
              </ul>
            </>
          )}
        </>
      )}
    </>
  );
};
