import * as React from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { getTinymce } from "@tinymce/tinymce-react/lib/cjs/main/ts/TinyMCE";
import Editable from "component/Editable";
import { Heading, InvertedButton } from "common/styledComponents/elements";
import styled from "styled-components";
import { Button, Icon, Modal } from "semantic-ui-react";
import { device } from "common/styledComponents/_config.style";
import { Box } from "component/overview/overviewStyles/overview.style";
import { EditName } from "pages/Suitcase/suitcaseOverviewPage.styles";
import { StyleSave, StyleCancel } from "common/styledComponents/_modals.style";
import { NewInsightContent } from "component/NewInsightContent";
import { StyledIcon, StyledDescription } from "pages/Suitcase/suitcaseOverviewPage";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { TinymceEditor } from "component/TinymceEditor";
import { Prompt } from "react-router-dom";
import { getMixpanel } from "common/api";
import { COLORS } from "component/UI/common";
import { ContentPageEmptyStateDescription, EmptyStateSecondary } from "component/EmptyState/EmptyState";

export const StyleModalEditor = styled(Modal)`
  max-width: 900px !important; top: 60px !important; bottom: 60px !important;
  @media ${device.tablet} {
    left: 60px !important ; right: 60px !important; width: auto !important;
  }
  @media ${device.tabletH} {
    top: 60px !important; bottom: 60px;
  }
`;

type IExplorePreviewResult = RouteComponentProps<{}> & {
  store?: any;
  update: boolean;
  setCurrentStep: (string) => void;
  onSaveHandler: () => void;
};

class ExplorePreviewResultComponent extends React.Component<IExplorePreviewResult> {
  state = {
    showEditor: false,
    showEditorLoader: true,
  };

  store = this.props.store;

  onChange = content => content;

  onSubmit = content => {
    this.store.insight.setDraftKeywords(content);
    this.setEditorDisplay(false);
  };

  setEditorDisplay = (vis: boolean) => {
    this.setState({ showEditor: vis });
  };

  componentDidMount() {
    if (this.props.update && this.store.insight?.draftKeywords === undefined) {
      this.store.insight.setDraftKeywords(this.store.insight?.result.keywords);
    }
  }

  render() {
    const { insight } = this.store;
    const { update, setCurrentStep, onSaveHandler } = this.props;

    return (
      <>
        <Prompt
          when={this.state.showEditor}
          message="Are you sure you want to leave? You may have unsaved changes."
        />
        {/* Back and Next buttons */}
        <div
          className="d-flex bg-white align-items-center justify-content-end w-100 border-bottom"
          style={{ height: 60, padding: 10, borderRadius: "4px 4px 0px 0px" }}
        >
          <Button
            className="mr-2"
            onClick={() => {
              setCurrentStep("chart");
              getMixpanel(insight.parent).track("Insight Builder > Chart", { "Button Click": "Back" });
            }}
            disabled={this.state.showEditor}
            size="small"
          >
            <Icon name="arrow left" /> Back
          </Button>

          <Button
            id="save-btn-preview-step"
            className="bg-primary text-white bg-hover-red"
            onClick={() => onSaveHandler()}
            size="small"
            disabled={this.state.showEditor}
          >
            Save insight<Icon name="save" className="ml-2 mr-0" />
          </Button>
        </div>
        {/* Insight preview */}
        <div className="overflow-auto" style={{ height: "calc(100% - 60px)" }}>
          <div className="mt-5 mb-5 mx-auto" style={{ width: 920 }}>
            {/* Insight name */}
            <Box style={{ marginBottom: "20px" }}>
              <div>
                <i
                  className="far fa-lightbulb fa-2x"
                  style={{ marginLeft: "5px", color: COLORS.indigo600, marginRight: "16px", width: "40px", lineHeight: "62px" }}
                />
              </div>
              <Editable
                text={update ? insight.result.name : insight.draftName}
                save={name => insight.setDraftName(name)}
                placeholder={update ? null : "Click to edit insight name"}
                style={{
                  color: `${insight.result.name.includes("Untitled") ? "#919191" : COLORS.indigo600}`, padding: "0", fontWeight: "bold", fontSize: "45px", fontFamily: "Open Sans", fontStyle: "normal", borderColor: "#C3C3C3", width: "66%",
                }} id="insightName" />
            </Box>

            {/* Insight description */}
            <EditName desc style={{ marginTop: 20, marginBottom: 40, width: "100%" }}>
              <div className="pdf-hide mt-4 mb-4 d-sm-flex align-items-sm-center justify-content-sm-between">
                <Heading>Summary</Heading>
                {
                  this.state.showEditor ?
                  <div>
                    <StyleCancel onClick={() => this.setEditorDisplay(false)}>
                      Cancel<StyledIcon className="fas fa-archive" />
                    </StyleCancel>
                    <StyleSave onClick={() => this.onSubmit(getTinymce(window)?.activeEditor?.getContent())}>
                      Save<StyledIcon className="fas fa-save" />
                    </StyleSave>
                  </div> :
                  <>
                    {
                      !insight.draftKeywords ?
                      <InvertedButton onClick={() => this.setEditorDisplay(true)}>
                        Write summary<StyledIcon className="fas fa-edit" />
                      </InvertedButton> :
                      <InvertedButton onClick={() => this.setEditorDisplay(true)}>
                        Edit summary<StyledIcon className="fas fa-edit" />
                      </InvertedButton>
                    }
                  </>
                }
              </div>
              {
                this.state.showEditor ?
                <>
                  {this.state.showEditorLoader && <ContentPlaceholder color="#FFFFFF" />}
                  <TinymceEditor
                    description={insight.draftKeywords}
                    initHandler={() => this.setState({ showEditorLoader: false })}
                  />
                </> :
                <>
                  {
                    insight.draftKeywords ?
                    <StyledDescription
                      dangerouslySetInnerHTML={{ __html: insight.draftKeywords }}
                    /> : (
                      <EmptyStateSecondary
                        hideIcon
                        heading="No Insight Summary Yet"
                        description={<ContentPageEmptyStateDescription onClick={() => this.setEditorDisplay(true)} />}
                      />
                    )
                  }
                </>
              }
            </EditName>
            {/* Main Insight Data Table & Chart Content */}
            <NewInsightContent readonly />

          </div>
        </div>
      </>
    );
  }
}

export const ExplorePreviewResult = withRouter(inject((stores: any) => ({
  store: stores.store,
}))(observer(ExplorePreviewResultComponent)));
