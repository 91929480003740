import * as React from "react";
import * as ReactDOM from "react-dom";
import { StyledDescription } from "pages/Suitcase/suitcaseOverviewPage";
import { DataFilter } from "./DataFilter";
import { useLocation } from "react-router-dom";
import { injectDPACCustomizations } from "component/DPACGIATCustomForm/InjectCustomFormMultiRow";

interface ComponentProps {
  html: any;
  dashboardID: number | string;
}

export const DashboardHtml = (props: ComponentProps): JSX.Element => {
  const { html, dashboardID } = props;
  const descriptionID = `dashboard_description_${dashboardID}`;
  // this state is used to ensure the parent div is mounted and mutation observer attached before adding the html child element
  const [mounted, setMounted] = React.useState(false);
  const location = useLocation();

  // render components for custom data filter tool
  const addDataFilterComponents = () => {
    const targetList = document.querySelectorAll(`#${descriptionID} .custom-tool-data-filter`);
    targetList.forEach(node => {
      const select = node.querySelector("select") as HTMLSelectElement;
      const variable = select.dataset.seerVariable!; // this will always be set
      const categories: { name: string; selected: boolean }[] = (Array.from(select.children) as HTMLOptionElement[]).map(opt => ({ name: opt.value, selected: opt.selected }));
      ReactDOM.render(<DataFilter variable={variable} categories={categories} dashboardDescriptionID={descriptionID} />, node);
    });
  };

  React.useEffect(() => {
    const observer = new MutationObserver((mutations, _observer) => {
      for (const mutation of mutations) {
        const target = mutation.target;
        if (target instanceof HTMLDivElement && target.id === descriptionID && mutation.addedNodes.length) {
          // dangerouslySetInnerHTML is now mounted, and we can attach any desired listeners / components
          addDataFilterComponents();
          injectDPACCustomizations(+dashboardID);
          _observer.disconnect();
          break;
        }
      }
    });
    const targetNode = document.getElementById(descriptionID);
    if (targetNode) {
      observer.observe(targetNode, { childList: true });
    }
    setMounted(true);
    return () => {
      observer.disconnect(); // doesn't matter if it's called multiple times
    };
  }, [html]);

  // Scroll to the anchored section when anchor link is set in URL after mounted
  React.useEffect(() => {
    if (mounted) {
      const { hash } = location;
      if (hash) {
        const findElement = document.querySelector(`a[name="${hash.slice(1)}"], ${hash}`);
        if (findElement) {
          findElement.scrollIntoView({ behavior: "auto" });
        }
      }
    }
  }, [mounted]);

  return (
    <div id={descriptionID}>
      {mounted && (
        <StyledDescription dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </div>
  );
};
