import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Form, Icon, Modal } from "semantic-ui-react";
import { getMixpanel } from "../common/api";
import { Button as StyledButton } from "../common/styledComponents/elements";
import { FormRadioGroupSimple } from "./FormRadioGroupSimple";
import { feedbackModalCompleted } from "common/helpers/user";

import { Helmet } from "react-helmet";

// Customer Satisfaction Survey Modal
// Example Modal Trigger:
//   history.push("/feedback", { background: history.location });

export const FeedbackModal = inject("store")(
  observer((props: any) => {
    const store = props.store;
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [hoverPlatformRating, setHoverPlatformRating] = useState(undefined);
    const [platformRating, setPlatformRating] = useState(undefined);
    const [howDisappointed, setHowDisappointed] = useState(undefined);
    const [strengths, setStrengths] = useState(undefined);
    const [improvements, setImprovements] = useState(undefined);

    // Modal Actions

    const handleClose = e => {
      setIsOpen(false);
      e.stopPropagation();
      const locationState: any = history.location.state;
      if (locationState && locationState.background) {
        history.push(locationState.background);
      } else {
        history.push("/");
      }
    };

    const handleOpen = () => setIsOpen(true);

    // Submission Handler

    const saveToMixpanel = () => {
      const mixpanelProperties = {};
      modalStep.properties!.map(property => {
        if (property.value) {
          mixpanelProperties[property.name] = property.value;
        }
      });
      if (Object.keys(mixpanelProperties).length) {
        getMixpanel(store).track("Feedback Submitted", mixpanelProperties);
      }
    };

    // Rating Functions

    const handleRate = value => setPlatformRating(value);
    const handleRatingHover = value => setHoverPlatformRating(value);
    const handleRatingHoverMouseOut = () => setHoverPlatformRating(undefined);

    // Written Feedback Functions

    const handleStrengthFeedback = (_, data) => setStrengths(data.value);
    const handleImprovementFeedback = (_, data) => setImprovements(data.value);

    // Modal Content JSX

    const platformRatingContent = (
      <>
        <h2 id="feedbackOverallRatingLabel">How would you rate the platform as a whole?</h2>
        <div className="d-inline-flex align-items-center" role="radiogroup" aria-labelledby="feedbackOverallRatingLabel">
          {/* Rating Stars */}
          {[...Array(10)].map((_, i) => (
            <span
              role="radio"
              aria-checked={platformRating === (i + 1)}
              className="text-medium"
              onMouseEnter={() => handleRatingHover(i + 1)}
              onMouseLeave={handleRatingHoverMouseOut}
              onClick={() => handleRate(i + 1)}
            >
              <span className="sr-only">{i + 1} Stars</span>
              <Icon
                name="star"
                size="large"
                className={
                  ((hoverPlatformRating && hoverPlatformRating! > i) ||
                    (!hoverPlatformRating && platformRating && platformRating! > i)) &&
                  "text-info"
                }
              />
            </span>
          ))}
          {/* Rating Value */}
          <h3 className="mt-0 ml-2 text-info fw-700" style={{ minWidth: "23px", minHeight: "23px" }}>
            {(platformRating || hoverPlatformRating) && `${hoverPlatformRating || platformRating || "  "}`}
          </h3>
        </div>
      </>
    );

    const howDisappointedContent = (
      <>
        <h2 id="feedbackNoLongerUseLabel">How would you feel if you could no longer use Seer Data?</h2>
        <div className="d-inline-flex align-items-center">
          <FormRadioGroupSimple
            labelID="feedbackNoLongerUseLabel"
            options={[
              "Not at all disappointed",
              "Not disappointed",
              "Undecided",
              "Somewhat disappointed",
              "Very disappointed",
            ]}
            setValue={setHowDisappointed}
          />
        </div>
      </>
    );

    const writtenFeedbackContent = (
      <>
        <Form>
          <h2 id="feedbackMainBenefitLabel">What is the main benefit you receive from Seer Data?</h2>
          <Form.TextArea
            name="main_benefit"
            aria-labelledBy="feedbackMainBenefitLabel"
            placeholder="Tell us your favourite features and the value you get from Seer Data."
            rows={3}
            onChange={handleStrengthFeedback}
            className="w-75 mx-auto"
          />

          <h2 id="feedbackHowImproveLabel">How can we improve Seer Data for you?</h2>
          <Form.TextArea
            name="how_improve"
            aria-labelledBy="feedbackHowImproveLabel"
            placeholder="Tell us your frustrations and what would make your life easier."
            rows={3}
            onChange={handleImprovementFeedback}
            className="w-75 mx-auto"
          />
        </Form>
      </>
    );

    const thankYouContent = (
      <>
        <div className="mx-auto text-center w-75 mb-4">
          <h2>Thank You!</h2>
          <h3>
            Thanks for helping us on our mission to become the world's problem solving platform for local communities.
          </h3>
        </div>
        <p>Your feedback will be used to guide our decision-making and product strategy.</p>
      </>
    );

    const steps = [
      {
        properties: [{ name: "Feedback: Platform Rating", value: platformRating }],
        action: () => {
          saveToMixpanel();
          setCurrentStep(1);
        },
        actionName: "Next",
        content: platformRatingContent,
      },
      {
        properties: [{ name: "Feedback: Loss of Access", value: howDisappointed }],
        action: () => {
          saveToMixpanel();
          setCurrentStep(2);
        },
        actionName: "Next",
        content: howDisappointedContent,
      },
      {
        properties: [
          { name: "Feedback: Open-Ended - Strengths", value: strengths },
          { name: "Feedback: Open-Ended - Improvements", value: improvements },
        ],
        action: () => {
          saveToMixpanel();
          setCurrentStep(3);
        },
        actionName: "Submit",
        content: writtenFeedbackContent,
      },
      {
        header: "",
        action: e => {
          handleClose(e);
          feedbackModalCompleted(); // update cookie
        },
        actionName: "Close",
        content: thankYouContent,
        footer: "",
      },
    ];

    const modalStep = steps[currentStep];

    return (
      <>
        <Helmet>
          <title>Feedback</title>
        </Helmet>
        <Modal
          className="text-center"
          size="small"
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          closeOnDimmerClick={false}
          closeIcon
        >
          <Modal.Header>{modalStep.header ?? "We would love to hear from you"}</Modal.Header>
          <Modal.Content className="text-center my-4 my-auto">
            <img src="../assets/seer-icon.png" width="100" className="my-3" alt="Seer Data icon" />
            {modalStep.content}
          </Modal.Content>
          <Modal.Actions>
            <div className="mx-auto text-center w-75">
              <p>
                {modalStep.footer ??
                  "We really want to understand how we are meeting your needs, so we appreciate your honest feedback."}
              </p>
            </div>
            <StyledButton onClick={modalStep.action} className="mb-3">
              {modalStep.actionName}
            </StyledButton>
          </Modal.Actions>
        </Modal>
      </>
    );
  })
);
