import * as React from "react";
import { toJS } from "mobx";
import { insertScript } from "common/helpers/script";
import { CustomImageLibrary, imageUploadHandler } from "./CustomImageLibrary";
import { InsightPickerModal } from "./InsightPickerModal";
import { ObjectAny } from "common/helpers/types";
import insightTool, { insightToolConfig, designUpdateInsightRefreshHandler } from "./includes/customJsInsightTool";
import { webUrl } from "common/constants";
import { unlayerCustomCSS } from "./includes/unlayerCustomCSS";
import { COLORS } from "component/UI/common";

interface IUnlayerEditor {
  config: ObjectAny | undefined;
  token: string;
}

export class UnlayerEditor extends React.Component<IUnlayerEditor> {
  editor: any = null;

  state: any = {
    isCustomImageLibraryOpen: false,
    unlayerImageDoneFn: null,
    insightModalOpen: false,
    insightModalEventData: null,
  };

  insightToolMessageHandler = (event: MessageEvent): any => {
    if (event.origin === "https://editor.unlayer.com" && event.data?.insightTool) {
      this.setState({ insightModalOpen: true, insightModalEventData: event.data?.insightTool });
    }
  };

  unLayerInit = async (): Promise<void> => {
    // insert unlayer on mount
    await insertScript("unlayer", { type: "text/javascript", src: "https://editor.unlayer.com/embed.js" });
    this.editor = window["unlayer"].createEditor({
      id: "unlayer-editor",
      projectId: 69944,
      displayMode: "web",
      customJS: [insightTool()],
      customCSS: [unlayerCustomCSS],
      tabs: {
        blocks: {
          enabled: false,
        },
        images: {
          enabled: false,
        },
      },
      features: {
        stockImages: { enabled: false },
        userUploads: false,
        textEditor: {
          tables: true,
          inlineFontControls: true,
          spellChecker: true,
        },
        pageAnchors: true,
      },
      tools: {
        ...insightToolConfig,
        form: {
          enabled: false,
        },
        button: {
          properties: {
            buttonColors: {
              value: {
                backgroundColor: COLORS.indigo500,
                hoverBackgroundColor: COLORS.indigo600,
              },
            },
          },
        },
        image: {
          properties: {
            src: {
              value: {
                url: `${webUrl}/assets/unlayer_placeholder.jpg`,
              },
            },
          },
        },
      },
    });
    this.editor!.loadBlank({
      backgroundColor: "#f5f5f5",
    });
    this.editor.addEventListener("editor:ready", () => {
      if (this.props.config) {
        this.editor.loadDesign(toJS(this.props.config));
      }
    });
    this.editor!.addEventListener("design:loaded", () => {
      if (!this.props.config) {
        this.editor!.setBodyValues({
          backgroundColor: "#f5f5f5",
          contentWidth: "100%",
        });
      }
    });
    this.editor!.registerCallback("selectImage", (_data, done) => {
      this.setState({ isCustomImageLibraryOpen: true });
      this.setState({ unlayerImageDoneFn: args => done(args) });
    });
    designUpdateInsightRefreshHandler(this.editor!);
    // register event listener to pick up custom insight tool messages from iframe
    window.addEventListener("message", this.insightToolMessageHandler, false);
  };

  componentDidMount(): void {
    this.unLayerInit();
  }

  componentWillUnmount(): void {
    window.removeEventListener("message", this.insightToolMessageHandler, false);
  }

  render(): JSX.Element {
    const { isCustomImageLibraryOpen, unlayerImageDoneFn, insightModalOpen, insightModalEventData } = this.state;
    return (
      <>
        {/* Unlayer Custom Image Upload Modal */}
        <CustomImageLibrary
          isOpen={isCustomImageLibraryOpen}
          done={unlayerImageDoneFn}
          close={() => this.setState({ isCustomImageLibraryOpen: false })}
          handleUpload={imageUploadHandler(this.props.token)}
        />
        <InsightPickerModal
          editorRef={this.editor}
          isOpen={insightModalOpen}
          data={insightModalEventData}
          close={() => this.setState({ insightModalOpen: false, insightModalEventData: null })}
        />
        <div id="unlayer-editor" style={{ height: "80vh" }} />
      </>
    );
  }
}
