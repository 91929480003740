import * as React from "react";
import { Avatar } from "./Avatar";

export interface AvatarItemProps {
  name: string;
  image: string;
}

interface AvatarGroupProps {
  items: AvatarItemProps[];
  size?: number;
}

const interleaveArray = (arr1, arr2) => {
  const result: any[] = [];
  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    if (arr1[i] !== undefined) {
      result.push(arr1[i]);
    }
    if (arr2[i] !== undefined) {
      result.push(arr2[i]);
    }
  }
  return result;
};

const maxDisplaySize = 5;

export function AvatarGroup({ items, size = 40 }: AvatarGroupProps) {
  // Display items alternating between items with images and without
  const itemsWithImages = items.filter(item => item.image);
  const itemsWithoutImages = items.filter(item => !item.image);
  const sortedItems = interleaveArray(itemsWithImages, itemsWithoutImages);

  return (
    <>
      {sortedItems.slice(0, maxDisplaySize).map((item, i, arr) => (
        <Avatar
          key={i}
          name={item.name}
          image={item.image}
          innerAvatarStyle={{ boxShadow: "white 0px 0px 0px 1px" }}
          style={{ marginRight: "-6px", zIndex: arr.length - i }}
          size={size}
        />
      ))}
      {sortedItems.length > maxDisplaySize || sortedItems.length === 0 ? (
        <div
          style={{
            marginRight: "-6px",
            boxShadow: "white 0px 0px 0px 1px",
            borderRadius: "50%",
            border: "none",
            backgroundColor: "#d2d2d2",
          }}
        >
          <div
            className="UserAvatar--inner"
            style={{
              lineHeight: sortedItems.length === 0 ? "25px" : `${size}px`,
              width: sortedItems.length === 0 ? "25px" : `${size}px`,
              height: sortedItems.length === 0 ? "25px" : `${size}px`,
              color: "darkslategrey",
              fontWeight: 600,
              fontSize: "13px",
              textAlign: "center",
            }}
          >
            {sortedItems.length === 0 ? "0" : `+${sortedItems.length - maxDisplaySize}`}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
