import { environment } from "common/constants";

export const templateInsights = environment === "production"
  ? {
    "Ancestry": [
      { id: 68198, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68199, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68197, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Cultural background": [
      { id: 68171, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68170, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68169, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Education": [
      { id: 68173, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68174, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68172, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Families": [
      { id: 68175, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68177, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68176, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Finance & Income": [
      { id: 68178, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68180, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68179, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "General characteristics": [
      { id: 68181, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68182, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68183, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Health & Wellbeing": [
      { id: 68187, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68185, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68186, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Housing": [
      { id: 68188, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68190, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68189, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Relationships": [
      { id: 68193, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68191, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68192, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
    "Work, Employment & Volunteering": [
      { id: 68195, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68194, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
      { id: 68196, key: "eeef0817-9d82-4e9f-bb1a-f901ceffe52cb8894fdd-e0af-4df2-9444-b2e680065402" },
    ],
  }
  : {
    "Ancestry": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Cultural background": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Education": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Families": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Finance & Income": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "General characteristics": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Health & Wellbeing": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Housing": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Relationships": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
    "Work, Employment & Volunteering": [
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
      { id: 1945, key: "984e96d7-06b2-40c3-8459-4371d643c75c5380ecb3-9f51-49aa-9cea-532944adb608" },
    ],
  };
