import * as React from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";

interface Props {
  completeness?: number;
}

export const TableCompletenessChart = (props: Props): JSX.Element => {
  const completeness = props.completeness || 0;
  const fill = completeness > 0.66
    ? "#2DC3C2"
    : completeness > 0.33
      ? "#F2C04C"
      : "#D71D4B";
  return (
    <ResponsiveContainer aspect={1}>
      <PieChart>
        <Pie
          dataKey="value"
          data={[{ value: completeness }, { value: 1 - completeness }]}
          innerRadius="70%"
          outerRadius="100%"
          fill="#EFEFEF"
          startAngle={90}
          endAngle={-270}
        >
          <Label position="center" value={`${Math.floor(completeness * 100)}%`}/>
          <Cell fill={fill} />
          <Cell fill="#EFEFEF" />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
