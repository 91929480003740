import * as React from "react";
import styled from "styled-components";
import { Button, ButtonProps, Dropdown, DropdownItemProps, Icon, SemanticICONS } from "semantic-ui-react";
import { COLORS } from "./common";

interface ColorConfig {
  bg: string;
  text: string;
  hover?: {
    bg: string;
    text: string;
  };
}

type ColorConfigOptions = "red" | "purple" | "aqua";

interface DropdownButtonProps extends ButtonProps {
  icon?: SemanticICONS;
  colorConfig?: ColorConfigOptions;
  dropdownOptions: DropdownItemProps[];
}

const DROPDOWN_BUTTON_COLORS: Record<string, ColorConfig> = {
  disabled: {
    bg: COLORS.red500,
    text: "#FFF",
  },
  red: {
    bg: COLORS.red500,
    text: "#FFF",
    hover: {
      bg: COLORS.red600,
      text: "#FFF",
    },
  },
  purple: {
    bg: COLORS.indigo600,
    text: "#FFF",
    hover: {
      bg: COLORS.indigo500,
      text: "#FFF",
    },
  },
  aqua: {
    bg: COLORS.aqua400,
    text: "#FFF",
    hover: {
      bg: COLORS.aqua500,
      text: "#FFF",
    },
  },
};

const getColorConfig = (props: DropdownButtonProps) => {
  if (props.disabled) {
    return DROPDOWN_BUTTON_COLORS.disabled;
  } else {
    return DROPDOWN_BUTTON_COLORS[props.colorConfig || "red"];
  }
};

const StyledButtonGroup = styled(Button.Group)<any>`
  min-height: 40px !important;
  .ui.button {
    background: ${(props: DropdownButtonProps) => getColorConfig(props).bg} !important;
    color: ${(props: DropdownButtonProps) => getColorConfig(props).text} !important;
    font-size: 1rem !important;
    font-family: "Open Sans" !important;
    &:hover {
      background: ${(props: DropdownButtonProps) => getColorConfig(props).hover?.bg || getColorConfig(props).bg} !important;
      color: ${(props: DropdownButtonProps) => getColorConfig(props).hover?.text || getColorConfig(props).text}} !important;
    }
  }
  &.ui.buttons .button:first-child {
    padding-right: 1rem !important;
    i.icon {
      margin-left: 0.5rem !important;
      margin-right: 0 !important;
      opacity: 1;
      font-size: 1rem !important;
    }
  }
  &.ui.buttons .button:last-child {
    i.icon {
      margin-top: 2px !important;
      opacity: 1;
    }
  }
`;

export const DropdownButton = (props: DropdownButtonProps): JSX.Element => {
  const { label, icon, disabled, colorConfig, onClick, dropdownOptions, ...rest } = props;
  return (
    <StyledButtonGroup disabled={disabled} colorConfig={colorConfig} {...rest}>
      <Button disabled={disabled} onClick={onClick}>
        {label}<Icon name={icon} />
      </Button>
      <Dropdown disabled={disabled} floating className="button icon" trigger={<></>}>
        <Dropdown.Menu>
          {dropdownOptions.map((item, idx) => {
            const { icon, text, onClick } = item;
            return (
              <Dropdown.Item key={idx} text={text} icon={icon} onClick={onClick} />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </StyledButtonGroup>
  );
};
