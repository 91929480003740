import * as React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { DateTime } from "luxon";
import Store from "../common/store/suitcase";
import { getMixpanel } from "../common/api";
import { $darkestgrey } from "../common/styledComponents/_config.style";
import { COLORS } from "./UI/common";

const TH = styled.td`
  background: ${$darkestgrey};
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  height: 30px;
  letter-spacing: 0.05em;
  font-weight: bold;
`;

const File = styled.td`
  color: #2d2d2d;
  text-align: center;
  font-size: 15px;
`;

const Name = styled.td`
  font-size: 18px;
  height: 45px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Date = styled.td`
  font-size: 15px;
  color: #2d2d2d;
`;

const Del = styled.td`
  color: ${COLORS.red500};
  float: right;
  margin-right: 10px;
  line-height: 42px;
  cursor: pointer;
  font-size: 15px;
`;

type PropsType = RouteComponentProps<{}> & {
  store?: Store;
  readonly?: boolean;
  isPublicURL?: boolean;
};

class TableListView extends React.Component<PropsType> {
  store = this.props.store!;
  input: HTMLInputElement | null | void = name;
  project_ids = this.store.parent.suitcase.projects.map(project => project.id);
  prepacked_suitcase_user_ids = this.store.parent.suitcase.prepackedprojects.map(user => user.id);
  // this.project_ids.includes(this.store.parent.suitcase.active!.id)
  // this.prepacked_suitcase_user_ids.parent.includes(this.store.user!.id)
  types = {
    ppt: "powerpoint",
    pptx: "powerpoint",
    doc: "word",
    docx: "word",
    xlsx: "excel",
    xls: "excel",
    png: "image",
    jpg: "image",
    jpeg: "image",
    gif: "image",
    mp4: "video",
    avi: "video",
    mov: "video",
    mkv: "video",
    pdf: "pdf",
  };

  deleteFile(id: number) {
    if (window.confirm("Are you sure you want to delete this file?")) {
      this.store.deleteFile(id);
      getMixpanel(this.store.parent).track("Delete attachment");
    }
  }

  render() {
    const { isPublicURL } = this.props;
    return (
      <table style={{ borderCollapse: "collapse", marginBottom: "20px" }}>
        <tbody>
          <tr>
            <TH style={{ width: "60px", textAlign: "center" }}>Type</TH>
            <TH
              style={{
                width: "550px",
              }}
            >
              Name
            </TH>
            <TH style={{ width: "280px" }}>Attached</TH>
            <TH style={{ width: "50px" }} />
          </tr>
          {this.store.active!.files.reverse().map((att, i) => {
            let name: any = att.name;
            const type = name.split(".")[name.split(".").length - 1];
            name = name.split(".");
            name.pop();
            name.join(".");
            return (
              <tr key={i} style={{ borderBottom: "1px solid #C3C3C3" }}>
                <File>
                  <i className={`far fa-file${this.types[type] ? `-${this.types[type]}` : ""}`} />
                </File>
                <Name>
                  <a style={{ color: COLORS.indigo600 }} href={att.file}>
                    {name}
                  </a>
                </Name>
                <Date>
                  {" "}
                  {/* This is split up for lowercase AM / PM. Luxon doesn't support it*/}
                  {DateTime.fromISO(att.created_at).setZone("local").toFormat("h:mm")}
                  {DateTime.fromISO(att.created_at)
                    .setZone("local")
                    .toFormat("a")
                    .toLowerCase()}{" "}
                  {DateTime.fromISO(att.created_at).setZone("local").toFormat("DDD")}
                </Date>
                {!isPublicURL && (
                  <Del onClick={() => this.deleteFile(att.id)}>
                    <i className="far fa-trash-alt" />
                  </Del>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default withRouter(inject("store")(observer(TableListView)));
