import * as React from "react";
import { Icon } from "semantic-ui-react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store/insightbuilder";
import { StylePopup, Title } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";

interface IDropdownTitle {
  insightbuilder?: Store | undefined;
  forMultiStep?: boolean;
  heading?: string;
  title: string;
  tooltip: string;
};

const DropdownTitleComponent = ({ insightbuilder, forMultiStep, heading, title, tooltip }: IDropdownTitle): JSX.Element => {
  if (forMultiStep) {
    // Show sentence and description on Multi-step page
    return (
      <>
        <h1 className="ff-primary fw-800 text-secondary fs-2000 mb-3">
          {heading}
        </h1>
        {
          tooltip === "" ||
          <h4 className="ff-primary fw-400 text-dark fs-1125 mt-0 mb-4">
            {tooltip}
          </h4>
        }
      </>
    );
  } else {
    // Show short title and info icon on Insight Builder page
    return (
      <div className="d-flex">
        <Title>
          {title}
        </Title>
        {
          tooltip === "" ||
          <StylePopup
            trigger={
              <Icon
                name="info circle"
                className="text-medium ml-1 cursor-pointer"
                size="small"
              />
            }
            content={tooltip}
            position="right center"
            size="mini"
            inverted
          />
        }
        {/* Loader for selecting options (insightbuilder.formatOutput()) */}
        {
          insightbuilder!.loading.selectOptions ?
          <div style={{ marginLeft: "auto" }}>
            <img src="/assets/small-loader.gif" style={{ height: 24 }} alt="Loading..." />
          </div> : null
        }
      </div>
    );
  }
};

export const DropdownTitle = inject((stores: any) => ({
  insightbuilder: stores.store.insightbuilder,
}))(observer(DropdownTitleComponent ));
