import React = require("react");
import styled from "styled-components";
import { COLORS } from "component/UI/common";

export const OnboardingContainer = styled.div`
  display: flex;
  width: 972px;
  height: 680px;
  background: white;
  border-radius: 0.6rem;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  overflow: auto;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin: 0px 20px;
    flex-direction: column;
    height: auto;
  }
`;

export const BaseContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const LeftContentContainer = styled(BaseContainer)`
  padding: 32px;
  @media only screen and (max-width: 576px) {
    padding: 32px 12px 12px 12px;
  }
`;

export const RightContentContainer = styled(BaseContainer)`
  padding: 32px;
  @media only screen and (max-width: 991px) {
    padding-top: 0;
  }
  @media only screen and (max-width: 576px) {
    padding: 0 12px 12px 12px;
  }
`;

export const ArtworkContainer = styled.div`
  padding: 32px;
  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
`;

export const Heading1 = styled.p`
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: ${COLORS.indigo600};
  @media only screen and (max-width: 991px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 1rem;
  }
`;

export const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  &:hover {
    background: ${COLORS.indigo200};
    transition: background 400ms;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 159px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface RoleCardProps {
  url: string;
  heading: string;
  description: string;
  onClick: () => void;
}

export const RoleCard = (props: RoleCardProps): JSX.Element => {
  const { url, heading, description, onClick } = props;
  return (
    <StyledCard onClick={onClick}>
      <ImageContainer className="image-container">
        <img src={url} alt={heading} />
      </ImageContainer>
      <div className="p-3">
        <p className="fs-1000 fw-700 mb-2" style={{ color: COLORS.grey800 }}>{heading}</p>
        <p className="fs-1000" style={{ color: COLORS.grey800 }}>{description}</p>
      </div>
    </StyledCard>
  );
};

interface LeftContentWrapperProps {
  content: React.ReactNode;
  buttons: React.ReactNode;
  currentStep: number;
}

export const LeftContentWrapper = (props: LeftContentWrapperProps): JSX.Element => {
  const { content, buttons, currentStep } = props;
  return (
    <div className="d-flex flex-column align-items-start justify-content-between h-100 position-relative">
      <OnboardingStepper total={3} active={currentStep} />
      <div className="flex-grow-1 d-flex align-items-center mt-5 mb-3 mb-sm-5 my-lg-0 w-100">
        <div className="w-100">
          {content}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-start justify-content-sm-end w-100">
        {buttons}
      </div>
    </div>
  );
};

const OnboardingStepper = (props): JSX.Element | null => {
  const { total, active } = props;
  if (total <= 0) {
    return null;
  }
  const steps = new Array(total).fill(0);
  return (
    <div className="d-flex position-absolute" style={{ top: 0, left: "50%", transform: "translateX(-50%)" }}>
      {steps.map((_, idx) => (
        <div key={idx} style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: idx + 1 <= active ? COLORS.aqua500 : COLORS.aqua200, marginRight: idx + 1 === total ? 0 : 16 }}></div>
      ))}
    </div>
  );
};
