import * as React from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";

const Text = styled.textarea<{ focus: boolean; editColor?: string }>`
  resize: none;
  height: 1px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  position: relative;
  top: 5px;
  ${props =>
    props.focus &&
    `
    border: 1px solid #919191;
    border-radius: 5px;
    background-color: white;
    ${props.editColor && `color: ${props.editColor} !important;`}
  `}
`;

class Store {
  @observable focus: boolean = false;
  @observable value: string = "";
  windowWidth: number = 0;
}

interface IProps {
  id: string; // Id for element
  save: (v: string) => void; // When user finishes, outputs the value
  text: string; // defaultValue
  readOnly?: boolean;
  style?: React.CSSProperties;
  placeholder?: string | null;
  onChange?: (v: string) => void;
  editColor?: string; // Text color when editing
  handleKey?: (key: string) => void;
  onCancel?: () => void; // Pressing escape
  store?: any; // global store
}

export default inject("store")(observer(
  class Editable extends React.Component<IProps> {
    store = new Store(); // local store
    el?: HTMLTextAreaElement;

    componentDidMount() {
      this.store.value = this.props.text;
      this.el = document.getElementById(this.props.id) as HTMLTextAreaElement;
      this.update();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
      const ui = this.props.store.ui;
      // don't overwrite current value if there is no data change because user may be editing the text
      if (prevProps.text !== this.props.text) {
        this.el!.value = this.props.text;
        this.update();
      } else if (this.store.windowWidth !== ui.windowWidth) {
        this.store.windowWidth = ui.windowWidth;
        this.update();
      }
    }

    handleKey(e: any) {
      this.props.handleKey && this.props.handleKey(e);
      if (e.key === "Escape") {
        this.el!.value = this.store.value;
        this.store.focus = false;
        this.el!.blur();
      }
    }

    update() {
      this.el!.style.height = "1px";
      this.el!.style.height = `${this.el!.scrollHeight + 4}px`;
    }

    save() {
      if (this.store.focus) {
        this.store.value = this.el!.value;
        this.props.save(this.el!.value);
        this.el!.value === "" && (this.el!.placeholder = this.props.placeholder || "");
        this.store.focus = false;
      } else {
        this.props.onCancel && this.props.onCancel();
      }
    }

    focus() {
      if (!this.props.readOnly) {
        this.store.focus = true;
        this.el!.placeholder = "";
      }
    }

    render() {
      return (
        <Text
          readOnly={this.props.readOnly}
          id={this.props.id}
          onChange={e => {
            this.update();
            this.props.onChange && this.props.onChange(e.target.value);
          }}
          onFocus={() => this.focus()}
          onBlur={() => this.save()}
          onKeyDown={e => this.handleKey(e)}
          focus={this.store.focus}
          spellCheck={false}
          style={this.props.style}
          defaultValue={this.props.text}
          placeholder={this.props.placeholder}
          editColor={this.props.editColor}
        />
      );
    }
  }
));
