import * as numeral from "numeral";
import { IValue } from "common/store/insightbuilder";
import {
  cloneDeep,
  find,
  findIndex,
  includes,
  uniq,
  isEqual,
} from "lodash";
import { escapeRegExp } from "./string";
import { IDimension, IFilter, Value, IDimensionValue } from "common/store/builder";
import { buildArrayCombos } from "./dimensions";

// Date time helpers

enum Month {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

enum Quarter {
  // Financial Year Quarters
  Q1 = 1,
  Q2,
  Q3,
  Q4,
  "Jan-Mar",
  "Apr-Jun",
  "Jul-Sep",
  "Oct-Dec",
  // There are months in the production data in some sources
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

// Sort function for ["Year Month", "Year Quarter"]
export const sortYear = (type = "Month") => (a, b) => {
  const typeEnum = type === "Month" ? Month : Quarter;
  const [aYear, aTypeString] = a.name.split(" ");
  const [bYear, bTypeString] = b.name.split(" ");
  const aYearNum = Number(aYear);
  const bYearNum = Number(bYear);

  // some data is in an invalid format to sort and we ignore, e.g. "Unnamed: 291" etc
  if (isNaN(aYearNum)) {
    return 1;
  } else if (isNaN(bYearNum)) {
    return -1;
  }

  // compare year
  if (Number(aYear) < Number(bYear)) {
    return -1;
  } else if (Number(aYear) > Number(bYear)) {
    return 1;
  }

  // compare month if year is equal
  if (typeEnum[aTypeString] < typeEnum[bTypeString]) {
    return -1;
  } else if (typeEnum[aTypeString] > typeEnum[bTypeString]) {
    return 1;
  }

  // no change if equal year and month (which shouldn't occur as values should be unique)
  return 0;
};

// helper for sortWeek function
const getWeekTime = (name: Value): boolean | number => {
  if (typeof name !== "string") {
    return false;
  }
  const match = name.match(/^(\d{4})\D(\d{2})\D(\d{2})$/);
  if (!match) {
    return false; // invalid format
  }
  const [_, year, month, day] = match;
  const date = new Date(`${month}/${day}/${year}`).getTime();
  if (isNaN(date)) {
    return false; // invalid date
  }
  return date;
};

// sort function for "Week Starting" and "Week Ending" formatted as "YYYY-MM-DD"
// allow for any non numeric symbol between the date numerals, not just '-'
export const sortWeek = (a: IDimensionValue, b: IDimensionValue): number => {
  const aTime = getWeekTime(a.name);
  const bTime = getWeekTime(b.name);
  if (!aTime && !bTime) {
    return 0; // both invalid
  }
  if (!aTime) {
    return 1; // a is invalid
  }
  if (!bTime) {
    return -1; // b is invalid
  }
  return (aTime as number) - (bTime as number);
};

// Helper for sortAgeRange function
// Return an array of the first number and the second number (if exists)
const getAgeValues = (name: Value): (string | undefined)[] | false => {
  if (typeof name !== "string") {
    return false;
  }
  const match = name.match(/^(\d+)(?:-)?(\d+)?/);
  if (!match) {
    return false; // invalid format
  }
  const [_, firstNumber, secondNumber] = match;
  return [firstNumber, secondNumber];
};

// sort function for "Age", "Age Range" and "AFM Age Range" formatted as "# <string>", "#-# <string>" or "#+ <string>"
export const sortAgeRange = (a: IDimensionValue, b: IDimensionValue): number => {
  const aAgeValues = getAgeValues(a.name);
  const bAgeValues = getAgeValues(b.name);
  if (!aAgeValues && !bAgeValues) {
    return 0; // both invalid
  }
  if (!aAgeValues) {
    return 1; // a is invalid
  }
  if (!bAgeValues) {
    return -1; // b is invalid
  }
  const [aFirstNumber, aSecondNumber] = aAgeValues;
  const [bFirstNumber, bSecondNumber] = bAgeValues;
  // Sort on the value of the first number
  const firstNumberComparison = Number(aFirstNumber) - Number(bFirstNumber);
  if (firstNumberComparison !== 0) {
    return firstNumberComparison;
  }
  // Sort on the second value when the first number matches
  // - sort later if the second value doesn't exist
  if (!aSecondNumber && !bSecondNumber) {
    return 0;
  } else if (aSecondNumber && !bSecondNumber) {
    return -1;
  } else if (!aSecondNumber && bSecondNumber) {
    return 1;
  }
  // - sort on the second number if both have
  return Number(aSecondNumber) - Number(bSecondNumber);
};

// Helper for sortIncomeBracket
// Return an array of the first number and the second number (if exists)
const getIncomeNumbers = (name: Value): (string | undefined)[] | false => {
  if (typeof name !== "string") {
    return false;
  }
  const match = name.replace(/[^\d\.\-\s]/g, "").match(/^([\d\.]+)(?:[^\d]+)?([\d\.]+)?/);
  if (!match) {
    return false; // invalid format;
  }
  const [_, firstNumber, secondNumber] = match;
  return [firstNumber, secondNumber];
};

// sort function for "Equivalised Total Household Income (Weekly)", "Other Income (Range)", "Total family income (weekly)", "Total Gross Income (Range)", "Total Household Income (weekly)", "Total Personal Income (weekly)", "Mortgage repayments (monthly) ranges"
export const sortIncomeBracket = (a: IDimensionValue, b: IDimensionValue): number => {
  const aIncomeNumbers = getIncomeNumbers(a.name);
  const bIncomeNumbers = getIncomeNumbers(b.name);
  if (!aIncomeNumbers && !bIncomeNumbers) {
    return 0; // both invalid
  }
  if (!aIncomeNumbers) {
    return 1; // a is invalid
  }
  if (!bIncomeNumbers) {
    return -1; // b is invalid
  }
  const [aFirstNumber, aSecondNumber] = aIncomeNumbers;
  const [bFirstNumber, bSecondNumber] = bIncomeNumbers;
  // Sort on the value of the first number
  const firstNumberComparison = Number(aFirstNumber) - Number(bFirstNumber);
  if (firstNumberComparison !== 0) {
    return firstNumberComparison;
  }
  // Sort on the second value when the first number matches
  // - sort later if the second value doesn't exist except for when the first number is "0"
  if (!aSecondNumber && !bSecondNumber) {
    return 0;
  } else if (aSecondNumber && !bSecondNumber) {
    return Number(aFirstNumber) === 0 ? 1 : -1;
  } else if (!aSecondNumber && bSecondNumber) {
    return Number(aFirstNumber) === 0 ? -1 : 1;
  }
  // - sort on the second number if both have
  return Number(aSecondNumber) - Number(bSecondNumber);
};

// Value Formatting Functions

const percentKeywords = ["proportion", "percent", "percentage", "rate", "%"];
const percentKeywordExclusions = ["rate per", "quintile", "decile", "percentile", "Total fertility rate (Births)", "Total fertility rate (Total fertility rate)", "rate (per", "per 1", "percentage point"];

export const isPercentage = (dataDescriptor: string|number): boolean => {
  const descriptor = String(dataDescriptor)?.toLowerCase() || "";
  if (descriptor && !percentKeywordExclusions.some(pt => descriptor.includes(pt.toLowerCase()))) {
    // special exclusion for "rate per" with anything in between rate and per keywords
    if (descriptor.match(/(^|[ ])rate .* per($|[ ])/)) {
      return false;
    }
    // check if has percent keywords
    if (percentKeywords.some(pt => {
      const escapedPt = escapeRegExp(pt);
      const test = new RegExp(`^[(]?${escapedPt}[)]? | [(]?${escapedPt}[)]? | [(]?${escapedPt}[)]?$|^[(]?${escapedPt}[)]?$`);
      return descriptor.match(test);
    })) {
      return true;
    }
  }
  return false;
};

// Percentage values should be in decimal format (eg. 0.12 = 12%) - exceptions where the data has not been ingested correctly sometimes has the value as a whole number (eg. 12 = 12%)
// const percentKeywordWholeNumbers = ["(% fully breastfed at", "Total fertility rate (Total fertility rate)"];
const percentKeywordWholeNumbers = []; // left this in, in case we need this in future

export const toPercentage = (value: number, variables?: (string | number)[], fractionDigitsOverride?: number): string => {
  const fractionDigits = value === 1
    ? 0 : typeof fractionDigitsOverride === "number"
      ? fractionDigitsOverride : 2;
  const treatAsWholeNumber = variables?.some(v => percentKeywordWholeNumbers.some(w => String(v).includes(w)));
  if (treatAsWholeNumber) {
    return`${value.toFixed(fractionDigits)}%`;
  } else {
    return `${(value * 100).toFixed(fractionDigits)}%`;
  }
};

// Format value in tables and chart tooltips
export const formatValue = (value: any, variables?: (string | number)[], decimalPoints?: number): string => {
  if (value === null || value === undefined) {
    return "-"; // ensure any missing values are not converted to a number
  }
  const formatAsPercentage = variables?.some(v => isPercentage(v));
  if (formatAsPercentage) {
    return toPercentage(parseFloat(value), variables, decimalPoints);
  } else {
    let format = "0,0.[00]";
    if (typeof decimalPoints === "number") {
      const baseFormat = "0,0";
      const decimalFormatArray: string[] = [];
      if (decimalPoints > 0) {
        decimalFormatArray.push(".[");
        for (let i = 1; i <= decimalPoints; i++) {
          decimalFormatArray.push("0");
        }
        decimalFormatArray.push("]");
      }
      format = baseFormat.concat(decimalFormatArray.join(""));
    }
    return numeral(value).format(format);
  }
};

// miscellaneous data helpers

// Formats array to become compatible with semantic_ui dropdown
export const makeDDF = (arr: string[]): any[] => arr.map(s => ({ text: s, value: s, key: s }));

// Makes the key for the value in tables
export const makeNameTable = (
  colName: string,
  col: string,
  rowName: string,
  row: string,
  filters: string[],
  filterValues: string[]
): string => {
  const dimValues = filters.map((filter, i) => `${filter}${filterValues[i]}`);
  rowName !== "" && dimValues.push(`${rowName}${row}`);
  dimValues.push(`${colName}${col}`);
  return dimValues.sort().join(" ");
};

// @TODO - revise, very inefficient
// Finds the highest number that hasn't been selected yet and pushes its position into the sorted array
export const sortArray = (arr: number[]): number[] => {
  const sorted: number[] = [];
  for (const _ of arr) {
    let highest = { val: -1, i: -1 };
    arr.forEach((val, i) => {
      val > highest.val && !sorted.includes(i) && (highest = { val, i });
    });
    sorted.push(highest.i);
  }
  return sorted;
};

// sort function used by insightbuilder.getSelected
export const customSort = (originalValues: IValue[]): IValue[] => {
  let values: IValue[] = [...originalValues];
  const weighted = values?.[0]?.weight != null;
  if (weighted) {
    values = values.sort((a, b) => a.weight! - b.weight!);
  }
  return values;
};

export const collator = new Intl.Collator("en", { ignorePunctuation: true });

// wheres whens hows
export const wheres = [
  "Indigenous Region (IREG)",
  "Indigenous Area (IARE)",
  "Local Government Area (LGA)",
  "Postal Area (POA)",
  "State Suburb (SSC)",
  "Suburb and Locality (SAL)",
  "Statistical Area Level 2 (SA2)",
  "Statistical Area Level 3 (SA3)",
  "Statistical Area Level 4 (SA4)",
  "Greater Capital City Statistical Area (GCCSA)",
  "Employment Region",
  "State or Territory",
  "Country",
  "Primary Health Network",
  "Hub",
  "District",
  "Region",
  "Locality",
  "Division",
  "Delivery Postcode",
  "Recipient Postcode",
  "Tasmanian Remoteness Area",
  "Burnie SA2s",
  "AEDC Community Area",
  "Department of Health & Human Services (DHHS) Area",
];
export const whens = [
  "Year",
  "Year ending December",
  "Year Ending",
  "Financial Year",
  "Year Month",
  "Year Quarter",
  "Year Range",
  "Year School Term",
  "Year Semester",
  "Date",
  "Year School Term Week",
  "Grant Approval Year",
  "Grant Approval Year Month",
  "Grant Approval Year Quarter",
  "Grant End Year",
  "Grant End Year Month",
  "Grant End Year Quarter",
  "Grant Start Year",
  "Grant Start Year Month",
  "Grant Start Year Quarter",
  "Week Starting",
  "Week Ending",
];
export const hows = ["Measured quantity"];

export const getDimType = (name: string): string => {
  if (includes(wheres, name)) {
    return "Where";
  } else if (includes(whens, name)) {
    return "When";
  } else if (includes(hows, name)) {
    return "How";
  }
  return "What";
};

export const qsFilterTypes = ["filters", "rows", "columns"];

// appends values for the same dimension from different sources, adds any missing sources and ensures only unique values exist
export const concatValues = (sourceValues: any[], nextValues) => {
  nextValues.forEach(value => {
    const valueIndex = findIndex(sourceValues, sValue => sValue.name === value.name);
    if (valueIndex !== -1) {
      const oldValue = sourceValues[valueIndex];
      sourceValues[valueIndex] = {
        ...oldValue,
        requirement: oldValue.requirement === "selected" ? "selected" : value.requirement,
        source: { ...oldValue.source, ...value.source },
      };
    } else {
      sourceValues.push(value);
    }
  });
  return sourceValues;
};

// filtering null values as qs has an unfixed bug where it returns these at times
export const mapDimensionValues = (values, datasetKey) => ((values || []).filter(value => !!value).map(value => ({
  ...value,
  requirement: value.requirement || "optional",
  source: { [datasetKey]: true },
})));

// add the data for each dimension, updating appropriately on existing dimensions based on whether the dimension is selected or not
export const addDimensionData = (source, dimension, datasetKey) => {
  if (!source[dimension.name]) {
    source[dimension.name] = {
      requirement: dimension.requirement || "optional",
      type: getDimType(dimension.name),
      source: { [datasetKey]: true },
      values: mapDimensionValues(dimension.values, datasetKey),
      comparableTo: dimension.comparable_to || [],
    };
  } else {
    const oldDim = source[dimension.name];
    source[dimension.name] = {
      ...oldDim,
      requirement: oldDim.requirement === "selected" ? "selected" : (dimension.requirement || "optional"),
      source: { ...oldDim.source, [datasetKey]: true },
      values: concatValues(oldDim.values, mapDimensionValues(dimension.values, datasetKey)),
      comparableTo: oldDim.comparableTo.concat(dimension.comparable_to || []),
    };
  }
};

// transform a quickstat response into builder dimensions format
export const transformResToDims = res => {
  const dims = {};
  const datasets = res?.body?.data?.datasets ?? [res?.body?.data?.dataset];
  datasets.forEach(dataset => {
    if (dataset) {
      dataset.dimensions.forEach(dimension => {
        addDimensionData(dims, dimension, dataset.key);

        dimension.comparable_to?.forEach(c => {
          addDimensionData(
            dims,
            {
              name: c,
              requirement: "comparable",
              values: [],
            },
            dataset.key
          );
        });
      });
    }
  });
  return dims;
};

export const getResSelectedAndCompulsory = (datasets: any, userDeselectedDimensions: any[]): Record<string, [string]> => {
  const selected = {};
  datasets.forEach(dataset => {
    dataset.dimensions.forEach(dimension => {
      if (dimension.requirement === "selected" ||
        (datasets.length === 1 && dimension.requirement === "compulsory" && userDeselectedDimensions.indexOf(dimension.name) <0)) {
        const selectedValues = (dimension.values || []).filter(val => !!val && (val.requirement === "selected" || (datasets.length === 1 && val.requirement === "compulsory"))).map(value => value.name);
        selected[dimension.name] = uniq([...(selected[dimension.name] || []), ...selectedValues]);
      }
    });
  });
  return selected;
};

export const getNewQsFilters = (store, resSelected) => {
  // clone to avoid updates in place
  const newFilters = cloneDeep({ filters: [ ...store.filters ], rows: [ ...store.rows ], columns: [ ...store.columns ] });
  Object.keys(resSelected).map(dimension => {
    // see if dimension already selected
    const selectedType = find(qsFilterTypes, type => !!find(newFilters[type], filter => filter.dimension === dimension));
    if (selectedType) {
      const found = find(newFilters[selectedType], filter => filter.dimension === dimension);
      // if in dynamicQueries, then only include resSelected values as the old ones were not used
      if (store.getDynamicDimFilter(dimension)) {
        found.values = uniq(resSelected[dimension]);
      } else {
        found.values = uniq([...found.values, ...resSelected[dimension]]);
      }
    } else {
      // if not found append to filters
      newFilters.filters.push({ dimension, values: resSelected[dimension] });
    }
  });
  return newFilters;
};

// sorts dimensions object category values
export const sortDimensionValues = dimensions => {
  Object.keys(dimensions).forEach(dimension => {
    const values = dimensions[dimension].values;
    if (values.length > 0 && values.every(value => typeof value.weight === "number")) {
      values.sort((a, b) => a.weight - b.weight);
    } else if (includes(["Year Month", "Year Quarter"], dimension)) {
      values.sort(sortYear(dimension.replace("Year ", "")));
    } else if (includes(["Week Starting", "Week Ending"], dimension)) {
      values.sort(sortWeek);
    } else if (includes(["Age", "Age Range", "AFM Age Range"], dimension)) {
      values.sort(sortAgeRange);
    } else if (includes(["Equivalised Total Household Income (Weekly)", "Other Income (Range)", "Total family income (weekly)", "Total Gross Income (Range)", "Total household income (weekly)", "Total personal income (weekly)", "Mortgage repayments (monthly) ranges"], dimension)) {
      values.sort(sortIncomeBracket);
    } else {
      values.sort((a, b) => collator.compare(a.name, b.name));
    }
  });
  return dimensions;
};

// making use of previously sorted dimensions object categories to avoid reimplementing sort for IFilter objects
export const sortSingleFilterValues = (filter: IFilter, dimensions: Record<string, IDimension>): void => {
  const newValues: Value[] = [];
  const dimension: IDimension = dimensions[filter.dimension];
  for (const { name } of dimension.values) {
    if (filter.values.includes(name)) {
      newValues.push(name);
    }
  }
  filter.values = newValues;
};

/*
  The purpose of this function is to automatically move QS (QuickStat) dimension filters between [columns, rows, filters]
  to ensure a user gets results from QS endpoint.

  The logic here is to try and fill rows / columns before filters, including moving filters to rows / columns on removing
  selections. This will be irrelevant with new QuickStat however will still be relevant on the frontend for grouping results
  appropriately.
 */
export const autoFilterTransform = store => {
  // clone to avoid updates in place
  const newFilters = cloneDeep({ filters: [ ...store.filters ], rows: [ ...store.rows ], columns: [ ...store.columns ] });
  if (newFilters.filters.length > 0 && newFilters.columns.length === 0) {
    newFilters.columns.push(newFilters.filters.shift());
  }
  if (newFilters.filters.length > 0 && newFilters.rows.length === 0) {
    newFilters.rows.push(newFilters.filters.shift());
  }
  qsFilterTypes.forEach(type => store[type] = newFilters[type]);
};

export const generateTableId = (tables: any[]): number => tables.reduce((acc, cur) => Math.max(acc, cur?.id || 0), 0) + 1;

// global comparable dimension types
export const comparableDimensions = {
  "Where": "!Location",
  "When": "!Time",
};

// if comparable dimensions are found, they are merged into one with their values concatenated. Global comparables are based on comparableDimensions, rest are based on comparable_to QS response
// comparable dimension is signified by the dimension string starting with "!"
// i.e. [{ dimension: "SA2", values: ["Sydney", ...], }, { dimension: "LGA", values: ["Canberra", ...], }] becomes [{ dimension: "!Location", values: ["SA2:::Sydney", "LGA:::Canberra", ...], }]
export const getCombinedDims = (dimensions: IFilter[]): IFilter[] => {
  // first determine comparable dimensions based on qs response (for non globals, see comparableDimensions)
  const selectedDimNames = dimensions.map(d => d.dimension);
  const qsComparableDims: string[][] = [];
  dimensions.forEach(item => {
    if (item.data?.comparableTo?.length) {
      // only add once per combination
      const existing = qsComparableDims.find(comparable => comparable.includes(item.dimension));
      const comparableDims = [item.dimension, ...item.data.comparableTo].filter(dimStr => selectedDimNames.includes(dimStr));
      if (!existing && comparableDims.length > 1) {
        qsComparableDims.push(comparableDims);
      }
    }
  });

  // Get count of "Where"/"When" dims as we only want to combine when count > 2 for a type
  const comparableDimCounts: any = {
    "Where": 0,
    "When": 0,
  };
  dimensions.forEach(d => {
    const type = getDimType(d.dimension);
    if (Object.keys(comparableDimCounts).includes(type)) {
      comparableDimCounts[type]++;
    }
  });

  const combinedDimensions: IFilter[] = [];
  dimensions.forEach(c => {
    const type = getDimType(c.dimension);
    const qsComparableIdx = qsComparableDims.findIndex(comparable => comparable.includes(c.dimension));
    const comparableType = Number(comparableDimCounts[type]) > 1
      ? "global" // global takes preference
      : qsComparableIdx >= 0
        ? "qs" : null; // then qs, otherwise not comparable
    if (comparableType) {
      const qsComparableId = comparableType === "global" ? comparableDimensions[type] : `!Group ${qsComparableIdx + 1}`;
      const existingIdx = combinedDimensions.findIndex(item => item.dimension === qsComparableId);
      if (existingIdx >= 0) {
        combinedDimensions[existingIdx] = {
          dimension: qsComparableId,
          values: [
            ...combinedDimensions[existingIdx].values,
            ...c.values.map(v => `${c.dimension}:::${v}`),
          ],
        };
      } else {
        combinedDimensions.push({
          dimension: qsComparableId,
          values: c.values.map(v => `${c.dimension}:::${v}`),
        });
      }
    } else {
      combinedDimensions.push(c);
    }
  });

  return combinedDimensions;
};

// Get updated variable(dimension) if it is updated in the table step (categories added/removed). This function is used for users go back to Table step and add/remove categories so we need to update the chart accordingly
export const getUpdatedDims = (oldDims: IFilter[] | [], updatedDims: IFilter[] | []): IFilter[] | [] => {
  if (oldDims.length === 0 || updatedDims.length === 0) {
    return [];
  } else {
    const newDims: IFilter[] = [];
    oldDims.forEach(od => {
      // need to find all occurrences of dim because you have situations where different categories are selected for same dim across tables
      const findDim = updatedDims.filter(ud => ud.dimension === od.dimension);
      const mergeDim = findDim.reduce((acc, next) => ({ ...next, values: uniq([...(acc.values || []), ...(next.values || [])]) }));
      if (mergeDim && !isEqual(mergeDim.values, od.values)) {
        newDims.push(mergeDim);
      } else {
        newDims.push(od);
      }
    });
    return newDims;
  }
};

// transforms a calculation to robot backend format and flattens any calc on calc recursively
export const robotCalcTableTransform = (targetTable, tables, resultRowMemo: any = null) => {
  const { eq, tables: tableRefs } = targetTable;

  // compile row numbers for non calc tables so that we can match them exactly
  const resultRows: any = resultRowMemo || []; // use memoized if set to avoid recreating in recursive calls
  if (!resultRows.length) {
    const resultTables = tables.filter(table => table.type === "result");
    let resultRowNumber = 1;
    for (const table of resultTables) {
      const filters = table.filters || [];
      const rows = table.rows || [];
      const dimensions = [...filters.map(filter => filter.dimension), ...rows.map(row => row.dimension)];
      const catCombos = buildArrayCombos(([...filters, ...rows]).map(item => item.values));
      for (const categories of catCombos) {
        resultRows.push({
          rowNumber: resultRowNumber,
          dimensions,
          categories,
          tableId: table.id,
        });
        resultRowNumber++;
      }
    }
  }

  // init payload data
  let rows = {};
  let formula = "";
  let tableRefCounter = 0;
  for (const _eq of eq) {
    if (typeof _eq !== "string") {
      const refTable = tables.find(table => table.id === tableRefs[tableRefCounter]);
      tableRefCounter++;
      if (refTable) {
        // table type logic
        if (_eq.type === "Table") {
          // compile the table row filter combinations
          const rowsAndFilters = [...(refTable.rows || []), ...(refTable.filters || [])];
          const rowsAndFiltersVariables = rowsAndFilters.map(item => item.dimension);
          const rowCategoryCombos = buildArrayCombos(rowsAndFilters.map(item => item.values));
          // compile the data required for the Robot Analysis Tool rows
          const refRowsData = rowCategoryCombos.map(rowCats => {
            const refRow = resultRows.find(resultRow => (
              resultRow.tableId === refTable.id
              && resultRow.dimensions.every(dim => rowsAndFiltersVariables.includes(dim))
              && resultRow.categories.every(cat => rowCats.includes(cat))
            ));
            return {
              refRow,
              filters: rowsAndFiltersVariables.reduce((prev, next, nextIdx) => ({ ...prev, [next]: [rowCats[nextIdx]] }), {}),
              dataset: refTable.userSelectedDatasets[0],
            };
          });
          // add to the formula and update rows if data not set
          if (refRowsData.every(data => data.refRow)) {
            for (const refRowData of refRowsData) {
              const { refRow, filters, dataset } = refRowData;
              const rowName = `row${refRow.rowNumber}`;
              if (!rows[rowName]) {
                rows[rowName] = { filters, dataset };
              }
            }
            formula += refRowsData.map(refRowData => `row${refRowData.refRow.rowNumber}`).join(",");
          }
          continue;
        }
        // row type logic
        const refRow = resultRows.find(row => (
          row.tableId === refTable.id
          && row.dimensions.every((dim) => _eq.filters.includes(dim))
          && row.categories.every((cat) => _eq.filterValues.includes(cat))
        ));
        if (refRow) {
          const rowName = `row${refRow.rowNumber}`;
          if (!rows[rowName]) {
            rows[rowName] = {
              filters: _eq.filters.reduce((prev, next, nextIdx) => ({ ...prev, [next]: [_eq.filterValues[nextIdx]] }), {}),
              dataset: refTable.userSelectedDatasets[0],
            };
          }
          formula += rowName;
        }
      }
    } else if (_eq === "calc") {
      const refTable = tables.find(table => table.id === tableRefs[tableRefCounter]);
      tableRefCounter++;
      if (refTable) {
        const res = robotCalcTableTransform(refTable, tables, resultRows);
        rows = { ...rows, ...res.rows };
        formula += `(${res.formula})`;
      }
    } else {
      formula += _eq;
    }
  }

  return { rows, formula };
};

// Hide decimal places when the max yaxis value is greater than format benchmark
export const YAXIS_DECIMAL_FORMAT_BENCHMARK = 0.1;

// Get the max value from chartData
export const getMaxValue = (chartData: any[]): number => {
  let maxValue = 0;
  chartData.forEach(singleChartData => {
    singleChartData.forEach(data => {
      Object.keys(data).forEach(key => {
        if (typeof data[key] === "number" && !key.includes("label") && data[key] > maxValue) {
          maxValue = data[key];
        }
      });
    });
  });
  return maxValue;
};

export const DEFAULT_TABLE_NAME_REGEX = /^Table \d+$/;

// variables compatible with the range selections (dynamicQueries)
export const RANGE_COMPATIBLE_VARIABLES = ["Year", "Date", "Year Month", "Year Quarter"];

// return all but the dimension "values", as it can be significant in quantity and greatly affect insight size if saved (i.e. 70+ MB insights with certain datasets)
// also remove source which can be somewhat large
// note the data available can be seen above in addDimensionData
export const getDimensionMetadata = (dimension: IDimension): any => ({
  ...dimension,
  values: undefined,
  source: undefined,
});
