import * as React from "react";
import { Form } from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";

interface Props extends FieldRenderProps<any> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  helperText?: string;
}

interface FieldInputFileProps extends Props {
  accept?: string;
}

export const FieldInput = ({ input, meta, label, placeholder, disabled, helperText, className }: Props): JSX.Element => (
  <Form.Field className={className}>
    {label && <label>{label}</label>}
    <Form.Input
      {...input}
      className="mb-0"
      disabled={!!disabled}
      placeholder={placeholder || ""}
      error={((meta.error || meta.submitError) && meta.touched) ? { content: (meta.error || meta.submitError), pointing: "above" } : undefined}
    />
    {helperText && <small className="text-muted">{helperText}</small>}
  </Form.Field>
);

export const FieldInputHidden = ({ input }: FieldRenderProps<any>): JSX.Element => <input {...input} className="d-none" />;

export const FieldInputFile = ({ input, meta, label, accept, disabled, helperText }: FieldInputFileProps): JSX.Element => (
  <Form.Field>
    {label && <label>{label}</label>}
    <Form.Input
      {...input}
      value={undefined}
      type="file"
      accept={accept}
      disabled={!!disabled}
      error={((meta.error || meta.submitError) && meta.touched) || undefined}
      onChange={({ target }) => input.onChange(target.files)} // instead of the default target.value so we don't need a ref
    />
    {helperText && <small className="text-muted">{helperText}</small>}
  </Form.Field>
);

export const FieldInputDate = ({ input, meta, label, accept, disabled, helperText, min = "" }: FieldInputFileProps): JSX.Element => (
  <Form.Field>
    {label && <label>{label}</label>}
    <Form.Input
      {...input}
      type="date"
      accept={accept}
      disabled={!!disabled}
      onKeyDown={(e) => e.preventDefault()}
      min={min}
      error={((meta.error || meta.submitError) && meta.touched) || undefined}
    />
    {helperText && <small className="text-muted">{helperText}</small>}
  </Form.Field>
);
