import * as React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Icon, Label, SemanticICONS } from "semantic-ui-react";
import { linkStyling } from "./includes/header.style";
import { PremiumIcon } from "./includes/PremiumIcon";

export const StyledNavLink = styled(NavLink)`${linkStyling}`;
export const StyledAnchorLink = styled.a`${linkStyling}`;

export interface NavItemProps {
  id?: string;
  to: any;
  name?: string;
  icon?: SemanticICONS;
  position: "left" | "right";
  externalPath?: string;
  tag?: string; // Teal tag/label for new features
  showDiamond?: boolean; // Show diamond icon when org plan is "Standard" and no access to the addOn feature
  className?: string;
}

export const NavItem: React.FC<NavItemProps> = ({ to, name, icon, externalPath, tag, showDiamond, className, ...rest }) => {

  if (externalPath) {
    return (
      <StyledAnchorLink {...rest} href={externalPath} key={`${name}${icon}${to?.pathname}`}>
        {name}
      </StyledAnchorLink>
    );
  }

  return (
    <StyledNavLink
      {...rest}
      key={`${name}${icon}${to?.pathname}`}
      to={to}
      target={to?.pathname?.includes("http") ? "_blank" : ""}
      className={className}
    >
      <div className="position-relative">
        <span>
          {name}
        </span>
        {icon && <Icon name={icon} className="text-secondary ml-1 mr-0" />}
        {tag && <Label circular className="bg-info position-absolute text-white mini" style={{ right: -15, top: -10 }}>{tag}</Label>}
        {showDiamond && <PremiumIcon className="position-absolute" style={{ right: -15 }} />}
      </div>
    </StyledNavLink>
  );
};
