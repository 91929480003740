/* HOC for using color applicator */
import * as React from "react";
import { brushCycle, brushCycleFade, brushInterpolate } from "common/helpers/palette";

export const brushes = {
  cycle: {
    label: "Cycle Colours",
    brush: brushCycle,
  },
  cycleFade: {
    label: "Cycle Fade Colours",
    brush: brushCycleFade,
  },
  interpolate: {
    label: "Interpolate Colours",
    brush: brushInterpolate,
  },
};

const canvasColors = canvasObjects => canvasObjects.map(obj => obj.color);

export const withColorApplicator = (WrappedComponent: React.ComponentClass<any> | React.FunctionComponent<any>) => (props: Record<string, any>): JSX.Element => {
  const [selectedPalette, updateSelectedPalette] = React.useState(0);
  const [selectedBrush, updateSelectedBrush] = React.useState("cycle");
  const [canvas, updateCanvas] = React.useState([]);
  const [colorPickerOpen, updateColorPickerOpen] = React.useState(false);
  const [paletteModalOpen, updatePaletteModalOpen] = React.useState(false);

  // reorder draggable canvas item
  const moveCanvasItem = (dragIdx, hoverIdx) => {
    const newCanvas = [...canvas];
    const [movedItem] = newCanvas.splice(dragIdx, 1);
    newCanvas.splice(hoverIdx, 0, movedItem);
    updateCanvas(newCanvas);
  };

  const getCanvasColors = () => canvasColors(canvas);
  const generateColors = (rows: number[]) => brushes[selectedBrush].brush(canvasColors(canvas), rows);

  const applicator = {
    brushes,
    selectedPalette,
    updateSelectedPalette,
    selectedBrush,
    updateSelectedBrush,
    canvas,
    updateCanvas,
    colorPickerOpen,
    updateColorPickerOpen,
    paletteModalOpen,
    updatePaletteModalOpen,
    moveCanvasItem,
    generateColors,
    getCanvasColors,
  };

  return <WrappedComponent {...props} applicator={applicator} />;
};
