import React = require("react");
import { Icon, Popup } from "semantic-ui-react";

import { Avatar } from "component/Avatar";
import {
  StyleNameSuitcase,
  StyleOrganisation,
  StyleTileSuitcase,
  StyleUserInfoSuitcase,
  AccessDropdown,
} from "component/Members/includes/addMember.style";
import { COLORS } from "component/UI/common";

export const UserAccessRow = ({ index, user, isOwner, value, onChange, editable, options, setByParent }) => (
    <StyleTileSuitcase key={index} indent={!isOwner} firstItem={isOwner}>
      <StyleUserInfoSuitcase>
        <div style={{ marginRight: "20px", marginBottom: "10px" }}>
          <Avatar name={`${user.first_name} ${user.last_name}`} image={user.avatar} />
        </div>
        <div>
          <StyleNameSuitcase style={{ color: `${COLORS.indigo600}` }}>
            <span>
              {user.first_name} {user.last_name}{" "}
            </span>
          </StyleNameSuitcase>
          <StyleOrganisation>{isOwner ? "Owner" : user.email}</StyleOrganisation>
        </div>
      </StyleUserInfoSuitcase>
      {!isOwner && (
        (editable && !setByParent) ?
        <AccessDropdown selectOnBlur={false} selection options={options} value={value} onChange={onChange} />
        :
        <div className="d-flex align-items-center">
        <Popup
          size="tiny"
          wide={true}
          content="Access inherited from parent suitcases"
          position="left center"
          trigger={
            <Icon name="info circle" color="grey" className="mr-2"/>
          }
        />
          <AccessDropdown disabled={!editable} selectOnBlur={false} selection options={options} value={value} onChange={onChange} />
        </div>
      )}
    </StyleTileSuitcase>
  );
