import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { NavLink, useHistory } from "react-router-dom";
import { Divider, Icon, List } from "semantic-ui-react";
import { getMixpanel } from "common/api";
import styled from "styled-components";
import { ObjectAny } from "common/helpers/types";
import { requestUrl } from "pages/Settings/includes/Organisations";
import { PremiumIcon } from "component/Header/includes/PremiumIcon";
import { CHART_PRIMARY_COLOR } from "common/helpers/chart";
import { getExplorePath } from "common/helpers/user";
import { COLORS } from "component/UI/common";

interface IBurgerMenu {
  store?: Store;
  onCloseMenu: () => void;
}

interface IBurgerMenuItem {
  label: string;
  to?: string | ObjectAny;
  target?: "_blank";
  onItemClick?: () => void;
  showDiamond?: boolean;
  hide?: boolean;
}

interface BurgerMenuItemComponent extends IBurgerMenuItem {
  onCloseMenu: () => any;
}

const StyleList = styled(List)`
  &.ui.list>a.item, &.ui.list>.item {
    color: ${CHART_PRIMARY_COLOR} !important;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    border-radius: 4px;
    :hover {
      background-color: ${COLORS.indigo100};
      transition: 0.6s;
    }
  }
  &.ui.relaxed.list:not(.horizontal)>.item {
    padding: 10px;
  }
`;

const BurgerMenuItemComponent = (props: BurgerMenuItemComponent) => {
  const { label, to, target, onItemClick, onCloseMenu, showDiamond } = props;

  if (onItemClick) {
    return (
      <List.Item
        onClick={() => {
          onItemClick();
          onCloseMenu();
        }}
        className="cursor-pointer"
      >
        <div className="d-flex align-items-center">
          <List.Content>{label}</List.Content>
          {showDiamond && <PremiumIcon className="ml-2" />}
        </div>
      </List.Item>
    );
  }

  return (
    <List.Item as={NavLink} target={target || "_self"} to={to} onClick={onCloseMenu}>
      <div className="d-flex align-items-center">
        <List.Content>{label}</List.Content>
        {showDiamond && <PremiumIcon className="ml-2" />}
      </div>
    </List.Item>
  );
};

const BurgerMenuComponent = (props: IBurgerMenu) => {
  const { store, onCloseMenu } = props;
  const history = useHistory();

  const featureMenuItems: IBurgerMenuItem[] = [
    {
      label: "Self-service Dataset Collection",
      to: "/datasets",
      showDiamond: (!store!.hasGroupAddOn("Self-service Data Ingestion") || store!.userPlan === "Plus" || store!.userPlan === "Unlimited"),
    },
    {
      label: "Data Catalog",
      to: "/catalog",
    },
    {
      label: "Explore Data",
      to: getExplorePath(),
    },
    {
      label: "Suitcases",
      to: "/suitcases",
    },
    {
      label: "Dashboards",
      to: "/dashboards",
      showDiamond: !store!.hasGroupAddOn("Dashboards") && store!.userPlan === "Standard",
    },
    {
      label: "Smart Insights",
      to: "/smart-insights",
      hide: store!.userPlan === "Standard",
    },
    {
      label: "Developer Portal",
      target: "_blank",
      to: store!.hasGroupAddOn("Developer Portal") ? "/analyst-portal" : { pathname: requestUrl },
      showDiamond: !store!.hasGroupAddOn("Developer Portal"),
    },
  ];

  const accountMenuItems: IBurgerMenuItem[] = [
    {
      label: "Account",
      to: "/settings/account",
    },
    {
      label: "Organisation",
      to: "/settings/org",
    },
    {
      label: "Notifications",
      to: "/notifications",
    },
    {
      label: "Upgrade Plan",
      onItemClick: () => {
        store!.handleUpgradeShow(true);
        getMixpanel(store!).track("Upgrade Plan Click");
      },
    },
  ];

  const otherMenuItems: IBurgerMenuItem[] = [
    {
      label: "Support",
      to: { pathname: "https://knowledge.seerdata.ai" },
      target: "_blank",
    },
    {
      label: "What's New",
      to: { pathname: "https://seerdata.ai/february-2024-new-features-and-improvements/" },
      target: "_blank",
    },
    {
      label: "Provide Feedback",
      onItemClick: () => {
        history.push("/feedback", { background: history.location });
      },
    },
  ];

  return (
    <div className="bg-white position-fixed w-100 h-100 overflow-auto" style={{ zIndex: 1000 }}>
      <Icon name="close" className="text-secondary position-absolute" style={{ top: 15, right: 15 }} onClick={onCloseMenu} />
      <StyleList className="p-5" relaxed>
        {featureMenuItems.filter(item => !item.hide)
          .map((item, idx) => {
            const { label, to, target, showDiamond } = item;
            return (
              <BurgerMenuItemComponent key={idx} label={label} to={to} target={target} onCloseMenu={onCloseMenu} showDiamond={showDiamond} />
            );
        })}
        <Divider />
        {accountMenuItems.map((item, idx) => {
          const { label, to, onItemClick } = item;
          return (
            <BurgerMenuItemComponent key={idx} label={label} to={to} onItemClick={onItemClick} onCloseMenu={onCloseMenu} />
          );
        })}
        <Divider />
        {otherMenuItems.map((item, idx) => {
          const { label, to, target, onItemClick } = item;
          return (
            <BurgerMenuItemComponent key={idx} label={label} target={target} to={to} onItemClick={onItemClick} onCloseMenu={onCloseMenu} />
          );
        })}
        <Divider />
        <BurgerMenuItemComponent label="Sign Out" onItemClick={() => store!.logout()} onCloseMenu={onCloseMenu} />
      </StyleList>
    </div>
  );
};

export const BurgerMenu = inject("store")(observer(BurgerMenuComponent));
