// All available variables for Igwt map.
const igwtVariables = {
  "transferred-households": {
    id: 1,
    original: "Transferred Households",
    renamed: "Transferred Households",
    bin: [
      90.93935091,
      161.7713378,
      247.7539396,
      375.6835897,
    ],
  },
  "wealth-transferred": {
    id: 2,
    original: "Wealth Transferred ($m, 2021-25)",
    renamed: "Wealth Transferred ($m)",
    bin: [
      67.65873974,
      126.2234448,
      201.077122,
      324.6492404,
    ],
  },
  "wealth-transferred-per-household": {
    id: 3,
    original: "Wealth Transferred per Household Transferred ($k, 2021-25)",
    renamed: "Wealth Transfer per Household ($k)",
    bin: [
      501.572784,
      700.2361945,
      882.4228939,
      1162.5116,
    ],
  },
  "median-gift-reported": {
    id: 4,
    original: "Median gift reported by taxpayers ($, 2018)",
    renamed: "Median gift $ reported by taxpayers",
    bin: [
      90,
      100,
      120,
      153,
    ],
  },
  "undertaking-voluntary-work": {
    id: 5,
    original: "Percent of persons undertaking voluntary work for an organisation or group (%, 2016)",
    renamed: "% undertaking voluntary work",
    bin: [
      0.151,
      0.184,
      0.212,
      0.25,
    ],
  },
  "taxpayers-reporting-gifts/donations": {
    id: 6,
    original: "Taxpayers reporting gifts\/donations per Working Age Population (15-64 years) (%, 2018)",
    renamed: "Taxpayers reporting gifts/donations (%)",
    bin: [
      0.212341607,
      0.252374887,
      0.287466041,
      0.321155069,
    ],
  },
  "seer-giving-index": {
    id: 7,
    original: "Seer Giving Index (percentile)",
    renamed: "Seer Giving Index (Percentile)",
    bin: [
      20.2,
      40.4,
      60.6,
      80.8,
    ],
  },
  "widows": {
    id: 8,
    original: "Widows (count, 2016)",
    renamed: "Widows (Total)",
    bin: [
      143,
      275,
      432,
      686,
    ],
  },
  "percent-population-widows": {
    id: 9,
    original: "Percent of population widows (%, 2016)",
    renamed: "% of population widows",
    bin: [
      0.025515956,
      0.035555814,
      0.044951723,
      0.056034012,
    ],
  },
  "women-over-65": {
    id: 10,
    original: "Women over 65 (count, 2016)",
    renamed: "Women over 65 (2016)",
    bin: [
      301,
      566.6,
      881,
      1375.2,
    ],
  },
  "women-over-65-with-one-child": {
    id: 12,
    original: "Women over 65 with at most one child (2016)",
    renamed: "Women over 65 with at most one child",
    bin: [
      40,
      84,
      138,
      229.2,
    ],
  },
  "percent-population-women-65": {
    id: 16,
    original: "Percent of population women over 65 (%, 2016)",
    renamed: "% of population women > 65",
    bin: [
      0.05236991,
      0.07419704,
      0.09175734,
      0.112967398,
    ],
  },
  "percent-population-65-1-child": {
    id: 18,
    original: "Percent of population women over 65 with at most one child (%, 2016)",
    renamed: "% of pop. women > 65 with <= 1 child",
    bin: [
      0.007260189,
      0.011071584,
      0.014497284,
      0.018334962,
    ],
  },
  "owner-manager": {
    id: 22,
    original: "Owner manager of incorporated enterprise with employees (count, 2016)",
    renamed: "Owner manager (Total)",
    bin: [
      62,
      113,
      187,
      306,
    ],
  },
  "percent-population-owner-managers": {
    id: 23,
    original: "Percent of population owner managers (%, 2016)",
    renamed: "% of population owner managers",
    bin: [
      0.010314342,
      0.014762386,
      0.019672464,
      0.026973869,
    ],
  },
  "people-over-65": {
    id: 24,
    original: "People over 65 (count, 2016)",
    renamed: "People over 65 (Total)",
    bin: [
      542.8,
      961,
      1459,
      2182.2,
    ],
  },
};

export const seerIgwtMapConfig = {
  colorPalettes: [
    {
      id: 1,
      name: "Seer Palette 1",
      palette: {
        primary: "#5f2d5f",
        background: "#f1ecf1",
        legend: ["#5f2d5f", "#9d3566", "#d4485a", "#f7703e", "#ffa600"],
      },
    },
    {
      id: 2,
      name: "Seer Palette 2",
      palette: {
        primary: "#5f2d5f",
        background: "#f5eaea",
        legend: ["#c49ec4", "#aa81aa", "#906490", "#774877", "#5f2d5f"],
      },
    },
  ],
  variables: [
    igwtVariables["wealth-transferred"],
    igwtVariables["wealth-transferred-per-household"],
    igwtVariables["taxpayers-reporting-gifts/donations"],
    igwtVariables["seer-giving-index"],
    igwtVariables["owner-manager"],
    igwtVariables["people-over-65"],
  ],
  initialSelectedVariables: [
    igwtVariables["seer-giving-index"].id,
    igwtVariables["wealth-transferred-per-household"].id,
  ],
};

export const cfaIgwtMapConfig = {
  colorPalettes: [
    {
      id: 1,
      name: "CFA Palette 1",
      palette: {
        primary: "#5f2d5f",
        background: "#f1ecf1",
        legend: ["#5f2d5f", "#9d3566", "#d4485a", "#f7703e", "#ffa600"],
      },
    },
    {
      id: 2,
      name: "CFA Palette 2",
      palette: {
        primary: "#5f2d5f",
        background: "#f5eaea",
        legend: ["#c49ec4", "#aa81aa", "#906490", "#774877", "#5f2d5f"],
      },
    },
  ],
  variables: [
    igwtVariables["wealth-transferred"],
    igwtVariables["wealth-transferred-per-household"],
    igwtVariables["taxpayers-reporting-gifts/donations"],
    igwtVariables["seer-giving-index"],
    igwtVariables["owner-manager"],
    igwtVariables["people-over-65"],
  ],
  initialSelectedVariables: [
    igwtVariables["seer-giving-index"].id,
    igwtVariables["wealth-transferred-per-household"].id,
  ],
};

export const perpetualIgwtMapConfig = {
  colorPalettes: [
    {
      id: 1,
      name: "Perpetual Palette",
      palette: {
        primary: "#002654",
        background: "#002654",
        legend: ["#fefdb7", "#fee71f", "#ffbb00", "#fd8e00", "#f25e14"],
      },
    },
  ],
  variables: [
    igwtVariables["wealth-transferred"],
    igwtVariables["wealth-transferred-per-household"],
    igwtVariables["seer-giving-index"],
    igwtVariables["widows"],
    igwtVariables["owner-manager"],
    igwtVariables["people-over-65"],
  ],
  initialSelectedVariables: [
    igwtVariables["seer-giving-index"].id,
    igwtVariables["wealth-transferred-per-household"].id,
  ],
};
