import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Label, Table } from "semantic-ui-react";
import { datasets as dataApi } from "common/api";
import Store from "common/store";

interface IDatasetIngestedSchema {
  datasetID: number;
  dataset?: any;
  store?: Store;
}

const DatasetIngestedSchemaComponent = ({ datasetID, store }: IDatasetIngestedSchema): JSX.Element => {
  const [variableOptions, setVariableOptions] = React.useState<any[]>([]);

  // "dataset key" is used when sending request to `/qs` to only get specified dataset's variables and categories
  const getDatasetKey = async () => {
    const res: any = await await dataApi.post("v2/search", "", store!.token!);
    if (res.body) {
      const allDatasets = res.body.data.datasets;
      const currentDataset = allDatasets.find(dataset => dataset.id === datasetID);
      return currentDataset.key;
    }
    return null;
  };

  const loadVariables = async () => {
    const key = await getDatasetKey();
    const res: any = await dataApi.post("v2/qs", { dataset: key }, store!.token!);
    if (res.body) {
      const variables = res.body.data.dataset.dimensions;
      const formattedDims = variables.map((variable) => ({
        ...variable,
        categories: variable.values.reduce((acc, val) => [...acc, val.name], []),
      }));

      const loadCategories = async (variable) => {
        const res: any = await dataApi.post("v2/qs", { dataset: key, filters: { [variable.name]: [] } }, store!.token!);
        if (res.body) {
          const dimensions = res.body.data.dataset.dimensions;
          const varWithCategories = dimensions.find(({ name }) => name === variable.name);

          if (varWithCategories) {
            const categories = varWithCategories.values.reduce((acc, val) => [...acc, val.name], []);
            return categories;
          } else {
            return [];
          }
        } else {
          return [];
        }
      };

      const variablesWithCategories = await Promise.all(formattedDims.map(async d =>({
        ...d,
        categories: d.categories.length > 0 ? d.categories : await loadCategories(d),
      })));
      setVariableOptions(variablesWithCategories);
    }
  };

  React.useEffect(() => {
    getDatasetKey();
    loadVariables();
  }, [datasetID]);

  return (
    <>
      <Table celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell key="variable" className="fs-1125 text-secondary">Variable</Table.HeaderCell>
            <Table.HeaderCell key="categories" className="fs-1125 text-secondary">Categories</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {variableOptions.map((opt,i) =>
            <>
              <Table.Row key={`key-${i}`} className="fs-1000 text-dark fw-500">
                <Table.Cell className="fw-700">{opt.name}</Table.Cell>
                <Table.Cell className="fw-700">
                  {opt.categories.map(c =>
                    <Label className="mb-1" basic>
                      {c}
                    </Label>
                  )}
                </Table.Cell>
              </Table.Row>
            </>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export const DatasetIngestedSchema = inject((stores: any) => ({
  store: stores.store,
  dataset: stores.store.dataset,
}))(observer(DatasetIngestedSchemaComponent));
