import { Popup as SemanticPopup, PopupProps as SemanticPopupProps } from "semantic-ui-react";
import styled from "styled-components";

interface PopupProps extends SemanticPopupProps {
}

export const Popup = styled(SemanticPopup).attrs((props: PopupProps): PopupProps => {
  const { inverted } = props;
  return {
    inverted: typeof inverted === "undefined" ? true : inverted, // inverted style is default
  };
})<PopupProps>`
  background-color: #2d2d2d !important;
  :before {
    background-color: #2d2d2d !important;
  }
`;
