import { MergeOp, TransformOp } from "common/store/dataset/urlUpload";
import * as React from "react";
import { StyledCircularLabel } from "../DatasetURLUpload.style";
import { Grid, Icon } from "semantic-ui-react";

export const ActionsList = (props) => {

  const { actions } = props;

  const displayActionConfig = (item: TransformOp | MergeOp) => {
    if (item.type === "column hash" || item.type === "column drop") {
      return <>{item.config}</>;
    } else if (item.type === "column rename") {
      return <>{item.config[0]}  <Icon className="mx-1" name='arrow right' /> {item.config[1]}</>;
    } else if (item.type === "column merge") {
      return <>{item.config.columns.join(item.config.separator)}</>;
    } else if (item.type === "left join") {
      const { left, leftColNames, right, rightColNames } = item;
      const leftTableName = typeof left === "number" ? `Table ${left + 1}` : "Staged Table";
      const rightTableName = `Table ${right + 1}`;
      return <>{`${leftTableName} - ${leftColNames.join(",")}`}<Icon className="mx-1" name="linkify" />{`${rightTableName} - ${rightColNames.join(",")}`}</>;
    } else {
      return <>Unknown operation</>;
    }
  };


  return (
    <>
      {actions.length === 0 ? <p className="text-center">No actions configured.</p> : (
        <Grid columns={4} className="mb-0">
          {actions.map((action, idx) => (
            <Grid.Row key={idx}>
              <Grid.Column width={1}><StyledCircularLabel circular size="small">{idx + 1}</StyledCircularLabel></Grid.Column>
              <Grid.Column width={7} className="pr-0"><p className="fw-600 text-capitalize">{action.type}</p></Grid.Column>
              <Grid.Column width={1} className="px-0"><div style={{ width: 1, height: 22, backgroundColor: "#5f5f5f"}}></div></Grid.Column>
              <Grid.Column width={6} className="px-0"><p className="">{displayActionConfig(action as TransformOp | MergeOp)}</p></Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      )}
    </>
  );
};
