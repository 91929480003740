import * as React from "react";
import { toJS } from "mobx";
import { insertScript } from "common/helpers/script";
import { CustomImageLibrary, imageUploadHandler } from "./CustomImageLibrary";
import { InsightPickerModal } from "./InsightPickerModal";
import { FilterPickerModal } from "./FilterPickerModal";
import { ObjectAny } from "common/helpers/types";
import insightTool, { insightToolConfig, designUpdateInsightRefreshHandler } from "./includes/customJsInsightTool";
import filterTool, { toolName as filterToolName } from "./includes/customJsFilterTool";
import customJsPostEvents from "./includes/customJsPostEvents";
import { webUrl } from "common/constants";
import { dashboardUnlayerCustomCSS } from "./includes/dashboardUnlayerCustomCSS";
import { COLORS } from "component/UI/common";
// import { environment } from "common/constants";

interface ComponentProps {
  config: ObjectAny | undefined;
  token: string;
  user?: ObjectAny;
  dashID: string;
}

export class DashboardUnlayerEditor extends React.Component<ComponentProps> {
  editor: any = null;

  state: any = {
    isCustomImageLibraryOpen: false,
    unlayerImageDoneFn: null,
    insightModalOpen: false,
    insightModalEventData: null,
    filterModalOpen: false,
    filterModalEventData: null,
  };

  customToolMessageHandler = (event: MessageEvent): any => {
    if (event.origin === "https://editor.unlayer.com" && event.data?.insightTool) {
      this.setState({ insightModalOpen: true, insightModalEventData: event.data?.insightTool });
    } else if (event.origin === "https://editor.unlayer.com" && event.data?.toolName === filterToolName) {
      this.setState({ filterModalOpen: true, filterModalEventData: event.data });
    }
  };

  unLayerInit = async (): Promise<void> => {
    const { user, dashID } = this.props;
    // const isSeerUser = environment !== "production" || user?.group.id === 102;
    const userAddOns = (user?.group.add_ons || []).map(add_on => add_on.name);
    const enabledTools = [insightTool()];
    if (userAddOns.includes("Dashboard Filters")) {
      enabledTools.push(filterTool());
    }
    const customJS = [
      ...enabledTools,
      customJsPostEvents,
    ];
    // insert unlayer on mount
    await insertScript("unlayer", { type: "text/javascript", src: "https://editor.unlayer.com/embed.js" });
    this.editor = window["unlayer"].createEditor({
      id: "dashboard-unlayer-editor",
      projectId: 69944,
      displayMode: "web",
      customJS,
      customCSS: [dashboardUnlayerCustomCSS],
      tabs: {
        blocks: {
          enabled: false,
        },
        images: {
          enabled: false,
        },
      },
      features: {
        stockImages: { enabled: false },
        userUploads: false,
        textEditor: {
          tables: true,
          inlineFontControls: true,
          spellChecker: true,
        },
        pageAnchors: true,
      },
      tools: {
        ...insightToolConfig,
        form: {
          enabled: !!user?.form_terms_accepted,
          properties: {
            fields: {
              editor: {
                data: {
                  allowAddNewField: true,
                },
              },
            },
            action: {
              editor: {
                data: {
                  allowCustomUrl: true,
                  actions: [
                    {
                      label: "Seer Data Forms",
                      method: "GET",
                      target: "_blank",
                      url: `${webUrl}/form-submission/dashboard/${dashID}`,
                    },
                  ],
                },
              },
            },
          },
        },
        button: {
          properties: {
            buttonColors: {
              value: {
                backgroundColor: COLORS.indigo500,
                hoverBackgroundColor: COLORS.indigo600,
              },
            },
          },
        },
        image: {
          properties: {
            src: {
              value: {
                url: `${webUrl}/assets/unlayer_placeholder.jpg`,
              },
            },
          },
        },
      },
      fonts: {
        showDefaultFonts: true, // set to true (default), just in case a URL is set / non browser font is added by Unlayer embed
        customFonts: [
          {
            label: "BeatLight",
            value: "Beatlight",
            url: "https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/fonts/beatlight.css",
          },
          {
            label: "Barlow",
            value: "Barlow",
            url: "https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
          },
          {
            label: "Jost",
            value: "Jost",
            url: "https://fonts.googleapis.com/css2?family=Jost:wght@500;700&display=swap",
          },
          {
            label: "Alber New",
            value: "alber-new-web",
            url: "https://use.typekit.net/ikw1ykh.css",
          },
          {
            label: "Verveine",
            value: "verveine",
            url: "https://use.typekit.net/ygg2glg.css",
          },
          {
            label: "Motley Forces",
            value: "Motley Forces",
            url: "https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/fonts/motley_forces.css",
          },
          {
            label: "Ropa Sans",
            value: "Ropa Sans",
            url: "https://fonts.googleapis.com/css2?family=Ropa+Sans:ital@0;1&display=swap",
          },
          {
            label: "Caveat Brush",
            value: "Caveat Brush",
            url: "https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap",
          },
          {
            label: "Poppins",
            value: "Poppins",
            url: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap",
          },
          {
            label: "Cardo",
            value: "Cardo",
            url: "https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap",
          },
          {
            label: "Roboto",
            value: "Roboto",
            url: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap",
          },
        ],
      },
    });
    this.editor.addEventListener("editor:ready", () => {
      if (this.props.config) {
        this.editor.loadDesign(toJS(this.props.config));
      } else {
        this.editor!.loadBlank({
          backgroundColor: "#ffffff",
        });
      }
    });
    this.editor!.addEventListener("design:loaded", () => {
      if (!this.props.config) {
        this.editor!.setBodyValues({
          contentWidth: "100%",
        });
      }
    });
    this.editor!.registerCallback("selectImage", (_data, done) => {
      this.setState({ isCustomImageLibraryOpen: true });
      this.setState({ unlayerImageDoneFn: args => done(args) });
    });
    designUpdateInsightRefreshHandler(this.editor!);
    // register event listener to pick up custom tool messages from iframe
    window.addEventListener("message", this.customToolMessageHandler, false);
  };

  componentDidMount(): void {
    this.unLayerInit();
  }

  componentWillUnmount(): void {
    window.removeEventListener("message", this.customToolMessageHandler, false);
  }

  render(): JSX.Element {
    const {
      isCustomImageLibraryOpen,
      unlayerImageDoneFn,
      insightModalOpen,
      insightModalEventData,
      filterModalOpen,
      filterModalEventData,
    } = this.state;
    return (
      <>
        {/* Unlayer Custom Image Upload Modal */}
        <CustomImageLibrary
          isOpen={isCustomImageLibraryOpen}
          done={unlayerImageDoneFn}
          close={() => this.setState({ isCustomImageLibraryOpen: false })}
          handleUpload={imageUploadHandler(this.props.token)}
        />
        <InsightPickerModal
          editorRef={this.editor}
          isOpen={insightModalOpen}
          data={insightModalEventData}
          close={() => this.setState({ insightModalOpen: false, insightModalEventData: null })}
        />
        <FilterPickerModal
          editorRef={this.editor}
          isOpen={filterModalOpen}
          data={filterModalEventData}
          close={() => this.setState({ filterModalOpen: false, filterModalEventData: null })}
        />
        <div id="dashboard-unlayer-editor" style={{ height: "calc(100vh - 226px)" }} />
      </>
    );
  }
}
