import * as React from "react";
import { Icon } from "semantic-ui-react";
import { premiumIconColor } from "./header.style";
import { StylePopup } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";

// Component for diamond icon
export const PremiumIcon = (props) => {
  const { className, size, style } = props;
  const iconStyle = style ? { ...style, color: premiumIconColor} : { color: premiumIconColor };
  return (
    <StylePopup
      trigger={(
        <Icon name="diamond" className={`${size || "small"} ${className} cursor-pointer`} style={iconStyle} />
      )}
      content="Premium Feature"
      position="right center"
      size="mini"
      inverted
    />
  );
};
