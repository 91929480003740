import { find } from "lodash";
import { escapeRegExp } from "common/helpers/string";
import { IMember, IProject } from "common/store/suitcase";

/* constants */
export const SUITCASES_TAB = {
  PRIVATE: 0,
  SHARED: 1,
  PRE_PACKED: 2,
};

/* helper functions */

// suitcase search filter, searches on suitcase name and owner name
export const applySearchFilter = (searchStr: string, onlySearchName?: boolean) => (suitcase: Record<string, any>): boolean => {
  if (!searchStr) {
    return true;
  }
  let searchableString = suitcase.name || "";
  if (!onlySearchName) {
    if (suitcase.owner) {
      const owner = suitcase.owner;
      searchableString += ` ${owner.first_name} ${owner.last_name}`;
    } else if (typeof suitcase.user_id === "number" && suitcase.users) {
      const owner = find(suitcase.users, user => user.id === suitcase.user_id);
      searchableString += ` ${owner?.first_name || ""} ${owner?.last_name || ""}`;
    }
  }
  return !!searchableString.toLowerCase().match(new RegExp(`${escapeRegExp(searchStr.toLowerCase())}`));
};

// Get all children insights recursively
export const getAccumulatedInsightsLength = (suitcases: IProject[], currentSuitcase: IProject): number => {
  const directInsights = currentSuitcase.insights.length;
  let directSubSuitcases_insights = 0;
  const directSubSuitcases = suitcases.filter(suitcase => suitcase.parent_id === currentSuitcase.id);
  if (directSubSuitcases.length > 0) {
    directSubSuitcases.forEach(suitcase => {
      directSubSuitcases_insights += getAccumulatedInsightsLength(suitcases, suitcase);
    });
  }
  return directInsights + directSubSuitcases_insights;
};

// NOT IN USE NOW, MAY BE USEFUL IN THE FUTURE: Get all children suitcases recursively
export const getAccumulatedSuitcasesLength = (suitcases: IProject[], currentSuitcase: IProject): number => {
  const directSubSuitcases = suitcases.filter(suitcase => suitcase.parent_id === currentSuitcase.id);
  if (directSubSuitcases.length > 0) {
    let directSubSuitcases_subSuitcasesLength = 0;
    directSubSuitcases.forEach(suitcase => {
      directSubSuitcases_subSuitcasesLength += getAccumulatedSuitcasesLength(suitcases, suitcase);
    });
    return directSubSuitcases.length + directSubSuitcases_subSuitcasesLength;
  } else {
    return 0;
  }
};

export const getSubSuitcasesLength = (projects: IProject[], suitcaseID: number): number => (
  projects.filter(project => project.parent_id === suitcaseID).length
);

export const getLatestUpdatedDate = (suitcase: IProject): string => (
  suitcase.insights
    .reduce((max, currentInsight) => (currentInsight.updated_at > max ? currentInsight.updated_at: max),
      suitcase.created_at)
);

// @TODO - rethink the naming - what is a reduced user? explain what the function is for etc
export const getReducedSuitcaseUsers = (suitcase: IProject): IMember[] => {
  const allUsers = [...suitcase.users, ...suitcase.users_implied];

  const reducedUsers = Array<IMember>();
  allUsers.forEach(user => {
    const userIndex = reducedUsers.findIndex(u => u.id === user.id);
    if (userIndex >= 0) {
      if (!user.read_only) {
        reducedUsers.splice(userIndex, 1, user);
      }
    } else {
      reducedUsers.push(user);
    }
  });

  return reducedUsers;
};

export const getAccessLevel = (suitcase: IProject, currentUserID: number, impliedOnly?: boolean): "Owner" | "Full Access" | "Read Only" | undefined => {
  const { user_id, users, users_implied } = suitcase;
  let accessValue;
  if (currentUserID === user_id) {
    accessValue = "Owner";
  } else {
    const allUserAccess = impliedOnly ? users_implied : [ ...users, ...users_implied ];
    const currentUserAccess = allUserAccess.filter(user => user.id === currentUserID);
    if (currentUserAccess.length > 0) {
      if (currentUserAccess.some(u => u.read_only === false)) {
        accessValue = "Full Access";  // If user has implied or explicit non-read-only access, that overrides any other access level specified
      } else {
        accessValue = "Read Only";
      }
    }
  }

  return accessValue;
};

export const isOwnerOfAncestorSuitcase = (suitcases: IProject[], suitcase: IProject, currentUserId: number): boolean => {
  if (suitcase.user_id === currentUserId) {
    return true;
  }

  if (!suitcase.parent_id) {
    return false;
  } else {
    const parentSuitcase = suitcases.find(s => s.id === suitcase.parent_id);
    if (parentSuitcase) {
      return isOwnerOfAncestorSuitcase(suitcases, parentSuitcase, currentUserId);
    } else {
      return false;
    }
  }
};

// NOT IN USE
export const sortFoldersFirstFunction = (a: IProject, b: IProject): number => (a.number_child_suitcases! > 0 && b.number_child_suitcases === 0) ? -1 : ((b.number_child_suitcases! > 0 && a.number_child_suitcases === 0) ? 1 : 0);

// returns suitcases with suitcase ids in filterIds excluded along with their descendant suitcases
export const excludeSuitcaseAndDescendants = (suitcases: any[], filterIds: number[]): any[] => {
  let filtered = suitcases.filter(sc => !filterIds.some(exclude => exclude === sc.id));
  const excludedChildIds = filtered.filter(sc => filterIds.some(exclude => exclude === sc.parent_id)).map(sc => sc.id);
  if (excludedChildIds.length) {
    filtered = excludeSuitcaseAndDescendants(filtered, excludedChildIds);
  }
  return filtered;
};

// filters suitcases to only return suitcases a specified userId has write access to
export const filterWriteAccessSuitcases = (suitcases: any[], userId: number): any[] => suitcases.filter(sc => {
  const users = [...sc.users, ...sc.users_implied];
  const user = users.find(item => item.id === userId);
  return user?.read_only === false;
});
