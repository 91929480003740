import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { SectionHeader } from "./SectionHeader";
import { Button } from "component/UI/Button";
import { Icon } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
import styled from "styled-components";
import { getMixpanel } from "common/api";
import Store from "common/store";

const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: row;
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.img`
  width: 458px;
  @media screen and (max-width: 850px) {
    width: 100%;
  }
`;

interface Props {
  store?: Store;
}

const DASHBOARD_GIF_LINK = "https://seer-media.s3.ap-southeast-2.amazonaws.com/homepage/homepage_dashboards.gif";

const Component = (props: Props): JSX.Element => {
  const { store } = props;
  return (
    <div className="mb-5">
      <SectionHeader heading="Data Driven Decision Making" />
      <ContentWrapper>
        <ImageWrapper src={DASHBOARD_GIF_LINK} alt="Homepage dashboards gif" />
        <div className="d-flex flex-column justify-content-between">
          <p className="fs-1125 fw-400 mb-4" style={{ color: COLORS.grey700 }}>
            The Seer Data Platform enables data access, analysis, sharing, and storytelling. It is co-designed with and for people who want to use data for decision-making.
          </p>
          <div className="d-flex flex-column gap-3 align-items-stretch align-items-sm-start">
            <Button
              icon
              colorConfig="purpleCTA"
              labelPosition="right"
              style={{ minHeight: 40, height: "auto" }}
              onClick={() => {
                window.open("https://www.ballaratfoundation.org.au/news/16/vital-signs", "_blank");
                getMixpanel(store!).track("View Example Dashboards", { Page: "Homepage", Dashboard: "Ballarat Vital Signs" });
              }}
            >
              View Ballarat Vital Signs Dashboard <Icon name="arrow alternate circle right outline" />
            </Button>
            <Button
              icon
              colorConfig="purpleCTA"
              labelPosition="right"
              style={{ minHeight: 40, height: "auto" }}
              onClick={() => {
                window.open("https://seerdata.ai/first-nations-2/", "_blank");
                getMixpanel(store!).track("View Example Dashboards", { Page: "Homepage", Dashboard: "Wonnarua Gap Closer" });
              }}
            >
              View Wonnarua Gap Closer Dashboard <Icon name="arrow alternate circle right outline" />
            </Button>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export const DashboardExamples = inject("store")(observer(Component));
