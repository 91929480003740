/** Signup-Related API Calls */

import { database } from "common/api";
import { webUrl } from "common/constants";

const postSignUp = async (body: {
  group_name?: any;
  first_name: any;
  last_name: any;
  email: any;
  phone?: any;
  verification_code?: any;
  invitation_token?: any;
}): Promise<boolean | null> => {
  const res: any = await database.post("users", body);
  if (res.statusCode === 200) {
    const token = res.body.data.user.token;
    const id = res.body.data.user.id;
    window.location.href = `${webUrl}/login?token=${token}&id=${id}&redirect=/welcome`;
    return true;
  } else if (res.statusCode === 400) {
    return false;
  } else {
    return null;
  }
};

interface QuickSignUpBody {
  group_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  verification_code: string;
}
export const quickSignUp = async (body: QuickSignUpBody): Promise<any> => {
  const res: any = await database.post("users", body);
  let error;
  let data;
  if (res.statusCode === 200) {
    data = res.body.data;
  } else if (res.statusCode === 400) {
    error = "The verification code entered seems to be wrong. Please try again.";
  } else {
    error = "Something went wrong. Please try again later.";
  }
  return { data, error };
};

export const createNewOrg = async (
  orgName: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  verificationCode: string,
  invitationToken: string,
): Promise<boolean | null> => {
  const body = {
    group_name: orgName,
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    verification_code: verificationCode,
    invitation_token: invitationToken,
  };
  return await postSignUp(body);
};

export const createNewUser = async (
  firstName: string,
  lastName: string,
  email: string,
  invitationToken: string
): Promise<boolean | null> => {
  const body = {
    first_name: firstName,
    last_name: lastName,
    email,
    invitation_token: invitationToken,
  };
  return await postSignUp(body);
};

interface VerificationBody {
  email: string;
  disable_magic_button?: boolean;
}
// Send signup verification email
export const sendVerification = async (body: VerificationBody): Promise<string> => {
  const res: any = await database.post("emails", { ...body, type: "verification" });
  return res?.statusCode === 200 ? "" : "Something went wrong. Please check your email and try again.";
};
