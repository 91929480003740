import * as React from "react";
import styled from "styled-components";
import { Placeholder } from "semantic-ui-react";

interface IContentPlaceholder {
  color?: string;
  className?: string;
}

export const ContentPlaceholder = ({ color = "#f5f5f5", className }: IContentPlaceholder): JSX.Element => {
  const StyledPlaceholder = styled(Placeholder)`
    max-width: none !important;
    &.ui.placeholder .line {
      background-color: ${color} !important;
    };
    margin-bottom: 40px;
  `;

  return (
    <StyledPlaceholder className={className}>
      <StyledPlaceholder.Line />
      <StyledPlaceholder.Line />
      <StyledPlaceholder.Line />
      <StyledPlaceholder.Line />
      <StyledPlaceholder.Line />
    </StyledPlaceholder>
  );
};
