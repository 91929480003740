import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Icon, Popup } from "semantic-ui-react";
import Store from "common/store";
import { ColorPicker } from "component/ColorPicker";
import { EditablePaletteName } from "./EditablePaletteName";
import { ActionConfirm } from "./ActionConfirm";
import { ColorCircle } from "./ColorCircle";
import styled from "styled-components";
import { getMixpanel } from "common/api";
import { COLORS } from "./UI/common";

const StyleAddPaletteButton = styled(Button)`
  &.ui.basic.button {
    color: ${COLORS.indigo600} !important;
    border: 1px solid ${COLORS.indigo600};
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: none !important;
    margin-bottom: 15px;
  };
  &.ui.basic.button:hover {
    box-shadow: none !important;
  };
`;

const lockPopupContent = (isOrgOwner, locked) => {
  if (isOrgOwner) {
    return locked ? "Palette locked, only you have permission to modify" : "Palette is unlocked and can be modified by anyone in your organisation";
  }
  return locked ? "Palette has been locked by your organisation owner" : "You have permission to modify this palette";
};

const ColorPalettesListComponent: React.FC<{ store: Store }> = ({ store }) => {
  const [colorPalettes, setColorPalettes] = React.useState(store.colorPalette.allColorPalettes);

  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [currentColor, setCurrentColor] = React.useState("");
  const [selectedPaletteID, setSelectedPaletteID] = React.useState(0);
  const [selectedPaletteName, setSelectedPaletteName] = React.useState("");
  const [selectedColorIndex, setSelectedColorIndex] = React.useState(0);
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const deleteColor = (paletteID: number, colorIndex: number) => {
    colorPalettes.map(colorPalette => {
      if (colorPalette.id === paletteID) {
        colorPalette.colors.splice(colorIndex, 1);
        const { name, colors } = colorPalette;
        store.colorPalette.updateColorPalette(paletteID, name, colors, colorPalette.locked);
        return;
      }
    });
  };

  const addColor = (color: string) => {
    colorPalettes.map(colorPalette => {
      if (colorPalette.id === selectedPaletteID) {
        colorPalette.colors.push(color);
        const { name, colors } = colorPalette;
        store.colorPalette.updateColorPalette(selectedPaletteID, name, colors, colorPalette.locked);
        return;
      }
    });
  };

  const editColor = (color: string) => {
    colorPalettes.map(colorPalette => {
      if (colorPalette.id === selectedPaletteID) {
        colorPalette.colors.splice(selectedColorIndex, 1, color);
        const { name, colors } = colorPalette;
        store.colorPalette.updateColorPalette(selectedPaletteID, name, colors, colorPalette.locked);
        return;
      }
    });
  };

  const deletePalette = () => {
    store.colorPalette.deleteColorPalette(selectedPaletteID);
  };

  const addPalette = () => {
    store.colorPalette.addColorPalette(store.user!.group.id, "New Colour Palette", []);
    if (window.location.pathname === "/settings/org/palettes") {
      getMixpanel(store).track("Create Colour Palette", { "Page": "Settings > Organisation > Colour Palettes" });
    }
  };

  const duplicatePalette = (paletteName: string, paletteColors: string[]) => {
    let name: string;
    // Check if palette's name contains "Copy of" to avoid multiple "Copy of"
    paletteName.match(/^Copy of/) ? name = paletteName : name = `Copy of ${paletteName}`;
    store.colorPalette.addColorPalette(store.user!.group.id, name, paletteColors);
  };

  const handleUpdatePaletteName = (paletteID, name, colors, locked) => {
    store.colorPalette.updateColorPalette(paletteID, name, colors, locked);
  };

  const handlePaletteLocked = (palette, locked) => {
    store.colorPalette.updateColorPalette(palette.id, palette.name, palette.colors, locked);
  };

  const handleColoredCircleOnClick = (color: string, paletteID: number, colorIndex: number) => {
    // setCurrentColor(color) to let ColorPicker run editColor()
    setCurrentColor(color);
    setColorPickerOpen(true);
    setSelectedPaletteID(paletteID);
    setSelectedColorIndex(colorIndex);
  };

  const handleAddColorOnClick = (paletteID: number) => {
    // setCurrentColor("") to let ColorPicker run addColor()
    setCurrentColor("");
    setColorPickerOpen(true);
    setSelectedPaletteID(paletteID);
  };

  const handleDeletePaletteOnClick = (paletteName: string, paletteID: number) => {
    setConfirmOpen(true);
    setSelectedPaletteID(paletteID);
    setSelectedPaletteName(paletteName);
  };

  React.useEffect(() => {
    if (window.location.pathname === "/settings/org/palettes") {
      getMixpanel(store).track("Page view", { "Page": "Settings > Organisation > Colour Palettes" });
    }
  }, []);

  // When store.colorPalette.allColorPalettes change triggers re-render to get updated color palettes
  React.useEffect(() => {
    setColorPalettes(store.colorPalette.allColorPalettes);
  }, [store.colorPalette.allColorPalettes]);

  // only org owner can modify locked palettes
  const isOrgOwner = store.user!.id === store.user!.group.user_id;

  return (
    <>
      <StyleAddPaletteButton
        basic
        size="small"
        onClick={addPalette}
        className="cursor-pointer"
      >
        Create colour palette<Icon name="tint" className="ml-1 mr-0"/>
      </StyleAddPaletteButton>
      {/* TODO: Add search function */}
      {colorPalettes.map(palette => {
        const canModify = !!palette.group_id && (isOrgOwner || !palette.locked);
        return (
          <div className="bg-light pt-3 px-3 pb-0 rounded-3 mb-3" key={`${palette.name}${palette.id || "default"}`} style={{ fontSize: 14 }}>
            <div className="d-flex justify-content-between ">
              {canModify ? (
                <EditablePaletteName
                  name={palette.name}
                  paletteID={palette.id!}
                  colors={palette.colors}
                  updateName={(paletteID, name, colors) => handleUpdatePaletteName(paletteID, name, colors, palette.locked)}
                />
              ) : (
                <p className="text-dark fs-1125 fw-600 mb-3">
                  {palette.name}
                  {!palette.locked && <span className="pl-2 text-muted fs-0750">Default Palette</span>}
                </p>
              )}
              <div className="d-flex">
                <Popup
                  content="Copy"
                  position="top center"
                  size="mini"
                  trigger={(
                    <div>
                      <Icon
                        onClick={() => duplicatePalette(palette.name, palette.colors)}
                        name="copy outline"
                        color="grey"
                        className="cursor-pointer"
                      />
                      <span
                        className="sr-only"
                        onClick={() => duplicatePalette(palette.name, palette.colors)}
                      >
                        Duplicate
                      </span>
                    </div>
                  )}
                />
                {canModify && (
                  <Popup
                    content="Delete"
                    position="top center"
                    size="mini"
                    trigger={(
                      <div>
                        <Icon
                          onClick={() => handleDeletePaletteOnClick(palette.name, palette.id!)}
                          name="trash alternate outline"
                          color="grey"
                          className="cursor-pointer"
                        />
                        <span
                          className="sr-only"
                          onClick={() => handleDeletePaletteOnClick(palette.name, palette.id!)}
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  />
                )}
                {!!palette.group_id && (
                  <Popup
                    content={lockPopupContent(isOrgOwner, palette.locked)}
                    position="top center"
                    size="mini"
                    trigger={(
                      <div>
                        <Icon
                          onClick={() => isOrgOwner && handlePaletteLocked(palette, !palette.locked)}
                          name={palette.locked ? "lock" : "lock open"}
                          color="grey"
                          className="cursor-pointer"
                        />
                        <span
                          className="sr-only"
                          onClick={() => isOrgOwner && handlePaletteLocked(palette, !palette.locked)}
                        >
                          Lock
                        </span>
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap mw-100">
              {palette.colors.map((color, colorIndex) => (
                <ColorCircle
                  key={colorIndex}
                  color={color}
                  colorIndex={colorIndex}
                  handleCircleOnClick={canModify ? (() => handleColoredCircleOnClick(color, palette.id!, colorIndex)) : null}
                  handleCrossOnClick={canModify ? (() => deleteColor(palette.id!, colorIndex)) : null}
                />
              ))}
              {canModify && (
                <Popup
                  content="Add"
                  position="top center"
                  size="mini"
                  trigger={(
                    <div>
                      <Icon
                        onClick={() => handleAddColorOnClick(palette.id!)}
                        circular
                        name="plus"
                        color="grey"
                        className="mb-3 cursor-pointer"
                      />
                      <span
                        className="sr-only"
                        onClick={() => handleAddColorOnClick(palette.id!)}
                      >
                        Add colour
                      </span>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        );
      })}
      <StyleAddPaletteButton
        basic
        size="small"
        onClick={addPalette}
        className="cursor-pointer"
      >
        Create colour palette<Icon name="tint" className="ml-1 mr-0"/>
      </StyleAddPaletteButton>
      {/* Delete color palette confirm modal */}
      <ActionConfirm
        open={confirmOpen}
        paletteName={selectedPaletteName}
        setConfirmOpen={open => setConfirmOpen(open)}
        confirmAction={deletePalette}
      />
      {/* ColorPicker modal when add/edit color  */}
      <ColorPicker
        open={colorPickerOpen}
        initialColor={currentColor === "" ? null : currentColor}
        setColorPickerOpen={open => setColorPickerOpen(open)}
        headerText={currentColor !== null ? "Update Color" : "Add Color"}
        saveText={<>Save <Icon name="save" /></>}
        onSave={newColor => {
          const action = currentColor === "" ? addColor : editColor;
          action(newColor);
        }}
      />
    </>
  );
};

export const ColorPalettesList = inject("store")(observer(ColorPalettesListComponent));
