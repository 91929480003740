import * as React from "react";
import { MapContainer as Map, GeoJSON } from "react-leaflet";
import { FullScreenControl } from "component/LeafletCustom/FullScreenControl";
import { Dropdown, Icon } from "semantic-ui-react";

const colorPalettes = [
  {
    id: 1,
    name: "Seer Palette 1",
    palette: {
      primary: "#5f2d5f",
      background: "#f1ecf1",
      legend: ["#5f2d5f", "#9d3566", "#d4485a", "#f7703e", "#ffa600"],
    },
  },
  {
    id: 2,
    name: "Seer Palette 2",
    palette: {
      primary: "#5f2d5f",
      background: "#f5eaea",
      legend: ["#c49ec4", "#aa81aa", "#906490", "#774877", "#5f2d5f"],
    },
  },
];

const variables = ["Population", "Population Density", "Stable Population", "Average IGWT per Household", "Combined Score"];

export const IgwtLga2021 = (): JSX.Element => {
  const [variableID, setVariableID] = React.useState(4);
  const [colorPaletteID, setColorPaletteID] = React.useState(1);
  const [showMapDropdown, setShowMapDropdown] = React.useState(false);
  const [showColorPaletteDropdown, setShowColorPaletteDropdown] = React.useState(false);
  const [data, setData] = React.useState<any>(null);

  const DEFAULT_ZOOM = 4;
  const DEFAULT_MAP_CENTRE: any = [-26.6980, 133.8807];
  const MAP_BOUNDS: any = [[-50, 50], [4, 200]];

  const colorPalette = colorPalettes.find(palette => palette.id === colorPaletteID)!.palette;
  // Value Range for "Population", "Population Density", "Stable Population" and "Average IGWT per Household" are 0-100
  // Value Range for "Combined Score" is 0-340
  let maxValue = 100;
  if (variables[variableID] === "Combined Score") {
    maxValue = 340;
  }

  const getColor = (val) => {
    const division = Math.floor(maxValue / 5); // "5" is the number of legend colors in colorPalette
    return (
      val < division
        ? colorPalette.legend[0]
        : val < division * 2
          ? colorPalette.legend[1]
          : val < division * 3
            ? colorPalette.legend[2]
            : val < division * 4
              ? colorPalette.legend[3]
              : colorPalette.legend[4]
    );
  };

  const geoJSONStyle = (feature) => ({
    weight: 0.6,
    fillOpacity: 1,
    fillColor: getColor(feature.properties[variables[variableID]]),
    color: colorPalette.background,
  });

  const onEachFeature = (feature: any, layer: any) => {
    const tooltipContent = `
      <div>
        <p style="color: ${colorPalette.primary}; margin-bottom: 10px">
          <strong>${feature.properties["LGA_NAME21"]}</strong>
          <br/>
          ${feature.properties["Existing CF Site"]}
        </p>
        <p style="margin-top: 0">
          ${variables.map(variable =>
            `${variable}${variable !== "Combined Score" ? " Percentile" : ""}: <strong>${Math.round(feature.properties[variable] * 100) / 100}</strong></br/>`
          ).join("")}
        </p>
      </div>
    `;

    layer.bindTooltip(tooltipContent, { direction: "top", sticky: true });
    layer.on("mouseover", () => {
      layer.setStyle({ fillOpacity: 0.95 });
    });
    layer.on("mouseout", () => {
      layer.setStyle({ fillOpacity: 1 });
    });
  };

  const dropdownOptions = variables.map((variable, idx) => ({
    key: idx,
    value: idx,
    text: variable,
  }));

  const colorPaletteDropdownOptions = colorPalettes.map(palette => ({
    key: palette.id,
    value: palette.id,
    text: palette.name,
  }));

  const handleDropdownChange = (value, callFunc) => {
    callFunc(+value);
  };

  const handleColorPaletteDropdownChange = (value) => {
    setColorPaletteID(+value);
    setShowColorPaletteDropdown(false);
  };

  const initData = async () => {
    const json = await fetch("/assets/map_data/iwt2021_lga_map.json").then(res => res.json()).catch(() => null);
    if (json) {
      setData(json);
    }
  };

  React.useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <div className="text-center mb-3">
        <h1 style={{ color: colorPalette.primary }}>IGWT LGA 2021</h1>
        <Dropdown
          selection
          options={dropdownOptions}
          value={variableID}
          onChange={(_, data) => handleDropdownChange(data.value, setVariableID)}
          className="z-index-max"
          style={{ color: colorPalette.primary }}
        />
      </div>
      {/* Main Map Area */}
      <Map center={DEFAULT_MAP_CENTRE} zoom={DEFAULT_ZOOM} minZoom={4} maxZoom={15} maxBounds={MAP_BOUNDS} style={{ background: colorPalette.background }}>
        <FullScreenControl onFullscreen={() => setShowMapDropdown(true)} onExitFullScreen={() => setShowMapDropdown(false)} />
        {/* Map dropdowns on fullscreen */}
        {showMapDropdown && (
          <div className="text-center position-absolute w-100 z-index-max" style={{ top: 15 }}>
            <select
              value={variableID}
              onChange={e => handleDropdownChange(e.target.value, setVariableID)}
              style={{ color: colorPalette.primary }}
              className="fs-1250 p-2"
            >
              {dropdownOptions.map(o => (
                <option key={o.value} value={o.value}>{o.text}</option>
              ))}
            </select>
          </div>
        )}
        {data && (
          <GeoJSON
            // key workaround for this version of react-leaflet that doesn't rerender on style/onEachFeature prop changes
            key={colorPalette.background.replace(/\W/g, "").concat(variables[variableID] || "")}
            data={data.features}
            style={geoJSONStyle}
            onEachFeature={onEachFeature}
          />
        )}
        <div className="position-absolute rounded-1" style={{ left: 15, bottom: 15, zIndex: 1000 }}>
          {/* Map color palettes dropdown */}
          {colorPalettes.length > 1 && (
            <div className="p-3 mb-3 rounded-1" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
              {showColorPaletteDropdown ? (
                <select
                  value={colorPaletteID}
                  onChange={e => handleColorPaletteDropdownChange(e.target.value)}
                  style={{ color: colorPalette.primary }}
                  className="fs-1000 p-1"
                >
                  {colorPaletteDropdownOptions.map(o => <option key={o.value} value={o.value}>{o.text}</option>)}
                </select>
              ) : (
                <div className="d-flex align-items-center cursor-pointer" style={{ color: colorPalette.primary }} onClick={() => setShowColorPaletteDropdown(true)}>
                  <Icon name="sync alternate" style={{ marginTop: -5 }} /><p className="fs-1000 my-0">Change colours</p>
                </div>
                )
              }
            </div>)
          }
          {/* Map Legend */}
          <div className="p-3 rounded-1" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
            <div className="d-flex mb-2">
              {colorPalette.legend.map((color, index) => <div style={{ height: 16, width: 20, background: color }} key={index} />)}
            </div>
            <p style={{ color: colorPalette.primary }}>Low to high</p>
          </div>
        </div>
      </Map>
    </>
  );
};
