import * as React from "react";
import { Label, Table } from "semantic-ui-react";
import { Avatar } from "component/Avatar";
import { AccessDropdown } from "component/Members/includes/addMember.style";
import styled from "styled-components";
import { COLORS } from "component/UI/common";

interface IManageAccessList {
  headerItems: string[];
  listItems: any;
  dropdownOnChange: (type, id, accessLevel) => void;
  isEditable: boolean;
  userOrgID?: number; // For showing "My organisation" label next to org name
}

const StyledTable = styled(Table)`
  border: none !important;
  background-color: #ffffff !important;
  &.ui.basic.table thead {
    background-color: #ffffff !important;
  }
  &.ui.basic.table tr {
    background-color: #ffffff !important;
  }
  &.ui.basic.table thead tr th {
    border: none !important;
    background-color: #ffffff !important;
  }
  &.ui.basic.table tbody tr td {
    border: none !important;
    border-top: 1px solid ${COLORS.grey200} !important;
    background-color: #ffffff !important;
  }
`;

const accessOptions = [
  { key: 1, text: "Full Access", value: "write" },
  { key: 2, text: "Read Only", value: "read" },
  { key: 3, text: "Remove Access", value: "remove" },
];

export const ManageAccessList = ({ headerItems, listItems, dropdownOnChange, isEditable, userOrgID }: IManageAccessList): JSX.Element => {
  // Check if this component is used for "Organisation Access List" or "Member Access List" as they have different columns
  const isUsedForOrg = headerItems.indexOf("Organisation") < 0;

  return (
    <>
      {
        listItems.length === 0 ?
        <p className="fw-500 text-medium">{`No ${isUsedForOrg ? "organisations" : "users"} have access to this dashboard.`}</p> :
        <StyledTable basic="very" unstackable>
          <StyledTable.Header>
            <StyledTable.Row>
              {
                headerItems.map(item => (
                  <StyledTable.HeaderCell key={item} className="text-medium fs-0875">{item}</StyledTable.HeaderCell>
                ))
              }
            </StyledTable.Row>
          </StyledTable.Header>
          <StyledTable.Body>
            {
              listItems.map(item => {
                const { id, name, logo, first_name, last_name, email, avatar, access_level, group } = item;
                const userName = `${first_name} ${last_name}`;
                return (
                  <StyledTable.Row key={id} className="fs-1000 text-dark">
                    {
                      isUsedForOrg ?
                      <>
                        <StyledTable.Cell collapsing><Avatar name={name} image={logo} /></StyledTable.Cell>
                        <StyledTable.Cell className="fw-700">
                          {name}
                          {userOrgID === id && <Label circular className="ml-2 text-medium fs-0750">My Organisation</Label>}
                        </StyledTable.Cell>
                      </> :
                      <>
                        <StyledTable.Cell collapsing><Avatar name={userName} image={avatar} /></StyledTable.Cell>
                        <StyledTable.Cell>
                          <p className="fw-700">{userName}</p>
                          <p className="text-medium fw-500 fs-0875">{email}</p>
                        </StyledTable.Cell>
                        <StyledTable.Cell><p className="fw-700">{group.name}</p></StyledTable.Cell>
                      </>
                    }
                    <StyledTable.Cell collapsing>
                      {
                        access_level === "owner"
                        ? <p className="text-medium fw-700">Owner</p>
                          : isEditable
                            ? <AccessDropdown
                                options={accessOptions}
                                value={access_level}
                                selectOnBlur={false}
                                selection
                                onChange={(_, { value }) => dropdownOnChange(isUsedForOrg ? "groups" : "users", id, value)}
                              />
                            : <p className="text-medium fw-700">
                                {access_level === "write" ? "Full Access" : "Read Only"}
                              </p>
                      }
                    </StyledTable.Cell>
                  </StyledTable.Row>
                );
              })
            }
          </StyledTable.Body>
        </StyledTable>
      }
    </>
  );
};
