import * as React from "react";
import { Checkbox, Icon, Modal } from "semantic-ui-react";
import { Button } from "component/UI/Button";

export const DashboardSettingsModal = (props) => {
  const { isOpen, onClose, canEmbedInsightClickThrough, setEmbedInsightClickThrough, allowInsightDownloads, setAllowInsightDownloads } = props;
  return (
    <Modal open={isOpen}>
      <Modal.Header>Dashboard Settings</Modal.Header>
      <Modal.Content>
        <div className="mb-4">
          <Checkbox
            toggle
            checked={canEmbedInsightClickThrough}
            size="small"
            className="mb-1"
            onChange={(_e, data) => setEmbedInsightClickThrough(data.checked)}
            label={<label className="fs-1000 fw-600 text-dark">Allow embedded Insight click through</label>}
          />
          <div className="d-flex mt-2 text-dark align-items-center">
            <Icon name="info circle" className="mr-2 fs-1000" style={{ marginTop: -6 }} /><p className="fs-0875">This feature requires <span><a href="https://knowledge.seerdata.ai/how-do-i-share-a-suitcase" target="_blank">Public Link Sharing</a></span> to be enabled on the Suitcases of embedded Insights.</p>
          </div>
        </div>
        <Checkbox
          toggle
          checked={allowInsightDownloads}
          size="small"
          onChange={(_e, data) => setAllowInsightDownloads(data.checked)}
          label={<label className="fs-1000 fw-600 text-dark">Allow embedded Insight download</label>}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button colorConfig="red" onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};
