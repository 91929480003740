import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { ExploreTable } from "./includes/ExploreTable";
import { ExploreFullQuery } from "./includes/ExploreFullQuery";
import { Button, Icon } from "semantic-ui-react";
import { getMixpanel } from "common/api";
import { useHistory, useLocation } from "react-router-dom";
import * as qs from "qs";
import { isAnyFilterSelected } from "common/helpers/explore";
import { ls } from "common/helpers/storage";
import { ExploreSwitch } from "component/insightBuilder/ExploreSwitch";

interface IExploreTableResult {
  store?: Store;
  update: boolean;
  suitcaseId: number;
  setCurrentStep: (string) => void;
  analystPortal?: boolean;
};

export const ExploreTableResult = inject("store")(observer(({ store, update, suitcaseId, setCurrentStep, analystPortal }: IExploreTableResult): JSX.Element => {
  const { insightbuilder, builder } = store!;
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const { from, flow, step } = qs.parse(search.slice(1) || "");

  const insightCode = window.location.pathname.includes("builder/new") ? null : insightbuilder?.parent?.insight?.result?.code;

  const isNextButtonActive = () => (builder.isTableStepComplete || insightCode) && !builder.robot.open;

  return (
    <>
      {/* Back and Next buttons */}
      <div
        className="d-flex bg-white align-items-center justify-content-between w-100 border-bottom"
        style={{ height: 60, padding: 10, borderRadius: "4px 4px 0px 0px" }}
      >
        <div className="ml-2 fs-1000 text-dark">
          <p className="d-inline">View your personalised </p>
          <a href="/catalog" target="_blank" className="fw-700 text-primary"
            onClick={() => getMixpanel(store!).track("Data Catalog", { Source: "Explore" })}
          >
            Data Catalog <Icon name="external alternate" />
          </a>
        </div>
        <div className="d-flex align-items-center">
          {!update && (
            <ExploreSwitch
              label="Go to Guided Explore"
              handleOnClick={() => {
                history.push(`${suitcaseId
                  ? `/suitcases/${suitcaseId}`
                  : ""}/explore${(isAnyFilterSelected(builder) && from === "guided_explore")
                    ? `?from=manual_explore${flow ? `&flow=${flow}` : ""}${step ? `&step=${step}` : ""}`
                    : ""}`
                );
                const mixpanelObj = {};
                if (isAnyFilterSelected(builder) && from === "guided_explore") {
                  mixpanelObj["From"] = "Manual Explore";
                  mixpanelObj["To"] = "Guided Explore";
                  mixpanelObj["Flow"] = flow;
                  mixpanelObj["Step"] = step;
                } else {
                  ls.setItem("isAdvancedExploreUser", false);
                  mixpanelObj["Set default"] = "Guided Explore";
                }
                getMixpanel(store!).track("Multi-step Explore", mixpanelObj);
              }}
              className="mr-3"
            />
          )}
          <Button
            id="next-btn-table-step"
            disabled={!isNextButtonActive()}
            className="bg-primary text-white bg-hover-red"
            onClick={() => {
              builder.clearEmptyDynamicQueries();
              builder.setActiveTableId(undefined);
              setCurrentStep("chart");
              builder.ui.setLeftTabFolded(false);
              getMixpanel(insightbuilder.parent).track("Insight Builder > Chart", { "Button Click": "Next" });
            }}
            size="small"
          >
            Next<Icon name="arrow right" />
          </Button>
        </div>
      </div>

      {/* Full query */}
      <ExploreFullQuery analystPortal={analystPortal} />

      {/* Table results */}
      <div className="overflow-auto" style={{ height: "calc(100% - 60px)", padding: 20 }}>
        <ExploreTable
          update={update}
          suitcaseId={suitcaseId}
        />
      </div>
    </>
  );
}));
