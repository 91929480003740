import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Divider } from "semantic-ui-react";
import { database } from "common/api";
import { Modal } from "component/UI/Modal";
import { Button } from "component/UI/Button";
import Store from "common/store";

interface Props {
  closeHandler: () => any;
  store?: Store;
}

export const Component = (props: Props) => {
  const store = props.store!;
  const { closeHandler } = props;
  const { user, token } = store;
  const acceptHandler = async (form_terms_accepted: boolean) => {
    await database.put(`users/${user!.id}`, { form_terms_accepted }, token!);
    await store.reloginUserFromAuthToken();
    closeHandler();
  };
  return (
    <Modal open>
      <Modal.Header>Enable Dashboard Forms</Modal.Header>
      <Modal.Content>
        <h2>Enable Dashboard Form creation by accepting the Terms of Service</h2>
        <p>If you choose not to enable Dashboard Forms now, you can do so in the Dashboard actions menu on Dashboards you own.</p>
        <Divider />
        <div className="my-3">
          <h3>3 simple tips on data collection and privacy</h3>

          <h4 style={{ textDecoration: "underline" }}>1. Avoid collecting personal information if possible</h4>
          <p className="mb-1">Users are responsible for making sure their surveys are compliant with privacy laws.</p>
          <p className="mb-1">The simplest thing to do, wherever possible, is to make surveys anonymous and avoid seeking or collecting people's personal information in the first place.</p>
          <p className="mb-1">
            <a href="https://www.oaic.gov.au/privacy/your-privacy-rights/your-personal-information/what-is-personal-information" target="_blank">Personal information</a>{" "}
            refers to any data linked to a particular individual that could reveal their identity. This includes details such as full names, email addresses, phone numbers, or residential addresses.
          </p>
          <p className="mb-1">If a person includes personal information in their response, such as in a free text question, you may decide you can de-identify it manually or destroy it when you no longer need it.</p>

          <h4 style={{ textDecoration: "underline" }}>2. Tell people how you will handle their responses</h4>
          <p>You could include a brief description of:</p>
          <ul>
            <li>The types of personal information being collected, if any.</li>
            <li>How you intend to use the data.</li>
            <li>Any plans to share survey data with third-party organisations and the manner in which it will be shared.</li>
            <li>Contact information for survey takers to reach out to you with any questions or concerns.</li>
          </ul>
          <p className="mb-1">
            The Australian Office of the Information Commissioner has outlined the more detailed information you should{" "}
            <a href="https://www.oaic.gov.au/privacy/your-privacy-rights/your-personal-information/collection-of-personal-information" target="_blank">tell people when personal information is collected</a>
            .
          </p>
          <p className="mb-1">We recommend that you include this information in dashboard content close to your form, or alongside links shared externally via email and other communications. You could also embed a hyperlink directly to your privacy notice on the dashboard.</p>

          <h4 style={{ textDecoration: "underline" }}>3. Adhere to privacy law</h4>
          <p className="mb-1">Consent is generally needed for the collection of{" "}
            <a href="https://www.oaic.gov.au/privacy/your-privacy-rights/your-personal-information/what-is-personal-information#SensitiveInfo" target="_blank">sensitive information</a>{" "}
            or to use or disclose personal information other than the purpose it was collected for (see tip 2).
          </p>
          <p className="mb-1">If you do need to get consent from people, you can include a mandatory question in your form.</p>

          <h4 style={{ textDecoration: "underline" }}>More information</h4>
          <p className="mb-1">
            The Office of the Australian Information Commissioner has created{" "}
            <a href="https://www.oaic.gov.au/privacy/privacy-guidance-for-organisations-and-government-agencies/organisations/tips-for-good-privacy-practice" target="_blank">tips for good privacy practice</a>
            .
          </p>
          <p className="mb-1">When uncertain, you should seek independent legal advice.</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button colorConfig="red" onClick={() => acceptHandler(true)}>Accept</Button>
        <Button colorConfig="red" onClick={() => acceptHandler(false)}>Reject</Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ModalFormsTOS = inject("store")(observer(Component));
