/* @TODO - note this is stubbed for WNAC map currently and will need to be refactored to suit once feature is built out fully */
import * as React from "react";
import * as qs from "qs";
import { MapContainer as Map, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
// import { useLeafletContext } from "@react-leaflet/core";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { FullScreenControl } from "component/LeafletCustom/FullScreenControl";
// import { BottomLeftContent, ContentBox } from "pages/Maps/includes/Layout";
import { stubMapConfigWNAC } from "pages/MapDataUpload/MapDataUpload";
import { customizations } from "./includes/helpers";
import { environment } from "common/constants";

export const FeatureMap = (props: RouteComponentProps): JSX.Element => {
  // @TODO - data loading for props.match.params.id instead of specific to wnac
  const { force_layers } = qs.parse(props.location.search.slice(1));
  const { title, defaultZoom, defaultMapCenter, mapBounds, baseMaps, layers, cssOverrides }: any = stubMapConfigWNAC;
  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    const abortController = new AbortController();
    const init = async () => {
      const layerData: any = [];
      const promises: any = [];
      for (const layer of layers) {
        const nextPromise = new Promise(async resolve => {
          const json = await fetch(`https://seer-maps.s3.ap-southeast-2.amazonaws.com/${environment === "production" ? "prod" : "staging"}/maps/wnac/${layer.name}.json`, {
            signal: abortController.signal,
            cache: "no-store",
          }).then(res => res.json()).catch(() => null);
          if (abortController.signal.aborted) {
            return resolve(false);
          }
          if (json) {
            layerData.push({ name: layer.name, json });
          }
          resolve(true);
        });
        promises.push(nextPromise);
      }
      const res = await Promise.all(promises);
      if (!res.includes(false)) {
        setData(layerData); // only update if component still mounted
      }
    };
    init();
    return () => abortController.abort();
  }, []);
  const LayersComponent = () => {
    if (!data?.length) {
      return null;
    }
    let useLayers = layers;
    if (force_layers?.length) {
      useLayers = layers.filter(layer => (force_layers as string[]).includes(layer.name as string));
    }
    return (
      <>
        {useLayers.map(layer => {
          const layerJSON = (data.find(item => item.name === layer.name))?.json;
          if (!layerJSON) {
            return null;
          }
          const { style, onEachFeature, pointToLayer } = customizations[layer.customizations] || customizations["default"];
          let Wrapper: any = LayersControl.Overlay;
          const wrapperProps = { key: layer.name, name: layer.label, checked: layer.checked };
          if (force_layers?.length) {
            Wrapper = React.Fragment;
            delete wrapperProps.name;
            delete wrapperProps.checked;
          }
          return (
            <Wrapper {...wrapperProps}>
              <GeoJSON
                data={layerJSON}
                style={style(layer)}
                onEachFeature={layer.onEachFeature ? onEachFeature(layer) : undefined}
                pointToLayer={layer.pointToLayer ? pointToLayer(layer) : undefined}
              />
            </Wrapper>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <body className="hide-header-and-hubspot" />
        <title>{title}</title>
      </Helmet>

      <Map
        center={defaultMapCenter}
        zoom={defaultZoom}
        minZoom={1}
        maxZoom={16}
        maxBounds={mapBounds}
        // style={{ background: colorPalette.background }}
        className="m-0 w-100 h-100"
      >
        <FullScreenControl />

        {!!force_layers?.length && <LayersComponent />}

        {/* layers */}
        <LayersControl position="bottomright">
          {/* base tile maps */}
          {baseMaps.map(baseMap => (
            <LayersControl.BaseLayer key={baseMap.name} name={baseMap.name} checked={baseMap.checked || undefined}>
              <TileLayer url={baseMap.url} attribution={baseMap.attribution} />
            </LayersControl.BaseLayer>
          ))}

          {/* GeoJSON layers */}
          {!force_layers?.length && <LayersComponent />}
        </LayersControl>
      </Map>
      {!!cssOverrides && <style>{cssOverrides}</style>}
    </>
  );
};
