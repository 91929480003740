import styled, { createGlobalStyle } from "styled-components";
import { StyledButton } from "./includes/CollapsibleContent";

const DPACCharcoal = "#333132";

export const GlobalStyle = createGlobalStyle`
  body {
    overflow-anchor: none; // to avoid scroll shifting on content changes due to applying filters
  }
`;

export const StyledPage = styled.div`
  font-family: 'Noto Sans', sans-serif !important;
  p, h1, h2, h3, h4, h5, h6 {
    font-family: 'Noto Sans', sans-serif !important;
  }
  color: ${DPACCharcoal};
  font-size: 18px;
  h1 {
    font-size: 48px;
  }
  p {
    font-size: 18px;
  }
`;

export const StyledPanes = styled.div`
  display: flex;
  flex-direction: column-reverse;
  
  >.left {
    width: 100%;
  }
  >.right {
    width: 100%;
    >.filters {
      border: 1px solid ${DPACCharcoal};
      border-radius: 5px;
      padding: 16px;
      height: fit-content;
    }
    >.help {
      border: 1px solid ${DPACCharcoal};
      .top {
        border-radius: 0 0 6px 6px;
        background-color: ${DPACCharcoal};
        a {
          color: #C0CEBA;
        }
      }
      :not(.top) a {
        color: ${DPACCharcoal};
      }
      a {
        &:hover, &:focus, &:active {
          text-decoration: underline;
        }
      }
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    >.left {
      padding-right: 48px;
    }
    >.right {
      width: 352px;
      min-width: 352px;
    }
  }
`;

export const StyledInput = styled.div`
  .ui.input {
    font-size: 18px !important;
    >input {
      font-weight: bold !important;
      border-color: ${DPACCharcoal} !important;
      border-radius: 12px !important;
      color: ${DPACCharcoal} !important;
      &::placeholder {
        color: #3A3835 !important;
      }
    }
  }
`;

export const StyledAccordion = styled.div`
  .accordion.ui {
    .title {
      text-align: left;
      font-weight: bold;
      padding: 16px;
      border-radius: 6px;
      background: ${DPACCharcoal};
      color: white;

      &:not(.active) {
        margin-bottom: 16px;
      }

      &:focus {
        box-shadow: 0px 0px 5px 3px #5E9ED6;
      }
    }

    .content {
      border: 1px solid lightgrey;
      border-radius: 6px;
      padding: 16px !important;
      margin-bottom: 16px;
      font-weight: bold;

      .ui.checkbox label {
        font-size: 16px;

        &::before {
          border: 1px solid ${DPACCharcoal};
        }

        &:hover {
          &::before {
            border: 1px solid #6E6E6E;
          }
        }

        &:active, &:focus {
          &::before {
            border: 1px solid #007fad;
          }
        }
      }

      > div:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`;

export const StyledHits = styled.div`
  .hit {
    font-weight: bold;
    border-bottom: 1px solid lightgrey;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    .top {
      padding: 24px;
      
      &.not_commenced {
        border-color: #3A3835;
        .status .icon {
          color: #3A3835;
        }
      }
      &.in_progress {
        border-color: #DDC88D;
        .status .icon {
          color: #DDC88D;
        }
      }
      &.completed {
        border-color: #879C81;
        .status .icon {
          color: #879C81;
        }
      }
      &.in_progress___delayed {
        border-color: #9C90AD;
        .status .icon {
          color: #9C90AD;
        }
      }
      
      .type {
        border-radius: 50px;
        padding: 4px 24px 4px 24px;
        color: white;
      }
    }
    .bottom {
      padding: 24px;
      li {
        line-height: 32px;
      }
      @media (min-width: 900px) {
        .info > div:first-child {
          min-width: 200px;
        }
      }
    }
    &.interim .top {
      border-style: solid;
      border-width: 2px;
      .type {
        background: #3B3835;
      }
    }
    &.recommendation .top {
      border-style: solid;
      border-width: 2px;
      .type {
        background: #455687;
      }
    }
  }
`;

export const StyledNoHits = styled.div`
  border: 2px dashed #455687;
  border-radius: 5px;
  padding: 80px;
`;

export const StyledClearFilterButton = styled(StyledButton)`
  color: #3B3835;
  font-weight: bold;
  &:disabled {
    color: #757575;
    cursor: not-allowed;
  }
`;
