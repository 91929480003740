import * as React from "react";
import { Form } from "semantic-ui-react";

export const FieldSelect = ({ input, meta, label, placeholder, options, disabled = false }) => {
  const error = meta.error || meta.submitError;
  const showError = error && meta.touched;
  return (
    <Form.Field disabled={disabled}>
      {label && <label>{label}</label>}
      <Form.Select
        {...input}
        disabled={disabled}
        onChange={(_, data) => input.onChange(data.value || "")}
        options={options}
        placeholder={placeholder || ""}
        error={showError}
        multiple={!!input.multiple}
      />
      {showError && (
        <p className="text-danger fs-0875"><b>{error}</b></p>
      )}
    </Form.Field>
  );
};
