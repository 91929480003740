import * as React from "react";
import { FixedSizeGrid } from "react-window";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { FakeTable, FakeTH, FakeTD } from "component/RecordTable/includes/FakeTable.style";

const DEFAULT_ROWS_TO_DISPLAY = 10;
export const ROW_HEIGHT = 35;
const DATA_CELL_WIDTH = 300;

interface Props {
  store?: Store;
  records: string[][];
  rowsToDisplay?: number;
}

const Component = (props: Props): JSX.Element => {
  const { records } = props;
  const rowsToDisplay = typeof props.rowsToDisplay === "number" ? props.rowsToDisplay : DEFAULT_ROWS_TO_DISPLAY;
  const { windowWidth } = props.store!.ui;
  const header = records[0];
  const colCount = header.length;
  const data = records.slice(1);
  const rowCount = data.length;
  const recordTableWidth = colCount * DATA_CELL_WIDTH;
  const [selectedCell, setSelectedCell] = React.useState([-1, -1]); // for highlighting purposes
  const [containerWidth, setContainerWidth] = React.useState(recordTableWidth); // start with full width but adjust to actual width of container once ref is initialised
  const maxTableWidth = Math.min(containerWidth, recordTableWidth);
  const containerRef = React.useRef(null);
  const headerRef = React.useRef(null);
  const gridRef = React.useRef(null);
  let scrollBarWidth = 0; // used to offset header width so content widths match and scroll is fully synced with grid
  if (gridRef.current) {
    const gridElement = gridRef.current as HTMLDivElement;
    scrollBarWidth = gridElement.offsetWidth - gridElement.clientWidth;
  }
  // update container width once ref is set or window width changes
  React.useEffect(() => {
    if (containerRef?.current) {
      setContainerWidth((containerRef.current as HTMLDivElement).clientWidth);
    }
  }, [containerRef, windowWidth]);

  return (
    <div ref={containerRef}>
      {/* add width to table to ensure border displays correctly when scroll bar appears */}
      <FakeTable style={{ width: maxTableWidth + 2 }}>
        <div className="overflow-hidden" style={{ width: maxTableWidth, whiteSpace: "nowrap" }} ref={headerRef}>
          {header.map((heading, idx) => {
            const thWidth = DATA_CELL_WIDTH + (idx === header.length - 1 ? scrollBarWidth : 0); // add scrollbar width to last element; non 0 if x-axis scrollbar is visible
            return <FakeTH key={heading} className="fs-1000 text-dark" style={{ width: thWidth }}>{heading}</FakeTH>;
          })}
        </div>
        <FixedSizeGrid
          outerRef={gridRef}
          style={recordTableWidth <= containerWidth ? { overflowX: "hidden" } : undefined}
          columnWidth={DATA_CELL_WIDTH}
          rowHeight={ROW_HEIGHT}
          columnCount={colCount}
          height={rowsToDisplay * ROW_HEIGHT}
          rowCount={rowCount}
          width={maxTableWidth}
          onScroll={({ scrollLeft }) => {
            // update header scroll position
            if (headerRef?.current) {
              (headerRef.current as HTMLDivElement).scrollLeft = scrollLeft;
            }
          }}
        >
          {({ columnIndex, rowIndex, style }) => {
            const cellData = data[rowIndex][columnIndex];
            let highlight: string = "";
            if (selectedCell[0] === columnIndex && selectedCell[1] === rowIndex) {
              highlight = "cell";
            } else if (selectedCell[0] === columnIndex || selectedCell[1] === rowIndex) {
              highlight = "col_row";
            }
            return (
              <FakeTD
                style={style}
                data-even={rowIndex % 2 === 1 ? true : undefined} // offset because row 0 in array is row 1
                data-highlight={highlight}
                title={cellData}
                onClick={() => {
                  let next = [columnIndex, rowIndex];
                  if (JSON.stringify(next) === JSON.stringify(selectedCell)) {
                    next = [-1, -1]; // reset on click highlighted cell
                  }
                  setSelectedCell(next);
                }}
              >
                {cellData}
              </FakeTD>
            );
          }}
        </FixedSizeGrid>
      </FakeTable>
    </div>
  );
};

export const RecordTable = inject("store")(observer(Component));
