/* Helper functions for tinymce WYSIWYG editor */
import { backendUrl } from "../constants";

export const imageUploadHandler = token => async (blobInfo, _progress) => {
  const formData = new FormData();
  formData.append("image", blobInfo.blob(), blobInfo.filename());
  const result = await fetch(`${backendUrl}/image-upload`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Token": token || "-none-",
    },
    body: formData,
  })
    .then(async res => {
      if (!res.ok) {
        const json = await res.json();
        throw new Error(`HTTP ${res.status}. ${json?.error?.message || "Image Upload Error"}`);
      }
      return res.json();
    })
    .catch(e => ({ error: e }));

  if (!result || result.error || !result.location) {
    throw Error(result?.error || "Image Upload Error");
  }
  return result.location;
};
