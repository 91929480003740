// @TODO - replace as much as we can with semantic-ui so we can get rid of most of this code
import styled, { css } from "styled-components";
import Select from "react-select";
import { COLORS } from "component/UI/common";

export const Heading = styled.h3`
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #504F5C;
`;

export const LabelHeading = styled.h4`
  font-family: "Open Sans";
  font-weight: bold;
  color: ${COLORS.indigo600};
  margin-top: calc(1rem / 2);
  margin-bottom: calc(1rem / 2);
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid #c3c3c3;
  margin: 40px 0px;
`;

export const Button = styled.button`
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: ${props => props.theme.color.heading};
  color: #ffffff;
  border: 0;
  padding: 12px 20px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  outline: none;
  &:focus {
    box-shadow: 0 0 0 0.5pt ${props => props.theme.color.focus};
  }
`;

export const InvertedButton = styled(Button)`
  font-family: "Open Sans";
  height: 40px;
  color: ${COLORS.red500};
  background: #f5f5f5;
  border: 1px solid #cccccc;
  box-shadow: none;
  outline: none;
  &:hover {
    background: white;
  }
`;

export const Input = styled.input`
  display: block;
  margin: 5px 0 20px;
  background-color: hsl(0, 0%, 98%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 40px;
  padding: 2px 8px;
  width: ${props => props.width};
  box-sizing: border-box;
  outline: none;
`;

export const PopupLabel = css`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.color.label};
`;

export const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 20px 0 0 0;
  min-width: 0;
  & > label:nth-of-type(odd) {
    background-color: #f6f6f6;
  }
  ${PopupLabel}
`;

export const Legend = styled.legend`
  margin: 0 0 15px 0;
`;

export const RadioCheckboxLabel = styled.label<{ checkbox?: boolean }>`
  font-weight: ${props => props.theme.font.weight.light};
  font-size: ${props => props.theme.font.size.xSmall};
  color: ${props => props.theme.color.list};
  background: url(/assets/${props => (props.checkbox ? "checkbox" : "radio")}-deselected.svg) left top no-repeat;
  display: block;
  min-height: 24px;
  padding: 12px 0 5px 44px;
  background-position-y: 6px;
  background-position-x: 5px;
  cursor: pointer;
`;

export const RadioCheckbox = styled.input<{ type?: string }>`
  opacity: 0;
  float: left;
  width: 26px;
  height: 26px;
  cursor: pointer;

  &:checked + label {
    background-image: url(/assets/${props => props.type}-selected.svg);
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    flex-grow: 1;
  }
`;

export const SearchInput = styled(Input)`
  margin: 0 0 0 10px;
  display: inline-block;
  min-height: 38px;
  width: 380px;

  @media (max-width: 787px) {
    width: 80%;
  }
`;

export const FileButtonSvg = styled.svg`
  cursor: pointer;
  padding-left: 10px;
  &:hover circle {
    fill: ${props => props.theme.color.heading};
  }
`;

export const ActionButtonSvg = styled.svg`
  cursor: pointer;
  padding-right: 10px;
  &:hover #outline {
    stroke: ${props => props.theme.color.heading};
  }
`;

export const Container = css`
  margin: 30px 0;
  background: #fff;
  border-radius: 5px;
  font-size: 12px;
`;

export const ChartHeading = styled.h3`
  color: ${props => props.theme.color.heading};
  font-weight: ${props => props.theme.font.weight.extraBold};
  font-size: ${props => props.theme.font.size.small};
`;

export const Icon = styled.svg`
  cursor: pointer;
  align-self: center;

  &:hover path {
    stroke: ${props => props.theme.color.heading};
  }

  &:hover polyline {
    stroke: ${props => props.theme.color.heading};
  }
`;

export const User = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: default ik;
`;

export const Arrow = styled.img<{ flip: boolean }>`
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
  transform: rotate(${props => (props.flip ? 180 : 0)}deg);
  position: relative;
  top: ${props => (props.flip ? 0 : -7)}px;
  transition: all 0.5s;
`;

export const Notification = styled.div<{ selected?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${props => (props.selected ? COLORS.indigo600 : COLORS.red500)};
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin: -2px 0 5px 5px;
`;

export const DArrow = styled.img<{ flip: boolean }>`
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
  transform: rotate(${props => (props.flip ? 180 : 0)}deg);
  position: relative;
  top: ${props => (props.flip ? 0 : -7)}px;
  float: right;
  transition: all 0.5s;
`;
