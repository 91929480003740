import * as React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { NotificationFeed } from "./notificationFeed";
import { useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { purpleBoxShadow, scrollBarStyle } from "component/Header/includes/header.style";
import { COLORS } from "component/UI/common";

const StyledPopup = styled(Popup)`
  &.ui.bottom.right.popup {
    margin-top: 15px;
    padding: 15px 5px 15px 15px;
    border: 0;
    box-shadow: ${purpleBoxShadow} !important;
  }
`;

const StyledPopupContentWrapper = styled(Popup.Content)`
  ${scrollBarStyle}
`;

export const NotificationPopup = inject("store")(
  observer((props: any) => {
    const { store } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = e => {
      setIsOpen(true);
      e.preventDefault();
      e.stopPropagation();
    };

    const handleClose = e => {
      setIsOpen(false);
      e.preventDefault();
      e.stopPropagation();
    };

    const getUnreadNotificationCount = () => store.notifications.filter(x => x.viewed === false).length;

    const unreadNotificationCount: number = getUnreadNotificationCount();

    const popupTrigger = (
      <div className="position-relative mr-3 d-none d-md-block">
        {unreadNotificationCount > 0 && (
          <div className="position-absolute bg-primary" style={{ height: 8, width: 8, color: COLORS.red500, borderRadius: 4, right: 2 }}></div>
        )}
        <Icon name="bell" className="text-secondary cursor-pointer" />
      </div>
    );

    return (
      <StyledPopup
        basic
        trigger={popupTrigger}
        position="bottom left"
        wide={true}
        on="click"
        verticalOffset={-4}
        horizontalOffset={-10}
        keepInViewPort={true}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <StyledPopupContentWrapper style={{ overflowY: "auto", maxHeight: "80vh" }}>
          <NotificationFeed handleClose={handleClose} displayLimit={10} />
        </StyledPopupContentWrapper>
      </StyledPopup>
    );
  })
);
