import * as React from "react";
import {
  Accordion as SemanticAccordion,
  Dropdown,
  AccordionProps,
  DropdownItemProps,
} from "semantic-ui-react";

interface ItemActions extends DropdownItemProps {
  key: string;
}

interface Item {
  key: string;
  title: string | JSX.Element;
  content: JSX.Element;
  actions?: ItemActions[];
}

interface Props extends AccordionProps {
  items: Item[];
}

export const Accordion = (props: Props): JSX.Element => {
  const [activeIndex, setActiveIndex]: [number, any] = React.useState(-1);
  const { items, ...semanticAccordionProps } = props;
  return (
    <SemanticAccordion {...semanticAccordionProps}>
      {items.map((item, idx) => (
        <React.Fragment key={item.key}>
          <SemanticAccordion.Title
            index={idx}
            active={idx === activeIndex}
            onClick={() => setActiveIndex(activeIndex === idx ? -1 : idx)}
          >
            <div className="d-flex justify-content-between">
              <div>{item.title}</div>
              <div>
                {item.actions?.length && (
                  <Dropdown icon="ellipsis vertical">
                    <Dropdown.Menu>
                      {item.actions}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </SemanticAccordion.Title>
          <SemanticAccordion.Content active={idx === activeIndex}>
            {item.content}
          </SemanticAccordion.Content>
        </React.Fragment>
      ))}
    </SemanticAccordion>
  );
};
