import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { parse } from "papaparse";
import { environment } from "common/constants";
import Protected from "component/protected";
import { Container, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import { HelpfulLinks } from "pages/Homepage/includes/HelpfulLinks";
import styled from "styled-components";
import { COLORS } from "component/UI/common";

const BASE_URL = "https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/uptime-reporting";
const FILE_NAME = "uptime.csv";
const s3env = environment === "production" ? "prod" : "staging";
const ROW_LABELS = [
  { label: "Whole platform", key: "all" },
  { label: "Backend", key: "backend" },
  { label: "Frontend", key: "frontend" },
  { label: "Data", key: "data" },
];

const StyledTable = styled(Table)`
  background: none !important;
  th {
    background: ${COLORS.grey200} !important;
  }
`;

interface Service {
  service: string;
  uptime: string;
}

interface Data {
  lastUpdate: string;
  services: Service[];
}

const Component = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [data, setData] = React.useState<Data | null>(null);

  React.useEffect(() => {
    fetch(`${BASE_URL}/${s3env}/${FILE_NAME}`, { cache: "no-cache" })
      .then(async (reportRes) => {
        if (reportRes.ok) {
          const lastUpdate = reportRes.headers.get("Last-Modified") || "";
          const csv = await reportRes.text();
          return ({ lastUpdate, csv });
        } else {
          return Promise.reject(new Error("Please try again later."));
        }
      })
      .then(({ lastUpdate, csv }) => {
        parse(csv, {
          header: true,
          complete: (result) => {
            Promise.resolve(setData({ lastUpdate, services: result.data as Service[] }));
          },
          error: (e) => {
            Promise.reject(e);
          },
        });
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <Helmet><title>Platform Service Report</title></Helmet>
      <h2 className="ff-primary fs-4000 text-secondary fw-700 mb-4">Platform Service Report</h2>
      {error
        ? <p className="fw-700">{error}</p>
        : loading
          ? <p className="fw-700">Loading...</p>
          : (
              <div>
                <p className="fw-700 mb-3">{`Last updated: ${new Date(data?.lastUpdate || "").toLocaleString()}`}</p>
                <StyledTable celled className="mb-4">
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Service</TableHeaderCell>
                      <TableHeaderCell collapsing>Uptime (last 30 days)</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {ROW_LABELS.map((row) => {
                      const { label, key } = row;
                      const findService = data?.services.find(s => s.service === key);
                      const uptime = findService ? `${(parseFloat(findService.uptime) * 100).toFixed(2)}%` : "-";
                      return (
                        <TableRow key={key}>
                          <TableCell>{label}</TableCell>
                          <TableCell>{uptime}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
                <HelpfulLinks hideLinks />
              </div>
          )
      }
    </Container>
  );
};

export const ServiceUptimeReport = Protected(inject("store")(observer(Component)));
