import * as React from "react";
import { IIndicatorModuleData } from "component/Modules/IndicatorModule";
import { RestackingDashboardModule } from "component/Modules/RestackingDashboardModule";
import { SummaryChart } from "component/Modules/IndicatorSummaryModule";

export const RestackingANCDashboardMDHS = () => {

  const indicatorData: IIndicatorModuleData[] = [
    {
      id: 3,
      heading: "Quantity: Are sufficient services available?",
      subheading: "Are sufficient services available?",
      description: "This relates to the physical access to local services in sufficient quantity. Quantity indicators help to determine the amount of resource and infrastructure needed to deliver the service for a given population at the right quality and dose.",
      indicators: [
        {
          indicatorID: 1,
          indicatorTitle: "Universal quantity indicator: Coverage of services available for pregnant women",
          chart1: {
            id: 105676,
            title: "",
          },
          chart2: {
            // id: 84981,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10980",
          chart1Explainer: {
            shows: "The chart above shows if there are antenatal care facilities available to cater for pregnant women in the community. 100% indicates that there are antenatal care facilities, while 0% indicates that there are no antenatal care facilities available in the community for pregnant women to access.",
          },
          // chart2Explainer: {
          //   shows: "The chart above shows ...",
          //   why: "This can be used to ...",
          // },
          why: "It is important that there is adequate health infrastructure to cater for all pregnant women in the community.",
        },
        {
          indicatorID: 2,
          indicatorTitle: "Universal quantity indicator: Number of maternity beds per 1,000 estimated pregnant women",
          chart1: {
            id: 106485,
            title: "",
          },
          chart2: {
            // id: 84981,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10980",
          chart1Explainer: {
            shows: "The chart above shows the number of maternity beds per 1,000 estimated pregnant women, plotted against national benchmarks.",
          },
          // chart2Explainer: {
          //   shows: "The chart above shows ...",
          //   why: "This can be used to ...",
          // },
          why: "It is important to ensure an adequate number of maternity beds to cater for all pregnant women in a community.",
        },
        {
          indicatorID: 3,
          indicatorTitle: "Universal quantity indicator: Number of ANC practitioners",
          chart1: {
            id: 93772,
            title: "",
          },
          chart2: {
            // id: 84211,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10980",
          chart1Explainer: {
            shows: "The chart above shows the number of general practitioners (QN3), midwives (QN4) and obstetricians/gynaecologists (QN5) per 10,000 women of child-bearing age in the community, plotted against national benchmarks.",
          },
          // chart2Explainer: {
          //   shows: "The chart above shows ...",
          //   why: "This can be used to ...",
          // },
          why: "To ensure an adequate number of general practitioners (QN3), midwives (QN4) and obstetricians/gynaecologists (QN5) to cater for all pregnant women in a community.",
        },
      ],
    },
    {
      id: 2,
      heading: "Quality: Are we delivering high-quality services?",
      subheading: "Are we delivering high quality services?",
      description: "Services or programs with ‘quality’ are those where delivery aligns to the desired outcomes in the evidence-base.",
      indicators: [
        {
          indicatorID: 1,
          indicatorTitle: "Universal quality indicator: Continuity of carer", // QL1
          chart1: {
            id: 84221,
            title: "",
          },
          chart2: {
            id: 84227,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who attended 5 or more appointments and attended 80% of their appointments with the same midwife. Data includes all pregnant women who have given birth at the target hospital.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who attended 5 or more appointments and attended 80% of their appointments with the same midwife, plotted against RSTO Evidence. Data includes all pregnant women who have given birth at the target hospital.",
          },
          why: "Women who experience continuity of care led by a midwife experience better outcomes.",
        },
        {
          indicatorID: 2,
          indicatorTitle: "Universal quality indicator: Routine test results", // QL2
          chart1: {
            id: 93754,
            title: "",
          },
          chart2: {
            id: 93755,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who have a complete record of the minimum set of routine test results available. The minimum set of 11 routine tests are listed in the Indicator Guide.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who have a complete record of the minimum set of routine test results available, plotted against RSTO Evidence. The minimum set of 11 routine tests are listed in the Indicator Guide.",
          },
          why: "Screening and assessment for maternal health issues are important for mother and baby health.",
        },
        {
          indicatorID: 3,
          indicatorTitle: "Universal quality indicator: Blood Pressure Recorded", // QL3
          chart1: {
            id: 93756,
            title: "",
          },
          chart2: {
            id: 93757,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "You The chart above shows the number of pregnant women who have their blood pressure recorded at all routine appointments*. \n\n *Routine appointments are defined as MatCare and Obstetric Consult appointments.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who have their blood pressure recorded at all routine appointments*, plotted against RSTO evidence. \n\n *Routine appointments are defined as MatCare and Obstetric Consult appointments.",
          },
          why: "Measuring blood pressure is used to identify existing high blood pressure and hypertension. Hypertension can lead to poor infant outcomes (e.g. low birth weight, preterm birth).",
        },
        {
          indicatorID: 4,
          indicatorTitle: "Universal quality indicator: BMI Recorded", // QL4
          chart1: {
            id: 84220,
            title: "",
          },
          chart2: {
            id: 84226,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who attended antenatal appointments where BMI was calculated and recorded.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who attended antenatal appointments where BMI was calculated and recorded, plotted against RSTO Evidence.",
          },
          why: "A low BMI during pregnancy increases the risk of having a low birth weight baby and/or preterm birth. A high BMI during pregnancy increases the risk of baby being born preterm and/or low birth weight, gestational diabetes and hypertensive disorders, congenital anomalies and neural tube defects.",
        },
        {
          indicatorID: 5,
          indicatorTitle: "Universal quality indicator: Substance use", // QL5, QL6
          chart1: {
            id: 84225,
            title: "",
          },
          chart2: {
            id: 84219,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who attended antenatal appointments where they are asked about their substance use, alcohol and tobacco included, with the answer documented.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who attended antenatal appointments where they are asked about their substance use, alcohol and tobacco included, with the answer documented, as plotted against RSTO Evidence.",
          },
          why: "Smoking in pregnancy increases the risk of ectopic pregnancy, preterm birth, miscarriage, reduced birth weight, small-for-gestational-age baby, stillbirth, fetal and infant mortality and sudden infant death syndrome.",
        },
        {
          indicatorID: 6,
          indicatorTitle: "Universal quality indicator: Family violence", // QL7
          chart1: {
            id: 84217,
            title: "",
          },
          chart2: {
            id: 84224,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who attended antenatal appointments where they are asked about family violence, with the answer documented.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who attended antenatal appointments where they are asked about family violence, with the answer documented, plotted against RSTO Evidence.",
          },
          why: "Violence poses serious health risks to pregnant women and babies. Women exposed to violence during pregnancy are at risk of miscarriage, preterm birth, having a low birth weight baby, and are more likely to develop depression in the postnatal period.",
        },
        {
          indicatorID: 7,
          indicatorTitle: "Universal quality indicator: Mental health history", // QL8
          chart1: {
            id: 84228,
            title: "",
          },
          chart2: {
            id: 84218,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who attended antenatal appointments, where their mental health history is recorded.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who attended antenatal appointments, where their mental health history is recorded, plotted against RSTO Evidence.",
          },
          why: "Mental health conditions, particularly in their more severe form are often associated with impaired functioning e.g. a woman's ability to care for her infant and the formation of secure infant attachment, which may in turn be associated with poorer outcomes in the child.",
        },
        {
          indicatorID: 8,
          indicatorTitle: "Universal quality indicator: Mental health screen", // QL9
          chart1: {
            id: 84216,
            title: "",
          },
          chart2: {
            id: 84223,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with a mental health screen.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with a mental health screen, plotted against RSTO Evidence.",
          },
          why: "Pregnant women are more vulnerable to depression and anxiety or worsening of symptoms. Unmanaged mental health issues can result in adverse outcomes such as miscarriage, preterm birth and small for-gestational-age baby.",
        },
        {
          indicatorID: 9,
          indicatorTitle: "Universal quality indicator: Pre-eclampsia risk factor", // QL10
          chart1: {
            id: 93744,
            title: "",
          },
          chart2: {
            id: 93745,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who have their risk factor for preeclampsia recorded at their booking appointment.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who have their risk factor for preeclampsia recorded at their booking appointment, plotted against RSTO evidence.",
          },
          why: "Pre-eclampsia can lead to fetal loss, preterm labour, low birth weight, perinatal death and gestational diabetes.",
        },
        {
          indicatorID: 10,
          indicatorTitle: "Universal quality indicator: Fundal height recorded", // QL11
          chart1: {
            id: 93746,
            title: "",
          },
          chart2: {
            id: 93747,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who have a recorded measure of symphysis fundal height at all routine appointments after 24 weeks 0 days gestation.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who have a recorded measure of symphysis fundal height at all routine appointments after 24 weeks 0 days gestation, plotted against RSTO evidence.",
          },
          why: "Allows detection of small-for gestational age fetus monitor for slow or static growth.",
        },
        {
          indicatorID: 11,
          indicatorTitle: "Universal quality indicator: Gestational diabetes", // QL12
          chart1: {
            id: 84215,
            title: "",
          },
          chart2: {
            id: 84222,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who complete testing for gestational diabetes at 24 weeks 0 days to 28 weeks 6 days (*the recommended point of pregnancy for diabetes screening) and have their test results available and acknowledged.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women attend one or more antenatal appointments and who complete testing for gestational diabetes at 24 weeks 0 days to 28 weeks 6 days (*the recommended point of pregnancy for diabetes screening), and have their test results available and acknowledged.",
          },
          why: "Women with gestational diabetes have a higher risk of induced labour and are more likely to have  a preterm birth, high birth weight, caesarean birth, hypertension and longer hospital stay than women without diabetes, and their babies are at risk of poorer outcomes.",
        },
        {
          indicatorID: 12,
          indicatorTitle: "Universal quality indicator: Fetal presentation recorded", // QL13
          chart1: {
            id: 93748,
            title: "",
          },
          chart2: {
            id: 93749,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who have a recorded fetal presentation at 30 weeks gestation.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who have a recorded fetal presentation at 30 weeks gestation, plotted against RSTO evidence.",
          },
          why: "Fetal presentation after 30 weeks will influence birth plan, measuring prelabour allows for interventions that promote vaginal birth.",
        },
        {
          indicatorID: 13,
          indicatorTitle: "Universal quality indicator: Education & awareness", // QL14
          chart1: {
            // id: 93748,
            title: "",
          },
          chart2: {
            // id: 93749,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with a BMI 30 kg/m2 or > who are referred for personalised advice from a trained person on healthy eating and physical activity.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with a BMI 30 kg/m2 or > who are referred for personalised advice from a trained person on healthy eating and physical activity, plotted against RSTO evidence.",
          },
          why: "Personalised advice on healthy eating and physical activity may be effective in improving women's eating behaviours and may prevent excessive weight gain. This may reduce risk of gestational diabetes, hypertensive disorders, and fetal growth restriction.",
        },
        {
          indicatorID: 14,
          indicatorTitle: "Universal quality indicator: Smoking service referral", // QL15
          chart1: {
            id: 93750,
            title: "",
          },
          chart2: {
            id: 93751,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who smoke and are referred to an evidence-based stop smoking service.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who smoke and are referred to an evidence-based stop smoking service.",
          },
          why: "Smoking cessation interventions reduce smoking rates in pregnant women which in turn may reduce the incidences of low birth weight and preterm births.",
        },
        {
          indicatorID: 29,
          indicatorTitle: "Universal quality indicator: Genetic screenings", // QL16
          chart1: {
            id: 102325,
            title: "",
          },
          chart2: {
            id: 102326,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who received genetic screenings before 13 weeks 6 days and have results available and acknowledged.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who received genetic screenings before 13 weeks 6 days and have results available and acknowledged.",
          },
          why: "Genetic screening allows diagnosis of genetic/ chromosomal anomalies during pregnancy.",
        },
        {
          indicatorID: 15,
          indicatorTitle: "Universal quality indicator: Ultrasound", // QL17
          chart1: {
            id: 93752,
            title: "",
          },
          chart2: {
            id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who complete an ultrasound between 18 weeks 0 days and 20 weeks 6 days and have their results available and acknowledged.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who complete an ultrasound between 18 weeks 0 days and 20 weeks 6 days and have their results available and acknowledged, plotted against RSTO Evidence.",
          },
          why: "The cervix length during pregnancy relates to the risk of having a preterm (early or premature) birth. Allows diagnosis of structural anomalies. Sensitivity in detecting structural anomalies increases after 18 weeks gestation. Detection of structural anomalies before 20 weeks gestation gives women the choice of terminating the pregnancy (where this is permitted under jurisdictional legislation).",
        },
        {
          indicatorID: 16,
          indicatorTitle: "Universal quality indicator: Monitoring fetal growth and wellbeing", // QL18
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with confirmed breech presentation after 37 weeks 0 days gestation who are offered and eligible for external cephalic version.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with confirmed breech presentation after 37 weeks 0 days gestation who are offered and eligible for external cephalic version, plotted against RSTO evidence.",
          },
          why: "Turning the baby (e.g. using external cephalic version [ECV]) reduces the number of babies who are breech at term, thereby improving the chance of a vaginal birth.",
        },
        {
          indicatorID: 19,
          indicatorTitle: "Universal quality indicator: Fetal movement information", // QL21
          chart1: {
            id: 106493,
            title: "",
          },
          chart2: {
            id: 106494,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women provided with verbal and written information regarding normal fetal movements during the antenatal period.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women provided with verbal and written information regarding normal fetal movements during the antenatal period, plotted against RSTO evidence.",
          },
          why: "Decreased fetal movement indicates risk of adverse outcomes including: intrauterine growth restriction, fetal death or preterm birth. Antenatal education about fetal movement has been shown to reduce the time from maternal perception of decreased fetal movements to help-seeking behaviour.",
        },
        {
          indicatorID: 20,
          indicatorTitle: "High risk quality indicator - Hypertensive disorder: Low-dose aspirin", // HT1
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women identified at risk of preeclampsia who are advised to take low-dose aspirin daily.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women identified at risk of preeclampsia who are advised to take low-dose aspirin daily, plotted against RSTO evidence.",
          },
          why: "Aspirin consumption reduces risk of pre-eclampsia in at-risk women and is likely to reduce intrauterine growth restriction by about 10%.",
        },
        {
          indicatorID: 30,
          indicatorTitle: "High risk quality indicator - Hypertension: Pre-eclampsia", // HT3
          chart1: {
            id: 102323,
            title: "",
          },
          chart2: {
            id: 102324,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who have been diagnosed with pre-eclampsia and have attended obstetrician appointment/s.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who have been diagnosed with pre-eclampsia and have attended obstetrician appointment/s.",
          },
          why: "Obstetricians have specialised training in antenatal care, labour care and postnatal care. They are trained in high-risk pregnancy and birthing and can perform caesarean sections. They can prescribe and monitor medication interventions.",
        },
        {
          indicatorID: 21,
          indicatorTitle: "High risk quality indicator - Mental health: Documented plan", // MH1
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women identified at risk of mental health issues who have a documented mental health plan.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women identified at risk of mental health issues who have a documented mental health plan, plotted against RSTO evidence.",
          },
          why: "Pregnant women are more vulnerable to depression and anxiety or worsening of symptoms. Unmanaged mental health issues can result in adverse outcomes such as miscarriage, preterm birth and smallfor-gestational-age baby.",
        },
        {
          indicatorID: 22,
          indicatorTitle: "High risk quality indicator - Mental health: Follow up", // MH2
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women referred to a mental health professional who are followed up by an ANC provider.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women referred to a mental health professional who are followed up by an ANC provider, plotted against RSTO evidence.",
          },
          why: "Pregnant women are more likely to engage in mental health services if they are supported through the process through follow up by an antenatal care provider e.g. gentle reminder, encouragement, reassurance.",
        },
        {
          indicatorID: 23,
          indicatorTitle: "High risk quality indicator - Diabetes: Test results available", // DM1
          chart1: {
            id: 93742,
            title: "",
          },
          chart2: {
            id: 93743,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women identified at risk of gestational diabetes at the booking appointment who receive testing for gestational diabetes and have their test results available and acknowledged. \n\n This indicator defines 'at risk' as any women having at least 1 of the following: body mass above 30 kg/m2; previous macrosomic baby weighing 4.kg or above; previous gestational diabetes; family history of diabetes, based on a first-degree relative; polycystic ovary syndrome (PCOS); and minority ethnic family origin with a high prevalence of diabetes. It is acknowledged that antipsychotics are also a risk factor, however it is not possible to extract data related to their use from the system.",
          },
          chart2Explainer: {
            shows: "The chart above shows the number of pregnant women identified at risk of gestational diabetes at the booking appointment who receive testing for gestational diabetes and have their test results available and acknowledged. \n\n This indicator defines 'at risk' as any women having at least 1 of the following: body mass above 30 kg/m2; previous macrosomic baby weighing 4.kg or above; previous gestational diabetes; family history of diabetes, based on a first-degree relative; polycystic ovary syndrome (PCOS); and minority ethnic family origin with a high prevalence of diabetes. It is acknowledged that antipsychotics are also a risk factor, however it is not possible to extract data related to their use from the system.",
          },
          why: "Women with gestational diabetes have a higher risk of induced labour, preterm birth, high birth weight, caesarean birth, hypertension and longer hospital stay than women without diabetes. Their babies are more likely to require special care nursery/neonatal intensive care admission.",
        },
        {
          indicatorID: 24,
          indicatorTitle: "High risk quality indicator - Diabetes: Pre-existing diabetes education and care", // DM2
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with pre-existing diabetes who are seen by members of the diabetes team or a person trained in diabetes education and care within 1 week of their triage.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with pre-existing diabetes who are seen by members of the diabetes team or a person trained in diabetes education and care within 1 week of their triage, plotted against RSTO evidence.",
          },
          why: "Women with pre-existing diabetes are more likely to have preterm birth, induced labour, caesarean birth, hypertension and longer hospital stay than women without pre-existing diabetes. Their babies have higher rates of stillbirth, high birth weight, low Apgar score and admission to special care nursery/ neonatal intensive care unit.",
        },
        {
          indicatorID: 25,
          indicatorTitle: "High risk quality indicator - Diabetes: HbA1c test results available", // DM3
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with pre-existing diabetes who have their HbA1c results available and acknowledged.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with pre-existing diabetes who have their HbA1c results available and acknowledged, plotted against RSTO evidence.",
          },
          why: "Early treatment of women with abnormal HbA1c is associated with a reduced risk of pre-eclampsia.",
        },
        {
          indicatorID: 26,
          indicatorTitle: "High risk quality indicator - Diabetes: Retinal assessment", // DM4
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with pre-existing diabetes whose retinal assessment status is checked.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with pre-existing diabetes whose retinal assessment status is checked, plotted against RSTO evidence.",
          },
          why: "Pregnant women with pre-existing diabetes are at increased risk of diabetic retinopathy. Regular eye exams allow for early detection and intervention to prevent vision impairment and loss.",
        },
        {
          indicatorID: 27,
          indicatorTitle: "High risk quality indicator - Diabetes: Gestational diabetes education and care", // DM5
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women diagnosed with gestational diabetes who are seen by members of the diabetes team or a person trained in diabetes education and care within 1 week of diagnosis.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women diagnosed with gestational diabetes who are seen by members of the diabetes team or a person trained in diabetes education and care within 1 week of diagnosis, plotted against RSTO evidence.",
          },
          why: "Treatment of gestational diabetes reduces the risk of perinatal complications.",
        },
        {
          indicatorID: 28,
          indicatorTitle: "High risk quality indicator - Diabetes: Blood glucose meter feedback", // DM6
          chart1: {
            // id: 93752,
            title: "",
          },
          chart2: {
            // id: 93753,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with diabetes who are asked about their blood glucose meter results and are provided with feedback.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with diabetes who are asked about their blood glucose meter results and are provided with feedback, plotted against RSTO evidence.",
          },
          why: "Stable blood glucose results reduced adverse outcomes in pregnant women (e.g. hypertension, diabetic retinopathy) and fetus (e.g. preterm birth).",
        },
      ],
    },
    {
      id: 1,
      heading: "Participation: Who is accessing our services?",
      subheading: "How are our services being accessed?",
      description: "This refers to both who uses the service and how much (dosage). Pregnant Women need to attend at the right dosage levels for benefits to be realised.",
      indicators: [
        {
          indicatorID: 1,
          indicatorTitle: "Universal participation indicator: Booking appointment in first trimester",
          chart1: {
            id: 84214,
            title: "",
          },
          chart2: {
            id: 84212,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who attend their booking appointment within the first trimester (12 weeks) of their pregnancy. The orange section indicates the number of pregnant women who attend their booking appointment later than the first trimester, that is, after 12 weeks of pregnancy.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who attend their booking appointment within the first trimester (12 weeks) of their pregnancy, plotted against RSTO Evidence.",
          },
          why: "Women attending antenatal care in the first trimester of pregnancy have lower maternal and perinatal mortality than women who attend late or not at all.",
        },
        {
          indicatorID: 2,
          indicatorTitle: "Targeted participation indicator: Booking appointment in first trimester",
          chart1: {
            id: 84213,
            title: "",
          },
          chart2: {
            id: 84211,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women recognised in a priority group who attend their booking appointment within 12 weeks 0 days gestation. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of all pregnant women recognised in a priority group who attend their booking appointment within 12 weeks 0 days gestation. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          why: "Pregnant women recognised in a priority group are at risk of poor maternal and perinatal outcomes. They are also more likely to have complex health needs and face multiple barriers accessing pregnancy care and navigating the healthcare system.",
        },
        {
          indicatorID: 3,
          indicatorTitle: "Universal participation indicator: Antenatal visits in first trimester",
          chart1: {
            id: 91162,
            title: "",
          },
          chart2: {
            id: 91163,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies.",
          },
          why: "Pregnant women experiencing their first pregnancy may require additional education and support e.g., what to expect at each stage of pregnancy.",
        },
        {
          indicatorID: 4,
          indicatorTitle: "Targeted participation indicator: Antenatal visits in first trimester",
          chart1: {
            id: 91355,
            title: "",
          },
          chart2: {
            id: 91356,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women recognised in a priority group who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women recognised in a priority group who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          why: "There is some evidence that perinatal mortality may be increased with reduced visits in some vulnerable pregnant women. Pregnant women experiencing their first pregnancy may require additional education and support e.g. what to expect at each stage of pregnancy.",
        },
      ],
    },
  ];

  const summaryCharts: SummaryChart[] = [
    {
      heading: "QUANTITY",
      chartID: indicatorData[0].indicators[0].chart1.id!,
      suitcaseID: 10980,
      hideTitle: false,
    },
    {
      heading: "QUALITY",
      chartID: indicatorData[1].indicators[0].chart2.id!,
      suitcaseID: 10981,
      hideTitle: false,
    },
    {
      heading: "PARTICIPATION",
      chartID: indicatorData[2].indicators[0].chart2.id!,
      suitcaseID: 10979,
      hideTitle: false,
    },
  ];

  const dashboardData = {
    heading: "Maryborough District Health Service",
    subheading: "Antenatal Care (ANC)",
    description: "Quality care during pregnancy monitors and supports the health and wellbeing of mothers and babies. The Restacking The Odds indicators in this area spans various topics like mental health, family violence, and gestational diabetes.",
    strategyLink: "https://www.rsto.org.au/",
    indicatorModuleData: indicatorData,
    summaryCharts,
  };

  return (
    <RestackingDashboardModule {...dashboardData} />
  );

};
