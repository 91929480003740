import * as Excel from "exceljs/dist/exceljs.min.js";

export type ParsedRecords = string[][];

export interface SSDCConfig {
  columnHash: string[]; // "variable_name"
  columnRename: [string, string][]; // [["variable_to_rename", "new_name"], ...]
  columnDrop: string[]; // "variable_name"
  columnMerge: any[]; // @TODO final format TBD
  salt: string;
}

export const getSeerFormattedDates = (col: string, val: string) => {
  const parser = require("any-date-parser");
  try {
    let datetime = parser.fromAny(val);
    const dates = {};
    dates[col] = datetime.toISOString().split("T")[0];
    dates[`${col} - Year`] = datetime.getFullYear();
    const yearMonth = datetime.toLocaleString('en-us',{year:'numeric', month:'long'});
    dates[`${col} - Year Month`] = `${yearMonth.split(" ")[1]} ${yearMonth.split(" ")[0]}`
    dates[`${col} - Week Starting`] = (new Date(datetime.setDate(datetime.getDate() - (datetime.getDay() - 1)))).toISOString().split("T")[0];
    return dates;
  } catch (e) {
    return null;
  }
};

export const applyOperation = (records: ParsedRecords, operation): ParsedRecords => {
  if (operation.type === "rename") {
    for (const obj of operation.config) {
      const index = records[0].indexOf(obj[0]);
      if (index !== -1) {
        records[0][index] = obj[1];
      }
    }
  }
  else if (operation.type === "delete") {
    for (const itemToDrop of operation.config) {
      const index = records[0].indexOf(itemToDrop);
      if (index !== -1) {
        for (const row of records) {
          row.splice(index, 1);
        }
      }
    }
  }
  return records;
}

export interface MergeTablesProps {
  leftTable: ParsedRecords;
  rightTable: ParsedRecords;
  leftColName: string;
  rightColName: string;
  joinType: "left";  // Just one type is supported for now
}

export const mergeTables = (props: MergeTablesProps): ParsedRecords => {
  const rightIndex = props.rightTable[0].indexOf(props.rightColName);
  const leftIndex = props.leftTable[0].indexOf(props.leftColName);
  // Remove the right column name
  const removeIndexes = [rightIndex];
  // Check if right table contains any other columns from the left that should be removed to avoid duplicates
  for (const col of props.leftTable[0]) {
    if (props.rightTable[0].includes(col) && col !== props.rightColName) {
      removeIndexes.push(props.rightTable[0].indexOf(col));
    }
  }
  // Arrange the indexes in reverse order so removing items won't shift other item's positions.
  removeIndexes.sort((a, b) => b - a);
  // Remove these duplicate columns
  for (const removeIndex of removeIndexes) {
    props.rightTable[0].splice(removeIndex, 1);
  }

  const mergedTable: ParsedRecords = [props.leftTable[0].concat(props.rightTable[0])];
  // Start from 1 as 0 is for columns
  for (let leftCount = 1; leftCount < props.leftTable.length; leftCount++) {
    const value = props.leftTable[leftCount][leftIndex];
    let matchFound = false;
    for (let rightCount = 1; rightCount < props.rightTable.length; rightCount++) {
      if (props.rightTable[rightCount][rightIndex] === value) {
        // Remove the duplicated column values
        for (const removeIndex of removeIndexes) {
          props.rightTable[rightCount].splice(removeIndex, 1);
        }
        mergedTable.push(props.leftTable[leftCount].concat(props.rightTable[rightCount]));
        matchFound = true;
        // Merge only one match
        break;
      }
    }
    if (!matchFound) {
      // Fill right cells with blank
      mergedTable.push(props.leftTable[leftCount].concat(Array(props.rightTable[0].length).fill("")));
    }
  }
  return mergedTable;
}

interface Column {
  name: string;
  type: "string" | "number" | "date";
}

type RuleMQ = {
  // Plain aggregation
  name: string;
  agg: string;
} | {
  // Aggregation with condition
  name: string;
  agg: string;
  where: string;
} | {
  // Mathematical expression of mqs
  name: string;
  formula: string;
} | {
  // Count of records
  name: string;
} | {
  // Count of records matching the condition
  name: string;
  where: string;
};

const generateAutoAggregations = (columns: Column[]): RuleMQ[] => {
  const eggs: RuleMQ[] = [];
  for (const col of columns) {
    if (col.type === "number") {
      eggs.push({
        name: `${col.name} - Total`,
        agg: `sum("${col.name}")`
      });
      eggs.push({
        name: `${col.name} - Lowest`,
        agg: `min("${col.name}")`
      });
      eggs.push({
        name: `${col.name} - Highest`,
        agg: `max("${col.name}")`
      });
      eggs.push({
        name: `${col.name} - Average`,
        agg: `max("${col.name}")`
      });
      eggs.push({
        name: `${col.name} - Number of records with values greater than 0`,
        agg: `count("${col.name}")`,
        where: `"${col.name}" > 0`
      });
    }
    // This applies to all column types
    eggs.push({
      name: `${col.name} - Number of records with values`,
      agg: `count("${col.name}")`,
      where: `"${col.name}" IS NOT NULL`
    });
  }
  eggs.push({
    name: `Number of records`,
    agg: `count(*)`,
  });
  return eggs;
}

// parse csv / xls,xlsx with csv-parse
export const parseInputFile = async (file: File): Promise<ParsedRecords | undefined> => {
  const parse = require("csv-parse/browser/esm").parse;
  if (!file) {
    return;
  }
  let inputString;

  // get string contents of Excel sheet or csv
  if (file.type !== "text/csv") {
    const workbook = new Excel.Workbook();
    const inBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(inBuffer);
    const outBuffer = await workbook.csv.writeBuffer();
    inputString = outBuffer.toString();
  } else {
    inputString = await file.text();
  }

  // parse and return
  return await new Promise(resolve => {
    parse(inputString, (err, records) => {
      if (err) {
        console.error(err);
        resolve(undefined);
      } else {
        resolve(records);
      }
    });
  });
};

// apply URL SSDC configuration to records
export const processRecords = async (records: ParsedRecords, config: SSDCConfig): Promise<ParsedRecords> => {
  const hashSHA512 = require("common/helpers/string").hashSHA512;

  const { columnHash, columnRename, columnDrop, columnMerge, salt } = config;
  const headerRow = records[0];
  const headerRowMeta = headerRow.map((variable, idx) => ({ variable, idx }));
  const dropIdxs = headerRowMeta
    .filter(item => columnDrop.includes(item.variable))
    .map(item => item.idx);
  const hashIdxs = headerRowMeta
    .filter(item => columnHash.includes(item.variable))
    .map(item => item.idx);
  // update header row based on config
  let updatedHeaderRow = headerRow
    .map(variable => {
      // rename  based on config
      for (const [renameVariable, rename] of columnRename) {
        if (variable === renameVariable) {
          return rename;
        }
      }
      return variable;
    })
    .filter((_, idx) => !dropIdxs.includes(idx)); // drop columns based on config
  // process the rest of the data
  const processedData = records.slice(1);
  const rowLength = processedData[0].length || 0;
  for (let rowIdx = 0; rowIdx < processedData.length; rowIdx++) {
    const row = processedData[rowIdx];
    for (let colIdx = 0; colIdx < rowLength; colIdx++) {
      // hash based on config
      if (!dropIdxs.includes(colIdx) && hashIdxs.includes(colIdx)) {
        row[colIdx] = await hashSHA512(`${row[colIdx]}${salt || ""}`);
      }
    }
    processedData[rowIdx] = row.filter((_, idx) => !dropIdxs.includes(idx)); // drop columns based on config
  }
  // column merges take place last
  for (const mergeConfig of (columnMerge || [])) {
    const mergeColumnIdxs = mergeConfig.columns.map(name => updatedHeaderRow.indexOf(name));
    if (mergeColumnIdxs.some(idx => idx < 0)) {
      continue; // a required column was not found
    }
    // remove source columns and append merged column, to header and records
    updatedHeaderRow = [...updatedHeaderRow.filter((_col, cIdx) => !mergeColumnIdxs.includes(cIdx)), mergeConfig.name];
    for (let rowIdx = 0; rowIdx < processedData.length; rowIdx++) {
      const mergedSource = mergeColumnIdxs.map(idx => processedData[rowIdx][idx]).join(mergeConfig.separator);
      let mergeString = mergedSource;
      if (mergeConfig.hash) {
        mergeString = await hashSHA512(`${mergedSource}${salt || ""}`);
      }
      processedData[rowIdx] = [...processedData[rowIdx].filter((_col, cIdx) => !mergeColumnIdxs.includes(cIdx)), mergeString];
    }
  }
  return [
    updatedHeaderRow,
    ...processedData,
  ];
};

// convert ParsedRecords to a File (text/csv) for upload
export const parsedRecordsToFile = async (records: ParsedRecords, filename: string): Promise<File> => {
  const stringify = require("csv-stringify/browser/esm/sync").stringify;

  // stringify the records to a csv string
  const csvString = stringify(records);
  // encode and create file for upload
  const encoder = new TextEncoder();
  const csvEncoded = encoder.encode(csvString);
  return new File([csvEncoded], filename, { type: "text/csv" });
};
