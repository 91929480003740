import * as React from "react";
import styled from "styled-components";

import * as UserAvatar from "react-user-avatar";

const StyledUserAvatar = styled(UserAvatar)`
  display: flex;
  color: white !important;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  border: none;
  cursor: default;
  .UserAvatar--img {
    border: 1px solid #e5e5e5 !important;
  }
`;

interface AvatarProps {
  name: string;
  image: string;
  innerAvatarStyle?: any;
  style?: any;
  size?: number;
  color?: string;
  className?: string;
}

export const Avatar = ({ name, image, innerAvatarStyle: style, style: containerStyle, size, color, className }: AvatarProps): JSX.Element => {
  let processedName;
  if (name.includes(" ")) {
    // Remove whitespace from both ends of "name"
    // Remove empty string from "words" array after "split(" ")"
    const words = name.trim().split(/\s+/);
    if (words.length > 4) { // Remove lower case words from the name when it's longer that 4 words
      // Generate new array which excludes lower case words
      const uppercaseWords: string[] = [];
      words.forEach(w => {
        if (w[0]?.toUpperCase() === w[0]) {
          uppercaseWords.push(w);
        }
      });
      if (uppercaseWords.length === 0) { // If "uppercaseWords" is empty, just use "words"
        processedName = [...words].splice(0, 4).map(w => w.toUpperCase()).join(" ");
      } else {
        processedName = [...uppercaseWords].splice(0, 4).join(" ");
      }
    } else {
      processedName = [...words].map(w => w.toUpperCase()).join(" ");;
    }
  } else {
    processedName = name.toUpperCase().substring(0, 3);
  }
  return (
    <div title={name} style={containerStyle} key={`${name}${size || 0}`}>
      <StyledUserAvatar
        // show large fonts for large Avatar (on Settings > Account Tab)
        style={size === 80 ? { ...style, ...{ fontSize: "24px" } } : style}
        size={size || 40}
        name={processedName}
        src={image}
        color={color ?? "#d2d2d2"}
        className={className}
      />
    </div>
  );
};
