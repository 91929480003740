import * as qs from "qs";
import Store from "./store";
import { backendUrl, dataUrl, mixpanelToken } from "./constants";

/**
 * utility for making calls our our services
 *
 * @param endpoint endpoint string, e.g. http://my-service/endpoint where "endpoint" is the parameter passed
 * @param params paramater object for query (if get/delete) or JSON body (if put/post)
 * @param port port of the http service
 * @param method http method
 */
const api = async <T>(
  base_uri: string,
  endpoint: string,
  params: any = null,
  method = "GET",
  token?: string
): Promise<T> => {
  const uri = base_uri + endpoint;

  if (method === "GET" || method === "DELETE" || method === "HEAD") {
    const headers = { "X-Token": token || "" };
    const q = params ? "?" + qs.stringify(params, { indices: false }) : "";
    if (token) {
      return ((await fetch(uri + q, { method, headers }).then(processResponse)) as unknown) as T;
    } else {
      return ((await fetch(uri + q, { method }).then(processResponse)) as unknown) as T;
    }
  } else if (method === "POST" || method === "PUT") {
    let headers;
    if (token) {
      headers = {
        "Content-Type": "application/json",
        "X-Token": token || "-none-",
      };
    } else {
      headers = { "Content-Type": "application/json" };
    }
    return ((await fetch(uri, {
      method,
      body: JSON.stringify(params),
      headers,
    }).then(processResponse)) as unknown) as T;
  } else {
    throw new Error("unsupported method: " + method);
  }
};

const processResponse = async (response: any) => {
  const statusCode = response.status;
  const text = await response.text();
  let body = {};
  if (text) {
    body = JSON.parse(text);
  }
  return Promise.all([statusCode, body]).then(res => ({
    originalResponse: response,
    statusCode: res[0],
    body: res[1],
  }));
};

export interface ITransport {
  get: <T>(endpoint: string, params?: any, token?: string) => Promise<T>;
  head: <T>(endpoint: string, params?: any, token?: string) => Promise<T>;
  put: <T>(endpoint: string, params?: any, token?: string) => Promise<T>;
  post: <T>(endpoint: string, params?: any, token?: string) => Promise<T>;
  delete: <T>(endpoint: string, params?: any, token?: string) => Promise<T>;
}

/**
 * return conveiniece get/post/post/delete methods with the port pre-set for the service
 *
 * @param port port of http api
 */
export const factory = (base_uri: string): ITransport => {
  // Add the trailing slash as required by this function to work
  base_uri = base_uri + "/";

  return {
    get: <T>(endpoint: string, params: any = null, token?: string) => api<T>(base_uri, endpoint, params, "GET", token),
    head: <T>(endpoint: string, params: any = null, token?: string) =>
      api<T>(base_uri, endpoint, params, "HEAD", token),
    put: <T>(endpoint: string, params: any = null, token?: string) => api<T>(base_uri, endpoint, params, "PUT", token),
    post: <T>(endpoint: string, params: any = null, token?: string) =>
      api<T>(base_uri, endpoint, params, "POST", token),
    delete: <T>(endpoint: string, params: any = null, token?: string) =>
      api<T>(base_uri, endpoint, params, "DELETE", token),
  };
};

export const getMixpanel = (mainStore: Store) => {
  const mixpanel = require("mixpanel-browser");
  mixpanel.init(mixpanelToken, mainStore.mixpanelConfig);
  if (mainStore.mixpanelConfigUpdate) {
    // config will be updated to not use proxy if doesn't receive a successful response
    mixpanel.set_config(mainStore.mixpanelConfig);
    mainStore.mixpanelConfigUpdate = false;
  }

  if (mainStore.user) {
    try {
      mixpanel.identify(mainStore.user.id);
      mixpanel.people.set({
        name: mainStore.user.first_name + " " + mainStore.user.last_name,
        email: mainStore.user.email,
      });
      mixpanel.add_group("Organisation", mainStore.user.group.id.toString() + "-" + mainStore.user.group.name);
      mixpanel.add_group(
        "Plan",
        mainStore.group_types.filter(group_type => group_type.id === mainStore.user!.group.group_type_id)[0].name
      );
      if (mainStore.user.group.add_ons.map(addOn => addOn.name).includes("Premium Trial")) {
        mixpanel.register({ "Premium Trial Period": true });
      }
    } catch (e) {
      // catch errors to avoid crashing the app when event fired and store isn't completely ready
      console.error(e);
    }
  }

  return mixpanel;
};

export const database = factory(backendUrl);
export const datasets = factory(dataUrl);
export const landingPageBackend = factory("https://backend.seerdata.com.au");
