import * as React from "react";
import { ChromePicker } from "react-color";
import { Icon } from "semantic-ui-react";

import { StyleModal, StyleContent, StyleActions, StyleHeader, StyleChange, StyleCancel } from "common/styledComponents/_modals.style";
import { StyleColorPicker } from "./popup/popupStyles/colorPicker.style";

const defaultColor = "#5f2d5f";

interface IColorPicker {
  open: boolean;
  setColorPickerOpen: (open: any) => void;
  onSave: (x: string) => void;
  initialColor?: string | null;
  headerText?: string;
  saveText?: JSX.Element | string;
}

export const ColorPicker = ({ open, initialColor, setColorPickerOpen, onSave, headerText, saveText }: IColorPicker): JSX.Element => {
  const [color, setColor] = React.useState(initialColor || defaultColor);

  // update current color if new initialColor passed
  React.useEffect(() => {
    setColor(initialColor || defaultColor);
  }, [initialColor]);

  return (
    <>
      <StyleModal onClose={() => setColorPickerOpen(false)} open={open}>
        <StyleHeader>{headerText || "Change Color"}</StyleHeader>
        <StyleContent>
          <StyleColorPicker>
            <ChromePicker
              width="100%"
              disableAlpha={true}
              color={color}
              onChange={(e) => setColor(e.hex)}
            />
          </StyleColorPicker>
        </StyleContent>
        <StyleActions>
          <StyleCancel onClick={() => setColorPickerOpen(false)}>
            Cancel <Icon name="close" />
          </StyleCancel>
          <StyleChange
            onClick={() => {
              onSave(color);
              setColorPickerOpen(false);
            }}
          >
            {saveText || <>Change <Icon name="exchange" /></>}
          </StyleChange>
        </StyleActions>
      </StyleModal>
    </>
  );
};
