import * as React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { toDonutChartData } from "common/helpers/chart";

interface IDonutChart {
  data: any[];
  chartKey: any;
  chartColors: string[];
  chartXAxisCombo: [];
  graphWidth: number;
  graphHeight: number;
  setTooltipDataKey: (value) => void;
  CustomToolTip: (props: any) => JSX.Element | null;
}

export const NewBuilderDonutChart = ({ data, chartKey, chartColors, chartXAxisCombo, graphWidth, graphHeight, setTooltipDataKey, CustomToolTip }: IDonutChart): JSX.Element => (
  <PieChart
    width={graphWidth}
    height={graphHeight}
  >
    {
      toDonutChartData(data, chartKey.values, chartXAxisCombo).map((singleData, index) => {
        const size = 65 / data.length;
        return (
          <Pie
            data={singleData}
            labelLine={false}
            dataKey="value"
            nameKey="name"
            innerRadius={`${(size * index) + 35}%`}
            outerRadius={`${(size * index) + size + 35}%`}
            startAngle={90}
            endAngle={450}
          >
            {
              chartKey.values.map((_value, idx) => (
                <Cell
                  key={`cell-${idx}`}
                  fill={chartColors[idx]}
                  strokeWidth={1}
                  onMouseEnter={() => {
                    setTooltipDataKey("value");
                  }}
                  onMouseLeave={() => {
                    setTooltipDataKey(undefined);
                  }}
                />
              ))
            }
          </Pie>
        );
      })
    }
    <Tooltip
      offset={0}
      isAnimationActive={false}
      content={<CustomToolTip chartType="donut" />}
    />
  </PieChart>
);
