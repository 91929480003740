import * as React from "react";
import { Table, Button, Popup, Icon, Form } from "semantic-ui-react";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { Prompt } from "react-router";
import {
  Schema,
  getVariableCombinations,
  transformDataTo2DArray,
  transform2DArrayToData,
  recalculateSummedTotals,
  generateEmpty2DArray,
  isSummedTotal,
} from "common/helpers/dataset";
import { ObjectAny } from "common/helpers/types";
import { ColumnVariableHeadings, RowVariableHeadings, RowCategories, compileTableRenderProperties } from "pages/DatasetTemplateTable/includes/TableSchemaPreview";
import { AggregateDataPreviews } from "./AggregateDataPreviews";

// @TODO - rework the logic for getting cell meta like (is summed total) for instance into doing it one time when schema is loaded into a 2d array to match the data indexes for better performance

const DataTableCell = styled(Table.Cell)`
  .input>textarea {
    font-weight: 700 !important;
    font-family: "Open Sans", sans-serif !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.modified .input>textarea {
    color: #2dc3c3 !important;
  }

  &.summed {
    background-color: #e5e5e5;

    &.modified .input>textarea {
      color: #2dc3c3 !important;
    }

    .input {
      opacity: 1 !important;
    }
  }
`;

interface TemplateTableProps {
  schema: Schema;
  rawData?: ObjectAny[] | null;
  handleSave: (_) => any;
  handleCancel: () => any;
  componentDataSubscriber?: (_) => any;
}

export const TemplateTable = (props: TemplateTableProps): JSX.Element => {
  const { schema, rawData, handleSave, handleCancel, componentDataSubscriber } = props;
  const { rows, columns } = schema;
  const { skipColumns, categoryColumns, categoryRows } = compileTableRenderProperties(schema);

  const rowCombos = getVariableCombinations(schema.rows);
  const colCombos = getVariableCombinations(schema.columns);
  const [initialData, setInitialData] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    const initialData = transformDataTo2DArray(rawData || generateEmpty2DArray(rowCombos.length, colCombos.length), rowCombos, colCombos);
    setInitialData(initialData);
    setData(initialData);
  }, [rawData]);

  // the reason we split by \n is that we are handling copy/paste from applications like microsoft excel along with normal input entry
  const handleNewValue = (rowIdx, colIdx, newValue, e) => {
    const rows = newValue.split("\n");
    if (rows.length === 0) {
      return;
    }

    const newData = cloneDeep(data);

    rows.forEach((row, relativeRowIdx) => {
      const cols = row.split("\t").map(val => val.trim());
      cols.forEach((value, relativeColIdx) => {
        const computedRowIdx = rowIdx + relativeRowIdx;
        const computedColIdx = colIdx + relativeColIdx;

        // Handle data that would be out of the table bounds
        if (newData.length <= computedRowIdx || newData[0].length <= computedColIdx) {
          return;
        }

        if (isNaN(value) || value.length === 0) {  // not a number or blank value
          newData[computedRowIdx][computedColIdx] = "";
        } else {
          // in case user is trying to enter decimals with more places than js can handle
          let numberString = value.slice(0, 12); // no more than 12 digits (1 trillion - 1) per number to avoid running into issues storing as js number
          if (numberString) {
            numberString = numberString.slice(-1) === "." ? numberString : Number(numberString).toString();
          }
          newData[computedRowIdx][computedColIdx] = numberString;
        }
      });
    });

    recalculateSummedTotals(newData, rowCombos, colCombos, schema); // update summed totals calculations
    setData(newData);

    if (e.type === "paste") {
      e.preventDefault();
    }
  };
  const onSave = () => {
    const newData = transform2DArrayToData(data, rowCombos, colCombos);
    handleSave(newData);
  };
  const onResetAll = () => {
    setData(initialData);
  };
  const hasChanges = JSON.stringify(initialData) !== JSON.stringify(data);

  React.useEffect(() => {
    if (componentDataSubscriber) {
      // share any useful state or variables here with parent components
      componentDataSubscriber({ hasChanges });
    }
  }, [hasChanges]);

  const ActionButtons = () => (
    <div>
      <Button onClick={handleCancel} className="mr-3">Cancel</Button>
      <Button onClick={onResetAll} className="mr-3">Reset Changes <Icon name="redo" className="ml-2 mr-0" /></Button>
      <Button className="bg-primary text-white bg-hover-red" onClick={onSave}>Save <Icon name="save" className="ml-2 mr-0" /></Button>
    </div>
  );

  return (
    <div>
      <Prompt message="You will lose all your unsaved changes." when={hasChanges} />
      <div className="mb-4">
        <ActionButtons />
      </div>
      <Form>
        <div className="overflow-auto mb-4 fs-1125" style={{ maxHeight: 700 }}>
          <Table size="small" compact celled>
            <Table.Body>
              {/* Column Variables with skipped cells and Categories */}
              <ColumnVariableHeadings columns={columns} skipColumns={skipColumns} categoryColumns={categoryColumns} />
              {/* Row Variables with empty cells */}
              <RowVariableHeadings rows={rows} categoryColumns={categoryColumns} />
              {/* Sheet Rows including Row Categories including data cells and skipped first column if no rows set */}
              {categoryRows.map((_, idx) => (
                <Table.Row key={`tbody.tr.${idx}`}>
                  <RowCategories idx={idx} rows={rows} />
                  {/* Data cells */}
                  {data[idx]?.map((value, cIdx) => {
                    const isSummed = isSummedTotal(idx, cIdx, rowCombos, colCombos, schema);
                    const isModified = value !== initialData[idx][cIdx];
                    return (
                      <DataTableCell
                        key={`tbody.tr.td.data.${idx}.${cIdx}`}
                        className={`${isModified ? "modified" : ""}${isSummed ? " summed" : ""}`}
                      >
                        <Popup
                          content={(
                            <div>
                              {[...Object.entries(colCombos[cIdx]), ...Object.entries(rowCombos[idx])].map(([key, value]) => (
                                <p className="m-0 fs-0875 text-nowrap"><b>{key}</b>: {value}</p>
                              ))}
                            </div>
                          )}
                          on="hover"
                          mouseEnterDelay={1000}
                          position="bottom left"
                          trigger={(
                            <div>
                              <div className="ui fluid transparent input">
                                <textarea
                                  className="input"
                                  style={{
                                    borderColor: "transparent",
                                    backgroundColor: "transparent",
                                    padding: 0,
                                    borderRadius: 0,
                                    height: "100%",
                                    resize: "none",
                                    overflowY: "hidden",
                                    overflowWrap: "normal",
                                    scrollbarWidth: "none",  /* Firefox */
                                    msOverflowStyle: "none",  /* IE and Edge */
                                  }}
                                  rows={1}
                                  value={value}
                                  disabled={isSummed}
                                  onChange={(e) => handleNewValue(idx, cIdx, e.target.value, e)}
                                  onPaste={(e) =>handleNewValue(idx,cIdx, e.clipboardData.getData("text"), e)}
                                />
                              </div>
                            </div>
                          )}
                        />
                      </DataTableCell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Form>
      <ActionButtons />
      <AggregateDataPreviews parentSchema={schema} parentData={rawData || []} />
    </div>
  );
};
