import React = require("react");
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Input, Dropdown, Image, Button, Icon, Grid, Header } from "semantic-ui-react";

import { StyleSettingsForm, StyleLabel } from "common/styledComponents/signUpForm.style";
import { StyleSettingsGrid, StyleSettingsGridRow } from "pages/Settings/includes/settings.style";
import { $border, $darkestgrey, $radius } from "common/styledComponents/_config.style";
import { database } from "common/api";
import EditPicture from "component/popup/editPicture";
import Store from "common/store";
import { PartnerInvitationsList } from "pages/PartnerOrganisations/includes/PartnerInvitationsList";

export const orgTypesOptions = [
  "Collective Impact Group",
  "Consultant",
  "Fundraiser",
  "Impact Investor or Business",
  "Local Government",
  "Local Organisation or Backbone",
  "Other",
  "Philanthropic or Government Funder",
  "Policy Maker or Advocate",
  "Service Provider",
  "Start-up or Incubator",
  "State or Federal Government",
].map(text => ({ text, key: text, value: text }));


const StyleInput = styled(Input)`
  &.ui.input>input {
    border: ${$border} !important;
    //radius: ${$radius} !important;
  }
  &.ui.input ::placeholder {
    color: ${$darkestgrey} !important;
  };
  &.disabled {
    &.ui.input ::placeholder {
      color: black !important;
    };
  };
  margin-bottom: 15px;
`;

const StyleDropdown = styled(Dropdown)`
  border: ${$border} !important;
  //radius: ${$radius} !important;
`;

const StyleImage = styled(Image)`
  width: 80px;
`;

interface OrgSettingsProps {
  store?: Store;
  onSubmitHandler: () => any;
}

export const OrgSettings = inject("store")(observer(({ store, onSubmitHandler }: OrgSettingsProps) => {
  const { user } = store!;
  const { group } = user!;
  const [orgName, setOrgName] = React.useState(group.name);
  const [orgCategory, setOrgCategory] = React.useState(group.category);

  const handleOrganisationNameChange = e => {
    setOrgName(e.target.value);
  };

  const handleOrganisationCategoryChange = e => {
    setOrgCategory(e.value);
  };

  const acceptInvitation = invitationID => {
    store!.group.groupAcceptInvitation(user!.group.id, invitationID);
  };

  const declineInvitation = invitationID => {
    store!.group.groupDeleteInvitation(invitationID);
  };

  const onSubmit = async () => {
    await database.put(`groups/${user!.group.id}`, { name: orgName, category: orgCategory }, store!.token!);
    onSubmitHandler();
  };

  const isOwner = (user!.id === user!.group.user_id);


  return (
    <StyleSettingsForm>
      <StyleSettingsGrid>
        <StyleSettingsGridRow columns={2}>
          <Grid.Column width={10}>
            <StyleLabel id="settingsModalOrgNameLabel">Organisation</StyleLabel>
            <StyleInput
              aria-labelledby="settingsModalOrgNameLabel"
              fluid
              disabled={!isOwner}
              placeholder={orgName}
              onChange={e => handleOrganisationNameChange(e)}
            />
            <StyleLabel id="settingsModalOrgTypeDropdownLabel">Organisation type</StyleLabel>
            <StyleDropdown
              aria-labelledby="settingsModalOrgTypeDropdownLabel"
              fluid
              search
              selection
              disabled={!isOwner}
              defaultValue={orgCategory}
              options={orgTypesOptions}
              onChange={(_, e) => handleOrganisationCategoryChange(e)}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <StyleLabel>Organisation logo</StyleLabel>
            <StyleImage
              bordered
              circular
              src={user!.group.logo || "/assets/seer-default-company-logo.png"}
              alt={user!.group.logo ? `${orgName} logo` : "Organisation logo not uploaded"}
            />
            {isOwner ? <EditPicture store={store!} type="logo" /> : null}
          </Grid.Column>
        </StyleSettingsGridRow>
      </StyleSettingsGrid>

      {user!.group.partnership_invitations_received.length > 0 && (
        <>
          <Header as="h1" size="large" className="text-secondary">Received partnership invitations</Header>
          <PartnerInvitationsList
            showUserRole="sender_user"
            partnerInvitations={user!.group.partnership_invitations_received}
            cancelLabel="Decline"
            cancelClickHandler={invitationID => declineInvitation(invitationID)}
            confirmLabel="Accept"
            confirmClickHandler={invitationID => acceptInvitation(invitationID)}
          />
        </>
      )}

      <Button size="big" className="bg-primary text-white bg-hover-red my-4" onClick={() => onSubmit()}>
        Save my changes <Icon name="arrow alternate circle right" />
      </Button>

    </StyleSettingsForm>
  );
}));
