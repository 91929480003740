import { COLORS } from "component/UI/common";
import styled from "styled-components";

export const CaseItem = styled.div<{ active?: boolean; disabled?: boolean }>`
  font-size: 15px;
  display: block;
  background: ${p => (p.active ? "white" : "#F5F5F5")};
  color: ${p => (p.disabled ? "#c3c3c3" : p.active ? COLORS.red500 : "#919191")};
  width: 100%;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: flex-start;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: ${p => (p.disabled ? "#f5f5f5" : "white")};
  }
`;

export const StyleIcon = styled.div`
  margin-left: 25px;
  display: inline-block;
  min-width: 55px;
  height: 80px;
  line-height: 89px;
`;

export const Title = styled.div`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

export const Description = styled.div`
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
`;
