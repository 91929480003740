import * as React from "react";
import { Helmet } from "react-helmet";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Button, Label } from "semantic-ui-react";


export const DataCatalogByVariable: React.FC<{ store?: Store }> = inject("store")(
  observer(({ store }) => {
    const { builder } = store!;
    const [dimension, setDimension] = React.useState("Topics");

    const getDatasetText = (key: string): string =>
      builder.allDatasets.find(d => d.key === key)?.name || key;

    const filterDimensions = dimType => {
      if (dimType === "How" && builder.globalDimensions["Measured quantity"]) {
        return Object.fromEntries(
          builder.globalDimensions["Measured quantity"].values.map(category => [
            category.name,
            { source: category.source },
          ])
        );
      }

      return Object.fromEntries(Object.entries(builder.globalDimensions).filter(([_, v]) => v.type === dimType));
    };

    const sortVariables = variables =>
      Object.keys(variables)
        .sort((a, b) => Object.keys(variables[b].source).length - Object.keys(variables[a].source).length)
        .reduce((obj, key) => {
          obj[key] = variables[key];
          return obj;
        }, {});

    const topics = sortVariables(filterDimensions("What"));
    const quantities = filterDimensions("How");
    const locations = filterDimensions("Where");
    const durations = filterDimensions("When");

    const typedVars = {
      Topics: { variables: topics, color: "olive" },
      "Measured Quantities": { variables: quantities, color: "green" },
      Locations: { variables: locations, color: "teal" },
      Durations: { variables: durations, color: "blue" },
    };

    return (
      <>
        <Helmet>
          <title>Data Catalog</title>
        </Helmet>
        <div className="mt-5">
          <h2>Variables</h2>

          <div className="d-inline-block mx-5">
            <span>Show me:  </span>
            <Button.Group>
              <Button color="olive" onClick={() => setDimension("Topics")}>
                Topics
              </Button>
              <Button color="teal" onClick={() => setDimension("Locations")}>
                Locations
              </Button>
              <Button color="blue" onClick={() => setDimension("Durations")}>
                Durations
              </Button>
              <Button color="green" onClick={() => setDimension("Measured Quantities")}>
                Measured Quantities
              </Button>
            </Button.Group>
          </div>

          <div className="d-flex">
            {[0, 1, 2, 3].map(i => {
              const typeDetails = typedVars[dimension];
              const varLength = Object.entries(typeDetails.variables).length;
              const colLength = Math.ceil(varLength / 4);
              const currentColumnVars: any = Object.entries(typeDetails.variables).slice(i * colLength, (i + 1) * colLength);

              return (
                <>
                  <div className="w-25 mx-3">
                    <span className={i > 0 ? "visibility-hidden" : ""}>
                      <Label size="mini" color={typeDetails.color} className="mt-5 mb-3">
                        {dimension}
                      </Label>
                    </span>

                    {currentColumnVars.map(([variable, varDetails]) => (
                      <>
                        <h4 className="my-0">{variable}</h4>

                        {Object.keys(varDetails.source).map(source => (
                          <p className="fs-0750">{getDatasetText(source)}</p>
                        ))}
                      </>
                    ))}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  })
);
