import * as React from "react";
import { Popup, Button, Icon } from "semantic-ui-react";
import { isEqual } from "lodash";
import Select from "react-select";
import * as qs from "qs";

interface Props {
  dashboardDescriptionID: string;
  variable: string;
  // selected in case we want to have default selections displayed in future, can ignore for now
  categories: { name: string; selected: boolean }[];
}

export const DataFilter = (props: Props) => {
  const { variable, categories, dashboardDescriptionID } = props;
  const options = categories.map(({ name }) => ({ label: name, value: name }));
  const defaultSelectedOptions = categories.filter(cat => cat.selected).map(({ name }) => ({ label: name, value: name }));
  const [open, setOpen] = React.useState(false);
  const [selectValue, setSelectValue] = React.useState<any>(defaultSelectedOptions);
  const [appliedValue, setAppliedValue] = React.useState<any>(defaultSelectedOptions);
  const hasUnappliedChanges = !isEqual(selectValue, appliedValue);
  const defaultsSelected = isEqual(appliedValue, defaultSelectedOptions);

  const applyInsightFilter = (filterValue) => {
    const embeds = document.querySelectorAll(`#${dashboardDescriptionID} iframe.seer-dashboards-insight`);
    for (const embed of Array.from(embeds) as HTMLEmbedElement[]) {
      const { src } = embed;
      const path = src.slice(0, src.indexOf("?"));
      const queryStr = src.slice(src.indexOf("?") + 1);
      const query = qs.parse(queryStr);
      query.filter = query.filter || {};
      query.filter[variable] = filterValue.map(opt => opt.value);
      if (!filterValue.length) {
        delete query.filter[variable]; // if no categories selected, remove the filter
      }
      // update the embed src with the newly applied filters
      embed.src = `${path}?${qs.stringify(query)}`;
    }
  };
  const onApply = () => {
    applyInsightFilter(selectValue);
    setOpen(false);
    setAppliedValue(selectValue);
  };
  const onReset = () => {
    applyInsightFilter(defaultSelectedOptions);
    setOpen(false);
    setSelectValue(defaultSelectedOptions);
    setAppliedValue(defaultSelectedOptions);
  };
  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      on="click"
      trigger={<Button>{variable} <Icon name="filter" className="ml-2" /></Button>}
      content={(
        <div style={{ minWidth: 190 }}>
          <div style={{ zIndex: 2 }} className="mb-3 position-relative">
            <Select
              value={selectValue}
              options={options}
              placeholder="Search or select..."
              isMulti
              onChange={nextValue => setSelectValue(nextValue)}
            />
          </div>
          <div className="d-flex">
            <Button disabled={defaultsSelected} onClick={onReset}>Reset</Button>
            <Button disabled={!hasUnappliedChanges} className="bg-primary text-white bg-hover-red" onClick={onApply}>Apply</Button>
          </div>
        </div>
      )}
    />
  );
};
