import * as React from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { Modal, Icon } from "semantic-ui-react";
import Store from "common/store";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { Collapsible } from "component/Collapsible";
import { IntroComponent } from "./includes/IntroComponent";
import { TopicComponent } from "./includes/TopicComponent";
import { TableComponent } from "./includes/TableComponent";
import { ButtonsComponent } from "./includes/ButtonsComponent";
import { BuilderSelector } from "pages/Builder/BuilderSelector";
import { AnimatedBackground } from "./includes/AnimatedBackground";
import { getMixpanel } from "common/api";

interface PropsType extends RouteComponentProps {
  store: Store;
};

export const DATASET_KEY = "abs-census";

const STEPS = ["Intro", "Topic", "What", "How", "Where", "Table", "Builder"];

const getEventLabel = (currentStep) => `Census Query Tool > ${currentStep}`;

class CensusQueryTool extends React.Component<PropsType> {
  state = {
    currentStep: "Intro",
    topics: [],
    selectedTopic: undefined,
    hideButtons: false, // Hide "Start, Back and Next" buttons on "Table" step when adding "categories"
  };

  nextClickHandler = async () => {
    const { currentStep, selectedTopic } = this.state;
    const { builder } = this.props.store;
    const stepIdx = STEPS.indexOf(currentStep);
    const nextStep = STEPS[stepIdx + 1];
    if (nextStep === "What" && selectedTopic) {
      // builder.setActiveTableId(1);
      await builder.selectValue("Topic", selectedTopic!, {[DATASET_KEY]: true});
    }
    if (nextStep === "Table") { // Finish editing table
      builder.saveTableQuery(1);
      await builder.setActiveTableId(undefined);
    }
    if (nextStep === "Builder") {
      this.props.history.push("/builder/new?source=census");
    }
    this.setState({ currentStep: nextStep });
    const mixpanelBody = { "Button Click": "Next" };
    if (currentStep === "Intro") {
      mixpanelBody["Button Click"] = "Start Exploring";
    } else if (currentStep === "Topic") {
      mixpanelBody["Button Click"] = "Create My Own Insight";
    } else if (currentStep === "Table") {
      mixpanelBody["Button Click"] = "Visualise";
    }
    getMixpanel(this.props.store).track(getEventLabel(currentStep), mixpanelBody);
  };

  backClickHandler = async () => {
    const { currentStep } = this.state;
    const stepIdx = STEPS.indexOf(currentStep);
    const previousStep = STEPS[stepIdx - 1];
    if (previousStep === "Intro") {
      await this.initialise();
      this.setState({ selectedTopic: undefined });
    }
    if (previousStep === "Topic") {
      await this.initialise();
    }
    this.setState({ currentStep: previousStep });
    getMixpanel(this.props.store).track(getEventLabel(currentStep), { "Button Click": "Back" });
  };

  resetClickHandler = () => {
    const { currentStep } = this.state;
    this.initialise();
    this.setState({ currentStep: "Intro", selectedTopic: undefined });
    getMixpanel(this.props.store).track(getEventLabel(currentStep), { "Button Click": "Start again" });
  };

  selectTopicHandler = (value) => {
    this.setState({ selectedTopic: value });
    if (value) {
      getMixpanel(this.props.store).track("Census Query Tool > Topic", { "Button Click": "Select topic", "Topic": value });
    }
  };

  initialise = async () => {
    const { builder } = this.props.store;
    await builder.newInsight();
    await builder.selectDataset(DATASET_KEY);
  };

  initAllTopics = () => {
    const { builder } = this.props.store;
    const topics = builder.dimensions["Topic"]?.values
      .filter(val => val.source[DATASET_KEY] && Object.keys(val.source).length === 1);
    this.setState({ topics });
  };

  async componentDidMount() {
    await this.initialise();
    this.initAllTopics();
    getMixpanel(this.props.store).track("Page view", { "Page": "Census Query Tool" });
  }

  render() {
    const { builder, ui } = this.props.store;
    const { currentStep, topics, selectedTopic, hideButtons } = this.state;
    const showLoader = builder.loading.reloadQuickStat || builder.loading.initialiseGlobalDimensions;
    const isNextButtonDisabled =
      currentStep === "Topic"
        ? selectedTopic
          ? false
          : true
        : currentStep === "Table" // Disable "Visaulise" button when there is a variable has no categories selected
          ? !Object.keys(builder.dimensions)
              .filter(key => builder.dimensions[key].requirement === "selected")
                .every(key => builder.dimensions[key].values.some(val => val.requirement === "selected"))
          : !(!!builder.selectedDimensionsByType[currentStep] && builder.selectedDimensionsByType[currentStep]?.every(dim => dim.values.length > 0));

    return (
      <div>
        <Helmet>
          <title>ABS Census Query Tool</title>
        </Helmet>
        {ui.windowWidth && ui.windowWidth < 992 && (
          <Collapsible
            defaultOpen
            render={({ isOpen, setIsOpen }) => (
              <Modal open={isOpen} onClose={() => setIsOpen(false)} closeIcon>
                <Modal.Content><Icon name="warning circle" color="yellow" className="pr-2" />Please open on a computer for the best experience</Modal.Content>
              </Modal>
            )}
          />
        )}
        {showLoader ? <ShowLoaderGlobal /> : null}
        <div className="my-5 d-flex align-items-center justify-content-center position-relative" style={{ zIndex: 1 }}>
          <div className="text-center" style={{ width: "940px", maxWidth: "100%" }}>
            <div className="bg-white rounded-3 shadow p-4 p-md-6 d-flex flex-column justify-content-between" style={{ minHeight: 670 }}>
              {currentStep === "Intro"
                ? <IntroComponent nextClickHandler={this.nextClickHandler} />
                : <>
                    <div className="mb-5">
                      {currentStep === "Topic"
                        ? <TopicComponent
                            topics={topics}
                            selectedTopic={selectedTopic}
                            selectHandler={(value) => this.selectTopicHandler(value)}
                            selectTemplateHandler={() => this.setState({ currentStep: "Where" })}
                          />
                        : currentStep === "Table"
                            ? <TableComponent hideButtons={(value) => this.setState({ hideButtons: value })} noMaxWidth />
                            : <BuilderSelector selectionGroups={[currentStep]} forMultiStep={true} />}

                    </div>
                    {!hideButtons &&
                      <ButtonsComponent
                        nextClickHandler={this.nextClickHandler}
                        nextDisabled={isNextButtonDisabled}
                        backClickHandler={this.backClickHandler}
                        resetClickHandler={this.resetClickHandler}
                        nextLabel={
                          currentStep === "Topic"
                            ? "Create my own insight"
                            : currentStep === "Table"
                              ? "Visualise"
                              : "Next"
                        }
                      />}
                  </>}
            </div>
          </div>
        </div>
        <AnimatedBackground />
      </div>
    );
  }
}

export default inject("store")(observer(CensusQueryTool));
