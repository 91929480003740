import { History } from "history";
import { includes } from "lodash";
import { database } from "common/api";
import Store from "common/store";
import { cookies, cookiesAvailable, ls } from "./storage";
import { dateSecondsFromNow, seconds } from "./date";
import { ObjectAny } from "common/helpers/types";
import { environment } from "common/constants";


export const userExists = async (email: string): Promise<boolean> => {
  const res: any = await database.head("users", { email });
  return res.statusCode === 200;
};

// Customer Feedback Modal display logic
const feedbackCookieName = "SEER_FEEDBACK";
const feedbackCookieExpires = dateSecondsFromNow(seconds.year); // expiry should be long enough so the trigger logic can execute
const feedbackCookieOpts = { expires: feedbackCookieExpires, path: "/" };
const shownCoolDownWeeks = 3; // how long to not show the modal after having shown it
const completedCoolDownWeeks = 4; // longer interval for users that completed the form

export const feedbackModalCheck = (store: Store, history: History): void => {
  if (!cookiesAvailable) {
    // cookies need to be enabled in the users browser
    return;
  }
  setTimeout(() => {
    if (store.token) {
      let feedbackCookie = cookies.get(feedbackCookieName);
      if (!feedbackCookie?.nextShow) {
        // if no cookie set, the feedback modal should show 7 days from account creation
        const accountCreationDate = new Date(store.user?.created_at || Date.now()); // fallback to now if any issues, so we don't show modal early
        const firstTriggerDate = new Date(accountCreationDate.getTime() + (1000 * 60 * 60 * 24 * 7)).toISOString(); // 7 days from account creation by default
        feedbackCookie = { nextShow: firstTriggerDate };
        cookies.set(feedbackCookieName, feedbackCookie, feedbackCookieOpts);
      }
      if ((new Date().getTime() - new Date(feedbackCookie.nextShow).getTime()) > 0) {
        // next display date has passed so show the modal and update the cookie for the next display date
        const nextShow = dateSecondsFromNow(seconds.week * shownCoolDownWeeks).toISOString();
        cookies.set(feedbackCookieName, { nextShow }, feedbackCookieOpts);
        history.push("/feedback", { background: history.location });
      }
    }
  }, 2000);
};

export const feedbackModalCompleted = (): void => {
  const nextShow = dateSecondsFromNow(seconds.week * completedCoolDownWeeks).toISOString();
  cookies.set(feedbackCookieName, { nextShow }, feedbackCookieOpts);
};

const tempUnlayerUsers = [774,277,650,824,820]; // @TODO - remove user restriction after go live (currently Seer Team - Ricki, Cat, Lavinia, Ryan, Nik)
export const getFeaturePermissions = (store: Store): ObjectAny => {
  const unlayerEditor = includes(["Plus", "Unlimited"], store.userPlan) && (environment !== "production" || includes(tempUnlayerUsers, store.user?.id));
  return {
    unlayerEditor,
    // @TODO - add more feature checks..
  };
};

export const getExplorePath = () => ls.getItem("isAdvancedExploreUser")
  ? "/builder/new"
  : "/explore";
