import * as React from "react";
import { Button, Icon } from "semantic-ui-react";

interface IExploreStep {
  currentStep: string;
  setCurrentStep: Function; 
  handleCancelEdit?: (() => void) | null;
};

export const ExploreStep = ({ currentStep, setCurrentStep, handleCancelEdit }: IExploreStep): JSX.Element => {
  const tableStepActive = currentStep === "table" || currentStep === "chart" || currentStep === "preview";
  const chartStepActive = currentStep === "chart" || currentStep === "preview";
  const previewStepActive = currentStep === "preview";

  const stepItems = [
    {
      step: "table",
      label: "Build your table",
      number: 1,
      isActive: tableStepActive,
      onClick: () => setCurrentStep("table"),
    },
    {
      step: "chart",
      label: "Build your chart",
      number: 2,
      isActive: chartStepActive,
      onClick: () => setCurrentStep("chart"),
    },
    {
      step: "preview",
      label: "Finalise your insight",
      number: 3,
      isActive: previewStepActive,
      onClick: () => setCurrentStep("preview"),
    },
  ];

  return (
    <div
      className="bg-white w-100 d-flex align-items-center justify-content-center"
      style={{ height: 60, borderBottom: "1px solid #E5E5E5" }}
    >
      {
        handleCancelEdit &&
        <Button
          onClick={handleCancelEdit}
          size="small"
          className="position-absolute"
          style={{ left: 20 }}
        >
          <Icon name="arrow left" style={{ marginLeft: -5 }}/>Cancel edit
        </Button>
      }
      <div className="position-relative d-flex justify-content-between w-75">
        {/* Step items */}
        {
          stepItems.map(item => {
            const { step, label, number, isActive, onClick } = item;

            return(
              <div className={`d-flex flex-column align-items-center ${isActive && currentStep !== step ? "cursor-pointer" : ""}`}
                onClick={() => isActive && onClick()}
              >
                <div
                  className={`rounded-circle bg-white ${isActive ? "text-primary" : "text-grey"}`}
                  style={{
                    width: 20, height: 20, borderWidth: 2, borderStyle: "solid", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 10,
                    transitionDelay: isActive ? "0.3s" : "0s",
                  }}
                >
                  <p className="ff-primary fs-0875 fw-700">{number}</p>
                </div>
                <p className={`ff-primary fs-0875 fw-700 ${isActive ? "text-primary" : "text-grey"}`}>
                  {label}
                </p>
              </div>
            );
          })
        }
        {/* Helper line to cover the second line */}
        <div
          className="bg-white"
          style={{ height: 2, width: 70, position: "absolute", top: 9, right: 0, zIndex: 3 }}>
        </div>
        {/* Red line */}
        <div
          className= "bg-primary"
          style={{
            height: 2, position: "absolute", top: 9, left: 56, zIndex: 2, transition: "width 0.3s",
            width: previewStepActive ? "calc(100% - 60px)" : chartStepActive ? "calc(50% - 80px)" : 0,
          }}>
        </div>
        {/* Base grey line */}
        <div
          className= "bg-grey"
          style={{ height: 2, width: "calc(100% - 60px)", position: "absolute", top: 9, left: 56, zIndex: 1 }}>
        </div>
      </div>
    </div>
  );
};
