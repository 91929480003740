import * as Excel from "exceljs/dist/exceljs.min.js";
import { saveAs } from "file-saver";
import { uniq } from "lodash";
import * as numeral from "numeral";
import { formatValue, getCombinedDims } from "common/helpers/data";
import { buildArrayCombos } from "common/helpers/dimensions";
import { calcToFormula, getCNumber, getMissingFallback } from "common/helpers/explore";
import { Builder } from "common/store/builder";

// Table Styles
const rowHeight = 25; // Cell Height
const fontWhiteBold = {
  color: { argb: "FFFFFFFF" },
  size: 12,
  name: "Arial",
  bold: true,
};
const fontWhiteNormal = {
  color: { argb: "FFFFFFFF" },
  size: 12,
  name: "Arial",
  bold: false,
};
const fontBlackBold = {
  color: { argb: "FF000000" },
  size: 12,
  name: "Arial",
  bold: true,
};
const fontBlackNormal = {
  color: { argb: "FF000000" },
  size: 12,
  name: "Arial",
  bold: false,
};
const bgDarkGrey = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF5F5F5F" },
};
const bgMediumGrey = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF919191" },
};
const bgLightGrey = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FFD0D0D0" },
};
const borderWhite = {
  top: { style: "thin", color: { argb: "FFFFFFFF" } },
  left: { style: "thin", color: { argb: "FFFFFFFF"} },
  bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
  right: { style: "thin", color: { argb: "FFFFFFFF" } },
};
const alignmentLeft = {
  vertical: "middle",
  horizontal: "left",
  indent: 1,
  wrapText: true,
};
const alignmentCenter = {
  vertical: "middle",
  horizontal: "center",
  wrapText: true,
};
const alignmentRight = {
  vertical: "middle",
  horizontal: "right",
  indent: 1,
  wrapText: true,
};

// Used for "Source" section to add rows for "columns", "rows", "filters"
const addSourceVariableRows = (worksheet, variables, label) => {
  variables.forEach(variable => {
    const { dimension, values } = variable;
    const columnRow = [label, dimension, ...values];
    const addedColumnRow = worksheet.addRow(columnRow);
    addedColumnRow.font = fontBlackNormal;
    addedColumnRow.alignment = alignmentLeft;
    addedColumnRow.getCell(1).font = fontBlackBold;
  });
};

const mergeColumnCells = (worksheet, initialNumber, initialValue, columnValueCombos, rowNumber, comboIdx, splitString?) => {
  let startValColumn = initialNumber + 1;
  let currentValValue = initialValue;
  columnValueCombos.forEach((combo, index) => {
    const currentCombo = splitString ? combo[comboIdx].split(":::")[0] : combo[comboIdx];
    if (currentCombo !== currentValValue) {
      if (startValColumn !== initialNumber + index) {
        worksheet.mergeCells(rowNumber, startValColumn, rowNumber, initialNumber + index);
      }
      startValColumn = initialNumber + index + 1;
      currentValValue = currentCombo;
    }
    // Merge the last group of same values
    if (index + 1 === columnValueCombos.length && startValColumn !== initialNumber + index + 1) {
      worksheet.mergeCells(rowNumber, startValColumn, rowNumber, initialNumber + index + 1);
    }
  });
};

// always convert number values to number format for excels, this is the preference
const csvCellValueFormatter = (strValue: string): number | string => {
  const val = numeral(strValue).value();
  return typeof val === "number" ? val : "-";
};

export const downloadCsvFile = (builder: Builder, insightName: string): void => {
  const workbook = new Excel.Workbook();
  workbook.addWorksheet("Table");
  const ws = workbook.getWorksheet("Table");

  // 1: Create header
  const combinedColumns = getCombinedDims(builder.columns);
  const columnValueCombos = buildArrayCombos(combinedColumns.map(col => col.values));
  let maxRowItemNum = 1; // The max number of row items across all tables: to know how many cells we need for the row variables (left section of the table)
  builder.tables.forEach(table => {
    if (table.rows?.length > maxRowItemNum) {
      maxRowItemNum = table.rows.length;
    }
  });
  const [firstColumItem, ...restColumItems] = combinedColumns;
  const columns: any[] = []; // Table's base column. For multiple column items, we need to use `addRow`
  let tableHeaderRowCount = 1; // For set all table header rows border

  // Create the table base column based on the first column variable
  if (firstColumItem.dimension.startsWith("!")) {
    // Add "Location/Time" to the column cells
    for (let i = 0; i < maxRowItemNum; i++) {
      columns.push({
        header: firstColumItem.dimension.slice(1),
        key: firstColumItem.dimension.slice(1),
        width: 25,
      });
    }
    // Add location/time variables dimensions to the column
    columnValueCombos.forEach(combo => {
      columns.push({
        header: combo[0].split(":::")[0],
        key: combo[0].split(":::")[0],
        width: 25,
      });
    });
    ws.columns = columns;
    tableHeaderRowCount++;
    // Add location/time variables values to the column
    const row: any[] = [];
    for (let i = 0; i < maxRowItemNum; i++) {
      row.push(firstColumItem.dimension.slice(1));
    }
    columnValueCombos.forEach(combo => {
      row.push(combo[0].split(":::")[1]);
    });
    const addedValuesRow = ws.addRow(row);
    tableHeaderRowCount++;
    addedValuesRow.font = fontWhiteNormal;
    addedValuesRow.fill = bgDarkGrey;
    addedValuesRow.alignment = alignmentCenter;
    // Merge cells by (start row, start column, end row, end column)
    // Merge "Time/Location" cells
    ws.mergeCells(1, 1, 2, maxRowItemNum);
    // Merge dimension cells
    mergeColumnCells(ws, maxRowItemNum, columnValueCombos[0][0].split(":::")[0], columnValueCombos, 1, 0, true);
    // Merge value cells
    mergeColumnCells(ws, maxRowItemNum, columnValueCombos[0][0], columnValueCombos, 2, 0);
  } else {
    // Add variable dimensions to the column
    for (let i = 0; i < maxRowItemNum; i++) {
      columns.push({
        header: firstColumItem.dimension,
        key: firstColumItem.dimension,
        width: 25,
      });
    }
    // Add variable values to the column
    columnValueCombos.forEach(combo => {
      columns.push({
        header: combo[0],
        key: combo[0],
        width: 25,
      });
    });
    ws.columns = columns;
    tableHeaderRowCount++;
    // Merge dimension cells
    ws.mergeCells(1, 1, 1, maxRowItemNum);
    // Merge value cells
    mergeColumnCells(ws, maxRowItemNum, columnValueCombos[0][0], columnValueCombos, 1, 0);
  }
  // Update base column row style
  const firstRow = ws.getRow(1);
  firstRow.font = fontWhiteBold;
  firstRow.fill = bgDarkGrey;
  firstRow.alignment = alignmentCenter;
  // When there is a single cell table, we need to set the 3rd column width for "Source" section
  if (columns.length === 2) {
    ws.getColumn(3).width = 25;
  }

  // Create the rest of table header if there are more than one item in columns (use "addRow")
  restColumItems?.forEach((column, idx) => {
    if (column.dimension.startsWith("!")) {
      // Create row for "Location/Time" and variable dimensions
      const rowOne: any[] = [];
      for (let i = 0; i < maxRowItemNum; i++) {
        rowOne.push(column.dimension.slice(1));
      }
      columnValueCombos.forEach(combo => {
        rowOne.push(combo[idx + 1].split(":::")[0]);
      });
      const addedDimRow = ws.addRow(rowOne);
      tableHeaderRowCount++;
      addedDimRow.font = fontWhiteBold;
      addedDimRow.fill = bgDarkGrey;
      addedDimRow.alignment = alignmentCenter;
      // Create row for "Location/Time" and variable dimensions
      const rowTwo: any[] = [];
      for (let i = 0; i < maxRowItemNum; i++) {
        rowTwo.push(column.dimension.slice(1));
      }
      columnValueCombos.forEach(combo => {
        rowTwo.push(combo[idx + 1].split(":::")[1]);
      });
      const addedValRow = ws.addRow(rowTwo);
      tableHeaderRowCount++;
      addedValRow.font = fontWhiteNormal;
      addedValRow.fill = bgDarkGrey;
      addedValRow.alignment = alignmentCenter;
      // Merge "Location/Time" cells
      ws.mergeCells(addedDimRow._number, 1, addedValRow._number, maxRowItemNum);
      // Merge dimension cells
      mergeColumnCells(ws, maxRowItemNum, columnValueCombos[0][idx + 1].split(":::")[0], columnValueCombos, addedDimRow._number, idx + 1, true);
      // Merge value cells
      mergeColumnCells(ws, maxRowItemNum, columnValueCombos[0][idx + 1], columnValueCombos, addedValRow._number, idx + 1);
    } else {
      // Create row for variable dimensions and values
      const row: any[] = [];
      for (let i = 0; i < maxRowItemNum; i++) {
        row.push(column.dimension);
      }
      columnValueCombos.forEach(combo => {
        row.push(combo[idx + 1]);
      });
      const addedRow = ws.addRow(row);
      tableHeaderRowCount++;
      addedRow.font = fontWhiteBold;
      addedRow.fill = bgDarkGrey;
      addedRow.alignment = alignmentCenter;
      // Merge dimension cells
      ws.mergeCells(addedRow._number, 1, addedRow._number, maxRowItemNum);
      // Merge value cells
      mergeColumnCells(ws, maxRowItemNum, columnValueCombos[0][idx + 1], columnValueCombos, addedRow._number, idx + 1);
    }
  });

  // 2: Add data row
  builder.tables.forEach((table) => {
    if (table.type === "calc") { // "Calc" tables
      const row: any = [];
      for (let i = 0; i < maxRowItemNum; i++) {
        row.push(table.name);
      }
      const { calcMissingFallback, calcMissingFallbackValue } = builder;
      columnValueCombos.forEach(columComb => {
        const value = getCNumber(builder.tables, table, columComb, combinedColumns, getMissingFallback(calcMissingFallback, calcMissingFallbackValue));
        row.push(value === null ? "-" : csvCellValueFormatter(formatValue(value, [table.format !== "Decimal" ? "(%)" : "Decimal"])));
      });
      const addedCalcRow = ws.addRow(row);
      // Merge "calc" table name cells
      ws.mergeCells(addedCalcRow._number, 1, addedCalcRow._number, maxRowItemNum);
      // Set styling
      addedCalcRow.font = fontBlackNormal;
      addedCalcRow.alignment = alignmentRight;
      for (let i = 1; i <= maxRowItemNum; i++) {
        const currentCell = addedCalcRow.getCell(i);
        currentCell.font = fontWhiteBold;
        currentCell.fill = bgMediumGrey;
      }
    } else {
      // Create row for table name
      const addedNameRow = ws.addRow([table.name]);
      ws.mergeCells(addedNameRow._number, 1, addedNameRow._number, maxRowItemNum + columnValueCombos.length);
      addedNameRow.font = fontWhiteBold;
      addedNameRow.fill = bgMediumGrey;
      addedNameRow.border = borderWhite;
      if (table.rows.length > 0) {
        // Create row for row variable dimensions
        const addedDimRow = ws.addRow(table.rows.map(row => row.dimension));
        addedDimRow.font = fontBlackBold;
        // Merge row cells for row variable dimensions (horizontally)
        if (table.rows.length < maxRowItemNum) {
          ws.mergeCells(addedDimRow._number, table.rows.length, addedDimRow._number, maxRowItemNum);
        }
        // Create row for variable values and data
        const rowValueCombos = buildArrayCombos(table.rows.map(col => col.values));
        rowValueCombos.forEach(rowCombo => {
          let row: any[] = [];
          row = [...rowCombo];
          if (rowCombo.length < maxRowItemNum) {
            for (let i = 0; i < maxRowItemNum - rowCombo.length; i++) {
              row.push(null);
            }
          }
          columnValueCombos.forEach(columnCombo => {
            const matchedResult = table.results.find(result =>
              columnCombo.every((_columnCombo, i) => {
                if (combinedColumns[i].dimension.startsWith("!")) {
                  return result[_columnCombo.split(":::")[0]]?.toString() === _columnCombo.split(":::")[1].toString();
                } else {
                  return result[combinedColumns[i].dimension].toString() === _columnCombo.toString();
                }
              }) &&
              rowCombo.every((_rowCombo, i) =>
                result[table.rows[i].dimension].toString() === _rowCombo.toString()
              )
            );
            row.push(matchedResult ? csvCellValueFormatter(formatValue(matchedResult["Value"], [matchedResult["Measured quantity"]])) : "-");
          });
          const addedDataRow = ws.addRow(row);
          addedDataRow.font = fontBlackNormal;
          addedDataRow.alignment = alignmentRight;
          // Merge cells for row variable values when there are "null"(empty) cells (horizontally)
          if (table.rows.length < maxRowItemNum) {
            ws.mergeCells(addedDataRow._number, table.rows.length, addedDataRow._number, maxRowItemNum);
          }
        });
        // Merge cells for row variable values (vertically)
        for (let i = 0; i < table.rows.length; i++) {
          let startRow = addedDimRow._number + 1;
          let currentValue = rowValueCombos[0][i];
          rowValueCombos.forEach((combo, index) => {
            if (combo[i] !== currentValue) {
              if (startRow !== addedDimRow._number + index) { // Avoid merge the cell itself
                ws.mergeCells(startRow, i + 1, addedDimRow._number + index, i + 1);
              }
              startRow = addedDimRow._number + index + 1;
              currentValue = combo[i];
            }
            // Merge the last group of same values
            if (index + 1 === rowValueCombos.length && startRow !== addedDimRow._number + index + 1) {
              ws.mergeCells(startRow, i + 1, addedDimRow._number + index + 1, i + 1);
            }
          });
        }
      } else {
        // Single row table
        const row: any[] = [];
        row.push(null);
        columnValueCombos.forEach(columnCombo => {
          const matchedResult = table.results.find(result =>
            columnCombo.every((_columnCombo, i) => {
              if (combinedColumns[i].dimension.startsWith("!")) {
                return result[_columnCombo.split(":::")[0]]?.toString() === _columnCombo.split(":::")[1].toString();
              } else {
                return result[combinedColumns[i].dimension].toString() === _columnCombo.toString();
              }
            })
          );
          row.push(matchedResult ? csvCellValueFormatter(formatValue(matchedResult["Value"], [matchedResult["Measured quantity"]])) : "-");
        });
        const addedRow = ws.addRow(row);
        addedRow.font = fontBlackNormal;
        addedRow.alignment = alignmentRight;
      }
    }
  });

  // 3: Source section
  ws.addRow([]).height = 50; // Add an empty row
  // Get the max number of each variable value's length for merging "Categories" column cells
  let maxCategoryNumber = 1;
  const resultTables: any[] = builder.tables.filter(table => table.type === "result");
  const tableDatasetKeys = uniq(resultTables.reduce((prev, curr) => prev.concat(...curr.userSelectedDatasets), []));
  const tableDatasets = builder.allDatasets
    .filter(dataset => tableDatasetKeys.indexOf(dataset.key) >= 0)
    .map(dataset => ({ key: dataset.key, name: dataset.name }));
  resultTables.forEach(table => {
    const { columns, rows, filters } = table;
    columns.forEach(column => {
      if (column.values.length > maxCategoryNumber) {
        maxCategoryNumber = column.values.length;
      }
    });
    rows.forEach(row => {
      if (row.values.length > maxCategoryNumber) {
        maxCategoryNumber = row.values.length;
      }
    });
    filters.forEach(filter => {
      if (filter.values.length > maxCategoryNumber) {
        maxCategoryNumber = filter.values.length;
      }
    });
  });
  // Add "Source" heading row
  const addedSourceRow = ws.addRow(["Source"]);
  ws.mergeCells(addedSourceRow._number, 1, addedSourceRow._number, 2 + maxCategoryNumber);
  addedSourceRow.font = fontWhiteBold;
  addedSourceRow.fill = bgDarkGrey;
  // Add "Datasets"
  tableDatasets.forEach(dataset => {
    const addedDatasetRow = ws.addRow(["Dataset:", dataset.name]);
    addedDatasetRow.getCell(1).font = fontBlackBold;
    addedDatasetRow.getCell(2).font = fontBlackNormal;
    addedDatasetRow.alignment = alignmentLeft;
    ws.mergeCells(addedDatasetRow._number, 2, addedDatasetRow._number, 2 + maxCategoryNumber);
    const addedKeyRow = ws.addRow(["Dataset Key:", dataset.key]);
    addedKeyRow.getCell(1).font = fontBlackBold;
    addedKeyRow.getCell(2).font = fontBlackNormal;
    addedKeyRow.alignment = alignmentLeft;
    ws.mergeCells(addedKeyRow._number, 2, addedKeyRow._number, 2 + maxCategoryNumber);
  });
  // Add tables info
  builder.tables.forEach(table => {
    if (table.type === "result") {
      const { name, columns, rows, filters } = table;
      // Table Name
      const addedTableName = ws.addRow([name]);
      addedTableName.fill = bgMediumGrey;
      addedTableName.font = fontWhiteBold;
      ws.mergeCells(addedTableName._number, 1, addedTableName._number, 2 + maxCategoryNumber);
      // Table header
      const addedTableHeader = ws.addRow([null, "Variables", "Categories"]);
      addedTableHeader.fill = bgLightGrey;
      addedTableHeader.font = fontBlackBold;
      addedTableHeader.alignment = alignmentLeft;
      addedTableHeader.border = borderWhite;
      ws.mergeCells(addedTableHeader._number, 3, addedTableHeader._number, 2 + maxCategoryNumber);
      // Table info, columns, rows and filters
      if (columns.length > 0) {
        addSourceVariableRows(ws, columns, "Columns");
        ws.mergeCells(addedTableHeader._number + 1, 1, addedTableHeader._number + columns.length, 1);
      }
      if (rows.length > 0) {
        addSourceVariableRows(ws, rows, "Rows");
        ws.mergeCells(addedTableHeader._number + columns.length + 1, 1, addedTableHeader._number + columns.length + rows.length, 1);
      }
      if (filters.length > 0) {
        addSourceVariableRows(ws, filters, "Filters");
        ws.mergeCells(addedTableHeader._number + columns.length + rows.length + 1, 1, addedTableHeader._number + columns.length + rows.length + filters.length, 1);
      }
    } else {
      // Table name
      const calcTableNameRow = ws.addRow([table.name]);
      calcTableNameRow.fill = bgMediumGrey;
      calcTableNameRow.font = fontWhiteBold;
      ws.mergeCells(calcTableNameRow._number, 1, calcTableNameRow._number, 2 + maxCategoryNumber);
      // Calc formula
      const calcTableFormulaRow = ws.addRow([calcToFormula(table, builder.tables).join("")]);
      calcTableFormulaRow.font = fontBlackBold;
      ws.mergeCells(calcTableFormulaRow._number, 1, calcTableFormulaRow._number, 2 + maxCategoryNumber);
    }
  });
  // Add a disclaimer if "calcMissingFallback" is set
  const { calcMissingFallback, calcMissingFallbackValue } = builder;
  if (typeof getMissingFallback(calcMissingFallback, calcMissingFallbackValue) === "number") {
    const addedCalcMissingFallbackRow = ws.addRow([`The calculations in this Insight rely on a fallback value of ${calcMissingFallbackValue} which has been applied to missing or null data points in this Insight.`]);
    addedCalcMissingFallbackRow.font = fontBlackBold;
    ws.mergeCells(addedCalcMissingFallbackRow._number, 1, addedCalcMissingFallbackRow._number, 2 + maxCategoryNumber);
  }

  // 4: Global style updates
  ws.eachRow(row => row.height = rowHeight);
  for (let i = 1; i <= maxRowItemNum; i++) { // Set all row value columns alignment
    ws.getColumn(i).alignment = alignmentLeft;
  }
  for (let i = 1; i < tableHeaderRowCount; i++) { // Set all table header rows border
    ws.getRow(i).border = borderWhite;
  }

  // 5: Export XLSX
  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: "application/octet-stream" });
    saveAs(blob, `Insight_Table_${insightName}.xlsx`);
  });
  return;
};
