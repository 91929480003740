import React = require("react");
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";

import Store from "common/store";
import Member from "./includes/Member";
import PendingInvitations from "./includes/PendingInvites";
import { StyleInput } from "common/styledComponents/_modals.style";
import { StyleMemberContainer } from "./includes/addMember.style";
import { getMixpanel } from "common/api";
import { Button } from "component/UI/Button";

interface MembersProps {
  store?: Store;
}

export const Members = inject("store")(observer((props: MembersProps) => {
  const store = props.store!;
  const { breakpoint } = store!.ui;
  const isMobileScreen = breakpoint === "mobile";
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    getMixpanel(store).track("Page view", { "Page": "Settings > Organisation > Members" });
  }, []);

  return (
    <>
      <div className="d-flex mb-4">
        <StyleInput
          aria-label="Invite email"
          fluid
          style={{ fontFamily: "Open Sans", marginRight: "10px" }}
          id="invite-code"
          placeholder="E.g user@company.com"
          type="email"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value || "")}
        />
        <Button
          colorConfig="aqua"
          onClick={() => store.group.sendEmailInvite(email).then(success => {
            if (success) {
              setEmail("");
              getMixpanel(store).track("Invite Member", { "Page": "Settings > Organisation > Members" });
            }
          })}
          disabled={!email}
          style={{ height: 40 }}
        >
          {isMobileScreen ? "" : "Invite "}<Icon name="user plus" className="ml-sm-0 ml-1" />
        </Button>
      </div>
      <StyleMemberContainer>
        <PendingInvitations store={store} />
        <Member store={store} />
      </StyleMemberContainer>
    </>
  );
}));

export default Members;
