import styled from "styled-components";
import { Dropdown, Form } from "semantic-ui-react";
import {
  $white,
  $shadow,
  $opensans,
  $border,
  $black,
  $radius,
  device,
  $darkgrey,
  $lightgrey,
} from "../../../common/styledComponents/_config.style";
import { Modal, Checkbox } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

// @TODO - fix me, declare types when extending on default html element props
export const StyleTileSuitcase = styled.div<any>`
  display: flex;
  border-bottom: 1px solid #c3c3c3;
  border-top: ${props => (props.firstItem ? "none" : "1px solid #C3C3C3")};
  min-height: 80px;
  margin-bottom: -1px;
  margin-left: ${props => (props.indent ? "40px" : "0px")};
`;

export const StyleUserInfoSuitcase = styled.div`
  flex: 1;
  width: 100%;
  min-height: 40px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyleInput = styled(Form.Input)`
  width: 74% !important;
  height: 40px !important;
  display: inline-block !important;
  font-family: "Open Sans" !important;
  .ui.input {
    height: 40px !important;
    font-family: "Open Sans" !important;
  }
  /* .prompt.label {
    background-color: black !important;
    border: 1px solid #e0b4b4!important;
    color: ${COLORS.red500} !important;
    width: 490px !important;
} */
`;

export const StyleModal = styled(Modal)`
  font-family: ${$opensans} !important;
  width: 540px !important;
`;

export const StyleHeader = styled(Modal.Header)`
  border-bottom: 1px solid #c3c3c3 !important;
  font-family: "Open Sans" !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 41px !important;
  color: #6c3563 !important;
`;
export const StyleContentT = styled(Modal.Content)`
  margin-bottom: -20px !important;
  display: flex !important;
  justify-content: space-between !important;
`;
export const StyleContentB = styled(Modal.Content)`
  height: 400px;
  overflow-y: auto !important;
`;

export const StyleClose = styled.button`
  cursor: pointer;
  width: 90px;
  height: 40px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${$white};
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border: none;
  outline: none;
`;
export const StyleInvite = styled.button`
  margin: 0 0 0 0;
  display: inline-block;
  cursor: pointer;
  width: 26% !important;
  height: 40px;
  border-radius: ${$radius};
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${$white};
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border: none;
  @media ${device.tablet} {
    font-size: 12px !important;
    height: 40px;
  }
`;

// Member List----------------------------------x
export const StyleMemberBlock = styled.span`
  display: inline-block;
  text-align: center;
  padding-top: 8px;
  width: 110px;
  height: 40px;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  transform: translateY(-20px);
  border: ${$border};
  box-sizing: border-box;
  border-radius: 4px;
`;
export const StyleMemberContainer = styled.div`
  // overflow-y: hidden !important; overflow-x: hidden !important;
  font-size: 14px;
`;
export const StylePendingInviteContainer = styled.div`
  /* transform: translateY(-144px); */
`;
export const StyleTile = styled.div`
  display: flex;
  border-bottom: 1px solid #c3c3c3;
  border-top: 1px solid #c3c3c3;
  height: 80px;
  margin-bottom: -1.1px;
`;
export const StyleUserInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyleAvatar = styled.img`
  width: 40px;
  border-radius: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const StyleCheckBoxDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: ${$border};
  padding: 3px 0 0 3px;
  position: relative;
  right: 10px;
`;

export const StyleName = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: ${COLORS.indigo600};
  margin: -3px 0 0 0;
`;

export const StyleNameSuitcase = styled(StyleName)`
  color: ${COLORS.red500};
`;

export const StyleOrganisation = styled.div`
  font-family: ${$opensans};
  font-weight: normal;
  font-size: 15px;
  color: ${$black};
  word-break: break-word;
`;
export const StyleSelectAll = styled.span`
  width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
`;

export const StyleCheckbox = styled(Checkbox)`
  top: 25px !important;
  left: -12px !important;
  &.checkbox.disabled.checkbox label {
    cursor: not-allowed !important;
  }
  label {
    opacity: 1 !important;
    &:before {
      width: 30px !important;
      height: 30px !important;
      border-radius: 15px !important;
      background-color: white !important;
    }
    &:after {
      -webkit-text-stroke: 1px white !important;
      transform: scale(1.4) !important;
      color: ${COLORS.red500} !important;
      margin: 7px 0 0 6px;
    }
  }
`;

export const StyleCheckboxAll = styled(Checkbox)`
  top: -5px;
  left: 8px;
  label {
    &:before {
      width: 30px !important;
      height: 30px !important;
      border-radius: 15px !important;
    }
    &:after {
      transform: scale(1.5) !important;
      color: ${COLORS.red500} !important;
      margin: 7px 0 0 6px;
    }
  }
`;

export const StyleTab1 = styled.button`
  @media ${device.tablet} {
    font-size: 12px !important;
  }
  cursor: pointer;
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  color: ${$darkgrey};
  width: 50%;
  display: inline-block;
  height: 40px;
  background: ${$lightgrey};
  border: ${$border};
  border-radius: ${$radius} 0px 0px 4px;
`;
export const StyleTab2 = styled.button`
  @media ${device.tablet} {
    font-size: 12px !important;
  }
  cursor: pointer;
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  color: ${$darkgrey};
  width: 50%;
  display: inline-block;
  height: 40px;
  background: ${$lightgrey};
  border: ${$border};
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
`;

export const AccessDropdown = styled(Dropdown)`
  &.org-access .menu .item:last-child {
    display: none;
  }
  align-self: center;
  width: 140px !important;
  height: 40px !important;
  padding-left: 23px !important;
  min-height: 40px !important;
  padding-top: -20px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  &:focus {
    background-color: white !important;
  }
  & > .text {
    line-height: 46px !important;
  }
  .menu {
    .item {
      .text {
        padding-left: 6px !important;
        font-family: "Open Sans" !important;
        font-weight: normal !important;
        color: ${$black} !important;
        font-size: 15px !important;
      }
    }
  }
  .text {
    margin-top: -12px !important;
    font-family: "Open Sans" !important;
    font-weight: bold !important;
    font-size: 15px !important;
    color: ${COLORS.red500} !important;
    margin-left: -6px !important;
    line-height: 10px;
    transform: translateY(-5px) !important;
  }
  i.dropdown.icon {
    padding-left: 10px !important;
    top: 23% !important;
    height: 40px !important;
  }
  .dropdown {
    min-height: 40px !important;
  }
  .ui.selection.dropdown {
    line-height: 10px !important;
  }
`;
