import styled from "styled-components";
import { AccordionContent, AccordionTitle } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const CustomAccordionTitle = styled(AccordionTitle)`
  background-color: #231f21;
  color: #fff !important;
  padding: 20px !important;
  display: flex;
  justify-content: space-between;
  h3 {
    font-family: Helvetica, sans-serif !important;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-bottom: 0px;
  }
  &:hover {
    background-color: #0b0a0b;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.blue};
  }
`;

export const CustomAccordionContent = styled(AccordionContent)`
  padding: 20px !important;
  font-family: Helvetica, sans-serif !important;
  color: #231f21;
  font-size: 20px;
  border: 1px solid #231f21;
`;
