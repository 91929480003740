import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { getMixpanel } from "common/api";
import { webUrl } from "common/constants";
import { quickSignUp, sendVerification } from "common/helpers/signup";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { composeValidators, mustBeNumber, mustBeValidEmail, required } from "common/helpers/finalForm";
import { Form, Icon } from "semantic-ui-react";
import { Button } from "component/UI/Button";
import { FieldInputRSTO } from "./FieldInput";

interface IProps {
  store?: Store;
  email: string;
}

const Component = (props: IProps): JSX.Element => {
  const { store, email } = props;
  const [step, setStep] = React.useState(1);

  const onSubmit = async (values) => {
    const { email, first_name, last_name, group_name } = values;
    const mixpanelProps = {
      "Email": email,
      "User Name": `${first_name} ${last_name}`,
      "Organisation Name": group_name,
    };
    const errors: any = {};
    if (step === 1) {
      getMixpanel(store!).track("RSTO Partner Signup > Send Verification", mixpanelProps);
      const verificationError = await sendVerification({ email: values.email });
      if (verificationError) {
        errors[FORM_ERROR] = verificationError;
      } else {
        setStep(2);
      }
    } else {
      getMixpanel(store!).track("RSTO Partner Signup > Create Organisation", mixpanelProps);
      const signupRes = await quickSignUp(values);
      const { data, error } = signupRes;
      if (error) {
        errors[FORM_ERROR] = error;
      } else {
        const token = data.user.token;
        const id = data.user.id;
        const redirect = "/custom/rsto/survey";
        window.location.href = `${webUrl}/login?token=${token}&id=${id}&redirect=${redirect}`;
      }
    }
    return errors;
  };

  React.useEffect(() => {
    getMixpanel(store!).track("Page view", { Page: "RSTO Partner Signup" });
  }, []);

  return (
    <>
      <div className="mb-4">
        <h2 className="text-muted mb-2">Join Seer Data & Analytics</h2>
        <p className="fs-1000 text-muted">Create a free account and get started today.</p>
      </div>
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{ email }}
        render={formRenderProps => {
          const { submitting, handleSubmit, submitError, modifiedSinceLastSubmit } = formRenderProps;
          return (
            <>
              <Form onSubmit={handleSubmit} id="login-ssdc-contributor" className="mb-4">
                <Field
                  label="Organisation Name"
                  name="group_name"
                  component={FieldInputRSTO}
                  validate={composeValidators(required)}
                  helperText="This will be the name of your group. Don't worry, you can change it later."
                  disabled={step === 2}
                />
                <div style={{ height: 10 }}></div>
                <div className="d-flex flex-column flex-xl-row justify-content-between">
                  <div>
                    <Field
                      label="First Name"
                      name="first_name"
                      component={FieldInputRSTO}
                      validate={composeValidators(required)}
                      disabled={step === 2}
                    />
                    <div style={{ height: 10 }}></div>
                  </div>
                  <div>
                    <Field
                      label="Last Name"
                      name="last_name"
                      component={FieldInputRSTO}
                      validate={composeValidators(required)}
                      disabled={step === 2}
                    />
                    <div style={{ height: 10 }}></div>
                  </div>
                </div>
                <Field
                  label="Email"
                  name="email"
                  component={FieldInputRSTO}
                  validate={composeValidators(required, mustBeValidEmail)}
                  helperText="We will email you a temporary code to log in."
                  disabled
                />
                <div style={{ height: 10 }}></div>
                <Field
                  label="Phone Number"
                  name="phone"
                  component={FieldInputRSTO}
                  validate={composeValidators(required, mustBeNumber)}
                  disabled={step === 2}
                />
                <div style={{ height: 10 }}></div>
                <Field
                  label="Verification Code"
                  name="verification_code"
                  component={FieldInputRSTO}
                  validate={step === 1 ? undefined : composeValidators(required)}
                  helperText="Please click the button below to verify your identity and enter the security code once you have received it."
                  disabled={step === 1}
                />
                {submitError && !modifiedSinceLastSubmit && <p className="text-danger fs-0875 mt-1 mb-0">{submitError}</p>}
              </Form>
              <div className="mb-1">
                <Button disabled={submitting} onClick={handleSubmit} icon fluid labelPosition="left" colorConfig="aquaCTA">
                  <Icon name="users" className="ml-2 mr-0" />
                  {step === 1 ? "SEND CODE" : "CREATE ORGANISATION"}
                </Button>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export const PartnerInviteForm = inject("store")(observer(Component));
