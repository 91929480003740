import * as React from "react";
import Select, { components, Props as SelectProps, ValueContainerProps, MultiValueProps } from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface SortableSelectProps extends SelectProps {
  onDragEnd: (result: any) => void;
  isSortDisabled?: boolean;
}

export const SortableSelect = (props: SortableSelectProps) => {
  const { onDragEnd, isSortDisabled = false, components: selectComponents, ...rest } = props;
  // Override "ValueContainer" and "MultiValue" to enable drag and drop functionality
  const components = {
    ...selectComponents,
    ValueContainer: SortableValueContainer(isSortDisabled),
    MultiValue: SortableMultiValue(isSortDisabled),
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Select {...rest} components={components} />
    </DragDropContext>
  );
};

export const SortableValueContainer = (isSortDisabled?: boolean) => (props: ValueContainerProps) => {
  const { hasValue } = props;
  return (
    <Droppable droppableId="category" isDropDisabled={isSortDisabled}>
      {(provided) => (
        <components.ValueContainer {...props}>
          <div
            ref={provided.innerRef}
            style={{ width: "100%", display: "flex", flexWrap: "wrap", alignItems: hasValue ? "start" : "center", flexDirection: hasValue ? "column" : "row" }}
            {...provided.droppableProps}
          >
            {props.children}
            {provided.placeholder}
          </div>
        </components.ValueContainer>
      )}
    </Droppable>
  );
};

export const SortableMultiValue = (isSortDisabled?: boolean) =>  (props: MultiValueProps) => {
  const { data, index } = props;
  return (
    // Convert number to string as "draggableId" must be string
    <Draggable draggableId={(data as any).key.toString()} index={index} isDragDisabled={isSortDisabled}>
      {(provided) => {
        const style = { ...provided.draggableProps.style, minWidth: 0, maxWidth: "100%" };
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={style}
            // This prevents the menu from being opened/closed when the user clicks on a value to begin dragging it
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <components.MultiValue {...props} />
          </div>
        );
      }}
    </Draggable>
  );
};
