import { Modal as SemanticModal, ModalProps as SemanticModalProps } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "component/UI/common";

interface ModalProps extends SemanticModalProps {
}

export const Modal = styled(SemanticModal).attrs((_props: ModalProps): ModalProps => {
  return {};
})<ModalProps>`
  &.ui.modal {
    .header {
      color: ${COLORS.indigo600};
    }
    .actions {
      background: #fff;
    }
  }
`;
