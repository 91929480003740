import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";
import { Container, Button, User, DArrow } from "../../common/styledComponents/elements";
import { H2 } from "../../common/styledComponents/typography";

export const CommentsContainer = styled.div`
  ${Container}
  padding: 0;
  border-radius: 4px;
  background-color: rgba(50, 46, 59, 0);
`;

export const Filter = styled.div`
  border-bottom: 1px solid lightgrey;
  margin: 5px 0 5px 0;
  padding-bottom: 3px;
`;

export const From = styled(H2)`
  font-size: 21px;
  margin: 0;
  display: inline-block;
`;

export const FilterPerson = styled.div`
  position: relative;
  top: 4px;
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
`;

export const Post = styled(Button)`
  float: left;
  margin-left: 50px;
`;

export const AllComments = styled.div`
  margin: 20px 0 -10px 0;
`;

export const CommentInput = styled(TextareaAutosize)`
  min-height: 100px;
  resize: none;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #c3c3c3;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const Responses = styled.div`
  position: relative;
  margin-top: 5px;
`;

export const CommentContainer = styled.div`
  overflow: auto;
`;
export const InputContainer = styled.div`
  display: flex;
`;

export const Response = styled(CommentContainer)`
  width: 95%;
  margin: 0 5%;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
`;

export const AvatarContainer = styled.div<{ owner: boolean }>`
  display: flex;
  justify-content: space-between;
`;

export const ReplyComment = styled(TextareaAutosize)<{ responses?: boolean }>`
  outline: none;
  resize: none;
  background-color: #fcfcfc;
  border: 1px solid #bcbcbc;
  font-size: 15px;
  padding: 10px;
  display: block;
  min-height: 80px;
  position: relative;
  border-radius: 4px;
  width: 100%;
  ${p =>
    p.responses &&
    `
    width: 95%;
    margin-left: 5%;
  `}
`;

export const PostReply = styled(Button)`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  width: 95px;
  height: 40px;
  float: right;
  padding: 0;
  z-index: 11;
  margin-left: 10px;
  position: relative;
  top: 10px;
  border-radius: 4px;
  margin: 0 0 25px 0;
`;

export const CancelReply = styled.button`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  width: 95px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
  color: rgb(145, 145, 145);
  cursor: pointer;
  float: right;
  background-color: rgba(0, 0, 0, 0);
  z-index: 11;
  position: relative;
  top: 10px;
  margin-bottom: 25px;
  &:hover {
    color: #c6344d;
  }
`;

export const Arrow = styled(DArrow)`
  top: ${props => (props.flip ? 14 : 7)}px;
`;

export const CommentAvatar = styled(User)`
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;
