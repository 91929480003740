import * as React from "react";
import { Button, Popup, Icon } from "semantic-ui-react";
import { cloneDeep } from "lodash";
import { Prompt } from "react-router";
import {
  Schema,
  getVariableCombinations,
  transformDataTo2DArray,
  transform2DArrayToData,
  recalculateSummedTotals,
  generateEmpty2DArray,
  isSummedTotal,
} from "common/helpers/dataset";
import { ObjectAny } from "common/helpers/types";
import { compileTableRenderProperties } from "pages/DatasetTemplateTable/includes/TableSchemaPreview";
import { AggregateDataPreviews } from "./AggregateDataPreviews";
import { MultiGrid, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";

// @TODO - rework the logic for getting cell meta like (is summed total) for instance into doing it one time when schema is loaded into a 2d array to match the data indexes for better performance

interface TemplateTableProps {
  schema: Schema;
  rawData?: ObjectAny[] | null;
  handleSave: (_) => any;
  handleCancel: () => any;
  componentDataSubscriber?: (_) => any;
}

const TABLE_BORDER = "1px solid rgba(34,36,38,.1)";

const ROW_HEIGHT = 40;

// Helper function to generate data for a react-virtualized table, including column headers, row headers, and all data points in an array.
const getFullTableData = (schema: Schema, data: string[][]): string[][] => {
  if (!data.length) {
    return [];
  }
  const { rows, columns } = schema;
  const colCombos = getVariableCombinations(columns);
  const rowCombos = getVariableCombinations(rows);
  // Generate fixed rows for "column variables"
  const { skipColumns } = compileTableRenderProperties(schema);
  const colHeaders: string[][] = [];
  for (const col of columns) {
    const eachRow = [col.variable];
    skipColumns.forEach(() => {
      eachRow.push("");
    });
    colCombos.forEach((item) => {
      eachRow.push(item[col.variable]);
    });
    colHeaders.push(eachRow);
  }
  // Generate fixed row for "row variables"
  const rowHeader: string[] = rows.map((row) => row.variable);
  colCombos.forEach(() => {
    rowHeader.push("");
  });
  const fixedHeaders = [...colHeaders, rowHeader];
  // Generate rows for "row variable categories and all data points"
  const dataRows: string[][] = [];
  rowCombos.forEach((row, idx) => {
    const eachRow = [...Object.values(row)];
    eachRow.push(...data[idx]);
    dataRows.push(eachRow as string[]);
  });
  return [...fixedHeaders, ...dataRows];
};

const cache = new CellMeasurerCache({
  fixedHeight: true,
});

export const TemplateTable = (props: TemplateTableProps): JSX.Element => {
  const { schema, rawData, handleSave, handleCancel, componentDataSubscriber } = props;
  const { rows, columns } = schema;

  const [initialData, setInitialData] = React.useState<string[][]>([]);
  const [data, setData] = React.useState<string[][]>([]);

  const colCombos = getVariableCombinations(columns);
  const rowCombos = getVariableCombinations(rows);

  const fullTableData = getFullTableData(schema, data);
  const fixedColumnCount = rows.length; // using "rows.length" as the count of fixed columns, as row categories will be fixed to the left
  const fixedRowCount = columns.length + 1; // using "columns.length + 1" as the count of fixed rows, as column categories will be fixed to the top
  const columnCount = fullTableData[0]?.length || 0;
  const tableHeight = Math.min(fullTableData.length * ROW_HEIGHT, 700);
  // Use useRef to sum up the column widths to calculate the actual table width.
  const tableWidthRef = React.useRef(0);
  const tableWidthCounterRef = React.useRef(1);

  const multiGridRef = React.useRef<any>(null);

  React.useEffect(() => {
    const initialData = transformDataTo2DArray(rawData || generateEmpty2DArray(rowCombos.length, colCombos.length), rowCombos, colCombos);
    setInitialData(initialData);
    setData(initialData);
  }, [rawData]);

  // the reason we split by \n is that we are handling copy/paste from applications like microsoft excel along with normal input entry
  const handleNewValue = (rowIdx, colIdx, newValue, e) => {
    const rows = newValue.split("\n");
    if (rows.length === 0) {
      return;
    }

    const newData = cloneDeep(data);

    rows.forEach((row, relativeRowIdx) => {
      const cols = row.split("\t").map(val => val.trim());
      cols.forEach((value, relativeColIdx) => {
        const computedRowIdx = rowIdx + relativeRowIdx;
        const computedColIdx = colIdx + relativeColIdx;

        // Handle data that would be out of the table bounds
        if (newData.length <= computedRowIdx || newData[0].length <= computedColIdx) {
          return;
        }

        if (isNaN(value) || value.length === 0) {  // not a number or blank value
          newData[computedRowIdx][computedColIdx] = "";
        } else {
          // in case user is trying to enter decimals with more places than js can handle
          let numberString = value.slice(0, 12); // no more than 12 digits (1 trillion - 1) per number to avoid running into issues storing as js number
          if (numberString) {
            numberString = numberString.slice(-1) === "." ? numberString : Number(numberString).toString();
          }
          newData[computedRowIdx][computedColIdx] = numberString;
        }
      });
    });

    recalculateSummedTotals(newData, rowCombos, colCombos, schema); // update summed totals calculations
    setData(newData);

    if (e.type === "paste") {
      e.preventDefault();
    }
  };
  const onSave = () => {
    const newData = transform2DArrayToData(data, rowCombos, colCombos);
    handleSave(newData);
  };
  const onResetAll = () => {
    setData(initialData);
  };
  const hasChanges = JSON.stringify(initialData) !== JSON.stringify(data);

  React.useEffect(() => {
    if (componentDataSubscriber) {
      // share any useful state or variables here with parent components
      componentDataSubscriber({ hasChanges });
    }
  }, [hasChanges]);

  React.useEffect(() => {
    multiGridRef.current.forceUpdateGrids(); // Forcefully re-render when "data" is updated. Reference: https://github.com/bvaughn/react-virtualized?tab=readme-ov-file#public-methods
    cache.clearAll();
  }, [data]);

  const ActionButtons = () => (
    <div>
      <Button onClick={handleCancel} className="mr-3">Cancel</Button>
      <Button onClick={onResetAll} className="mr-3">Reset Changes <Icon name="redo" className="ml-2 mr-0" /></Button>
      <Button className="bg-primary text-white bg-hover-red" onClick={onSave}>Save <Icon name="save" className="ml-2 mr-0" /></Button>
    </div>
  );

  const cellRenderer = (props) => {
    const { key, columnIndex, rowIndex, style: propStyle, parent } = props;
    const value = fullTableData[rowIndex][columnIndex];
    const dataRowIndex = rowIndex - fixedRowCount; // row index of the data point in "data"
    const dataColIndex = columnIndex - fixedColumnCount; // column index of the data point in "data"
    const isSummed = isSummedTotal(dataRowIndex, dataColIndex, rowCombos, colCombos, schema);
    const isDataCell = dataRowIndex >= 0 && dataColIndex >= 0;
    const isBlankRowCell = columnIndex >= rows.length && rowIndex === columns.length;
    let isModified = false;
    if (isDataCell) {
      isModified = value !== initialData[dataRowIndex][dataColIndex];
    }
    const style = {
      ...propStyle,
      borderBottom: TABLE_BORDER,
      borderRight: TABLE_BORDER,
      textWrap: "nowrap",
      backgroundColor: isDataCell && isSummed
        ? "#e5e5e5"
        : isBlankRowCell
          ? "#4e4e4e"
          : propStyle.backgroundColor,
    };
    // Get the actual table width between rerenders
    if (rowIndex === 0 && columnIndex < columnCount && tableWidthCounterRef.current <= columnCount && typeof style.width === "number") {
      tableWidthRef.current = tableWidthRef.current + style.width;
      tableWidthCounterRef.current = tableWidthCounterRef.current + 1;
    }
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        parent={parent}
      >
        <div style={style} className="py-2 px-3">
          {isDataCell ? (
            <Popup
              content={(
                <div>
                  {[...Object.entries(colCombos[dataColIndex]), ...Object.entries(rowCombos[dataRowIndex])].map(([key, value]) => (
                    <p className="m-0 fs-0875 text-nowrap"><b>{key}</b>: {value}</p>
                  ))}
                </div>
              )}
              on="hover"
              mouseEnterDelay={1000}
              position="bottom left"
              trigger={(
                <textarea
                  style={{
                    borderColor: "transparent",
                    backgroundColor: isSummed ? "#e5e5e5" : "transparent",
                    padding: 0,
                    borderRadius: 0,
                    resize: "none",
                    overflowY: "hidden",
                    overflowWrap: "normal",
                    scrollbarWidth: "none",  /* Firefox */
                    msOverflowStyle: "none",  /* IE and Edge */
                    color: isModified ? "#2dc3c3" : "#000",
                    width: "100%",
                  }}
                  value={value}
                  rows={1}
                  disabled={isSummed}
                  onChange={(e) => handleNewValue(dataRowIndex, dataColIndex, e.target.value, e)}
                  onPaste={(e) =>handleNewValue(dataRowIndex, dataColIndex, e.clipboardData.getData("text"), e)}
                />
              )}
            />
          ) : (
            <p>{value}</p>
          )}
        </div>
      </CellMeasurer>
    );
  };

  return (
    <div>
      <Prompt message="You will lose all your unsaved changes." when={hasChanges} />
      <div className="mb-4">
        <ActionButtons />
      </div>
      <div className="mb-4" style={{ height: tableHeight }}>
        <AutoSizer>
          {({ width }) => {
            // Display the table at its actual width if it is smaller than the container width.
            const tableWidth = tableWidthCounterRef.current > columnCount ? Math.min(width, tableWidthRef.current) : width;
            return (
              <MultiGrid
                ref={multiGridRef}
                cellRenderer={cellRenderer}
                fixedColumnCount={fixedColumnCount}
                fixedRowCount={fixedRowCount}
                columnCount={columnCount}
                rowCount={fullTableData.length}
                columnWidth={cache.columnWidth}
                rowHeight={ROW_HEIGHT}
                deferredMeasurementCache={cache}
                width={tableWidth}
                height={tableHeight}
                enableFixedColumnScroll
                enableFixedRowScroll
                style={{
                  border: TABLE_BORDER,
                  fontSize: 14,
                }}
                styleTopLeftGrid={{
                  backgroundColor: "#5f2d5f",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                styleTopRightGrid={{
                  backgroundColor: "#919191",
                  color: "#fff",
                  fontWeight: "bold",
                  overflow: "hidden",
                }}
                styleBottomLeftGrid={{
                  backgroundColor: "#919191",
                  color: "#fff",
                  fontWeight: "bold",
                  overflow: "hidden",
                }}
                styleBottomRightGrid={{
                  backgroundColor: "#fff",
                }}
                hideTopRightGridScrollbar
                hideBottomLeftGridScrollbar
                overscanColumnCount={10}
                overscanRowCount={10}
              />
            );
          }}
        </AutoSizer>
      </div>
      <ActionButtons />
      <AggregateDataPreviews parentSchema={schema} parentData={rawData || []} />
    </div>
  );
};
