import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Dropdown, Grid, Icon, Label } from "semantic-ui-react";
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from "common/helpers/chart";
import { Link } from "react-router-dom";
import { linkStyling, StyledHeaderDropdown } from "./includes/header.style";
import { PremiumIcon } from "./includes/PremiumIcon";

export const StyledMegaMenuLink = styled.div`${linkStyling}`;

const MegaMenuItemWrapper = (props) => (
  <div className="p-3 cursor-pointer bg-hover-light-purple rounded-2">
    {props.render()}
  </div>
);

const MegaMenuItemLeft = (props) => {
  const { comingSoon, to, heading, description, ...rest } = props;
  if (comingSoon) {
    return (
      <div className="p-3 rounded-2">
        <div className="d-flex align-items-center mb-1">
          <p className="fs-1000 fw-600 mr-2 mb-0" style={{ color: CHART_PRIMARY_COLOR }}>{heading}</p>
          <Label size="mini" className="bg-info text-white ml-2">Coming Soon</Label>
        </div>
        <p className="fs-0875 text-wrap" style={{ color: CHART_SECONDARY_COLOR }}>{description}</p>
      </div>
    );
  }
  return (
    <Link {...rest} to={to}>
      <MegaMenuItemWrapper render={() => (
        <>
          <p className="fs-1000 fw-600 mb-1" style={{ color: CHART_PRIMARY_COLOR }}>{heading}</p>
          <p className="fs-0875 text-wrap" style={{ color: CHART_SECONDARY_COLOR }}>{description}</p>
        </>
      )} />
    </Link>
  );
};

export const DatasetsMegaMenuComponent = (props) => {
  const { store } = props;
  const showPremiumIconSSDC = (!store!.hasGroupAddOn("Self-service Data Ingestion") || store!.userPlan === "Plus" || store!.userPlan === "Unlimited");

  return (
    <StyledHeaderDropdown
      icon={<></>}
      trigger={(
        <StyledMegaMenuLink>
          <span>Datasets</span>
          <Icon name="angle down" className="mr-0 ml-1" />
        </StyledMegaMenuLink>
      )}
      className="d-none d-md-block"
    >
      <Dropdown.Menu>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column className="mb-0" style={{ maxWidth: 320 }}>
              {/* <MegaMenuItemLeft comingSoon heading="Open Datasets" description="Explore all open datasets in the Seer platform" /> */}
              <MegaMenuItemLeft
                id="userflow-element-header-datasets-ssdc"
                heading="Self-service Dataset Collection"
                description={(
                  <>
                    Collect and ingest your own data into the Seer platform
                    {showPremiumIconSSDC && <PremiumIcon className="ml-2" />}
                  </>
                )}
                to="/datasets"
              />
              <MegaMenuItemLeft heading="Data Catalog" description="See what information is available in your datasets" to="/catalog" />
            </Grid.Column>
            <Grid.Column className="mb-0">
              <Link to="/datasets">
                <MegaMenuItemWrapper render={() => (
                  <>
                    <img src="/assets/ssdc_feature_image.png" alt="Self-service Data Collection" className="mb-1" />
                    <div style={{ maxWidth: 260 }}>
                      <div className="d-flex align-items-center mb-1">
                        <p className="fs-1000 fw-600 mb-0" style={{ color: CHART_PRIMARY_COLOR }}>Self-service Dataset Collection</p>
                        {showPremiumIconSSDC && <PremiumIcon className="ml-2" />}
                      </div>
                      <p className="fs-0875 text-wrap" style={{ color: CHART_SECONDARY_COLOR }}>Collect and ingest your own data into the Seer platform</p>
                    </div>
                  </>
                )} />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Dropdown.Menu>
    </StyledHeaderDropdown>
  );
};

export const DatasetsMegaMenu = inject("store")(observer(DatasetsMegaMenuComponent));
