import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { Builder } from "common/store/builder";
import { Grid, Icon } from "semantic-ui-react";

import {
  CDecimal,
  CPercent,
  CButton,
  CTextArea,
  CForm,
  CButtonDark,
} from "./insightBuilderStyles/table.style";
import { COLORS } from "component/UI/common";

type PropsType = RouteComponentProps<{}> & {
  store: Builder;
};

export const Calculator = withRouter(
  inject("store")(
    observer(
      class Calculator extends React.Component<PropsType> {
        store = this.props.store;

        render() {
          const { calc } = this.store;

          return (
            <div className="w-100">
              <CForm className="mb-3 position-relative">
                <CTextArea
                  value={calc.eqString}
                  rows={2}
                  placeholder="Type or click to start"
                  // remove any whitespace chars from the string before applying
                  onChange={e => calc.eqString = (e.target.value || "").replace(/\s/g, "")}
                />
                {calc.eqString && (
                  <Icon name={this.store.calcPossible ? "check circle" : "times circle"} size="small" className={`position-absolute ${this.store.calcPossible ? "text-info" : "text-primary"}`} style={{ top: 17, right: 5 }} />
                )}
              </CForm>
              <div className="mb-3">
                <CDecimal
                  selected={calc.type === "Decimal"}
                  onClick={() => (calc.type = "Decimal")}
                >
                  0.0
                </CDecimal>
                <CPercent
                  selected={calc.type === "Percent"}
                  onClick={() => (calc.type = "Percent")}
                >
                  %
                </CPercent>
              </div>
              <Grid columns="equal" style={{ marginLeft: -7, marginRight: -7 }}>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => (calc.eqString = "")}>AC</CButtonDark>
                  </Grid.Column>
                  {["(", ")"].map(s => (
                    <Grid.Column className="px-2">
                      <CButtonDark key={s} onClick={() => calc.eqString += s}>
                        {s}
                      </CButtonDark>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => calc.eqString += "/"}>
                      <i className="fas fa-divide"></i>
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  {["7", "8", "9"].map(s => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => calc.eqString += s}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => calc.eqString += "*"}>
                      <i className="fas fa-times"></i>
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  {["4", "5", "6"].map(s => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => calc.eqString += s}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => calc.eqString += "-"}>
                      <i className="fas fa-minus"></i>
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  {["1", "2", "3"].map(s => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => calc.eqString += s}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => calc.eqString += "+"}>
                      <i className="fas fa-plus"></i>
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Grid.Column className="px-2">
                    <CButton onClick={() => (calc.eqString = calc.eqString.slice(0, -1))}>
                      <i className="fas fa-backspace"></i>
                    </CButton>
                  </Grid.Column>
                  {["0", "."].map(s => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => calc.eqString += s}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButton
                      style={{
                        color: "white",
                        border: "none",
                        background: `${this.store.calcPossible ? COLORS.red500 : "#919191"}`,
                      }}
                      onClick={() => this.store.calcPossible && this.store.doCalc()}
                    >
                      =
                    </CButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          );
        }
      }
    )
  )
);
