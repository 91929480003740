import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import * as qs from "qs";
import { Form, Icon } from "semantic-ui-react";
import { ISODateToLocalDate } from "common/helpers/dataset";
import { Avatar } from "component/Avatar";
import Store from "common/store";
import { COLORS } from "component/UI/common";
import { Button } from "component/UI/Button";
import { DashboardAction } from "common/store/dashboard";
import { PillButton } from "./components";
import { ActionInput, ActionInputSecondary, ActionTextarea } from "./Collaborate.style";
import { pad } from "common/helpers/string";
import { useLocation } from "react-router-dom";
import { StylePopup } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";

interface Props {
  userName: string;
  avatar: string ;
  onCancel: () => void;
  onSave: (actionBody: Partial<DashboardAction>) => void;
  action?: DashboardAction;
  enablePastDates?: boolean;
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { userName, avatar, onCancel, onSave, action, enablePastDates, store } = props;
  const { title, outcome, dashboard_reference, description, due_date } = action || {};
  const { dashboard: dashboardStore } = store!;
  const { dashboardSelection } = dashboardStore;
  const location = useLocation();
  const { search } = location;
  const { fullscreen } = qs.parse(search.slice(1) || "");
  const isFullscreen = fullscreen === "true";

  const [showLinkInsight, setShowLinkInsight] = React.useState(false);
  const [currentTitle, setCurrentTitle] = React.useState(title);
  const [currentOutcome, setCurrentOutcome] = React.useState(outcome);
  const [currentDescription, setCurrentDescription] = React.useState<any>(description);
  const [currentDueDate, setCurrentDueDate] = React.useState(due_date);

  const today = new Date();
  const todayDate = `${today.getFullYear()}-${pad(2, "0")(today.getMonth() + 1)}-${pad(2, "0")(today.getDate())}`;

  const saveDisabled = !currentTitle;

  const onSaveClick = () => {
    const actionBody = {
      title: currentTitle,
      outcome: currentOutcome,
      dashboard_reference: dashboardSelection,
      description: currentDescription,
      due_date: currentDueDate,
    };
    onSave(actionBody);
  };

  const onCancelClick = () => {
    onCancel();
    dashboardStore.setDashboardSelection(dashboard_reference || null);
  };

  React.useEffect(() => {
    dashboardStore.setSelectionOverlay("select");
    dashboardStore.setDashboardSelection(dashboard_reference || null);
  }, []);

  return (
    <div className="bg-white p-4 mb-3 rounded-3" style={{ border: `1px solid ${COLORS.blue}`}}>
      {showLinkInsight ? (
        <>
          <div className="d-flex mb-3">
            <p className="fs-1000 fw-700 mr-2" style={{ color: COLORS.aqua600 }}>Select Content</p>
            <Icon name="tag" style={{ color: COLORS.aqua600 }} />
          </div>
          <p className="fs-1000 mb-3">
            Select content from dashboard on the left by hovering over insights and clicking “Save”.
          </p>
          <div className="d-flex mt-2 justify-content-end">
            <PillButton size="small" color="red" label="Cancel" onClick={() => {
              setShowLinkInsight(false);
              dashboardStore.setDashboardSelection(null);
            }} />
            <PillButton size="small" disabled={!dashboardSelection} color="aqua" label="Save" onClick={() => {
              setShowLinkInsight(false);
            }} />
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between mb-3">
            <Avatar
              className="cursor-pointer mr-2"
              color={COLORS.indigo500}
              image={avatar}
              name={userName}
              size={25}
            />
            <StylePopup
              trigger={(
                <div>
                  {dashboardSelection ? (
                    <Button
                      colorConfig="red"
                      disabled={isFullscreen}
                      onClick={() => dashboardStore.setDashboardSelection(null)}
                    >
                      Unselect Content <Icon name="minus" className="ml-2 mr-0" />
                    </Button>
                    ) : (
                      <StylePopup
                        trigger={(
                          <div>
                            <Button
                              colorConfig="aqua"
                              disabled={isFullscreen}
                              onClick={() => setShowLinkInsight(true)}
                            >
                              Select Content <Icon name="tag" className="ml-2 mr-0" />
                            </Button>
                          </div>
                        )}
                        content="Click here to tag the content relating to this action."
                        position="bottom right"
                        offset={[-60, 0]}
                        size="mini"
                        inverted
                      />
                  )}
                </div>
              )}
              disabled={!isFullscreen}
              content="This feature is disabled in full screen mode"
              position="bottom right"
              size="mini"
              offset={[-40, 0]}
              inverted
            />
          </div>
          <Form>
            <Form.Field>
              <div className="mb-1">
                <label className="fs-1000 fw-700" style={{ color: COLORS.indigo600 }}>What are you planning to do?</label>
              </div>
              <ActionInput
                placeholder="Type action title e.g. Weekly newsletter"
                value={currentTitle}
                onChange={(e) => setCurrentTitle(e.target.value)}
              />
            </Form.Field>
              <Form.Field>
              <div className="mb-1">
                <label className="fs-1000 fw-700 d-inline" style={{ color: COLORS.grey700 }}>What are you hoping to accomplish?</label><span style={{ color: COLORS.grey600 }}> (optional)</span>
              </div>
              <ActionInputSecondary
                placeholder="Your target outcome e.g. Increase participation"
                value={currentOutcome}
                onChange={(e) => setCurrentOutcome(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <div className="mb-1">
                <label className="fs-1000 fw-700 d-inline" style={{ color: COLORS.grey700 }}>What are the details?</label><span style={{ color: COLORS.grey600 }}> (optional)</span>
              </div>
              <ActionTextarea
                placeholder="Detailed description e.g. Include photos and stories from the week"
                value={currentDescription}
                onChange={(e) => setCurrentDescription(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <div className="mb-1">
                <label className="fs-1000 fw-700 d-inline" style={{ color: COLORS.grey700 }}>Review Date</label><span style={{ color: COLORS.grey600 }}> (optional)</span>
              </div>
              <ActionInputSecondary
                type="date"
                min={enablePastDates ? "" : todayDate}
                value={currentDueDate ? ISODateToLocalDate(currentDueDate as string) : ""}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => setCurrentDueDate(e.target.value)}
              />
            </Form.Field>
          </Form>
          <div className="d-flex mt-3 justify-content-end">
            <PillButton size="large" color="red" label="Cancel" className="mr-2" onClick={onCancelClick} />
            <PillButton size="large" disabled={saveDisabled} color="aqua" label={action ? "Update" : "Create"} onClick={onSaveClick} />
          </div>
        </>
      )}
    </div>
  );
};

export const AddAndEditAction = inject("store")(observer(Component));
