import { observable, action, computed } from "mobx";
import Insight from "./insight";
import { database, getMixpanel } from "../api";

interface ICommentResponse {
  id: number;
  user_id: number;
  avatar: string;
  first_name: string;
  last_name: string;
  message: string;
  created_at: string;
  edited: number;
  edited_at: string;
  thread_id: number;
  reply_to: number;
  needsanswer: number;
  answered: number;
}

export default class Comments {
  parent: Insight;

  @observable type: "suitcases" | "insights" = "insights";
  @observable message: string = "";
  @observable threads: string[] = ["Insight Overall", "Charts"];
  @observable ids = { edit: -1, reply: -1, like: -1, filterUser: -1 };
  @observable activeThread: number = 0;
  @observable editMessage: string = "";
  @observable replyMessage: string = "";
  @observable filter: string = "";
  maxDepth: number = 8; // Maxium depth for indented comments

  constructor(parent: Insight) {
    this.parent = parent;
  }

  @computed get tComments() {
    // Where to get comments from
    return this.type === "suitcases" ? this.parent.parent.suitcase.active!.comments : this.parent.result.comments;
  }

  @computed get id() {
    return this.type === "suitcases" ? this.parent.parent.suitcase.active!.id : this.parent.result.id;
  }

  @computed get comments() {
    return this.tComments.filter(
      comment =>
        (comment.thread_id === this.activeThread && // Correct thread
          (this.ids.filterUser === -1 || this.ids.filterUser === comment.user_id) && // Correct filtered Users
          comment.message.toLowerCase().includes(this.filter.toLowerCase())) || // Matches search filter
        (comment.thread_id === this.activeThread && comment.reply_to !== null)
    ); // And replies to those comments
  }

  @computed get mainComments() {
    return this.comments.filter(comment => comment.reply_to === null);
  }

  @action editComment(message: string, i: number) {
    this.ids.edit = i;
    this.editMessage = message;
  }

  @action async updateComment(comment: ICommentResponse) {
    if (this.editMessage.length) {
      await database.put(`comments/${comment.id}`, { message: this.editMessage }, this.parent.parent.token!);
      this.ids.edit = -1;
      this.getComments(this.id);
    }
  }

  @action openReply(id: number) {
    this.ids.reply = id;
    window.setTimeout(() => {
      document.getElementById(`reply${id}p`)!.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
      document.getElementById(`reply${id}`)!.focus();
    }, 1);
  }

  @action async postReply(id: number) {
    if (this.replyMessage.length) {
      await database.post(
        `${this.type}/${this.id}/comments`,
        {
          message: this.replyMessage,
          thread_id: this.activeThread,
          reply_to: id,
        },
        this.parent.parent.token!
      );
      getMixpanel(this.parent.parent).track("Reply to comment", { Type: this.type });
      this.ids.reply = -1;
      this.getComments(this.id);
    }
  }

  getReplies(id: number) {
    return this.comments.filter(comment => comment.reply_to === id);
  }

  @action async addComment() {
    if (!this.message.length) {
      return;
    }
    const res: any = await database.post<ICommentResponse>(
      `${this.type}/${this.id}/comments`,
      {
        message: this.message,
        thread_id: this.activeThread,
        reply_to: null,
      },
      this.parent.parent.token!
    );
    const comment = res.body.data.comment;
    Object.assign(comment, {
      first_name: this.parent.parent.user!.first_name,
      last_name: this.parent.parent.user!.last_name,
      color: "blue",
    });
    getMixpanel(this.parent.parent).track("Add comment", { Type: this.type });
    if (this.type === "suitcases") {
      this.parent.parent.suitcase.active!.comments = [...this.parent.parent.suitcase.active!.comments, comment];
    } else {
      this.parent.result.comments = [...this.parent.result.comments, comment];
    }
    this.message = "";
  }

  @action deleteComment(id: number) {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      database.delete(`comments/${id}`, "", this.parent.parent.token!);
      this.getComments(this.id);
    }
  }

  @action async getComments(id: number) {
    if (this.type === "insights") {
      const res: any = await database.get(`insights/${id}`, "", this.parent.parent.token!);
      this.parent.result.comments = res.body.data.insight.comments;
    } else {
      // reload suitcase to get updated comments
      this.parent.parent.suitcase.getSuitcases(id);
    }
  }
}
