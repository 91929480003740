import * as React from "react";
import { Heading } from "common/styledComponents/elements";
import { IIndicatorModuleData, IndicatorModule } from "component/Modules/IndicatorModule";
import { IndicatorSummaryModule, SummaryChart } from "component/Modules/IndicatorSummaryModule";
import { Button, Icon } from "semantic-ui-react";
// import image from "assets/images/rsto_square_bg_02.png";

export interface IRestackingDashboardModule {
  heading: string;
  subheading: string;
  description: string;
  strategyLink: string;
  indicatorModuleData: IIndicatorModuleData[];
  hideSummary?: boolean;
  summaryCharts?: SummaryChart[];
}

const FEEDBACK_FORM_LINK = "https://seerdata-company.monday.com/boards/4632112814/views/103871146";

export const RestackingDashboardModule = (props: IRestackingDashboardModule) => {


  const { heading, subheading, description, strategyLink, indicatorModuleData, hideSummary, summaryCharts } = props;

  // const heading = "Quantity";
  const icons = {
    "Quantity": "users",
    "Quality": "",
    "Participation": "",
  };

  // @TODO - remove this won't be needed when it's used on an actual dashboard - this code was copied from src/app/pages/Dashboard/Dashboard.tsx:230
  React.useEffect(() => {
    // message handler for update embedded insight heights
    const messageHandler = (event: MessageEvent) => {
      if (event.origin === window.origin && event.data?.iframeHeight) {
        const insightIframes: any = document.querySelectorAll("iframe.seer-dashboards-insight");
        for (const frame of insightIframes) {
          if (event.source === frame.contentWindow) {
            frame.parentElement.style.height = event.data.iframeHeight + "px";
            break;
          }
        }
      }
    };
    window.addEventListener("message", messageHandler, false);
    return () => window.removeEventListener("message", messageHandler, false); // remove event listener on unmount
  }, []);

  return (
    <div className="bg-light py-5" style={{
      backgroundRepeat: "repeat-x",
      backgroundImage: "url(/assets/images/rsto_square_bg_04.png)",
      backgroundSize: "contain",
      marginTop: "-90px",
    }}>
      <div className="d-flex justify-content-center">
        <div className="bg-white h-100 rounded-4 p-5 mx-4" style={{ width: "85%" }} id="u_content_heading_1">
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <Heading className="fs-2500 fw-400 mb-2" style={{ fontFamily: "Bebas Neue", textTransform: "uppercase", color: "#65C4DB" }}>
                {heading}
              </Heading>
              <p className="fs-1500 mb-0" style={{ fontFamily: "Bebas Neue", textTransform: "uppercase" }}>
                {subheading}
              </p>
              <Heading className="fs-1000 mt-0">
                {description} <a target="_blank" href={strategyLink} style={{ color: "#65C4DB" }}>Learn more</a>
              </Heading>
            </div>
            {/* <div className="w-35 text-right">
              <p className="fs-1000">Understand how your service is tracking against Restacking the Odds Strategy 2 - Early Childhood Education & Care. Explore data trends and changes against key dimensions of Quantity, Quality, and Participation and the individual indicators within. Collaborate and learn with your team for process and service delivery improvements, integrate with other dashboards, create reports to share with stakeholders.</p>
            </div> */}
            <div className="d-flex flex-column justify-content-between align-items-end">
              <img src="/assets/images/RSTO_Logomark_FullColour.png" width={100} />
              <Button as="a" href={FEEDBACK_FORM_LINK} target="_blank" className="w-100 text-left px-3 text-white" style={{ backgroundColor: "#84d1de" }} >
                <div className="d-flex">
                  <Icon name="clipboard outline" size="large" className="mr-2 mt-1" />
                  <div>
                    <span>Feedback</span>
                    <p className="fs-0875 fw-400 mt-1">Leave dashboard feedback</p>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {hideSummary !== true && summaryCharts && (
        <IndicatorSummaryModule summaryCharts={summaryCharts} />
      )
      }
      <div className="d-flex justify-content-center">
        <div className="text-white rounded-4 p-2 mt-5 mb-3" style={{ width: "85%", backgroundColor: "#f68d3a" }} id="u_content_indicator_heading_1">
          <p className="fs-1500 mb-0 text-center" style={{ fontFamily: "Bebas Neue", textTransform: "uppercase" }}>
            Indicator Modules
          </p>
        </div>
      </div>

      {indicatorModuleData.map((d) => (<IndicatorModule data={d} />))}
    </div>
  );

};
