import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";
import { $black, $radius, $opensans, $white, $shadow } from "../../common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c3c3c3;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const CommentsHeading = styled.h1`
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 30px;
  color: ${COLORS.indigo600};
  line-height: 41px;
  display: flex;
  margin-bottom: 15px;
  margin-top: 100px !important;
`;

export const Member = styled.span<{ owner?: boolean }>`
  margin-right: ${props => (props.owner ? 0 : 5)}px;
`;

export const EditName = styled.div<{ desc?: boolean }>`
  font-size: ${props => (props.desc ? 15 : 30)}px;
`;

export const Delete = styled.button`
  position: relative;
  top: -25px;
  margin: 10px 0 10px 10px;
  cursor: pointer;
  display: inline-block;
  line-height: 0 !important;
  padding: 11px 15px;
  text-align: center;
  color: ${COLORS.red500};
  font-weight: normal;
  font-size: 15px;
  border: 1px solid #c3c3c3;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  background: #f5f5f5;
  &:hover {
    background: white;
  }
`;
export const VerticalDash = styled.div`
  display: inline-block;
  width: 3px;
  height: 30px;
  background-color: #c3c3c3;
  margin-left: 19px;
`;
export const StyleNameContainer = styled.div`
  display: flex;
`;

export const Name = styled.h3`
  // display: absolute; (imagine someone meant position here?)
  left: 100px;
  color: ${COLORS.indigo600};
  display: inline-block;
  margin-top: -30px;

  margin: 0;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

export const StyleSuitaseType = styled.p`
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${COLORS.indigo600};
  margin: 0;
`;
export const StyleMemberLength = styled.p`
  font-family: "Open Sans";

  font-weight: normal;
  font-size: 15px;
  color: #2d2d2d;
`;

export const StyActionsDropdown = styled(Dropdown)`
  width: 140px !important;
  min-width: 140px !important;
  height: 40px !important;
  padding-left: 23px !important;
  min-height: 40px !important;
  padding-top: -20px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  &:focus {
    background-color: white !important;
  }
  .menu {
    .item {
      .text {
        font-family: "Open Sans" !important;
        font-weight: normal !important;
        color: ${$black} !important;
        font-size: 15px !important;
      }
    }
  }
  .text {
    font-family: "Open Sans" !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    color: ${COLORS.red500} !important;
  }
  i.dropdown.icon {
    padding-left: 10px !important;
    top: 23% !important;
    height: 40px !important;
  }
  .dropdown {
    min-height: 40px !important;
  }
  .ui.selection.dropdown {
    line-height: 10px !important;
  }
`;

export const StyleShareButton = styled.button`
  cursor: pointer;
  width: auto;
  height: 40px;
  border-radius: ${$radius};
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${$white};
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 20px;
`;
