import styled from "styled-components";
import { Table } from "semantic-ui-react";
import { $darkgrey, $lightgrey } from "common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

export const StyleTable = styled(Table)`
  border-width: 0px !important;
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
  background: none !important;
  tr td:first-child {
    border-top-left-radius: 4px; !important;
    border-bottom-left-radius: 4px; !important;
  };
  tr td:last-child {
    border-top-right-radius: 4px; !important;
    border-bottom-right-radius: 4px; !important;
  };
  tr td:first-child {
    border-top-left-radius: 4px; !important;
    border-bottom-left-radius: 4px; !important;
  };
  tr td:last-child {
    border-top-right-radius: 4px; !important;
    border-bottom-right-radius: 4px; !important;
  };
`;

export const StyleTableHeader = styled(Table.Header)`
  font-size: 12px !important;
  th {
    color: ${$darkgrey} !important;
    background: ${$lightgrey} !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  };
  tr {
    box-shadow: none !important;
    cursor: none !important;
  }
`;

export const StyleTableHeaderCell = styled(Table.HeaderCell)`
  padding-left: 15px !important;
`;

export const StyleTableRow = styled(Table.Row)<{ highlight?: string}>`
  background: #ffffff;
  cursor: pointer !important;
  th {
    border: none !important;
  };
  td {
    ${p => (p.highlight === "true" ? `border-width: 1px !important; border-style: solid !important; border-color: ${COLORS.indigo600} !important; border-left: 0px !important; border-right: 0px !important;` : "border: solid 1px #ffffff !important;")}
    .UserAvatar {
      cursor: pointer !important;
    };
  };
  td:first-of-type {
    ${p => (p.highlight === "true" ? "border-left: 1px !important; border-style: solid !important;" : null)}
  };
  td:last-of-type {
    ${p => (p.highlight === "true" ? `border-right: 1px !important; border-style: solid !important; border-color: ${COLORS.indigo600} !important;` : null)}
  };
`;

export const StyleTableCellHeading = styled(Table.Cell)`
  font-size: 18px !important;
  font-weight: bold !important;
  color: ${COLORS.indigo600};
`;

export const StyleTableCellText = styled(Table.Cell)`
  font-size: 16px !important;
  font-weight: normal !important;
  color: ${$darkgrey};
`;
