import * as React from "react";
import { inject } from "mobx-react";
import Store from "common/store";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "component/UI/Button";
import { Icon } from "semantic-ui-react";

type PropsType = {
  store?: Store;
  fileHandler: Function;
};

export const FileUploader = inject("store")(observer((props: PropsType) => {
  const store = props.store!;
  const fileHandler = props.fileHandler;

  const [input, setInput] = useState<HTMLInputElement | null>(null);

  const types = {
    // ppt: "powerpoint",
    // pptx: "powerpoint",
    // doc: "word",
    // docx: "word",
    xlsx: "excel",
    xls: "excel",
    csv: "excel",
    // png: "image",
    // jpg: "image",
    // jpeg: "image",
    // gif: "image",
    // mp4: "video",
    // avi: "video",
    // mov: "video",
    // mkv: "video",
    // pdf: "pdf",
  };

  return (
    <div className="d-flex justify-content-center">
      <Button
        icon
        labelPosition="left"
        colorConfig="aquaCTA"
        style={store.user!.group.expired ? { cursor: "not-allowed" } : {}}
        onClick={() => store.user!.group.expired ? null : document.getElementById("fileUpload")!.click()}
      >
        <Icon name="paperclip" />
        Upload files
      </Button>
      <input
        style={{ display: "none" }}
        id="fileUpload"
        type="file"
        accept={Object.keys(types).map(t => `.${t}`).join(",")}
        onChange={() => {
          input?.files && fileHandler(Array.from(input.files));
        }}
        ref={ref => (setInput(ref))}
        multiple
      />
    </div>
  );
}));
