import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { SectionHeader } from "./SectionHeader";
import { Grid, GridColumn, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "component/UI/common";
import { Link } from "react-router-dom";
import { DASHED_LINE_STYLE } from "component/EmptyState/EmptyState";
import { Button } from "component/UI/Button";
import Store from "common/store";
import { getMixpanel } from "common/api";

export const StyledLink = styled(Link)`
  color: ${COLORS.blue};
  font-weight: 400;
  font-size: 16px;
  &:hover {
    color: ${COLORS.blue};
  }
  @media screen and (max-width: 767px) {
    margin: 0 -1rem !important;
    display: block; // Workaround to fix the 2nd line of wrapped text not aligned with the 1st line
  }
`;

const StyledGridColumn = styled(GridColumn)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`;

const LINKS = [
  { text: "Set up your profile in Settings", link: "/settings/account" },
  { text: "What open data sources are available in Seer Data Platform?", link: "https://knowledge.seerdata.ai/what-data-can-be-found-or-added-to-seer" },
  { text: "Add brand colour palettes", link: "/settings/org/palettes" },
  { text: "What is Self Service Data Collection?", link: "https://knowledge.seerdata.ai/what-is-self-service-data-collection" },
  { text: "View your Organisation's current plan", link: "/settings/org" },
  { text: "What is an Insight?", link: "https://knowledge.seerdata.ai/what-is-an-insight" },
];

interface Props {
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { store } = props;
  return (
    <div className="mb-5">
      <SectionHeader heading="Helpful Links" />
      <Grid columns={2} stackable className="mb-5">
        {LINKS.map((item, idx) => {
          const { text, link } = item;
          return (
            <StyledGridColumn key={idx}>
              <StyledLink to={{ pathname: link }} target="_blank">{text}</StyledLink>
            </StyledGridColumn>
          );
        })}
      </Grid>
      <div className="bg-white py-5 px-2 d-flex flex-column align-items-center" style={{ backgroundImage: `url("${DASHED_LINE_STYLE}")`, borderRadius: 8 }}>
        <div className="d-flex flex-column align-items-center px-3" style={{ maxWidth: 500 }}>
          <div className="d-flex align-items-center justify-content-center mb-4" style={{ backgroundColor: COLORS.indigo100, height: 50, width: 50, borderRadius: "50%" }}>
            <Icon name="info circle" className="text-secondary mr-0" style={{ marginTop: -4 }} />
          </div>
          <p className="fs-1500 fw-700 mb-4 text-center" style={{ color: COLORS.grey800 }}>Have other questions on your mind?</p>
          <div className="d-flex flex-column flex-sm-row justify-content-center w-100">
            <Button
              colorConfig="purpleInverted"
              className="mr-0 mr-sm-3 mb-2 mb-sm-0"
              onClick={() => {
                window.open("https://knowledge.seerdata.ai/", "_blank");
                getMixpanel(store!).track("Go to Knowledge Base Center", { Page: "Homepage" });
              }}
            >
              Support resources
            </Button>
            <Button
              icon
              colorConfig="aquaCTA"
              onClick={() => {
                window.open("https://seerdata.ai/contact/", "_blank");
                getMixpanel(store!).track("Go to Contact Us", {Page: "Homepage" });
              }}
            >
              Contact us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HelpfulLinks = inject("store")(observer(Component));
