import * as React from "react";
import { Dropdown, Tab } from "semantic-ui-react";
import styled from "styled-components";
import { $opensans } from "common/styledComponents/_config.style";
import { TypeTooltip } from "./widgets";
import { whens, wheres } from "common/helpers/data";
import { CustomAutoSuggest } from "./CustomAutosuggest";
import { COLORS } from "component/UI/common";

interface IAddVariableTab {
  fieldMeta: any;
  qsVariables: any;
  resetForm: () => void;
  handleSubmit: () => any;
}

const StyleVariableTab = styled(Tab)`
  .ui.menu {
    font-family: ${$opensans};
    font-size: 18px !important;
  };
  a.item {
    color: #7f7f7f !important;
    font-weight: bold !important;
    padding: 20px !important;
  };
  a.active {
    color: ${COLORS.red500} !important;
  };
  .tab {
    padding: 20px;
    min-height: 260px;
  };
`;

export const AddVariableTab = ({ fieldMeta, qsVariables, resetForm, handleSubmit }: IAddVariableTab): JSX.Element | null => {
  const [ selectedItem, setSelectItem ] = React.useState<any>(undefined);
  const [ variableOptions, setVariableOptions ] = React.useState<any>(undefined);

  if (!fieldMeta) {
    return null;
  }

  return (
    <StyleVariableTab
      onTabChange={() => {
        resetForm();
        setVariableOptions(undefined);
      }}
      panes={[
        {
          menuItem: "Select a variable",
          render: () => (
            <Tab.Pane>
              <p className="fs-1125">Select the variable from our Seer Data Platform.</p>
              <p className="text-secondary fs-1125 fw-600 my-2">1. Select Location<TypeTooltip type="Where" icon="info circle" className="mx-0" /> or Time<TypeTooltip type="When" icon="info circle" className="mx-0" /></p>
              <Dropdown
                fluid
                scrolling
                selection
                placeholder="Select..."
                options={[
                  { key: "Where", text: "Location - Location based variables", value: "Where" },
                  { key: "When", text: "Time - Time based variables", value: "When" },
                ]}
                onChange={(_, { value }) => {
                  resetForm();
                  setSelectItem(value);
                  if (value === "Where") {
                    setVariableOptions(wheres);
                  } else {
                    setVariableOptions(whens);
                  }
                }}
                error={(fieldMeta.error || fieldMeta.submitError) && fieldMeta.touched && !selectedItem}
              />
              <p className="text-secondary fs-1125 fw-600 mt-3 mb-2">2. Select a variable</p>
              <Dropdown
                fluid
                scrolling
                selection
                placeholder="Select..."
                disabled={!variableOptions}
                options={variableOptions ? variableOptions.map(option => ({ key: option, text: option, value: option })) : []}
                onChange={(_, { value }) => {
                  resetForm();
                  fieldMeta.change(value);
                  fieldMeta.focus();
                  handleSubmit();
                }}
                error={(fieldMeta.error || fieldMeta.submitError) && fieldMeta.touched}
              />
              {
                ((fieldMeta.error || fieldMeta.submitError) && fieldMeta.touched) &&
                <p className="text-primary mt-2">{fieldMeta.error || fieldMeta.submitError}</p>
              }
            </Tab.Pane>
          ),
        },
        {
          menuItem: "Search or create variable",
          render: () => (
            <Tab.Pane>
              <p className="fs-1125">Search over existing variables or create a custom variable.</p>
              <CustomAutoSuggest fieldMeta={fieldMeta} rawOptions={qsVariables} resetForm={resetForm} submitForm={handleSubmit} />
            </Tab.Pane>
          ),
        },
      ]}
    />
  );
};
