import * as React from "react";
import { CustomFormHeading, CustomFormSelect, CustomFormSelectWrapper, CustomFormTextarea } from "./includes/CustomFormDropdown.style";

const AGENCY_AND_DEPARTMENT_OPTIONS = ["Select", "Department for Education", "Children and Young People", "Department of Health", "Department of Justice", "Department of Police", "Fire and Emergency Management", "Department of Premier and Cabinet", "Department of Natural Resources and Environment Tasmania", "Department of State Growth", "Department of Treasury and Finance", "Brand Tasmania", "Integrity Commission Tasmania", "Tasmanian Audit Office", "Tourism Tasmania", "Other"];

export const CustomFormDropdown = (props): JSX.Element => {
  const { title } = props;
  const [showTextarea, setShowTextarea] = React.useState(false);
  const [value, setValue] = React.useState<any>("");

  return (
    <>
      <CustomFormSelectWrapper>
        <CustomFormSelect
          aria-label="Government Agency/Department"
          defaultValue="Select"
          onChange={(e) => {
            if (e.target.value === "Other") {
              setShowTextarea(true);
              setValue("");
            } else {
              setShowTextarea(false);
              setValue(e.target.value); // Set the value for the textarea below, so "PrintResponseEventInjection" can always get the response for "Government Agency/Department" at one place
            }
          }}
        >
          {AGENCY_AND_DEPARTMENT_OPTIONS.map(v => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </CustomFormSelect>
      </CustomFormSelectWrapper>
      <CustomFormHeading id="other" className={showTextarea ? "d-block" : "d-none"}>*If other, please type your answer here:</CustomFormHeading>
      <CustomFormTextarea
        aria-labelledby="other"
        className={`seer-custom-form-field ${showTextarea ? "d-block" : "d-none"}`}
        title={title}
        placeholder="Your answer"
        rows="1"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
};
